import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AppDropDown(props) {
  const [defaultValue, setDropdownValue] = React.useState(props.defaultValue);
  const handleChange = (event) => {
    let thisvalue = event.target.value;
    console.log(thisvalue);
    let id = props.id;
    setDropdownValue(thisvalue);
    if(props.handleChangeData){
      props.handleChangeData(id,thisvalue);
    }else if(props.handleChangeClass){
      
      let cls_list = props.list_item;
      let secLists = [];
      for (let index = 0; index < cls_list.length; index++) {
        const element = cls_list[index];
        const clsid = element.key;
        if(parseInt(clsid)===parseInt(thisvalue)){
          let sec_list = element.sections;
          secLists = sec_list;
        }
      }
      props.handleChangeClass(id,thisvalue,secLists);
    }else if(props.handleChangeSubject){
      let sub_list = props.list_item;
      
      let subLists = [];
      for (let index = 0; index < sub_list.length; index++) {
        const element = sub_list[index];
        const subid = element.key;
        if(parseInt(subid)===parseInt(thisvalue)){
          let sub_list = element.sections;
          subLists = sub_list;
        }
      }
      props.handleChangeSubject(id,thisvalue,subLists);
    }else if(props.handleChangeUniform){
      let ulist = props.list_item;
      let l_item = [];
      for (let index = 0; index < ulist.length; index++) {
        const element = ulist[index];
        const clsid = element.key;
        if(parseInt(clsid)===parseInt(thisvalue)){
          let sec_list = element;
          l_item = sec_list;
        }
      }
      props.handleChangeUniform(id,thisvalue,l_item);
    }
  };

  return (
    
      <FormControl fullWidth required={(props.required!==undefined && props.required===true)?true:false}>
        <InputLabel id={props.id+"-select-label"}>{props.label}</InputLabel>
        <Select
          labelId={props.id+"-select-label"}
          id={props.id+"-select"}
          value={defaultValue}
          label={props.label}
          onChange={handleChange}
          size={props.size}
          inputProps={{className:props.className}}
          disabled={(props.disabled===true)?true:false}
        >
            {(props.list_item.map((value,idx)=>{
                return (
                    <MenuItem value={value.key}>{value.value}</MenuItem>
                )
            }))}
        </Select>
      </FormControl>
    
  );
}