import { Box, Button, ButtonGroup, Dialog, Divider, Grid, ListItemButton, ListItemIcon, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Cancel, Check, CheckCircle, ChevronRight, Close, Edit, Error, Print, PrintSharp } from '@mui/icons-material';
import ExamTypes from '../settings/exam/ExamTypes';
import Classes from '../settings/classes/Classes';
import PrintMarksheet from './PrintMarksheet';
import AppUtils from '../../AppUtils';
import PrintMarksAsList from './PrintMarksAsList';
import ScheduledExamDD from './ScheduledExam';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
     
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
export default function MarksEntry(props) {
    const state = useSelector((state) => state);
    const [selected_index,setSelectedStudentIndex] = React.useState('');
    const [selected_student_id,setSelectedStudentId] = React.useState('');
    const [is_exam_type_selected,setIsExamType] = React.useState(false);
    const [exam_type,setExamType] = React.useState(0);
    const [exam_type_name,setExamTypeName] = React.useState('');
    const [is_class_selected,setIsClass] = React.useState(false);
    const [is_clicked_proceed,setIsClickedProceed] = React.useState(false);
    const [is_clicked_student,setIsClickedStudent] = React.useState(false);
    const [is_save_marks,setIsSaveMarks] = React.useState(false);
    const [is_print,setIsPrint] = React.useState(false);
    const [is_print_list,setIsPrintList] = React.useState(false);
    const [className,setClass] = React.useState(0);
    const [studentsList,setStudentsList] = React.useState([]);
    const [routineData,setRoutineData] = React.useState([]);
    const [marksData,setMarksData] = React.useState({});
    const [schoolInfo,setSchoolInfo] = React.useState([]);
    const [printData,setPrintData] = React.useState([]);
    let [divisions,setDivisions] = React.useState([]);    
    let [grades,setGrades] = React.useState([]);    

    let [division_name,setDivisionName] = React.useState('');    
    let [grade_name,setGradeName] = React.useState('');    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            APP_HTTP.REQUEST('settings/get_divisions',{},true,true).then((response) => {
                let resp = response.data;
                let dvsns = resp.response;
                setDivisions(dvsns);
            });
            APP_HTTP.REQUEST('settings/get_grades',{},true,true).then((response) => {
                let resp = response.data;
                let grds = resp.response;
                setGrades(grds);
            });
        }
    },[])
   
    const handleChangeExamType = (id,value) =>{
        setExamType(value);
        if(value>0){
            setIsExamType(true);
        }else{
            setIsExamType(false);
        }
    }
   
   const handleSelectClass = (id,value) =>{
        setClass(value);
        if(value>0){
            setIsClass(true);
        }else{
            setIsClass(false);
        }
   }
   const handleShowStudents = () =>{
    setSelectedStudentIndex('');
    setSelectedStudentId('');
    setStudentsList([]);
    setIsClickedProceed(true);
    let send_data = {
        scheduled_exam_id : exam_type,
        order_by :'section_id',
        type :'marksheet',
      };
      APP_HTTP.REQUEST('student/get_students_on_params',send_data,true,true).then((response) => {
         let students = response.data.response;
         setStudentsList(students);
         setIsClickedProceed(false);
      });
   }
   const handleSelectStudent = (index,id) =>{
    let stinfo = [...studentsList];
    if(stinfo[index]){
        if(stinfo[index].grade){
            setGradeName(stinfo[index].grade);
        }
        if(stinfo[index].division){
            setDivisionName(stinfo[index].division);
        }
    }
    setSelectedStudentIndex(index);
    setSelectedStudentId(id);
    setIsClickedStudent(true);
    let send_data = {
        exam_type : exam_type,
        student_id : id,
        type:'marks_entry'
      };
      APP_HTTP.REQUEST('exam/get_scheduled_exam_on_id',send_data,true,true).then((response) => {
          let resp = response.data.all.sorted_schedules;
          let school_info = response.data.all.school_info;
          let ex_type_name = response.data.all.exam_type_name;
          setExamTypeName(ex_type_name);
          setRoutineData(resp);
          setSchoolInfo(school_info);
          setIsClickedStudent(false);
      });
   }

   const handleSetMarks = (index,id,value) =>{
        let routData = [...routineData];
        
        let mData = marksData;  
        if(mData[selected_student_id]===undefined){
            mData[selected_student_id] = {};
            if(mData[selected_student_id][id]===undefined){
                mData[selected_student_id][id] ={};
            }
        }else if(mData[selected_student_id][id]===undefined){
            mData[selected_student_id][id] ={};
        }
        mData[selected_student_id][id] = value;
        let theory_marks = 0;
        let total_marks = 0;
        if(value!=='' && parseFloat(value)>0){
            total_marks = parseFloat(value);
        }
        if(routData[index]!==undefined && routData[index].assignment_marks!==undefined && parseFloat(routData[index].assignment_marks)>0){
            total_marks = total_marks + parseFloat(routData[index].assignment_marks);
        }
        routData[index].total_marks = total_marks;
        setRoutineData(routData);
        setMarksData(mData);
        let qs = document.querySelectorAll('.obtained-marks');
        
        handleMarksCalculation(routData);
   }
   const handleMarksCalculation = (qs)=>{
        let empty_count = 0;
        let total_marks_obtained = 0;
        for (let index = 0; index < qs.length; index++) {
            const element = qs[index];
            if(element.total_marks==="" || element.total_marks===0){
                empty_count = empty_count+1;
            }else{
                if(!isNaN(element.total_marks)){
                    total_marks_obtained = total_marks_obtained + parseFloat(element.total_marks);
                }
            }
        }
        let stinfo = [...studentsList];
        if(empty_count===0){
            stinfo[selected_index].marksheet_status = 3;
        }else if(empty_count===qs.length){
            stinfo[selected_index].marksheet_status = 1;
        }else{
            stinfo[selected_index].marksheet_status = 2;
        }
        setStudentsList(stinfo);
        
        // MARKS CALCULATION

        let total_full_marks = 0;
        for (let index = 0; index < routineData.length; index++) {
            const rdata = routineData[index];
            let sub_id = rdata.id;
            let is_active = rdata.active;
            
            if(is_active===true){
                total_full_marks = total_full_marks + parseFloat(rdata.full_marks);
            }
        }
        let cal_marks = (total_marks_obtained / total_full_marks)*100;
        
        let div = 'N/A';
        for (let index = 0; index < divisions.length; index++) {
            const element = divisions[index];
            let greater_than = parseFloat(element.greater_than);
            let less_than = parseFloat(element.less_than);
            if(cal_marks>=greater_than && cal_marks<less_than){
                let div_name = element.name;
                div = div_name;
                break;
            }
        }
        setDivisionName(div);

        let grade = 'N/A';
        
        for (let index = 0; index < grades.length; index++) {
            const element = grades[index];
            let greater_than = parseFloat(element.greater_than);
            let less_than = parseFloat(element.less_than);
            if(cal_marks>=greater_than && cal_marks<less_than){
                let grade_name = element.name;
                grade = grade_name;
                break;
            }
        }
        setGradeName(grade);
   }
   const handleSaveMarks = ()=>{
    let stinfo = [...studentsList];
    setIsSaveMarks(true);
    let mData = marksData[selected_student_id];
    let send_data = {
        exam_type : exam_type,
        class_id : className,
        student_id : selected_student_id,
        marks:mData,
        subjectMarks : routineData
      };
      APP_HTTP.REQUEST('exam/save_marks',send_data,true,true).then((response) => {
        setIsSaveMarks(false);
        let next_index = selected_index+1;
        if(response.data.response.marks!==undefined){
            let stMarks = response.data.response.marks;
            let grade = response.data.response.grade;
            let division = response.data.response.division;
            let selSt = stinfo[selected_index];
            selSt.marks = stMarks;
            selSt.grade = grade;
            selSt.division = division;
            stinfo[selected_index] = selSt; 
            setStudentsList(stinfo);
        }
        if(stinfo[next_index]!==undefined){
            let next_st_id = stinfo[next_index].student_id;
            handleSelectStudent(next_index,next_st_id);
        }
      });
   }
   const handlePrintMarksheet = () =>{
    let stuInfo = studentsList[selected_index];
    let pdata = {
        school_info: stuInfo.school_info,
        studentData: [stuInfo],
        exam_type_name : exam_type_name
    }
    setPrintData(pdata);
    setIsPrint(true);
    setTimeout(() => {
        setIsPrint(false);
    }, 1500);
   }
   const handlePrintAllMarksheet = () =>{
    let stuInfo = studentsList;
    let pdata = {
        school_info: stuInfo[0].school_info,
        studentData: stuInfo,
        exam_type_name : exam_type_name
    }
    setPrintData(pdata);
    setIsPrint(true);
    setTimeout(() => {
        setIsPrint(false);
    }, 1500);
   }
   const handlePrintAsList = () =>{
        let stuInfo = studentsList;
        let subject_marks = stuInfo[0].marks;
        let subjects = [];
        for (let si = 0; si < subject_marks.length; si++) {
            const element = subject_marks[si];
            let sub_item = {name:element.name,date:element.exam_date_display}
            subjects.push(sub_item);
        }
        let class_name = stuInfo[0].class;
    
        let pdata = {
            class_name : class_name,
            subjects : subjects,
            school_info: stuInfo[0].school_info,
            studentData: stuInfo,
            exam_type_name : exam_type_name
        }
        
        setPrintData(pdata);
        setIsPrintList(true);
        setTimeout(() => {
            setIsPrintList(false);
        }, 1500);
   }
   const handleModifySubjectMarks = (index,value,type,id)=>{
        let routData = [...routineData];
        routData[index][type] = value;
        let mData = marksData;  
        let theory_marks = 0;
        if(mData[selected_student_id]!==undefined && type==='assignment_marks'){
            if(mData[selected_student_id][id]!==undefined && parseFloat(mData[selected_student_id][id])>0){
                theory_marks = parseFloat(mData[selected_student_id][id]);
            }
            let assignment_marks = value;
            if(assignment_marks===''){
                assignment_marks = 0;
            }
            assignment_marks = parseFloat(assignment_marks);
            let total_marks = theory_marks + assignment_marks;
            routData[index]['total_marks'] = total_marks;
        }
        setRoutineData(routData);
        handleMarksCalculation(routData);
   }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>MARKS ENTRY FOR STUDENTS</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Enter student exam marks by choosing the following filter</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                <ScheduledExamDD handleChangeData={handleChangeExamType}/>
                </Grid>
              {/*   <Grid item xs={12} sm={12} md={3} spacing={2}>
                    {(is_exam_type_selected) &&
                        <Classes show="dropdown" className="student-data" handleChangeData={handleSelectClass} defaultValue={className}/>
                    }
                </Grid> */}
                {(AppUtils.has_access(state,'10_subject_marks_entry_view') || AppUtils.has_access(state,'10_subject_marks_entry_add') || AppUtils.has_access(state,'10_subject_marks_entry_print') || AppUtils.has_access(state,'10_subject_marks_entry_edit')) &&
                <Grid item xs={12} sm={12} md={1} spacing={2}>
                    {(is_exam_type_selected===true) &&
                        <>
                            {(is_clicked_proceed===false) &&
                                <Button variant='outlined' size='medium' onClick={handleShowStudents}>Proceed</Button>
                            }
                            {(is_clicked_proceed===true) &&
                                <LoaderButton
                                variant='outlined' size='medium'/>
                            }
                        </>
                    }
                </Grid>
                }
                {(AppUtils.has_access(state,'10_subject_marks_entry_print')) &&
                <>
                {(studentsList.length>0) &&
                <>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <Button variant='outlined' size='small' onClick={handlePrintAsList} sx={{float:'right'}}>
                        <PrintSharp/>&nbsp;&nbsp;Print as List
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                    <Button variant='outlined' size='small' onClick={handlePrintAllMarksheet} sx={{float:'right'}}>
                        <PrintSharp/>&nbsp;&nbsp;Print All Report Card
                    </Button>
                </Grid>
                </>
                }
                </>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                
                <Grid item xs={12} sm={12} md={4} spacing={2}>
                    {(is_clicked_proceed===true && studentsList.length===0) &&
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                        <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                        <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                        <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                        <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                        <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                    </Stack>
                    }
                    {(studentsList.length>0) &&
                        <>
                            <h3 style={{margin:'0px'}}>STUDENTS LIST</h3>
                            <p style={{margin:'0px',fontSize:'14px'}}>Select student to enter their marks</p>
                            <Divider />
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {(studentsList.map((value,index)=>{
                                    return (
                                        <>
                                            <ListItem key={value.student_name+''+index} disablePadding  onClick={() => handleSelectStudent(index,value.student_id)} selected={(selected_index===index)?true:false}>
                                            <ListItemButton>
                                                    <ListItemAvatar>
                                                        {(value.marksheet_status===1) &&
                                                            <Cancel sx={{color:'red'}}/>
                                                        }
                                                        {(value.marksheet_status===2) &&
                                                            <Error sx={{color:'orange'}}/>
                                                        }
                                                        {(value.marksheet_status===3) &&
                                                            <CheckCircle sx={{color:'green'}}/>
                                                        }
                                                    </ListItemAvatar>
                                                    <ListItemText primary={value.student_name} secondary={
                                                        <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                          {value.roll_number+' '+value.class+'('+value.section+')'}
                                                        </Typography>
                                                       
                                                        </React.Fragment>
                                                    }/>
                                                   
                                                    <ListItemIcon>
                                                        <ChevronRight />
                                                    </ListItemIcon>
                                            </ListItemButton>
                                         
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    )
                                }))}
                            
                            </List>
                        </>
                    }
                </Grid>
                
                <Grid item xs={12} sm={12} md={8} spacing={2}>
                {(is_clicked_student===false) &&
                <>
                {(routineData && routineData.length>0 && studentsList[selected_index]) &&
                    <>
                    <h3 style={{margin:'0px',textTransform:'uppercase'}}>MARKS ENTRY FOR {(studentsList[selected_index] && studentsList[selected_index].student_name)?studentsList[selected_index].student_name:''}
                    {(AppUtils.has_access(state,'10_subject_marks_entry_print')) &&
                    <>
                        {(studentsList[selected_index].marksheet_status!==1) &&
                            <Button variant='outlined' size='medium' onClick={handlePrintMarksheet} sx={{float:'right'}}>
                                <PrintSharp/>&nbsp;&nbsp;Print Report Card
                            </Button>
                        }
                    </>
                    }
                    </h3>
                    <p style={{margin:'0px',fontSize:'14px'}}>Enter subject marks for the student in following fields.</p>
                    <Divider />
                    <TableContainer component={Paper} sx={{marginTop:'10px'}}>
                    <Table aria-label="customized table">
                      <TableHead sx={{background:'rgba(25, 118, 210, 0.08)'}}>
                        <TableRow>
                          <StyledTableCell sx={{fontWeight:'bold'}}>SUBJECT</StyledTableCell>
                          <StyledTableCell sx={{fontWeight:'bold'}}>FULL MARKS</StyledTableCell>
                          <StyledTableCell sx={{fontWeight:'bold'}}>PASS MARKS</StyledTableCell>
                          <StyledTableCell sx={{fontWeight:'bold'}}>ASSIGNMENT MARKS</StyledTableCell>
                          <StyledTableCell sx={{fontWeight:'bold'}}>THEORY MARKS</StyledTableCell>
                          <StyledTableCell sx={{fontWeight:'bold'}}>MARKS OBTAINED</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {routineData && routineData.map((value,index)=>{
                            return (
                                <>
                                    {(value.active===true) &&
                                    <StyledTableRow>
                                        <StyledTableCell>
                                        <Typography>
                                        {value.name}
                                        </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        <TextField
                                            label="Full Marks"
                                            size="small"
                                            fullWidth
                                            inputProps={{className:"full-marks"}}
                                            value={value.full_marks}
                                            onChange={(e)=>handleModifySubjectMarks(index,e.target.value,'full_marks',value.id)}
                                        />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        <TextField
                                            label="Pass Marks"
                                            size="small"
                                            fullWidth
                                            inputProps={{className:"pass-marks"}}
                                            value={value.pass_marks}
                                            onChange={(e)=>handleModifySubjectMarks(index,e.target.value,'pass_marks',value.id)}
                                        />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        <TextField
                                            label="Assignment Marks"
                                            size="small"
                                            fullWidth
                                            inputProps={{className:"pass-marks"}}
                                            value={value.assignment_marks}
                                            onChange={(e)=>handleModifySubjectMarks(index,e.target.value,'assignment_marks',value.id)}
                                        />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        <TextField
                                        label="Theory Obtained"
                                        size="small"
                                        fullWidth
                                        inputProps={{className:"obtained-marks"}}
                                        value={(marksData[selected_student_id]!==undefined && marksData[selected_student_id][value.id]!==undefined)?marksData[selected_student_id][value.id]:value.marks_obtained}
                                        onChange={(event)=>handleSetMarks(index,value.id,event.target.value)}
                                        />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Typography>{(value.total_marks!==undefined)?value.total_marks:0}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    }
                                </>
                            )
                        })}
                        <StyledTableRow>
                            <StyledTableCell colSpan={5}>
                                {(division_name!=="") &&
                                    <Typography variant="span">Division : {division_name}</Typography>
                                }
                                {(grade_name!=="") &&
                                    <Typography variant="span" sx={{marginLeft:'10px'}}>Grade : {grade_name}</Typography>
                                }
                            </StyledTableCell>
                            {(AppUtils.has_access(state,'10_subject_marks_entry_add') || AppUtils.has_access(state,'10_subject_marks_entry_edit')) &&
                            <StyledTableCell sx={{textAlign:'right'}}>
                                {(is_save_marks===false) &&
                                    <Button variant='outlined' size='medium' onClick={handleSaveMarks}>Save</Button>
                                }
                                {(is_save_marks===true) &&
                                    <LoaderButton
                                    variant='outlined' size='medium'/>
                                }
                            </StyledTableCell>
                            }
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                    </TableContainer>
                    </>
                }           
                </>   
                }     
                {(is_clicked_student===true) &&
                     <Stack spacing={1}>
                     <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                     <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                     <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                     <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                 </Stack>
                }
                </Grid>
            </Grid>
        </Box>
        {(is_print===true) &&
            <PrintMarksheet data={printData}/>
        }
        {(is_print_list===true) &&
            <PrintMarksAsList data={printData}/>
        }
    </Box>
  );
}