export const CHANGE_MENU = "CHANGE_MENU";
export const CREATE_CLASS = "CREATE_CLASS";
export const UPDATE_CLASS = "UPDATE_CLASS";
export const OPEN_ADD_CLASS = "OPEN_ADD_CLASS";
export const DELETE_CLASS = "DELETE_CLASS";
export const SET_CLASS_LIST = "SET_CLASS_LIST";
export const DELETE_ITEM_CONFIRM = "DELETE_ITEM_CONFIRM";
export const CREATE_SECTION = "CREATE_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const OPEN_ADD_SECTION = "OPEN_ADD_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
export const OPEN_ADD_SUBJECT = "OPEN_ADD_SUBJECT";
export const CREATE_SUBJECT = "CREATE_SUBJECT";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const OPEN_ADD_STAFF_TYPE = "OPEN_ADD_STAFF_TYPE";
export const CREATE_STAFF_TYPE = "CREATE_STAFF_TYPE";
export const UPDATE_STAFF_TYPE = "UPDATE_STAFF_TYPE";
export const DELETE_STAFF_TYPE = "DELETE_STAFF_TYPE";
export const SET_SECTION_LIST = "SET_SECTION_LIST";
export const SET_SUBJECT_LIST = "SET_SUBJECT_LIST";
export const SET_STAFF_TYPE_LIST = "SET_STAFF_TYPE_LIST";
export const SET_STUDENTS_LIST = "SET_STUDENTS_LIST";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const IS_STUDENT_EDIT = "IS_STUDENT_EDIT";
export const SET_STUDENT_INFO = "SET_STUDENT_INFO";
export const IS_STUDENT_VIEW = "IS_STUDENT_VIEW";
export const SET_STAFFS_LIST = "SET_STAFFS_LIST";
export const DELETE_STAFF = "DELETE_STAFF";
export const IS_STAFF_EDIT = "IS_STAFF_EDIT";
export const SET_STAFF_INFO = "SET_STAFF_INFO";
export const IS_STAFF_VIEW = "IS_STAFF_VIEW";
export const CREATE_PAYMENT_TYPE = "CREATE_PAYMENT_TYPE";
export const UPDATE_PAYMENT_TYPE = "UPDATE_PAYMENT_TYPE";
export const OPEN_ADD_PAYMENT_TYPE = "OPEN_ADD_PAYMENT_TYPE";
export const DELETE_PAYMENT_TYPE = "DELETE_PAYMENT_TYPE";
export const SET_PAYMENT_TYPE_LIST = "SET_PAYMENT_TYPE_LIST";

export const SET_PAYMENTS_LIST = "SET_PAYMENTS_LIST";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const IS_PAYMENT_EDIT = "IS_PAYMENT_EDIT";
export const SET_PAYMENT_INFO = "SET_PAYMENT_INFO";
export const IS_PAYMENT_VIEW = "IS_PAYMENT_VIEW";
export const IS_OPEN_CREATE_DEMAND_BILL = "IS_OPEN_CREATE_DEMAND_BILL";

export const OPEN_ADD_EXAM_TYPE = "OPEN_ADD_EXAM_TYPE";
export const CREATE_EXAM_TYPE = "CREATE_EXAM_TYPE";
export const UPDATE_EXAM_TYPE = "UPDATE_EXAM_TYPE";
export const DELETE_EXAM_TYPE = "DELETE_EXAM_TYPE";
export const SET_EXAM_TYPE_LIST = "SET_EXAM_TYPE_LIST";
export const DELETE_SCHEDULED_EXAM = "DELETE_SCHEDULED_EXAM";

export const OPEN_ADD_CLASS_ROOM = "OPEN_ADD_CLASS_ROOM";
export const CREATE_CLASS_ROOM = "CREATE_CLASS_ROOM";
export const UPDATE_CLASS_ROOM = "UPDATE_CLASS_ROOM";
export const DELETE_CLASS_ROOM = "DELETE_CLASS_ROOM";
export const SET_CLASS_ROOM_LIST = "SET_CLASS_ROOM_LIST";

export const SET_TRANSPORTS_LIST = "SET_TRANSPORTS_LIST";
export const DELETE_TRANSPORT = "DELETE_TRANSPORT";
export const IS_TRANSPORT_EDIT = "IS_TRANSPORT_EDIT";
export const SET_TRANSPORT_INFO = "SET_TRANSPORT_INFO";
export const IS_TRANSPORT_VIEW = "IS_TRANSPORT_VIEW";
export const SET_ACCESS_CHECK = "SET_ACCESS_CHECK";
export const CHECK_ACCESS = "CHECK_ACCESS";

export const OPEN_ADD_HOLIDAY = "OPEN_ADD_HOLIDAY";
export const CREATE_HOLIDAY = "CREATE_HOLIDAY";
export const UPDATE_HOLIDAY = "UPDATE_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const SET_HOLIDAY_LIST = "SET_HOLIDAY_LIST";
export const SET_SCHOOL_INFO = "SET_SCHOOL_INFO";
export const OPEN_UPDATE_STUDENT_FEE_STRUCTURE = "OPEN_UPDATE_STUDENT_FEE_STRUCTURE";
export const SET_BULK_DEMAND_BILL_PRINT = "SET_BULK_DEMAND_BILL_PRINT";
export const SPANEL_PATH = "/spanel";