import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let printDataInitialState = {
    
}
let total_pending = 0;
const PendingPaymentXls = (props)=> {
    let [printData,setprintData] = React.useState(printDataInitialState);

    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
        }
       
    },[props])
   
  return (
    <>
    <ReactHTMLTableToExcel
        id="pending-table-xls-button"
        className="download-xls-button"
        table="pending-xls-list-table"
        filename="pending-list"
        sheet="pending-list"
        buttonText="Download XLS"
    />
    <div style={{display:'none'}} >
        <table id="pending-xls-list-table">
            <tbody>
                <tr>
                    <th colSpan={7} style={{textAlign:'center'}}>PENDING PAYMENT LIST</th>
                </tr>       
                <tr>
                    
                    <th style={{textAlign:'left'}}>ROLL NUMBER</th>
                    <th style={{textAlign:'left'}}>NAME</th>
                    <th style={{textAlign:'left'}}>FATHER NAME</th>
                    <th style={{textAlign:'left'}}>CLASS</th>
                    <th style={{textAlign:'left'}}>MOBILE</th>
                    <th style={{textAlign:'left'}}>LAST PAYMENT</th>
                    <th style={{textAlign:'right'}}>AMOUNT</th>
                </tr>            
                {props.printData.response && props.printData.response.map((value,index)=>{
                    return (
                        <tr>
                       
                        <td style={{textAlign:'left'}}>
                            <p>{value.student_info.roll_number}</p>
                        </td>
                        <td style={{textAlign:'left',whiteSpace: 'nowrap',textTransform:'uppercase'}}>
                            
                                {value.student_info.student_name}
                        </td>
                        <td style={{textAlign:'left',whiteSpace: 'nowrap',textTransform:'uppercase'}}>
                           
                            <p style={{marginTop:'5px',fontSize:'14px'}}>
                                {value.student_info.father_name}
                            </p>
                        </td>
                        <td style={{textAlign:'left',whiteSpace: 'nowrap',textTransform:'uppercase'}}>
                            <p style={{margin:'0px'}}>
                                <span style={{fontSize:'14px'}}>{value.student_info.class}({value.student_info.section})</span>
                            </p>
                           
                        </td>
                        <td style={{textAlign:'left',whiteSpace: 'nowrap'}}>
                            <p>
                                 {value.student_info.mobile_number}
                                    {(value.student_info.alt_mobile_number!=="") &&
                                    <>
                                    | {value.student_info.alt_mobile_number}
                                    </>
                                    }
                                    </p>
                            
                        </td>
                        <td style={{textAlign:'left'}}>
                            {(value.latest_payment_info!==undefined && value.latest_payment_info[0]!==undefined && value.latest_payment_info[0].display_date!==undefined) &&
                            <>
                                <p style={{margin:'0px'}}>
                                    {value.latest_payment_info[0].payment_sequence} ({value.latest_payment_info[0].display_date})
                                </p>
                            </>
                            }
                        </td>      
                        <td style={{textAlign:'right'}}>₹{AppUtils.currency_format(value.total_pending_amount)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div>
    </>
  );
}
export default PendingPaymentXls;