import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Divider, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { deepOrange, deepPurple } from '@mui/material/colors';
import AppPagination from '../AppPagination';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import DeleteConfirm from '../DeleteConfirm';
import { handleConfirmDeleteTransport, handleSetTransportsList, handleToggleEditTransport,handleSetTransportInfo, handleToggleViewTransport } from './Action';
import CreateTransport from './CreateTransport';
import { handleChangeMenu } from '../../actions/Action';
import AppUtils from '../../AppUtils';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TransportList = () => {
  const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
  
  const [transport_data,setTransportData] = React.useState([]);
  const state = useSelector((state) => state);
  const is_delete_item = state.is_delete_item;
  const transport_list = state.transports_list;
  const [number_of_page,setNumberOfPage] = React.useState(0);
  const [total_records,setTotalRecords] = React.useState(0);
  const [is_loading_list,setIsLoadingList] = React.useState(true);
  
  
  const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            let current_path = window.location.pathname;
            let numberPattern = /\d+/g;
            let get_page = current_path.match( numberPattern );
            if(get_page===null){
              handleLoadTransportList(1)
            }
        }
    },[])
    const handleLoadTransportList = (page) =>{
      setIsLoadingList(true);
      dispatch(handleSetTransportsList([]));
      APP_HTTP.REQUEST('transport/get_transports',{page:page,total_records:total_records},true,true).then((response) => {
          let resp = response.data;
          let stf_list = resp.response;
          let tot_record = resp.total_record;
          let no_of_page = resp.number_of_page;
          setTotalRecords(tot_record);
          setNumberOfPage(no_of_page);
        
          dispatch(handleSetTransportsList(stf_list));
          setIsLoadingList(false);
          setTransportData(resp);
      });
    }
    const handleNavigatePage = (page) =>{
      handleLoadTransportList(page);
    }

    const handleDeleteItem = (id)=> {
      let transportValue = transport_list[id];
      let transport_id = transportValue.transport_id;
      
      let delData = {id:transport_id,index:id,target:'transport'};
      setDeleteData(delData);
      dispatch(handleConfirmDeleteTransport(true));
  }
  const handleEditItem = (id)=>{
    let transport_info = transport_list[id];
    let transport_id = transport_info.transport_id;
    dispatch(handleChangeMenu('transport','edit/'+transport_id,'Update Transport',false));
    dispatch(handleToggleEditTransport(true));
    dispatch(handleSetTransportInfo(transport_info));
    //setTransportData(transport_info)
  }
  /* const handleViewItem = (id)=>{
    let transport_info = transport_list[id];
    let transport_id = transport_info.transport_id;
    dispatch(handleChangeMenu('transport','view/'+transport_id,'Transport Profile',false));
    dispatch(handleToggleViewTransport(true));
    dispatch(handleSetTransportInfo(transport_info));
    //setTransportData(transport_info)
  } */
  const handleSearch = (e)=>{
    let thisvalue = e.currentTarget.value;
    if(thisvalue!==''){
      if(thisvalue.length<2){
        return false; 
      }
      setIsLoadingList(true);
      dispatch(handleSetTransportsList([]));
      APP_HTTP.REQUEST('transport/search_transport',{search:thisvalue},true,true,false,true).then((response) => {
            if(response.data){

              let resp = response.data;
              let stf_list = resp.response;
              let tot_record = resp.total_record;
              let no_of_page = resp.number_of_page;
              setTotalRecords(tot_record);
              setNumberOfPage(no_of_page);
              
              dispatch(handleSetTransportsList(stf_list));
              setIsLoadingList(false);
            
          }
          
      });
    }else{
      let resp = transport_data
      let stf_list = resp.response;
      if(stf_list){
        let tot_record = resp.total_record;
        let no_of_page = resp.number_of_page;
        setTotalRecords(tot_record);
        setNumberOfPage(no_of_page);
        
        dispatch(handleSetTransportsList(stf_list));
        setIsLoadingList(false);
      }
    }
  }
  return (
      <>
          <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            
              <Grid item xs={12} sm={12} md={3} spacing={2}>
              <TextField
                label="Search Transport"
                size="small"
                fullWidth
                onChange={handleSearch}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9} spacing={2}></Grid>
             
              <Divider />
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ROUTE NUMBER</StyledTableCell>
                        <StyledTableCell>ROUTE NAME</StyledTableCell>
                        <StyledTableCell>VENDOR</StyledTableCell>
                        <StyledTableCell>DRIVER</StyledTableCell>
                        <StyledTableCell>VEHICLE NUMBER</StyledTableCell>
                        <StyledTableCell>NUMBER OF SEATS</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transport_list && transport_list.map((row,index) => (
                        <StyledTableRow>
                          <StyledTableCell>
                          <Avatar sx={{ bgcolor: deepOrange[500] }}>{row.transport_number}</Avatar>
                          
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.transport_name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.transporter_name}
                          </StyledTableCell>
                          <StyledTableCell>{row.driver_name}</StyledTableCell>
                          <StyledTableCell>{row.vehicle_number}</StyledTableCell>
                          <StyledTableCell>
                            {row.number_of_seat} ({row.seat_m} X {row.seat_n})
                          </StyledTableCell>
                          
                          <StyledTableCell>
                          {(AppUtils.has_access(state,'11_transport_delete')) &&
                            <Delete fontSize='small' onClick={() =>handleDeleteItem(index)} sx={{cursor:'pointer'}}/>
                          }
                            {(AppUtils.has_access(state,'11_transport_edit')) &&
                            <Edit  fontSize='small' onClick={() =>handleEditItem(index)} sx={{cursor:'pointer'}}/>
                            }
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      {(is_loading_list===true) &&
                      <StyledTableCell component="th" scope="row" colSpan={6} sx={{textAlign:'center'}}>
                      Please wait while loading the list...
                      </StyledTableCell>
                      }   
                      {(is_loading_list===false && transport_list && transport_list.length===0) &&
                      <StyledTableCell component="th" scope="row" colSpan={6} sx={{textAlign:'center'}}>
                        Oops ! No Record(s) Found.
                      </StyledTableCell>
                      }   
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <AppPagination number_of_page={number_of_page} handleNavigatePage={handleNavigatePage} url={'/transports/page'}/>
              </Grid>
            </Grid>
            {(is_delete_item) &&
              <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
            }
          </Box>
      </>
  );
}

export default TransportList;