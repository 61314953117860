import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, ButtonGroup, Divider, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { handleChangeMenu } from '../../actions/Action';
import ViewInbox from './ViewInbox';
import { Reply } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AppInbox = (props) => {
    const initialized = React.useRef(false);
    const [view_id,setViewID] = React.useState(0);
    const [inbox_list,setInboxList] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isLoadMore,setIsLoadMore] = React.useState(false);
    const [isEmpty,setIsEmpty] = React.useState(false);
    const [list_page,setPage] = React.useState(1);
    const state = useSelector((state) => state);
    const main_menu = state.main_menu;
    
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handLoadInbox(list_page);
        }
    },[])
    const handLoadInbox = (page) =>{
      let inb_list = [...inbox_list];
      
      APP_HTTP.REQUEST('inbox/get_messages',{page:page},true,true).then((response) => {
          let resp = response.data;
          let in_list = resp.response;
          if(in_list.length===0){
            setIsEmpty(true);
          }
          for (let index = 0; index < in_list.length; index++) {
            const element = in_list[index];
            inb_list.push(element);
          }
          setInboxList(inb_list);
          setIsLoadMore(false);
      });
    }
    const handleViewMessage = (inbox_id) =>{
      setViewID(inbox_id);
    }
    const handleClose = () =>{
      setViewID(0);
    }
    const handleLoadMore = () =>{
      setIsLoadMore(true);
      let npage = list_page + 1;
      handLoadInbox(npage);
      setPage(npage);
    }
  return (
      <>
      {(AppUtils.has_access(state,'180_inbox_view')) &&
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
          
              <Divider />
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                 
                    <TableBody>
                        {(inbox_list.map((value,index)=>{
                          return(
                            <StyledTableRow sx={{cursor:'pointer'}} onClick={()=>handleViewMessage(value.inbox_id)}>
                              <StyledTableCell>
                                <Typography sx={{textTransform: 'uppercase',fontWeight: 700,color: 'gray',fontSize:'14px'}}>Message from {value.type}</Typography>
                                <Typography  sx={{fontWeight: 600,color: 'gray',fontSize:'14px'}}>
                                  From :  
                                  {(value.type==='parent') && 
                                  <> {value.contact_info.father_name} | {value.contact_info.student_sequence} | {value.contact_info.student_name} |  {value.contact_info.roll_number} {value.contact_info.class}({value.contact_info.section})
                                  </>
                                  }
                                  {(value.type==='student') && 
                                  <>
                                    {value.contact_info.student_sequence} | {value.contact_info.student_name} |  {value.contact_info.roll_number} {value.contact_info.class}({value.contact_info.section})
                                  </>
                                  }
                                  {(value.type==='staff') && 
                                  <>
                                    {value.contact_info.staff_name}
                                  </>
                                  }
                                </Typography>
                                <Typography  sx={{fontFamily:'cursive',color: '#333'}}>{value.message}</Typography>
                              </StyledTableCell>
                              
                              <StyledTableCell>
                                <Reply />
                              </StyledTableCell>
                              
                            </StyledTableRow>
                          )
                        }))}
                       
                        {(inbox_list.length===0 && isLoading===false) &&
                        <StyledTableRow>
                          <StyledTableCell colSpan={2} sx={{textAlign:'center'}}><Typography>Inbox is Empty</Typography></StyledTableCell>
                        </StyledTableRow>
                        }
                        {(inbox_list.length===0 && isLoading===true) &&
                        <StyledTableRow>
                          <StyledTableCell colSpan={2} sx={{textAlign:'center'}}><Typography>Loading...</Typography></StyledTableCell>
                        </StyledTableRow>
                        }
                        {(isLoadMore===true) &&
                        <StyledTableRow>
                          <StyledTableCell colSpan={2} sx={{textAlign:'center'}}>
                            <Stack spacing={1}>
                              <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                        }
                        {(inbox_list.length>0 && isLoading===false && isLoadMore==false && isEmpty===false) &&
                        <StyledTableRow>
                          <StyledTableCell colSpan={2} sx={{textAlign:'center'}}><Button variant='outlined' size='small' onClick={handleLoadMore}>Load More</Button></StyledTableCell>
                        </StyledTableRow>
                        }
                        
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {(view_id>0) &&
              <ViewInbox view_id = {view_id} handleClose={handleClose}/>
            }
          </Box>
        }
      </>
  );
}

export default AppInbox;