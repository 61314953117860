import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import APP_HTTP from '../../APP_HTTP';
import AppDropDown from '../AppDropDown';
import { Box, Divider, Grid, Typography } from '@mui/material';
const CreateUser = (props)=> {
  const [userInfo,setUserInfo] = React.useState({user_name:'',role_id:'',mobile:'',email:''});

  const [isUserExist,setUserExist] = React.useState(false);
  const [user_roles,setUserRoles] = React.useState([]);
  const initialized = React.useRef(false);
  React.useEffect(()=>{
      if (!initialized.current) {
          initialized.current = true;
          getRoles();
          if(props.data && props.data.user_name){
            setUserInfo(props.data);
          }
      }
  },[props])
  
  const getRoles = () =>{
    APP_HTTP.REQUEST('users/get_roles',{},true,true).then((response) => {
        let resp = response.data.response;
        let roles = [];
        for (let index = 0; index < resp.length; index++) {
          const element = resp[index];
          let item = {key:element.role_id,value:element.role_name};
          roles.push(item);
        }
        setUserRoles(roles);
    });
 }
  const handleClose = () => {
    props.handleClose();
  };
  const handleRefreshList = () => {
    props.handleRefreshList();
  };
  const handleSaveUser = () =>{
    APP_HTTP.REQUEST('users/save_user',{data:userInfo},true,true).then((response) => {
      let resp = response.data;
      if(resp.success===1){
        handleRefreshList();
        handleClose();
      }else{
        setUserExist(true);
      }
    });
  }
  const handleSetUserInfo = (key,value) =>{
    let uInfo = {...userInfo};
    uInfo[key] = value;
    setUserInfo(uInfo);
  }
  const handleChangeData = (key,value) =>{
    let uInfo = {...userInfo};
    uInfo['role_id'] = value;
    setUserInfo(uInfo);
  }
  return (
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle sx={{padding:'10px 25px'}}>Save User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="mrtop-10 mrbtm-30">
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                {(props.data && props.data.user_name) &&
                <>
                  <Typography variant='p' sx={{color:'rgba(0, 0, 0, 0.6)',fontSize:'12px'}}>Username</Typography><br />
                  <Typography variant='p'>{props.data.user_name}</Typography>
                </>
                }
                {(props.data && props.data.user_name===undefined) &&
                  <TextField
                    autoFocus
                    margin="dense"
                    id="user_name"
                    label="Enter User Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e)=>handleSetUserInfo('user_name',e.target.value)}
                    value={userInfo.user_name}
                  />
                }
                  {(isUserExist===true) &&
                    <Typography sx={{fontSize:'12px',color:'red'}}>User Name has been already taken, please try another one</Typography>
                  }
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                {(props.data && props.data.role_id && user_roles.length>0 && props.data.role_id>1) &&
                  <AppDropDown label="Select Role" id="user_role" defaultValue={userInfo.role_id} list_item={user_roles} size="small" className="roles_data" handleChangeData={handleChangeData}/>
                }
                {(props.data && props.data.role_id && user_roles.length>0 && parseInt(props.data.role_id)===1) &&
                  <>
                    <Typography variant='p'  sx={{color:'rgba(0, 0, 0, 0.6)',fontSize:'12px'}}>Role</Typography><br />
                    <Typography variant='p'>{props.data.role_name}</Typography>
                  </>
                }
                {(props.data && props.data.role_id === undefined) &&
                  <AppDropDown label="Select Role" id="user_role" defaultValue={userInfo.role_id} list_item={user_roles} size="small" className="roles_data" handleChangeData={handleChangeData}/>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="mobile_number"
                    label="Enter Mobie Number"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e)=>handleSetUserInfo('mobile',e.target.value)}
                    value={userInfo.mobile}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Enter Email"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e)=>handleSetUserInfo('email',e.target.value)}
                    value={userInfo.email}
                  />
              </Grid>
          </Grid>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSaveUser} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreateUser;