import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import { useSelector, useDispatch } from "react-redux";
import { handleConfirmDeleteExamType, handleOpenAddExamType,handleSetExamTypeList } from './Action.jsx';
import AppDropDown from '../AppDropDown.jsx';
import APP_HTTP from '../../APP_HTTP.jsx';

const ScheduledExamDD = (props) => {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const [scheduled_exam,setScheduledExam] = React.useState([]);
    const initialized = React.useRef(false);
    React.useEffect(()=>{
    
        if (!initialized.current) {
            initialized.current = true;
            APP_HTTP.REQUEST('exam/get_scheduled_exam',{},true,true).then((response) => {
                let resp = response.data;
                let sched_list = resp.response;
                let slist_arr=[{key:0,value:'Select Scheduled Exam'}];
                for (let index = 0; index < sched_list.length; index++) {
                    const element = sched_list[index].sched_dd_arr;
                    slist_arr.push(element);
                }
                setScheduledExam(slist_arr);
            });
        }

    },[])

    const handleChange = (id,value) =>{
        if(props.handleChangeData){
            props.handleChangeData(id,value);
        }
    }
   
  return (
    <AppDropDown label="Scheduled Exam" id="scheduled_exam" list_item={scheduled_exam} size="small" handleChangeData={handleChange} defaultValue={0}/>
  );
}
export default ScheduledExamDD;