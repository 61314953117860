import { OPEN_ADD_CLASS,CREATE_CLASS,UPDATE_CLASS, DELETE_ITEM_CONFIRM, DELETE_CLASS,SET_CLASS_LIST } from "../../../actionTypes/actionTypes";

const handleOpenAddClass = (is_open_add_class) =>{
  return {
    type: OPEN_ADD_CLASS,
    state :{
      is_open_add_class : is_open_add_class,
    }
  };
}
const handleSetClassList = (class_list) =>{
  return {
    type: SET_CLASS_LIST,
    state :{
      class_list : class_list,
    }
  };
}

const handleSaveClassToList = (class_list) =>{
  return {
    type: CREATE_CLASS,
    state :{
      class_list : class_list,
    }
  };
}
const handleUpdateClass = (update_id,name) =>{
  return {
    type: UPDATE_CLASS,
    state :{
      update_id : update_id,
      name : name
    }
  };
}
const handleConfirmDeleteClass = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteClass = (index, delete_data) =>{
  return {
    type: DELETE_CLASS,
    state :{
      id : index,
    }
  };
}

export { handleOpenAddClass, handleSaveClassToList,handleUpdateClass,handleConfirmDeleteClass,handleDeleteClass,handleSetClassList };