import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Avatar, Button, Checkbox, Divider, FormControlLabel, FormGroup, InputLabel, MenuItem, NativeSelect, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import LoaderButton from '../../LoaderButton.jsx';
import BasicDatePicker from '../../DatePicker.jsx';
import AppCheckbox from '../../AppCheckbox.jsx';
import Classes from '../classes/Classes.jsx';
import Sections from '../sections/Sections.jsx';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const BulkRollNumberAllocation = (props) => {
    const [selected_class,setClass] = React.useState(0);
  const [selected_section,setSection] = React.useState(0);
  const [student_list,setStudentList] = React.useState([]);
  const [is_loading,setIsLoading] = React.useState(false);
  const [is_saving,setIsSaving] = React.useState(false);
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
        }
    },[])
    const handleChangeClass = (id,value) =>{
        setClass(value);
   }
   const handleChangeSection = (id,value) =>{
        setSection(value);
   }
   const handleLoadStudents = ()=>{
        setStudentList([]);
        setIsLoading(true);
        APP_HTTP.REQUEST('student/search_student',{search:'',class:selected_class,section:selected_section},true,true,false,true).then((response) => {
            if(response.data){
              let resp = response.data;
              let stf_list = resp.response;
              setStudentList(stf_list);
            }
            setIsLoading(false);
      });
   }
  const handleChangeRollNumber = (value,index) =>{
    let st_list = [...student_list];
    st_list[index].roll_number = value;
    setStudentList(st_list);
  }
  const handleSaveRollNumber = () =>{
    setIsSaving(true);
    APP_HTTP.REQUEST('student/save_roll_number',{student_list:student_list},true,true,false,true).then((response) => {
        setIsSaving(false);
  });
  }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Bull Class Roll Number Allocation
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            {(selected_class>0) &&
                                <Sections show="dropdown" handleChangeData={handleChangeSection} defaultValue={selected_section}/>  
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            {(selected_section>0) &&
                                <>
                                    {(is_loading===false) &&
                                    <Button onClick={handleLoadStudents} variant='outlined' size='medium'>Go</Button>
                                    }
                                    {(is_loading===true) &&
                                        <LoaderButton variant='outlined' size='medium'/>
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Divider />
                        </Grid>
                    </Grid>
                    {(student_list.length>0) &&
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>NAME</StyledTableCell>
                        <StyledTableCell>ROLL NUMBER</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {student_list.map((row,index) => (
                        <StyledTableRow key={row.student_sequence}>
                          <StyledTableCell>
                          <Avatar
                            alt={row.student_name}
                            src={row.photo}
                          />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" >
                            {row.student_sequence} | {row.student_name}
                          </StyledTableCell>
                     
                          <StyledTableCell>
                          <TextField
                            onChange={(e)=>handleChangeRollNumber(e.target.value,index)}
                            label="Roll Number"
                            fullWidth
                            size='small'
                            defaultValue={row.roll_number}
                            />
                          </StyledTableCell>
                          
                        </StyledTableRow>
                      ))}
                      <StyledTableRow>
                        <StyledTableCell colSpan={3} style={{textAlign:'right'}}>
                        {(is_saving===false) &&
                        <Button onClick={handleSaveRollNumber} variant='outlined' size='medium'>Save</Button>
                        }
                        {(is_saving===true) &&
                            <LoaderButton variant='outlined' size='medium'/>
                        }
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </Box>
    </>
  );
}

export default BulkRollNumberAllocation;