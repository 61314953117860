import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
    {/*   {'Copyright © '}
      <Link color="inherit" href="https://scoogro.com">
        <Typography variant='span'>Scoo</Typography><Typography variant='span'>Gro</Typography>
      </Link>{' '}
      {new Date().getFullYear()} */}
    </Typography>
  );
}