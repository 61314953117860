import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddClassRoom,handleSaveClassRoomToList,handleUpdateClassRoom } from './Action';
import APP_HTTP from '../../../APP_HTTP';
let initialState = {id:0,name:'',is_edit:false};
const CreateClassRoom = (props)=> {
  const [classRoomItem,setClassRoomItem] = React.useState(initialState);
  const [classRoomName,setClassRoomName] = React.useState('');
  const state = useSelector((state) => state);
  const is_open_add_class_room = state.is_open_add_class_room;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(handleOpenAddClassRoom(false));
  };
  React.useEffect(() => {
      let class_roomValue = props.class_roomValue;
      setClassRoomItem(class_roomValue);
      setClassRoomName(class_roomValue.name);
  },[props]);
  const handleSaveClassRoom = () =>{
    let update_idx = 0;
    let update_id = 0;
    if(props.class_roomValue && props.class_roomValue.is_edit && props.class_roomValue.update_id>=0){
      update_idx = props.class_roomValue.update_id;
      update_id = props.class_roomValue.id;
      dispatch(handleUpdateClassRoom(update_idx,classRoomName));
    }
    APP_HTTP.REQUEST('settings/create_class_room',{id:update_id,name:classRoomName},true,true).then((response) => {
      let resp = response.data;
      let id = resp.id;
      if(update_id===0){
        classRoomItem.id = parseInt(id);
        dispatch(handleSaveClassRoomToList(classRoomItem));
      }
    });
    handleClose();
  }
  const handleSetClassRoomName = (e) =>{
    let thisvalue = e.currentTarget.value;
    let clsitem = classRoomItem;
    clsitem.name = thisvalue;
    setClassRoomItem(clsitem);
    setClassRoomName(thisvalue);
  }
  return (
      <Dialog open={is_open_add_class_room} onClose={handleClose}>
        <DialogTitle>Create Class Room</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="class_room_name"
            label="Enter Class Room Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetClassRoomName}
            value={classRoomName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSaveClassRoom} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreateClassRoom;