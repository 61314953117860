import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AppDropDown from '../AppDropDown';
import BasicDatePicker from '../DatePicker';
import AppUtils from '../../AppUtils';
import { Print } from '@mui/icons-material';
import PrintTransactions from './PrintTransactions';
import StudentCard from '../students/StudentCard';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  let login_info = JSON.parse(localStorage.getItem('MnzYs4pVGL'));
function Transaction(props) {
  const state = useSelector((state) => state);
   const [payment_type,setPaymentType] = React.useState('all');
   const [date_type,setDateType] = React.useState('transaction');
   let [contact_info,setContactInfo] = React.useState('');
   let [contact_list,setContactList] = React.useState([]);
   const [fromDate,setFromDate] = React.useState(new Date());
   const [toDate,setToDate] = React.useState(new Date());
   const [filterType,setFilterType] = React.useState('today');
   const [selection_type,setSelectionType] = React.useState('student');
   const [page,setPage] = React.useState(0);
   const [transaction_list,setTransactionList] = React.useState([]);
   const [total_transaction_amount,setTotalTransactionAmount] = React.useState(0);
   const [total_in_amount,setTotalInAmount] = React.useState(0);
   const [total_out_amount,setTotalOutAmount] = React.useState(0);
   const [cash_in_counter,setCashInCounter] = React.useState(0);
   const [user_list_id,setUserListID] = React.useState(0);
   const [isGenerating,setIsGenerating] = React.useState(false);
   const [is_print,setIsPrint] = React.useState(false);
   const [isLoadMore,setIsLoadMore] = React.useState(false);
   const [school_info,setSchoolInfo] = React.useState(false);
   const [paymentModes,setPaymentModes] = React.useState([]);
   const [paymentOutModes,setPaymentOutModes] = React.useState([]);
   const [usersList,setUsersList] = React.useState([]);
   const [isAllRecordLoaded,setIsAllRecordLoaded] = React.useState(false);
   const initialized = React.useRef(false);
   React.useEffect(()=>{
      if (!initialized.current) {
          initialized.current = true;
          if(AppUtils.has_access(state,'161_all_user_report_view')){
            handleGetAllUsers();
          }
      }
  },[])
  const handleGetAllUsers = ()=>{
    APP_HTTP.REQUEST('users/get_active_users',{},true,true,false,true).then((response) => {
      if(response.data!==null &&  response.data!==undefined && response.data.response!==undefined){
        let resp = response.data;
        let search_list = resp.response;
        let uinfo = [{key:0,value:'All Users'}];
        for (let index = 0; index < search_list.length; index++) {
          const element = search_list[index];
          uinfo.push(element);
        }
        setUsersList(uinfo);
      }
    });
  }
   const handleChangePaymentType = (id,value)=>{
    setPaymentType(value);
   }
   const handleChangeDateType = (id,value)=>{
    setDateType(value);
   }
   const handleSetUserID = (id,value)=>{
    setUserListID(value);
   }
   const handlePrintList = () =>{
    setIsPrint(true);
    setTimeout(() => {
      setIsPrint(false);
    }, 1500);
  }
   const handleChangeSelection = (id,value) =>{
    setSelectionType(value);
    setContactList([]);
   }
   const handleChangeFilterType = (id,value)=>{
    setFilterType(value);
   }
  
  const handleChangeFromDate = (id,date) =>{
    setFromDate(date);
  }
  const handleChangeToDate = (id,date) =>{
    setToDate(date);
  }
  const handleResetList = () =>{
    setTotalTransactionAmount(0);
    setTotalInAmount(0);
    setTotalOutAmount(0);
    setCashInCounter(0);
    setPage(0);
    setIsGenerating(false);
    setIsLoadMore(false);
    setTransactionList([]);
    setIsAllRecordLoaded(false);
  }
  
  const handleGenerateReport = () =>{
    setIsGenerating(true);
    handleLoadReport(0);
  }
  const handleLoadMore = () =>{
    setIsLoadMore(true);
    let load_page = page;
    handleLoadReport(load_page);
  }
  const handleLoadReport = (npage) =>{
    let transList = [...transaction_list];
    let next_page = npage+1;
    setPage(next_page);
    let data = {created_by:user_list_id,page:next_page,fromDate:fromDate,toDate:toDate,filterType:filterType,transaction_type:payment_type,selection_type:selection_type,contact_info:contact_info,date_type:date_type};
     APP_HTTP.REQUEST('reports/transaction',data,true,true,false,true).then((response) => {
       setIsGenerating(false);
      if(next_page===1){
      }else{
        setIsLoadMore(false);
      }
      let trans_list = response.data.response;
      let payment_modes = response.data.payment_modes;
      let payment_out_modes = response.data.payment_out_modes;
      let sinfo = response.data.school_info;
      setSchoolInfo(sinfo);
      setPaymentModes(payment_modes);
      setPaymentOutModes(payment_out_modes);
      let total_amount = response.data.total_amount;
      let in_amount = response.data.in_amount;
      let out_amount = response.data.out_amount;
      let cash_in_hand = response.data.cash_in_counter;
      setTotalTransactionAmount(total_amount);
      setTotalInAmount(in_amount);
      setTotalOutAmount(out_amount);
      setCashInCounter(cash_in_hand);
      if(trans_list.length>0){
        for (let index = 0; index < trans_list.length; index++) {
          const element = trans_list[index];
          transList.push(element);
        }
        setTransactionList(transList);
      }else{
        setIsAllRecordLoaded(true);
      }
    });
  }
  const handleChangeInputValue = (value) =>{
     if(value===""){
      return false;
    }
    if(selection_type==='student'){
      APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',limit:30},true,true,false,true).then((response) => {
        if(response.data!==null &&  response.data!==undefined && response.data.response!==undefined){
          let resp = response.data;
          let search_list = resp.response;
          setContactList(search_list);
        }
      });
    }else if(selection_type==='staff'){
      APP_HTTP.REQUEST('staff/search_staff',{search:value,type:'autocomplete',limit:30},true,true,false,true).then((stresponse) => {
        if(stresponse.data!==null &&  stresponse.data!==undefined && stresponse.data.response!==undefined){
          let stresp = stresponse.data;
          let search_list = stresp.response;
          setContactList(search_list);
        }
      });
    }
  }
  const handleChangeValue = (value) =>{
    
    setContactInfo(value); 
  }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>TRANSACTION REPORT</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Here, you can track the payments transaction what comes in and what goes out report as per applied filter.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(AppUtils.has_access(state,'161_all_user_report_view')) &&
                <>
                  <Grid item xs={12} sm={12} md={2} spacing={2}>
                      <AppDropDown label="Created By" list_item={usersList} size="small" handleChangeData={handleSetUserID} defaultValue={user_list_id}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} spacing={2}>
                      <Divider />
                  </Grid>
                  </>
                }
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <AppDropDown label="Date Type" list_item={[{key:'transaction',value:'Transaction Date'},{key:'payment',value:'Payment Date'}]} size="small" handleChangeData={handleChangeDateType} defaultValue={date_type}/>
                </Grid>
               {/*  <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <AppDropDown label="Type" list_item={[{key:'student',value:'Student'},{key:'staff',value:'Staff'}]} size="small" handleChangeData={handleChangeSelection} defaultValue={selection_type}/>
                </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                  {(selection_type==='student') &&
                    <Autocomplete
                          value={contact_info}
                          noOptionsText={'No Student Found ! Type To Search'}
                          onChange={(event, newValue) => handleChangeValue(newValue)}
                          onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                          disablePortal
                          id="contact_dropdown"
                          options={contact_list}
                          size="small"
                          renderInput={(params) => <TextField {...params} label={'Select Student'} />}
                      />
                  }
                  {(selection_type==='staff') &&
                    <Autocomplete
                          value={contact_info}
                          noOptionsText={'No Staff Found ! Type To Search'}
                          onChange={(event, newValue) => handleChangeValue(newValue)}
                          onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                          disablePortal
                          id="contact_dropdown"
                          options={contact_list}
                          size="small"
                          renderInput={(params) => <TextField {...params} label={'Select Staff'} />}
                      />
                  }
                </Grid> */}

                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <AppDropDown label="Transaction Type" list_item={[{key:'all',value:'ALL'},{key:'in',value:'PAYMENT IN(+)'},{key:'out',value:'PAYMENT OUT(-)'}]} size="small" handleChangeData={handleChangePaymentType} defaultValue={payment_type}/>
                </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <AppDropDown label="Filter Type" list_item={[{key:'today',value:'Today'},{key:'yesterday',value:'Yesterday'},{key:'thisweek',value:'This Week'},{key:'lastweek',value:'Last Week'},{key:'thismonth',value:'This Month'},{key:'custom',value:'Custom'}]} size="small" handleChangeData={handleChangeFilterType} defaultValue={filterType}/>
                </Grid>
                {(filterType==='custom') &&
                <>
                  <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="Select From Date" size="small" handleChangeData={handleChangeFromDate}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="Select To Date" size="small" handleChangeData={handleChangeToDate}/>
                  </Grid>
                </>
                }
               
                
                <>
                {(AppUtils.has_access(state,'15_tranaction_view')) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    {(isGenerating===false && transaction_list.length===0) &&
                      <Button variant='outlined' size='medium' onClick={handleGenerateReport}>GENERATE</Button>
                    }
                    {(isGenerating===false && transaction_list.length>0) &&
                      <Button variant='outlined' size='medium' onClick={handleResetList}>RESET LIST</Button>
                    }
                    {(isGenerating===true) &&
                    <LoaderButton label="Generating..."  variant='outlined' size='medium'/>
                    }
                </Grid>
                }
                </>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                
                {/*<Grid item xs={12} sm={12} md={12} spacing={2} sx={{textAlign:'right'}}>
                    <DownloadAttendence data={{payment_type:payment_type,attendence_ids:attendence_ids,attendence_info:attendence_info,attendence_list:attendence_list}}/>
                  </Grid>
                 */}
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                {(total_transaction_amount!==0) &&
                  <Button variant='outlined' size='medium' onClick={handlePrintList}><Print></Print> Print</Button>   
                }
                </Grid>
                      {(parseFloat(total_in_amount)>0) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                      <>
                        <Typography variant='h6' style={{textAlign:'left',color:'rgb(8 172 8)',fontSize: '18px',border: '1px solid', padding: '5px',textAlign: 'center',borderRadius: '5px'}}> 
                          Earnings: ₹{AppUtils.currency_format(total_in_amount)}
                          {(paymentModes.map((pmval,pmidx)=>{
                          return(
                            <>
                            {(pmval.amount>0) &&
                            <>
                              <Typography variant='h6' style={{textAlign:'left',color:'#5e86ad',fontSize: '14px',border: '1px solid', padding: '2px',textAlign: 'center',borderRadius: '5px',marginBottom:'5px'}}> 
                                {pmval.value}: ₹{AppUtils.currency_format(pmval.amount)}
                              </Typography>
                            </>
                            }
                            </>
                          )
                        }))}
                        </Typography>
                      </>
                </Grid>
                      }
                      {(parseFloat(total_out_amount)>0) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                      <>
                        <Typography variant='h6' style={{textAlign:'left',color:'red',fontSize: '18px',border: '1px solid', padding: '5px',textAlign: 'center',borderRadius: '5px'}}> 
                          Expense: ₹{AppUtils.currency_format(total_out_amount)}
                          {(paymentOutModes.map((pmval,pmidx)=>{
                          return(
                            <>
                            {(pmval.amount>0) &&
                            <>
                              <Typography variant='h6' style={{textAlign:'left',color:'#5e86ad',fontSize: '14px',border: '1px solid', padding: '2px',textAlign: 'center',borderRadius: '5px',marginBottom:'5px'}}> 
                                {pmval.value}: ₹{AppUtils.currency_format(pmval.amount)}
                              </Typography>
                            </>
                            }
                            </>
                          )
                        }))}
                        </Typography>
                      </>
                </Grid>
                      }
              
                
                {(total_transaction_amount!==0) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <Typography variant='h6' style={{textAlign:'left',color:(total_transaction_amount<0)?'red':'rgb(8 172 8)',fontSize: '18px',border: '1px solid', padding: '5px',textAlign: 'center',borderRadius: '5px'}}> 
                      Total {(total_transaction_amount<0)?'Loss':'Profit'} <Typography>₹{AppUtils.currency_format(total_transaction_amount)}</Typography> 
                    </Typography>
                </Grid>
                }
                {(cash_in_counter!==0) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <Typography variant='h6' style={{textAlign:'left',color:(cash_in_counter<0)?'red':'rgb(8 172 8)',fontSize: '18px',border: '1px solid', padding: '5px',textAlign: 'center',borderRadius: '5px'}}> 
                      Cash {(cash_in_counter<0)?'Out from Counter':'In Counter'} <Typography>₹{AppUtils.currency_format(cash_in_counter)}</Typography> 
                    </Typography>
                </Grid>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                     
                    <TableContainer component={Paper}>
                      <Table  aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>PAYMENT NO</StyledTableCell>
                            <StyledTableCell>PAYMENT TYPE</StyledTableCell>
                            <StyledTableCell>
                              <p style={{margin:'0px'}}>PAYMENT DATE</p>
                              <p style={{margin:'0px'}}>TRANSACTION DATE</p>
                            </StyledTableCell>
                            <StyledTableCell>CONTACT NAME</StyledTableCell>
                            {(paymentModes.map((value,index)=>{
                              return(<StyledTableCell style={{textTransform:'uppercase'}}>{value.value}</StyledTableCell>)
                            }))}
                            {/* <StyledTableCell>PARTICULARS</StyledTableCell> */}
                            {(payment_type!=='all') &&
                              <StyledTableCell>AMOUNT</StyledTableCell>
                            }
                            {(payment_type==='all') &&
                              <>
                                <StyledTableCell>EARNING</StyledTableCell>
                                <StyledTableCell>EXPENSE</StyledTableCell>
                              </>
                            }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transaction_list && transaction_list.map((row,index) => (
                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                <a href={'/payment/view/'+row.payment_id} target='_blank'>{row.payment_sequence}</a>
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.payment_type}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <p style={{margin:'0px'}}>{row.payment_date}</p>
                                <p style={{margin:'0px'}}>{row.transaction_date}</p>
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {(row.contact_type==='student') &&
                                  <StudentCard info={row.contact_info} is_photo={false}/>
                                }
                                {(row.contact_type!=='student') &&
                                <>
                                  {row.contact_info.name}
                                </>
                                }
                              </StyledTableCell>
                              {(paymentModes.map((pmval,pmidx)=>{
                                return(
                                  <StyledTableCell component="th" scope="row">
                                  {(row.payment_mode_data_display[pmval.id]!==undefined && row.payment_mode_data_display[pmval.id]!=='') &&
                                  <>₹{AppUtils.currency_format(row.payment_mode_data_display[pmval.id].value)}</>
                                  }
                                  {(row.payment_mode_data_display[pmval.id]===undefined || row.payment_mode_data_display[pmval.id]==='') &&
                                    <>-</>
                                  }
                                  </StyledTableCell>
                                )
                              }))}
                             {/*  <StyledTableCell component="th" scope="row">
                                {row.particulars}
                              </StyledTableCell> */}

                            {(payment_type!=='all') &&
                               <StyledTableCell component="th" scope="row">
                                ₹{AppUtils.currency_format(row.paid_amount)}
                               </StyledTableCell>
                            }
                            {(payment_type==='all') &&
                              <>
                                <StyledTableCell>
                                  {(row.payment_type_key==='payment_in') &&
                                    <>
                                    ₹{AppUtils.currency_format(row.paid_amount)}
                                    </>
                                  }
                                  {(row.payment_type_key==='payment_out') &&
                                    <>
                                    -
                                    </>
                                  }
                                </StyledTableCell>
                                <StyledTableCell>
                                {(row.payment_type_key==='payment_out') &&
                                    <>
                                    ₹{AppUtils.currency_format(row.paid_amount)}
                                    </>
                                  }
                                  {(row.payment_type_key==='payment_in') &&
                                    <>
                                    -
                                    </>
                                  }
                                </StyledTableCell>
                              </>
                            }
                            </StyledTableRow>
                          ))}
                          {(transaction_list.length===0 && isGenerating===false) &&
                           <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={(payment_type==='all')?(paymentModes.length+7):(paymentModes.length+6)} sx={{textAlign:'center'}}>
                              Please apply fitler to see the report
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                          {(isLoadMore===true) &&
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={(payment_type==='all')?(paymentModes.length+7):(paymentModes.length+6)}>
                            <Stack spacing={1}>
                                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                  </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                           {(isGenerating===true) &&
                            <>
                                
                                {Array.from(Array(5), (e, i) => {
                                    return(
                                      <StyledTableRow>
                                      <StyledTableCell component="th" scope="row" colSpan={(payment_type==='all')?(paymentModes.length+7):(paymentModes.length+6)}>
                                      <Stack spacing={1}>
                                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                            </Stack>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    )
                                    })}
                                
                            </>
                            }
                          {/* {(isAllRecordLoaded===false && transaction_list.length>0) &&
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row" sx={{textAlign:'center'}} colSpan={(payment_type==='all')?7:6}>
                            {(isLoadMore===false) &&
                              <Button variant='outlined' size='small' onClick={handleLoadMore}>Load More</Button>
                            }
                            {(isLoadMore===true) &&
                              <LoaderButton label="Loading..."  variant='outlined' size='small'/>
                            }
                            </StyledTableCell>
                          </StyledTableRow>
                          } */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                   
                </Grid>
                {(is_print===true) &&
                  <PrintTransactions printData={transaction_list} total_transaction_amount={total_transaction_amount} total_in_amount={total_in_amount} total_out_amount={total_out_amount} cash_in_counter={cash_in_counter} payment_type={payment_type} paymentModes={paymentModes} paymentOutModes={paymentOutModes} schoolInfo={school_info}/>
                }
            </Grid>
        </Box>
    </Box>
  );
}
export default React.memo(Transaction);