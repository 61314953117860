import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from "react-redux";
import { Autocomplete, Button, ButtonGroup, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Textarea } from '@mui/joy';
import PrintHeader from '../PrintHeader';
import APP_HTTP from '../../APP_HTTP';
import { CheckCircle } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
import LoaderButton from '../LoaderButton';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';
import PrintCertificate from './PrintCertificate';

export default function PrepareCertificate() {
  const dispatch = useDispatch();
  const [is_print, setIsPrint] = React.useState(false);
  const [templateData, setTemplateData] = React.useState({});
  const [certificate_list, setCertificateList] = React.useState([]);
  const [school_info, setSchoolInfo] = React.useState({});
  const [selected_template,setSelectedTemplate] = React.useState('');
  const [template_id,setTemplateID] = React.useState(0);
  let [selected_class,setClass] = React.useState(0);
  let [selected_section,setSection] = React.useState(0);
  let [selected_session,setSession] = React.useState(0);
  let [payee_payer_list,setPayeePayerList] = React.useState([]);
  let [contact_info,setContactInfo] = React.useState([]);
  const state = useSelector((state) => state);
  const is_college = state.school_info.school_info.is_college;
  const initialized = React.useRef(false);
  React.useEffect(()=>{
      if (!initialized.current) {
        initialized.current = true;
        handleGetCertificates();
        handleGetSchoolInfo();
      }
  },[])
  const handleChangeSession = (id,value) =>{
    setPayeePayerList([]);
    setSession(value);
    setClass(0);
    setSection(0);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setPayeePayerList(search_list);
    });
  }
  const handleChangeClass = (id,value) =>{
    setPayeePayerList([]);
    setClass(value);
    setSection(0);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:selected_session,class:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setPayeePayerList(search_list);
    });
  }
  const handleChangeSection = (id,value) =>{
    setSection(value);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:selected_session,class:selected_class,section:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setPayeePayerList(search_list);
    });
  }
  const handleChangeInputValue = (value) =>{
    
  }
  const handleChangeValue = (value) =>{
    setContactInfo(value);
  }
  const handleGetSchoolInfo = ()=>{
    APP_HTTP.REQUEST('school/get_school_info',{},true,true).then((response) => {
        let resp = response.data;
        let sinfo = resp.response;
        setSchoolInfo(sinfo)
    });
  }
  const handleGetCertificates = ()=>{
    APP_HTTP.REQUEST('settings/get_certificates',{type:'general'},true,true).then((response) => {
        let resp = response.data;
        let cerlist = resp.response;
        setCertificateList(cerlist)
    });
  }
  const handleSelectCertificate = (tid,tdata)=>{
    let tem_id = parseInt(tdata.template_id);
    let temp_data = tdata.template_data;
    setTemplateID(tid);
    setTemplateData(tdata);
  }
  const handlePrint = () =>{
    setIsPrint(true);
    setTimeout(() => {
        setIsPrint(false);
    }, 3000);
  }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={2} spacing={2}>
                <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    {(certificate_list.map((value,index)=>{
                        return(
                            <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'center'}}>
                                <Box style={{position:'relative',cursor:'pointer'}} onClick={(e)=>handleSelectCertificate(value.template_id,value)}>
                                    <img src={'/bg'+value.template_id+'.png'} alt="image" style={{boxShadow:'3px 3px 5px 0px #333',height:'140px',width:'100%'}}/>
                                    {(template_id===value.template_id) &&
                                        <>
                                        <Box style={{background:'blue',opacity:'0.3',height:'140px',width:'100%',position:'absolute',top:0}}></Box>
                                        <CheckCircle fontSize="large" style={{color:'#fff', position:'absolute',right:0,left:0,top:'35%',margin:'0 auto'}}/>
                                        </>
                                    }
                                </Box>
                                <Typography style={{marginTop:'5px',fontSize:'12px', fontWeight:'bold',color:'#333'}}>{value.template_name}</Typography>
                                <hr />
                            </Grid>
                        )
                    }))}
                </Grid>
            </Grid>
            {(template_id>0) &&
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    {(is_college===true) &&
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <SessionDropdown handleChangeData={handleChangeSession}/>
                    </Grid>
                    }
                <Grid item xs={12} sm={12} md={12}>
                    <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>
                </Grid>
                {(selected_class>0) &&
                    <Grid item xs={12} sm={12} md={12}>
                    <Sections show="dropdown" handleChangeData={handleChangeSection}  defaultValue={selected_section}/>
                    </Grid>
                }
                {(selected_class>0) &&
                <Grid item xs={12} sm={12} md={12}>
                <Autocomplete
                        value={contact_info}
                        noOptionsText={'No Student Found ! Type To Search'}
                        onChange={(event, newValue) => handleChangeValue(newValue)}
                        onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                        disablePortal
                        id="payee_dropdown"
                        options={payee_payer_list}
                        size="small"
                        renderInput={(params) => <TextField {...params} label={'Select Student'} />}
                    />
                </Grid>
                }
                {(contact_info.name!==undefined) &&
                    <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                        <Button  size='medium' variant='contained' color='success' onClick={handlePrint}>Print</Button>
                    </Grid>
                }
                
                    </Grid>
                </Grid>
            }
        </Grid>
        {(is_print===true) &&
        <PrintCertificate printData={templateData} school_info={school_info} contact_info={contact_info}/>
        }
    </Box>
  );
}