import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, ButtonGroup, Divider, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppPagination from '../AppPagination';
import { useUtils } from '@mui/x-date-pickers/internals';
import AppUtils from '../../AppUtils';
import StaffCard from '../staffs/StaffCard';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const FromStaff = (props) => {
  const [number_of_page,setNumberOfPage] = React.useState(0);
  const [total_records,setTotalRecords] = React.useState(0);
  const [is_loading_list,setIsLoadingList] = React.useState(true);
  const [leave_lists,setLeaveList] = React.useState([]);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            let current_path = window.location.pathname;
            let numberPattern = /\d+/g;
            let get_page = current_path.match( numberPattern );
            if(get_page===null){
              handleGetLeaveRequest(1);
            }
        }
    },[])
    const handleDeclineRequest = (id,index) =>{
      handleLeaveRequestAction(id,2,index);
    }
    const handleAcceptRequest = (id,index) =>{
      handleLeaveRequestAction(id,1,index);
    }
    const handleLeaveRequestAction = (id,action,index) =>{
      const llist = [...leave_lists];
      llist[index].status = action;
      setLeaveList(llist);
      APP_HTTP.REQUEST('leave/request_action',{id:id,action:action,type:'staff'},true,true).then((response) => {
      });
    }
    const handleNavigatePage = (page) =>{
      handleGetLeaveRequest(page);
    }
    const handleGetLeaveRequest = (page) =>{
      setIsLoadingList(true);
      
      APP_HTTP.REQUEST('leave/get_leave_request',{page:page,total_records:total_records,type:'staff'},true,true).then((response) => {
          let resp = response.data;
          let stu_list = resp.response;
          let tot_record = resp.total_record;
          let no_of_page = resp.number_of_page;
          setTotalRecords(tot_record);
          setNumberOfPage(no_of_page);
          setIsLoadingList(false);
          setLeaveList(stu_list);
      });
    }
  return (
      <>
      {(AppUtils.has_access(state,'170_from_staff_view')) &&
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={3} spacing={2}>
             {/*  <TextField
                label="Search Payment"
                size="small"
                fullWidth
                onChange={handleSearch}
                /> */}
              </Grid>
              <Divider />
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>LEAVE DATE</StyledTableCell>
                        <StyledTableCell>LEAVE DAYS</StyledTableCell>
                        <StyledTableCell>REASON</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(leave_lists.map((value,index)=>{
                        return(
                          <StyledTableRow>
                          <StyledTableCell>
                          <StaffCard info={value.contact_info} is_photo={true}/>
                            
                          </StyledTableCell>
                          
                          <StyledTableCell>{value.leave_from_date} To {value.leave_to_date}</StyledTableCell>
                          <StyledTableCell>{value.days}</StyledTableCell>
                          <StyledTableCell>{value.leave_reason}</StyledTableCell>
                          <StyledTableCell sx={{textAlign:'right'}}>
                          <ButtonGroup
                            disableElevation
                            variant="contained"
                            aria-label="Disabled elevation buttons"
                            >
                            {(parseInt(value.status)===1) &&
                              <Typography sx={{color:'green'}}> Accepted</Typography>
                            }
                            {(parseInt(value.status)===2) &&
                              <Typography sx={{color:'red'}}> Declined</Typography>
                            }
                            {(parseInt(value.status)===0) &&
                            <>
                            {(AppUtils.has_access(state,'170_from_staff_decline')) &&
                            <Button size="small" color="error" onClick={()=>handleDeclineRequest(value.staff_leave_request_id,index)}>Decline</Button>
                          }
                          {(AppUtils.has_access(state,'170_from_staff_accept')) &&
                            <Button size="small" color="success" onClick={()=>handleAcceptRequest(value.staff_leave_request_id,index)}>Accept</Button>
                          }
                            </>
                            }
                        </ButtonGroup>
                          </StyledTableCell>
                        </StyledTableRow>
                        )
                      }))}
                        
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <AppPagination number_of_page={number_of_page} handleNavigatePage={handleNavigatePage} url={'/leave_requests/staff/page'}/>
              </Grid>
            </Grid>
          </Box>
      }
      </>
  );
}

export default FromStaff;