import { OPEN_ADD_STAFF_TYPE, CREATE_STAFF_TYPE, UPDATE_STAFF_TYPE, DELETE_STAFF_TYPE, DELETE_ITEM_CONFIRM,SET_STAFF_TYPE_LIST } from "../../../actionTypes/actionTypes";

const handleOpenAddStaffTypes = (is_open_add_staff_type) =>{
  return {
    type: OPEN_ADD_STAFF_TYPE,
    state :{
      is_open_add_staff_type : is_open_add_staff_type,
    }
  };
}
const handleSetStaffTypeList = (staff_type_list) =>{
  return {
    type: SET_STAFF_TYPE_LIST,
    state :{
      staff_type_list : staff_type_list,
    }
  };
}
const handleSaveStaffTypeToList = (staff_type_list) =>{
  return {
    type: CREATE_STAFF_TYPE,
    state :{
      staff_type_list : staff_type_list,
    }
  };
}
const handleUpdateStaffType = (update_id,name) =>{
  return {
    type: UPDATE_STAFF_TYPE,
    state :{
      update_id : update_id,
      name : name
    }
  };
}
const handleConfirmDeleteStaffTypes = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteStaffType = (index, delete_data) =>{
  return {
    type: DELETE_STAFF_TYPE,
    state :{
      id : index,
    }
  };
}

export { handleOpenAddStaffTypes, handleSaveStaffTypeToList,handleUpdateStaffType,handleConfirmDeleteStaffTypes,handleDeleteStaffType,handleSetStaffTypeList };