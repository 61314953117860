import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Avatar, Button, Checkbox, Divider, FormControlLabel, FormGroup, InputLabel, MenuItem, NativeSelect, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import LoaderButton from '../../LoaderButton.jsx';
import BasicDatePicker from '../../DatePicker.jsx';
import AppCheckbox from '../../AppCheckbox.jsx';
import Classes from '../classes/Classes.jsx';
import Sections from '../sections/Sections.jsx';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const ClassSectionShifting = (props) => {
  const [student_count,setStudentCount] = React.useState(0);
  const [selected_class,setClass] = React.useState(0);
  const [selected_section,setSection] = React.useState(0);
  const [selected_second_class,setSecondClass] = React.useState(0);
  const [selected_second_section,setSecondSection] = React.useState(0);
  const [student_list,setStudentList] = React.useState([]);
  const [is_loading,setIsLoading] = React.useState(false);
  const [is_saving,setIsSaving] = React.useState(false);
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
        }
    },[])
    const handleChangeClass = (id,value) =>{
        setClass(0);
        setTimeout(() => {
            setClass(value);
            setSection(0);
            setSecondClass(0);
            setSecondSection(0);
        }, 100);
   }
   const handleChangeSection = (id,value) =>{
        setSection(value);
        setSecondSection(0);
        APP_HTTP.REQUEST('student/get_class_student_count',{class:selected_class,section:value},true,true,false,true).then((response) => {
            setStudentCount(response.data.count)
        });
   }
    const handleChangeSecondClass = (id,value) =>{
        setSecondClass(value);
        setSecondSection(0);
    }
   const handleChangeSecondSection = (id,value) =>{
        setSecondSection(value);
   }
   const handleShiftClass = ()=>{
        setIsLoading(true);
        APP_HTTP.REQUEST('settings/shift_class',{class:selected_class,section:selected_section,new_class:selected_second_class,new_section:selected_second_section},true,true,false,true).then((response) => {
            setIsLoading(false);
        });
   }
  const handleChangeRollNumber = (value,index) =>{
    let st_list = [...student_list];
    st_list[index].roll_number = value;
    setStudentList(st_list);
  }
  const handleSaveRollNumber = () =>{
    setIsSaving(true);
    APP_HTTP.REQUEST('student/save_roll_number',{student_list:student_list},true,true,false,true).then((response) => {
        setIsSaving(false);
  });
  }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Shift Student Old Class to its New Class
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography style={{color:'red',fontFamily:'monospace'}}>Shift Student Class in Decending Order i.e Heigher Class To Lower Class</Typography>
                    <Typography style={{fontFamily:'monospace'}}>
                        Example : 9 to 10, 8 to 9, 7 to 8, 6 to 7, 5 to 6, 4 to 5, 3 to 4, 2 to 3, 1 to 2 etc.
                    </Typography>
                </Grid>  
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            <Classes show="dropdown" label="From Class" handleChangeData={handleChangeClass} defaultValue={selected_class}/> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            {(selected_class>0) &&
                                <Sections label="From Section" show="dropdown" handleChangeData={handleChangeSection} defaultValue={selected_section}/>  
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} spacing={2}>
                            {(student_count>0) &&
                               <Typography style={{fontSize: '14px', fontFamily: 'monospace', color: '#45ba2c', textTransform: 'uppercase'}}>{student_count} student(s) are ready to shift!</Typography>
                            }
                            {(selected_section>0 && student_count==0) &&
                               <Typography style={{fontSize: '14px', fontFamily: 'monospace', color: 'red', textTransform: 'uppercase'}}>No student(s) found! Please confirm class & section</Typography>
                            }
                        </Grid>
                    </Grid>
                    {(student_count>0) &&
                    <>
                    <Grid container spacing={2} className="mrtop-10">
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            {(selected_section>0) &&
                                <Classes label="To Class" show="dropdown" handleChangeData={handleChangeSecondClass} defaultValue={selected_second_class}/> 
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            {(selected_second_class>0) &&
                                <Sections label="To Section" show="dropdown" handleChangeData={handleChangeSecondSection} defaultValue={selected_second_section}/>  
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="mrbtm-30" style={{marginTop:'5px'}}>
                        <Grid item xs={12} sm={12} md={6} spacing={2} style={{textAlign:'right'}}>
                            {(selected_second_section>0) &&
                                <>
                                    {(is_loading===false) &&
                                    <Button onClick={handleShiftClass} variant='outlined' size='medium'>Proceed To Shift</Button>
                                    }
                                    {(is_loading===true) &&
                                        <LoaderButton variant='outlined' size='medium'/>
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                    </>
                    }
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Divider />
                        </Grid>
                    </Grid>
                   
                </Grid>
            </Grid>
        </Box>
    </>
  );
}

export default ClassSectionShifting;