import { Dialog } from '@mui/material';
import * as React from 'react';

const SuccessLoader = (props)=> {
  return (
    <Dialog open={true}>
        <img src="/success-check.gif" alt="image" height={'200'}/>
    </Dialog>
  );
}

export default SuccessLoader;