import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { handleChangeMenu } from "../actions/Action";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from './Copyright';
import APP_HTTP from '../APP_HTTP';
import LoaderButton from './LoaderButton';
import { Alert, Stack } from '@mui/material';


const theme = createTheme();

export default function Login() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [uLogData, setUlogData] = React.useState({school_name:'',username:''});
  const [is_error, setIsError] = React.useState(false);
  const [is_submit_click, setSubmit] = React.useState(false);
  const initialized = React.useRef(false);
  React.useEffect(()=>{
    if (!initialized.current) { 
      initialized.current = true;
      let logData = localStorage.getItem('_login');
      if(logData!==null){
        logData = JSON.parse(logData);
        setUlogData(logData);
      }
    }
  },[]);
  const handleSubmit = (event) => {
    setIsError(false);
    setSubmit(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let school_name = data.get('school_name');
    let username = data.get('username');
    let storeData = {
      school_name:school_name,
      username:username,
    }
    localStorage.setItem('_login',JSON.stringify(storeData));
    let send_data = {
      school_name: data.get('school_name'),
      username: data.get('username'),
      password: data.get('password'),
    };
    APP_HTTP.REQUEST('school/login',send_data).then((response) => {
      if(response.data.success===1){
        let data = response.data.data;
        localStorage.setItem('MnzYs4pVGL',JSON.stringify(data));
        let redirect_page = data.redirect_page;
        window.location.href = '/'+redirect_page;
        setSubmit(false);
      }else{
        setIsError(true);
        setSubmit(false);
      }
    });
  };
  const handleSelectMenu = (label,menu,sub_menu='',is_enable_add_btn=false) => {
    dispatch(handleChangeMenu(menu,sub_menu,label,is_enable_add_btn));
    /* if(menu==='logout'){
      window.location.href = '/login';
    }*/
  }
  const handleChangeData = (key,value) =>{
    let udata = {...uLogData};
    udata[key] = value;
    setUlogData(udata);
  }
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="school_name"
              label="School/College Name"
              name="school_name"
              autoComplete="school_name"
              autoFocus
              value={uLogData.school_name}
              onChange={(e)=>handleChangeData('school_name',e.target.value)}
            />
            <TextField
              value={uLogData.username}
              onChange={(e)=>handleChangeData('username',e.target.value)}
              margin="normal"
              required
              fullWidth
              id="username"
              label="User Name"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            {(!is_submit_click) &&
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            }
            {(is_submit_click) &&
                <LoaderButton
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}/>
            }
            {(is_error) &&
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Alert severity="error">Oops ! Something Went Wrong.</Alert>
            </Stack>
            }
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
             {/*  <Grid item>
                <Link onClick={() => handleSelectMenu('Signup','signup','',false)}  sx={{cursor:'pointer'}} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}