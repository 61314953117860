import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function AppCheckbox(props) {
  const [isChecked, setIsChecked] = React.useState(false);
  const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(props.checked!==undefined){
                setIsChecked(props.checked);
            }
        }
       
    },[props])
  const handleChangeCB = (e,key) =>{
    if(props.handleChangeCB){
        props.handleChangeCB(e,key);
    }
    setIsChecked(e.target.checked);
  }
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          checked={isChecked}
          value={props.value}
          control={<Checkbox onChange={(e)=>handleChangeCB(e,props.cb_key)}/>}
          label={props.label}
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );
}
