import { DELETE_ITEM_CONFIRM,DELETE_STUDENT,SET_STUDENTS_LIST,IS_STUDENT_EDIT,SET_STUDENT_INFO,IS_STUDENT_VIEW } from "../../actionTypes/actionTypes";

const handleConfirmDeleteStudent = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteStudent = (index, delete_data) =>{
  return {
    type: DELETE_STUDENT,
    state :{
      id : index,
    }
  };
}
const handleSetStudentsList = (students_list) =>{
    return {
      type: SET_STUDENTS_LIST,
      state :{
        students_list : students_list,
      }
    };
  }
const handleToggleEditStudent = (is_student_edit) =>{
    return {
      type: IS_STUDENT_EDIT,
      state :{
        is_student_edit : is_student_edit,
      }
    };
  }
const handleToggleViewStudent = (is_student_view) =>{
    return {
      type: IS_STUDENT_VIEW,
      state :{
        is_student_view : is_student_view,
      }
    };
  }
const handleSetStudentInfo = (student_info) =>{
    return {
      type: SET_STUDENT_INFO,
      state :{
        student_info : student_info,
      }
    };
  }

export { handleConfirmDeleteStudent, handleDeleteStudent,handleSetStudentsList,handleToggleEditStudent,handleSetStudentInfo,handleToggleViewStudent};