import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { handleConfirmDeleteSection, handleOpenAddSection, handleSetSectionList } from './Action.jsx';
import CreateSection from './CreateSection.jsx';
import DeleteConfirm from '../../DeleteConfirm.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppUtils from '../../../AppUtils.jsx';
import { handleSetClassList } from '../classes/Action.jsx';


const Sections = (props) => {
    const [is_loading,setLoading] = React.useState(true);
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    const state = useSelector((state) => state);
    const is_college = state.school_info.school_info.is_college;
    const is_open_add_section = state.is_open_add_section;
    const is_delete_item = state.is_delete_item;
    const section_list = state.section_list;
    const class_list = state.class_list;
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if(section_list.length===0){
            if (!initialized.current) {
                initialized.current = true;
                APP_HTTP.REQUEST('settings/get_section_list',{},true,true).then((response) => {
                    let resp = response.data;
                    let secli = resp.response;
                    setLoading(false);
                    dispatch(handleSetSectionList(secli));
                });
               /*  APP_HTTP.REQUEST('settings/get_class_list',{},true,true).then((response) => {
                    let resp = response.data;
                    let clsli = resp.response;
                    //setLoading(false);
                    dispatch(handleSetClassList(clsli));
                }); */
            }
        }
    },[dispatch])
    let lbl_value =(is_college===true)?'Select Course Year':'Select Section';
    const list_item = [{key:0,value:lbl_value}];
    for (let index = 0; index < section_list.length; index++) {
        const element = section_list[index];
        let cls_item = {key:element.id,value:element.name};
        list_item.push(cls_item)
    }
    const [sectionValue,setClassEdit] = React.useState({id:0,name:'',is_edit:true});
    
    const handleDeleteItem = (id)=> {
        let secValue = section_list[id];
        let clsId = secValue.id;
        
        let delData = {id:clsId,index:id,target:'section'};
        setDeleteData(delData);
        dispatch(handleConfirmDeleteSection(true));
    }
    const handleEditItem = (id)=>{
        let secValue = section_list[id];
        if(secValue.is_edit===undefined){
            secValue['is_edit'] = true;
        }else {
            secValue.is_edit = true;
        }
        if(secValue.update_id===undefined){
            secValue['update_id'] = id;
        }else{
            secValue.update_id = id;
        }
        setClassEdit(secValue);
        dispatch(handleOpenAddSection(true));
    }
    const openAddSection = () =>{
        setClassEdit({id:0,name:'',is_edit:false});
        dispatch(handleOpenAddSection(true));
    }
  return (
    <>
    {(props.show==='list') &&
    <>
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        {(is_college===true)?'Course Year':'Section'}
                        {(AppUtils.has_access(state,'22_section_add')) &&
                        <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='large' onClick={openAddSection}/>
                        }
                    </Typography>
                </Grid>
                {(AppUtils.has_access(state,'22_section_view')) &&
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <Divider />
                    <List>
                        {(section_list.map((value,index)=>{
                            let cls_lbl = value.name;
                            if(value.class_label!==""){
                                cls_lbl = cls_lbl+=' ('+value.class_label+')';
                            }
                            return (
                                <>
                                <ListItem key={cls_lbl} disablePadding>
                                    <ListItemButton>
                                        <ListItemText primary={cls_lbl} />
                                        {(AppUtils.has_access(state,'22_section_delete')) &&
                                            <Delete fontSize='small' onClick={() =>handleDeleteItem(index)}/>
                                        }
                                        {(AppUtils.has_access(state,'22_section_edit')) &&
                                            <Edit  fontSize='small' onClick={() =>handleEditItem(index)}/>
                                        }
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                            </>
                            )
                        }))}
                        {(is_loading===false && section_list.length===0) &&
                             <ListItem key={"No Record Found"} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"No Record Found"} />
                                </ListItemButton>
                            </ListItem>
                        }
                        {(is_loading===true && section_list.length===0) &&
                             <ListItem key={"Loading..."} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Loading..."} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                
                </Grid>
                }
            </Grid>
        </Box>
       {(is_open_add_section) && 
        <CreateSection sectionValue={sectionValue}/>
       }
        {(is_delete_item) &&
        <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
       }
    </>
    }
    {(props.show==='dropdown') &&
        <>
            {(list_item.length>0) &&
                <AppDropDown label={(props.label!==undefined)?props.label:(is_college===true)?'Course Year':'Section'} id="section"  defaultValue={(props.defaultValue)?props.defaultValue:0} list_item={list_item} size="small" className={props.className} handleChangeData={props.handleChangeData} required={(props.required!==undefined && props.required===true)?true:false}/>
            }
        </>
    }
    </>
  );
}

export default Sections;