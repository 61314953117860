import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Textarea } from '@mui/joy';
import PrintHeader from '../PrintHeader';
import APP_HTTP from '../../APP_HTTP';
import { CheckCircle } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
import LoaderButton from '../LoaderButton';

export default function GeneralCertificates() {
  const dispatch = useDispatch();
  const [template_text, setTemplateText] = React.useState({type:'general',name:'','title':'','subtitle':'','desc1':'','desc2':'',signature:''});
  const [certificate_list, setCertificateList] = React.useState([]);
  const [school_info, setSchoolInfo] = React.useState({});
  const [bg_list,setBgList] = React.useState([1,2,3,4,5]);
  const [selected_template,setSelectedTemplate] = React.useState('');
  const [template_id,setTemplateID] = React.useState(0);
  const [is_saving_template,setSavingTemplate] = React.useState(false);
  const [is_refresh,setIsReferesh] = React.useState(false);
  const state = useSelector((state) => state);

  const initialized = React.useRef(false);
  React.useEffect(()=>{
      if (!initialized.current) {
        initialized.current = true;
        handleGetCertificates();
        handleGetSchoolInfo();
      }
  },[])
  const handleChangeText = (type,text)=>{
    let tmp_txt ={...template_text};
    tmp_txt[type] = text;
    setTemplateText(tmp_txt);
  }
  const handleGetSchoolInfo = ()=>{
    APP_HTTP.REQUEST('school/get_school_info',{},true,true).then((response) => {
        let resp = response.data;
        let sinfo = resp.response;
        setSchoolInfo(sinfo)
    });
  }
  const handleGetCertificates = ()=>{
    APP_HTTP.REQUEST('settings/get_certificates',{type:'general'},true,true).then((response) => {
        let resp = response.data;
        let cerlist = resp.response;
        setCertificateList(cerlist)
    });
  }
  const handleSelectTemplate = (temp_id)=>{
    setSelectedTemplate(temp_id)
  }
  const handleSaveTemplate = () =>{
    setSavingTemplate(true);
    APP_HTTP.REQUEST('settings/save_certificate',{template_id:template_id,selected_template:selected_template,template:template_text},true,true).then((response) => {
        setSavingTemplate(false);
        handleGetCertificates();
    });
  }
  const handleSelectCertificate = (tid,tdata)=>{
    let tmp_name = tdata.template_name;
    let tem_id = parseInt(tdata.template_id);
    let certificate_template_id = tdata.certificate_template_id;
    let temp_data = tdata.template_data;
    setTemplateID(certificate_template_id);
    setSelectedTemplate(tem_id);
    setTemplateText(temp_data);
  }
  const hadleCancel = () =>{
    setIsReferesh(true);
    setTemplateID(0);
    setSelectedTemplate('');
    setTemplateText({type:'general',name:'','title':'','subtitle':'','desc1':'','desc2':'',signature:''});
    setTimeout(() => {
        setIsReferesh(false);
    }, 50);
  } 
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            {(is_refresh===false && certificate_list.length>0) &&
            <>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Generated Certificates</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        {(certificate_list.map((value,index)=>{
                        return(
                            <FormControlLabel value={value.certificate_template_id} control={<Radio  onChange={(e)=>handleSelectCertificate(value.certificate_template_id,value)}/>} label={value.template_name} />
                            )
                        }))}
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
            </>
            }
            <Grid item xs={12} sm={12} md={2} spacing={2}>
                <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    {(bg_list.map((value,index)=>{
                        return(
                            <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'center'}}>
                                <Box style={{position:'relative',cursor:'pointer'}} onClick={()=>handleSelectTemplate(value)}>
                                    <img src={'/bg'+value+'.png'} alt="image" style={{boxShadow:'3px 3px 5px 0px #333',height:'140px',width:'100%'}}/>
                                    {(selected_template===value) &&
                                        <>
                                        <Box style={{background:'blue',opacity:'0.3',height:'140px',width:'100%',position:'absolute',top:0}}></Box>
                                        <CheckCircle fontSize="large" style={{color:'#fff', position:'absolute',right:0,left:0,top:'35%',margin:'0 auto'}}/>
                                        </>
                                    }
                                </Box>
                                <Typography style={{marginTop:'5px',fontSize:'12px', fontWeight:'bold',color:'#333'}}>TEMPLATE {value}</Typography>
                                <hr />
                            </Grid>
                        )
                    }))}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3} spacing={2}>
            {(selected_template!=='') &&
                <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
            <Typography variant='h5' style={{background:'#5e86ad', color:'#fff',padding:'5px'}}>Design Settings</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <TextField
                margin="dense"
                label="Enter Certificate Name"
                type="text"
                fullWidth
                size="small"
                onChange={(e)=>handleChangeText('name',e.target.value)}
                value={template_text.name}
                 
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <TextField
                margin="dense"
                label="Enter Certificate Title"
                type="text"
                fullWidth
                size="small"
                onChange={(e)=>handleChangeText('title',e.target.value)}
                value={template_text.title}
                 
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <TextField
                margin="dense"
                label="Enter Sub Title"
                type="text"
                fullWidth
                size="small"
                onChange={(e)=>handleChangeText('subtitle',e.target.value)}
                value={template_text.subtitle}
                 
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <Textarea placeholder="Enter Description..."  minRows={5}  onChange={(e)=>handleChangeText('desc1',e.target.value)} value={template_text.desc1}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <TextField
                margin="dense"
                label="Enter Signature"
                type="text"
                fullWidth
                size="small"
                onChange={(e)=>handleChangeText('signature',e.target.value)}
                value={template_text.signature}
                 
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6} spacing={2}>
            <Button  size='medium' variant='contained' onClick={hadleCancel} color='error'>Cancel</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} spacing={2} style={{textAlign:'right'}}>
            {(is_saving_template===false) &&
                <Button  size='medium' variant='contained' onClick={handleSaveTemplate} color='success'>Save</Button>
            }
            {(is_saving_template===true) &&
                <LoaderButton size='medium' variant='outlined' />
            }
            </Grid>
            <Grid item xs={12} sm={12} md={12} spacing={2}><Divider /></Grid>
            </Grid>
            }
            </Grid>
            <Grid item xs={12} sm={12} md={7} spacing={2}>
                {(selected_template!=='') &&
                    <Box style={{height:'500px', width:'700px',position:'relative',display:'flex',justifyContent:'center'}}>
                        <img src={'/bg'+selected_template+'.png'} alt="image" style={{boxShadow:'3px 3px 5px 0px #333',height:'100%',width:'100%'}}/>
                        {(selected_template===1) &&
                            <>
                                <img src={school_info.info.photo} alt="image" height={50} style={{position:'absolute',top:'60px'}}/>
                                <Typography variant='h4' style={{position:'absolute', top:'113px',color:'#1a5a84',fontSize:'24px'}}>{school_info.info.school_name}</Typography>
                                <Typography variant='h4' style={{position:'absolute', top:'170px',color:'#fac941',fontWeight:'bold'}}>{template_text.title}</Typography>
                                <Typography variant='h6' style={{position:'absolute', top:'217px',color:'#1a5a84',fontWeight:'bold'}}>{template_text.subtitle}</Typography>
                                <Typography variant='h4' style={{position:'absolute', top:'245px',color:'#fac941'}}>{AppUtils.stylishTextConverter('Student Name')}</Typography>
                                <Typography style={{position:'absolute', top:'295px',color:'#1a5a84',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify'}}>{template_text.desc1}</Typography>
                                <Typography style={{position:'absolute', bottom:'110px',color:'#1a5a84',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'16px',right:'20px'}}>
                                    {AppUtils.stylishTextConverter(template_text.signature)}</Typography>
                            </>
                        }
                        {(selected_template===2) &&
                            <>
                            <img src={school_info.info.photo} alt="image" height={50} style={{position:'absolute',top:'18px'}}/>
                                <Typography variant='h4' style={{position:'absolute', top:'71px',color:'#1b3a4f',fontSize:'24px'}}><u>{school_info.info.school_name}</u></Typography>
                                <Typography variant='h4' style={{position:'absolute', top:'116px',color:'#e8ba24',fontWeight:'bold'}}>{template_text.title}</Typography>
                                <Typography variant='h6' style={{position:'absolute', top:'160px',color:'#1b3a4f',fontWeight:'bold',fontStyle:'italic'}}>{template_text.subtitle}</Typography>
                                <Typography variant='h4' style={{position:'absolute', top:'193px',color:'#e8ba24'}}><u>{AppUtils.stylishTextConverter('Student Name')}</u></Typography>
                                <Typography style={{position:'absolute', top:'250px',color:'#1b3a4f',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'18px'}}>{template_text.desc1}</Typography>
                                <Typography style={{position:'absolute', bottom:'70px',color:'#1b3a4f',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'16px',right:'60px'}}>
                                    {AppUtils.stylishTextConverter(template_text.signature)}</Typography>
                            </>
                        }
                        {(selected_template===3) &&
                            <>
                            <img src={school_info.info.photo} alt="image" height={50} style={{position:'absolute',top:'27px'}}/>
                                <Typography variant='h4' style={{position:'absolute', top:'77px',color:'##98d7fb',fontSize:'24px'}}>{school_info.info.school_name}</Typography>
                                <Typography variant='h4' style={{position:'absolute', top:'112px',color:'#7dd956',fontWeight:'bold'}}>{template_text.title}</Typography>
                                <Typography variant='h6' style={{position:'absolute', top:'1154px',color:'#ff65c5',fontStyle:'italic'}}>{template_text.subtitle}</Typography>
                                <Typography variant='h3' style={{position:'absolute', top:'177px',color:'#fecd2e'}}>{AppUtils.stylishTextConverter('Student Name')}</Typography>
                                <Typography style={{position:'absolute', top:'242px',color:'#1a5a84',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify'}}>{template_text.desc1}</Typography>
                                <Typography style={{position:'absolute', bottom:'120px',color:'#1b3a4f',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'16px',right:'50px'}}>
                                    {AppUtils.stylishTextConverter(template_text.signature)}</Typography>
                            </>
                        }
                        {(selected_template===4) &&
                            <>
                            <img src={school_info.info.photo} alt="image" height={50} style={{position:'absolute',top:'51px'}}/>
                                <Typography variant='h4' style={{position:'absolute', top:'91px',color:'#1a5a84',fontSize:'24px'}}>{school_info.info.school_name}</Typography>
                                <Typography variant='h4' style={{position:'absolute', top:'128px',color:'#fac941',fontWeight:'bold'}}>{template_text.title}</Typography>
                                <Typography style={{position:'absolute', top:'168px',color:'#1a5a84',fontWeight:'bold',fontSize:'18px'}}>{template_text.subtitle}</Typography>
                                <Typography variant='h4' style={{position:'absolute', top:'200px',color:'#1a5a84',fontWeight:'normal'}}>{AppUtils.stylishTextConverter('Student Name')}</Typography>
                                <Typography style={{position:'absolute', top:'260px',color:'#1a5a84',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'18px'}}>{template_text.desc1}</Typography>
                                <Typography style={{position:'absolute', bottom:'105px',color:'#1a5a84',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'16px',right:'50px'}}>
                                    {AppUtils.stylishTextConverter(template_text.signature)}</Typography>
                            </>
                        }
                        {(selected_template===5) &&
                            <>
                            <img src={school_info.info.photo} alt="image" height={50} style={{position:'absolute',top:'12px'}}/>
                                <Typography variant='h4' style={{position:'absolute', top:'60px',color:'#fff',fontSize:'24px'}}>{school_info.info.school_name}</Typography>
                                <Typography variant='h4' style={{position:'absolute', top:'100px',color:'#fff',fontWeight:'bold'}}>{template_text.title}</Typography>
                                <Typography variant='h6' style={{position:'absolute', top:'152px',color:'yellow',fontWeight:'bold'}}>{template_text.subtitle}</Typography>
                                <Typography variant='h4' style={{position:'absolute', top:'194px',color:'#fff',fontSize:'40px'}}>{AppUtils.stylishTextConverter('Student Name')}</Typography>
                                <Typography style={{position:'absolute', top:'269px',color:'#fff',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'20px'}}>{template_text.desc1}</Typography>
                                <Typography style={{position:'absolute', bottom:'90px',color:'#fff',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'16px',right:'40px'}}>
                                    {AppUtils.stylishTextConverter(template_text.signature)}</Typography>
                            </>
                        }
                    </Box>
                }
            </Grid>
        </Grid>
    </Box>
  );
}