import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../APP_HTTP';
import { CheckCircle } from '@mui/icons-material';
import { Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';
const ChangePassword = (props)=> {
  const state = useSelector((state) => state);
  const [pwd_data,setPwdData] = React.useState({old:'',new:'',confirm:''})
  const [pwd_error,setPwdError] = React.useState('')
  const [is_changed,setIsChanged] = React.useState(false);
  const [is_saving,setIsSaving] = React.useState(false);
  const dispatch = useDispatch();
  const initialized = React.useRef(false);
  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  },[props]);
  const handleCloseChangePassword = ()=>{
    props.handleCloseChangePassword();
  }
  const handleChangePwdData = (value,type)=>{
    let pdata = {...pwd_data};
    pdata[type] = value;
    setPwdData(pdata);
  }
  const handleChangePassword = ()=>{
    let oldp = pwd_data.old;
    let newp = pwd_data.new;
    let confirmp = pwd_data.confirm;
    let error = '';
    if(newp!==confirmp){
      error = 'Oops! new password & confirm password does not match';
      setPwdError(error);
      setTimeout(() => {
        setPwdError('');
      }, 5000);
      return false;
    }
    if(oldp==='' || newp==='' || confirmp===''){
      error = 'All field(s) are mandatory';
      setPwdError(error);
      setTimeout(() => {
        setPwdError('');
      }, 5000);
      return false;
    }
    setIsSaving(true);
    let _login = JSON.parse(localStorage.getItem('_login'));
    let username = _login.username;
    pwd_data.username = username;
    APP_HTTP.REQUEST('erp/change_password',{pwd_data},true,true).then((response) => {
      let success = response.data.success;
      let message = response.data.message;
      if(success===1){
        setIsChanged(true);
        setTimeout(() => {
          localStorage.removeItem('MnzYs4pVGL');
          localStorage.removeItem('referer');
          window.location.href = '/login';
        }, 1000);
      }else{
        setPwdError(message);
      }
      setIsSaving(false);
  });
  }
  return (
      <Dialog open={true}>
        {(is_changed===false) &&
          <DialogTitle>Change Password</DialogTitle>
        }
        {(is_changed===false) &&
        <DialogContent>
          <TextField
            margin="dense"
            onChange={(e)=>handleChangePwdData(e.target.value,'old')}
            label="Enter Old Password"
            type="password"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            label="Enter New Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e)=>handleChangePwdData(e.target.value,'new')}
          />
          <TextField
            margin="dense"
            label="Enter Confirm Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e)=>handleChangePwdData(e.target.value,'confirm')}
          />
          <p style={{color:'red'}}>{pwd_error}</p>
        </DialogContent>
      }
      {(is_changed===true) &&
        <DialogContent style={{textAlign:'center'}}>
          <CheckCircle style={{fontSize:'150px', color:'green'}}/>
          <Typography style={{fontSize:'24px',color:'green'}}>Password Changed Successfully</Typography>
        </DialogContent>
      }
      {(is_changed===false) &&
        <DialogActions>
          <Button size='small' onClick={handleCloseChangePassword}>Close</Button>
          {(is_saving===false) &&
          <Button variant='contained' color='error' size='small' onClick={handleChangePassword}>Change Password</Button>
          }
          {(is_saving===true) &&
            <LoaderButton variant='contained' color='error' size='small'/>
          }
        </DialogActions>
    }
      </Dialog>
  );
}
export default ChangePassword;