import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded, Print } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Avatar, Button, Checkbox, Divider, FormControlLabel, FormGroup, InputLabel, MenuItem, NativeSelect, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import LoaderButton from '../../LoaderButton.jsx';
import BasicDatePicker from '../../DatePicker.jsx';
import AppCheckbox from '../../AppCheckbox.jsx';
import Classes from '../classes/Classes.jsx';
import Sections from '../sections/Sections.jsx';
import PrintClassRoutine from './PrintClassRoutine.jsx';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      borderTop: 0,
    },
  }));
let class_routine_init = [
    [{period:1, subject:''},{period:2, subject:''},{period:3, subject:''},{period:4, subject:''},{period:5, subject:''},{period:6, subject:''},{period:7, subject:''}],
    [{period:1, subject:''},{period:2, subject:''},{period:3, subject:''},{period:4, subject:''},{period:5, subject:''},{period:6, subject:''},{period:7, subject:''}],
    [{period:1, subject:''},{period:2, subject:''},{period:3, subject:''},{period:4, subject:''},{period:5, subject:''},{period:6, subject:''},{period:7, subject:''}],
    [{period:1, subject:''},{period:2, subject:''},{period:3, subject:''},{period:4, subject:''},{period:5, subject:''},{period:6, subject:''},{period:7, subject:''}],
    [{period:1, subject:''},{period:2, subject:''},{period:3, subject:''},{period:4, subject:''},{period:5, subject:''},{period:6, subject:''},{period:7, subject:''}],
    [{period:1, subject:''},{period:2, subject:''},{period:3, subject:''},{period:4, subject:''},{period:5, subject:''},{period:6, subject:''},{period:7, subject:''}],
];
let days =['MON','TUE','WED','THU','FRI','SAT'];
const ClassRoutine = (props) => {
  const [selected_class,setClass] = React.useState(0);
  const [selected_section,setSection] = React.useState(0);
  const [is_saving,setIsSaving] = React.useState(false);
  const [is_loading,setIsLoading] = React.useState(false);
  const [is_print,setIsPrint] = React.useState(false);
  const [is_routine_loaded,setIsRoutineLoaded] = React.useState(false);
  const [class_routine,setClassRoutine] = React.useState([]);
  const [printData,setPrintData] = React.useState({});
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
        }
    },[])
    const handleChangeClass = (id,value) =>{
        setClass(value);
    }
   const handleChangeSection = (id,value) =>{
        setSection(value);
   }
   const handleSaveRoutine = () =>{
    setIsSaving(true);
    APP_HTTP.REQUEST('settings/save_class_routine',{class_id:selected_class,section_id:selected_section,routine:class_routine},true,true,false,true).then((response) => {
        setIsSaving(false);
    });
   }
   const handleGenerateRoutine = ()=>{
        setClassRoutine([]);  
        setIsRoutineLoaded(false);
        setIsLoading(true);
        APP_HTTP.REQUEST('settings/get_class_routine',{class_id:selected_class,section_id:selected_section},true,true,false,true).then((response) => {
            if(response.data){
              let resp = response.data;
              let routine = resp.response;
                if(routine.length>0){
                  setClassRoutine(routine[0].routine);
                  setPrintData(routine[0]);
                }else{
                  setClassRoutine(class_routine_init);  
                }
            }
            setIsLoading(false);
            setIsRoutineLoaded(true);
        });
   }
   const handleChangeClassRoutineText = (index,idx,value) =>{
        let cls_routine = [...class_routine];
        cls_routine[index][idx].subject = value
        setClassRoutine(cls_routine);
   }
   const handlePrintRoutine = () =>{
    setIsPrint(true);
    setTimeout(() => {
        setIsPrint(false);
    }, 1500);
   }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Class Routine
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            {(selected_class>0) &&
                                <Sections show="dropdown" handleChangeData={handleChangeSection} defaultValue={selected_section}/>  
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                            {(selected_section>0) &&
                                <>
                                    {(is_loading===false) &&
                                    <Button onClick={handleGenerateRoutine} variant='outlined' size='medium'>Go</Button>
                                    }
                                    {(is_loading===true) &&
                                        <LoaderButton variant='outlined' size='medium'/>
                                    }
                                </>
                            }
                        </Grid>
                        {(is_routine_loaded===true) &&
                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                        <Button onClick={handlePrintRoutine} variant='outlined' size='medium'><Print/> Print</Button>
                        </Grid>
                        }
                    </Grid>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Divider />
                        </Grid>
                        {(is_routine_loaded===true) &&
                            <>
                                <Grid item xs={12} sm={12} md={12} spacing={2}>
                                    <table border={0} cellPadding={10} cellSpacing={0}>
                                        <tr>
                                            <th style={{borderTop: '1px solid black',borderLeft: '1px solid black',borderRight: '1px solid black'}}>DAYS</th>
                                            <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>1</th>
                                            <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>2</th>
                                            <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>3</th>
                                            <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>4</th>
                                            <th rowSpan={7} style={{borderTop: '1px solid black',borderBottom: '1px solid black',borderRight: '1px solid black'}}>
                                                <h1 style={{textOrientation:'upright',writingMode:'vertical-lr',letterSpacing:'50px',margin:'0px'}}>LUNCH</h1>
                                            </th>
                                            <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>5</th>
                                            <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>6</th>
                                            <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>7</th>
                                        </tr>
                                        {(class_routine.map((value,index)=>{
                                            return(
                                                <tr>
                                                <th style={{borderTop: '1px solid black',borderRight: '1px solid black',borderLeft: '1px solid black',borderBottom: (index==5)?'1px solid black':''}}>{days[index]}</th>
                                                {(value.map((routineData,idx)=>{
                                                    return(
                                                        <th style={{borderTop: '1px solid black',borderRight: '1px solid black',borderBottom: (index==5)?'1px solid black':''}}>
                                                            <TextField
                                                                margin="dense"
                                                                label="Subject"
                                                                type="text"
                                                                fullWidth
                                                                size="large"
                                                                defaultValue={routineData.subject}
                                                                onChange={(e)=>handleChangeClassRoutineText(index,idx,e.target.value)}
                                                            />
                                                        </th>
                                                    )
                                                }))}
                                            </tr>
                                            )
                                        }))}
                                        <tr>
                                            <th colSpan={9} style={{borderBottom: '1px solid black',borderRight: '1px solid black',borderLeft: '1px solid black',textAlign:'right'}}>
                                            {(is_saving===false) &&
                                                <Button onClick={handleSaveRoutine} variant='outlined' size='medium'>Save</Button>
                                                }
                                                {(is_saving===true) &&
                                                    <LoaderButton variant='outlined' size='medium'/>
                                                }
                                            </th>
                                        </tr>
                                    </table>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        {(is_print) &&
            <PrintClassRoutine routineData={printData}/>
        }
    </>
  );
}

export default ClassRoutine;