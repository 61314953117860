import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddStaffTypes,handleSaveStaffTypeToList,handleUpdateStaffType } from './Action';
import APP_HTTP from '../../../APP_HTTP';
let initialState = {id:0,name:'',is_edit:false};
const CreateStaffType = (props)=> {
  const [staffTypeItem,setItem] = React.useState(initialState);
  const [staffTypeName,setName] = React.useState('');
  const state = useSelector((state) => state);
  const is_open_add_staff_type = state.is_open_add_staff_type;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(handleOpenAddStaffTypes(false));
  };
  React.useEffect(() => {
      let staffTypeValue = props.staffTypeValue;
      setItem(staffTypeValue);
      setName(staffTypeValue.name);
  },[props]);
  const handleSave = () =>{
    let update_idx = 0;
    let update_id = 0;
    if(props.staffTypeValue && props.staffTypeValue.is_edit && props.staffTypeValue.update_id>=0){
      update_idx = props.staffTypeValue.update_id;
      update_id = props.staffTypeValue.id;
      dispatch(handleUpdateStaffType(update_idx,staffTypeName));
    }
    APP_HTTP.REQUEST('settings/create_staff_type',{id:update_id,name:staffTypeName},true,true).then((response) => {
      let resp = response.data;
      let id = resp.id;
      if(update_id===0){
        staffTypeItem.id = parseInt(id);
        dispatch(handleSaveStaffTypeToList(staffTypeItem));
      }
    });
    handleClose();
  }
  const handleSetName = (e) =>{
    let thisvalue = e.currentTarget.value;
    let clsitem = staffTypeItem;
    clsitem.name = thisvalue;
    setItem(clsitem);
    setName(thisvalue);
  }
  return (
      <Dialog open={is_open_add_staff_type} onClose={handleClose}>
        <DialogTitle>Create Staff Stype</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="staff_type"
            label="Enter Staff Type"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetName}
            value={staffTypeName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSave} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreateStaffType;