import * as React from 'react';
import Button from '@mui/material/Button';
import { Autocomplete, Box, Divider, Grid, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, tableCellClasses } from '@mui/material';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import { styled } from '@mui/material/styles';
import APP_HTTP from '../../APP_HTTP';
import { Close } from '@mui/icons-material';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const modal_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:'90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding:'25px',
  /* maxHeight:'95%',
  overflowY:'auto' */
};
export default function AddOpeningBalance(props) {
  const [isSaving,setIsSave] = React.useState(false);
  const [selected_class,setClass] = React.useState(0);
  const [selected_section,setSection] = React.useState(0);
  const [opening_balance,setOpeningBalance] = React.useState('');
  const [student_list,setStudentListData] = React.useState([]);
  const [opening_balance_list,setOpeningBalanceList] = React.useState([{class:0,student_info:'',opening_balance:''}]);
  let [contact_info,setContactInfo] = React.useState([]);

  const handleChangeValue = (value,idx) =>{
    let obtmp = [...opening_balance_list];
    obtmp[idx].student_info = value;
    setOpeningBalanceList(obtmp);
  }

  const handleChangeClass = (id,value) =>{
    setClass(value);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',class:value,section:selected_section},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setStudentListData(search_list);
    });
    let obtmp = [...opening_balance_list];
    obtmp[id].class = value;
    setOpeningBalanceList(obtmp);
}
const handleChangeSection = (id,value) =>{
    setSection(value);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',class:selected_class,section:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setStudentListData(search_list);
    });
}
const handleChangeInputValue = (value) =>{
    
}
const handleChangeOpeningBalance = (value,idx) =>{
  let obtmp = [...opening_balance_list];
  obtmp[idx].opening_balance = value;
  setOpeningBalanceList(obtmp);
}

const handleClose = () =>{
 props.handleCloseOB();
}
const handleSaveOpeningBalance = () =>{
  setIsSave(true);
  APP_HTTP.REQUEST('student/save_opening_balance',{opening_balance_list},true,true,false,true).then((response) => {
    setIsSave(true);
    handleClose();
  });
}
const handleAddMoreOB = () =>{
  let obtmp = [...opening_balance_list];
  let oblist = {class:0,student_info:'',opening_balance:''};
  obtmp.push(oblist);
  setOpeningBalanceList(obtmp);
}
const handleRemoveItem = (idx) =>{
  let obtmp = [...opening_balance_list];
  obtmp.splice(idx,1);
  setOpeningBalanceList(obtmp);
}
  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 style={{
                    margin: '0px',
                    textAlign: 'left'
                }}>Add Student Back Dues
                </h4><p style={{
                    margin: '0',
                    fontSize: '12px',
                    fontFamily: 'monospace'
                }}>Add student pending amount to the record for future use</p>
          </Typography>
          <Box sx={{maxHeight:'400px',overflowY:'auto'}}>
            <Grid container spacing={2}>
                
                <Grid item xs={12} sm={12} md={12} spacing={2} sx={{marginTop:'10px'}}>
                    <Divider />
                </Grid>

                <Grid item xs={12} sm={12} spacing={2}>
                  <TableContainer component={Paper}>
                    <Table  aria-label="customized table">
                      <TableBody>
                        {(opening_balance_list.map((value,index)=>{
                            return(
                              <StyledTableRow>
                            <StyledTableCell>
                              <Classes show="dropdown" id={index} handleChangeData={handleChangeClass} defaultValue={value.class}/>  
                            </StyledTableCell>
                            <StyledTableCell>
                                <Autocomplete
                                value={value.student_info}
                                noOptionsText={'No ! Student Found'}
                                onChange={(event, newValue) => handleChangeValue(newValue,index)}
                                onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue,index)}
                                disablePortal
                                id="payee_dropdown"
                                options={student_list}
                                size="small"
                                renderInput={(params) => <TextField {...params} label={'Select Student'} />}
                            />
                            </StyledTableCell>
                            <StyledTableCell sx={{width:'200px'}}>
                              <TextField
                                margin="dense"
                                label="Back Dues"
                                type="text"
                                fullWidth
                                size="small"
                                value={value.opening_balance}
                                onChange={(event)=>handleChangeOpeningBalance(event.target.value,index)}
                              />
                            </StyledTableCell>
                            <StyledTableCell style={{textAlign:'right'}}>
                              {((opening_balance_list.length-1)===index || opening_balance_list.length===1) &&
                                <Button variant='outlined' size='size' sx={{marginRight:'10px'}} onClick={handleAddMoreOB}>Add More</Button>
                              }
                              <Close sx={{cursor:'pointer'}} onClick={()=>handleRemoveItem(index)}/>
                            </StyledTableCell>
                          </StyledTableRow>
                            )
                        }))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                
               {/*  <Grid item xs={12} sm={12} md={3} spacing={2}>
                  <Sections show="dropdown" handleChangeData={handleChangeSection} defaultValue={selected_section}/>   
                  
                </Grid> */}
              
            </Grid>
            
          </Box>
          <Typography id="modal-modal-footer">
            <Grid container spacing={2}>    
                <Grid item xs={12} sm={12} md={12} spacing={2} sx={{marginTop:'10px'}}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2} sx={{paddingTop:'10px',textAlign:'right'}}>
                    <Button sx={{float:'left'}} onClick={handleClose}>Close</Button>
                    <Button  autoFocus variant="outlined" onClick={handleSaveOpeningBalance}>
                        Save
                    </Button>
                </Grid>
            </Grid>
          </Typography>
        </Box>
      </Modal>
      
    </div>
  );
}