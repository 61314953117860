import { OPEN_ADD_SECTION,CREATE_SECTION,UPDATE_SECTION, DELETE_ITEM_CONFIRM, DELETE_SECTION ,SET_SECTION_LIST} from "../../../actionTypes/actionTypes";

const handleOpenAddSection = (is_open_add_section) =>{
  return {
    type: OPEN_ADD_SECTION,
    state :{
      is_open_add_section : is_open_add_section,
    }
  };
}
const handleSetSectionList = (section_list) =>{
  return {
    type: SET_SECTION_LIST,
    state :{
      section_list : section_list,
    }
  };
}
const handleSaveSectionToList = (section_list) =>{
  return {
    type: CREATE_SECTION,
    state :{
      section_list : section_list,
    }
  };
}
const handleUpdateSection = (update_id,name) =>{
  return {
    type: UPDATE_SECTION,
    state :{
      update_id : update_id,
      name : name
    }
  };
}
const handleConfirmDeleteSection = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteSection = (index, delete_data) =>{
  return {
    type: DELETE_SECTION,
    state :{
      id : index,
    }
  };
}

export { handleOpenAddSection, handleSaveSectionToList,handleUpdateSection,handleConfirmDeleteSection,handleDeleteSection,handleSetSectionList };