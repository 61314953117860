import { OPEN_ADD_HOLIDAY,CREATE_HOLIDAY,UPDATE_HOLIDAY, DELETE_ITEM_CONFIRM, DELETE_HOLIDAY ,SET_HOLIDAY_LIST} from "../../../actionTypes/actionTypes";

const handleOpenAddHoliday = (is_open_add_holiday) =>{
  return {
    type: OPEN_ADD_HOLIDAY,
    state :{
      is_open_add_holiday : is_open_add_holiday,
    }
  };
}
const handleSetHolidayList = (holiday_list) =>{
  return {
    type: SET_HOLIDAY_LIST,
    state :{
      holiday_list : holiday_list,
    }
  };
}
const handleSaveHolidayToList = (holiday_list) =>{
  return {
    type: CREATE_HOLIDAY,
    state :{
      holiday_list : holiday_list,
    }
  };
}
const handleUpdateHoliday = (update_id,name) =>{
  return {
    type: UPDATE_HOLIDAY,
    state :{
      update_id : update_id,
      name : name
    }
  };
}
const handleConfirmDeleteHoliday = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteHoliday = (index, delete_data) =>{
  return {
    type: DELETE_HOLIDAY,
    state :{
      id : index,
    }
  };
}

export { handleOpenAddHoliday, handleSaveHolidayToList,handleUpdateHoliday,handleConfirmDeleteHoliday,handleDeleteHoliday,handleSetHolidayList };