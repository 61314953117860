import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import PrintHeader from '../../PrintHeader';
import PrintFooter from '../../PrintFooter';
import Watermark from '../../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
let days =['MON','TUE','WED','THU','FRI','SAT'];

const PrintClassRoutine = (props)=> {
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(props.routineData){
                handlePrintClassRoutine();
            }
        }
       
    },[props])
   
   const handlePrintClassRoutine = ()=>{
    if(document.getElementById("routinePrintBlock")){
        var divContents = document.getElementById("routinePrintBlock").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:18px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:center;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
            a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="routinePrintBlock" style={{display:'none'}}>
        <div style={{position:'relative'}}>
        <Watermark />
        <table border={0} cellPadding={10} cellSpacing={0}>
            <PrintHeader header_data={props.routineData.school_info} colSpan={9}/>
            <tr>
                <th colSpan={9}>
                    <h2 style={{marginBottom:'0px'}}>CLASS ROUTINE FOR CLASS {props.routineData.class_name}({props.routineData.section_name})</h2>
                </th>
            </tr>
            <tr>
                <th style={{borderTop: '1px solid black',borderLeft: '1px solid black',borderRight: '1px solid black'}}>DAYS</th>
                <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>1</th>
                <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>2</th>
                <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>3</th>
                <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>4</th>
                <th rowSpan={7} style={{borderTop: '1px solid black',borderBottom: '1px solid black',borderRight: '1px solid black',writingMode:'vertical-lr',padding:'0px'}}>
                    <h1 style={{textOrientation:'upright',letterSpacing:'50px',margin:'0px'}}>LUNCH</h1>
                </th>
                <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>5</th>
                <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>6</th>
                <th style={{borderTop: '1px solid black',borderRight: '1px solid black'}}>7</th>
            </tr>
            {(props.routineData.routine.map((value,index)=>{
                return(
                    <tr>
                    <th style={{borderTop: '1px solid black',borderRight: '1px solid black',borderLeft: '1px solid black',borderBottom: (index==5)?'1px solid black':''}}>{days[index]}</th>
                    {(value.map((routineData,idx)=>{
                        return(
                            <th style={{borderTop: '1px solid black',borderRight: '1px solid black',borderBottom: (index==5)?'1px solid black':''}}>
                               {(routineData.subject!=="")?routineData.subject:'-'}
                            </th>
                        )
                    }))}
                </tr>
                )
            }))}
            <PrintFooter colSpan={9}/>
        </table>
        </div>
    </div>
  );
}
export default PrintClassRoutine;