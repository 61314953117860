import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CreateUser from './CreateUser';
import { Edit } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
import AppDropDown from '../AppDropDown';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function AppUsers(props) {
  const state = useSelector((state) => state);
  const [isCreateUser,setIsCreateUser] = React.useState(false);
  const [filter_type,setFilterType] = React.useState('parent');
  const [editUserInfo,setEditUser] = React.useState({});
  const [users,setUsers] = React.useState([]);
  const [isUserLoading,setIsUserLoading] = React.useState(false);
  const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            getUsers('parent','');
        }
    },[])
  
  const getUsers = (type,search) =>{
    setIsUserLoading(true);
    APP_HTTP.REQUEST('users/get_app_users',{type:type,search:search},true,true).then((response) => {
        let resp = response.data.response;
        setUsers(resp);
        setIsUserLoading(false);
    });
 }
 const handleVerify = (idx,status) =>{
    let uinfo = [...users];
    let id = uinfo[idx].app_user_id;
    uinfo[idx].status=status;
    setUsers(uinfo);
    APP_HTTP.REQUEST('users/mark_app_user_as_verified',{id:id,status:status},true,true).then((response) => {

    });
 }
 const handleShowPassword = (idx,status) =>{
  let uinfo = [...users];
  let id = uinfo[idx].role_id;
  uinfo[idx].sup=status;
  setUsers(uinfo);
 }
 const handleEditUser = (idx) =>{
    let uinfo = [...users];
    let ui = uinfo[idx];
    setEditUser(ui);
    setIsCreateUser(true);
 }
 const handleAppUserType = (id,value)=>{
    setFilterType(value);
    getUsers(value,'');
 }
 const handleSearchUserName = (value)=>{
    if(value.length>2){
      getUsers(filter_type,value);
    }else{
      getUsers(filter_type,'');
    }
 }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>
                      MOBILE APP USERS 
                    </h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Here, We can you find all your mobile app users to allow/revoke user to use mobile app.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(AppUtils.has_access(state,'32_app_users_view')) &&
                  <>
                    <Grid item xs={12} sm={12} md={3} spacing={2} style={{marginTop:'8px'}}>
                      <AppDropDown label="App User Type" list_item={[{key:'staff',value:'Teachers'},{key:'parent',value:'Parents'}]} size="small" handleChangeData={handleAppUserType} defaultValue={filter_type} id={'app_user_type'}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                        <TextField
                            margin="dense"
                            label="Type Mobile Number to Search"
                            type="text"
                            fullWidth
                            size="small"
                            onChange={(e)=>handleSearchUserName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} spacing={2}>
                      
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                      <Grid container spacing={2}>
                        {(users.map((value,index)=>{
                          return(
                            <Grid item xs={12} sm={12} md={3} spacing={2} sx={{marginTop:'10px'}}>
                              <Box sx={{boxShadow:'1px 1px 1px  1px #d7d7d7',padding: '10px 10px',position:'relative'}}>
                              
                              <Typography variant="h2" sx={{fontSize: '18px',textAlign: 'left',fontWeight:'700'}}>
                              <Typography  variant='span'>
                                {value.username} 
                              </Typography>
                              
                            
                              {(parseInt(value.status)===1) &&
                                <>
                                  <Typography variant='span' style={{marginLeft:'5px',fontSize:'10px',padding:'1px 5px',background:'#01c701',color:'#fff'}}>Verified</Typography>
                                  {(AppUtils.has_access(state,'32_app_users_delete')) &&
                                  <Typography variant='span' onClick={()=>handleVerify(index,0)} sx={{fontSize: '10px',color: 'red',marginLeft: '5px', cursor:'pointer' }}>Deny</Typography>
                                  }
                                </>
                              }
                              {(parseInt(value.status)===0) &&
                              <>
                                <Typography  variant='span' style={{marginLeft:'5px',fontSize:'10px',padding:'1px 5px',background:'red',color:'#fff'}}>Unverified</Typography>
                                {(AppUtils.has_access(state,'32_app_users_verify')) &&
                                <Typography variant='span' onClick={()=>handleVerify(index,1)} sx={{fontSize: '10px',color: '#01c701',marginLeft: '5px', cursor:'pointer' }}>Verify</Typography>
                                }
                              </>
                              }
                              
                              </Typography>
                              {(value.type==="student") &&
                                <Typography sx={{fontSize:'12px'}}>{(value.contact_info[0]!==undefined && value.contact_info[0].student_name!==undefined)?value.contact_info[0].student_name:''} (student)</Typography>
                              }
                              {(value.type==="parent") &&
                                <Typography sx={{fontSize:'12px'}}>{(value.contact_info[0]!==undefined && value.contact_info[0].father_name!==undefined)?value.contact_info[0].father_name:''} (parent)</Typography>
                              }
                              {(value.type==="staff") &&
                                <Typography sx={{fontSize:'12px'}}>{value.contact_info[0].staff_name} (Teacher)</Typography>
                              }
                              <Typography variant="span" style={{fontSize:'12px'}}>Password : {(value.sup===true)?value.up:'XXXXXX'}</Typography>
                              {(AppUtils.has_access(state,'32_app_users_show_password')) &&
                              <>
                              {(value.sup===false) &&
                                <Typography variant="span" onClick={()=>handleShowPassword(index,true)} sx={{fontSize: '10px',color: 'blue', cursor:'pointer',marginLeft:'5px' }}>Show Password</Typography>
                              }
                              {(value.sup===true) &&
                                <Typography variant="span" onClick={()=>handleShowPassword(index,false)} sx={{fontSize: '10px',color: 'blue', cursor:'pointer',marginLeft:'5px' }}>Hide Password</Typography>
                              }
                              </>
                              }
                              </Box>
                            </Grid>
                          )
                        }))}
                      {(isUserLoading===true) &&
                      <Grid item xs={12} sm={12} md={3} spacing={2} sx={{marginTop:'10px'}}>
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" sx={{width:'100%'}} height={80} />
                        </Stack>
                      </Grid>
                      }
                      </Grid>
                    </Grid>
                  </>
                }
            </Grid>
        </Box>
    </Box>
  );
}
export default React.memo(AppUsers);