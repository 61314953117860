import { SET_ACCESS_CHECK, CHANGE_MENU,OPEN_ADD_CLASS, CHECK_ACCESS,SET_SCHOOL_INFO } from "../actionTypes/actionTypes";
const handleUpdateURL = (main_menu,sub_menu,menu_label) =>{
  let url = '/'+main_menu;
  if(sub_menu!==''){
    url = url+'/'+sub_menu;
  }
  const nextState = {};
  window.history.pushState(nextState, menu_label, url);
}
const handleChangeMenu = (main_menu,sub_menu,menu_label,is_enable_add_btn) =>{
  handleUpdateURL(main_menu,sub_menu,menu_label);
  return {
    type: CHANGE_MENU,
    state :{
      main_menu : main_menu,
      sub_menu : sub_menu,
      menu_label : menu_label,
      is_enable_add_btn : is_enable_add_btn
    }
  };
}
const handleSetAccessCheck = (access_level) =>{
  return {
    type: SET_ACCESS_CHECK,
    state :{
      access_info : {access_level:access_level}
    }
  };
}
const handleSetSchoolInfo = (school_info) =>{
  return {
    type: SET_SCHOOL_INFO,
    state :{
      school_info : {school_info:school_info}
    }
  };
}

export { handleChangeMenu,handleSetAccessCheck,handleSetSchoolInfo };