import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Checkbox, Divider, FormControlLabel, InputLabel, MenuItem, NativeSelect, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, tableCellClasses } from '@mui/material';

import { useSelector, useDispatch } from "react-redux";
import AppDropDown from '../../AppDropDown.jsx';
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppUtils from '../../../AppUtils.jsx';
import { Close, HandshakeSharp } from '@mui/icons-material';
import LoaderButton from '../../LoaderButton.jsx';
import StartNewSession from './StartNewSession.jsx';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



const SessionAndYear = (props) => {
    const [month_list,setMonthList] = React.useState([{key:0,value:'Apr'},{key:1,value:'May'},{key:2,value:'Jun'},{key:3,value:'Jul'},{key:4,value:'Aug'},{key:5,value:'Sep'},{key:6,value:'Oct'},{key:7,value:'Nov'},{key:8,value:'Dec'},{key:9,value:'Jan'},{key:10,value:'Feb'},{key:11,value:'Mar'}]);
    
    const [session_month,setSessionMonth] = React.useState(0);
    const [is_attach_next_month,setIsAttachNextMonth] = React.useState(false);
    const [is_loading,setIsLoading] = React.useState(true);
    const [isSaving,setIsSaving] = React.useState(false);
    const [session_list,setSessionList] = React.useState([]);
    const [session_years,setSessionYears] = React.useState([]);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const school_info = state.school_info.school_info;
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleSetSessonYears();
            APP_HTTP.REQUEST('settings/get_option',{name:'session_month'},true,true).then((response) => {
                let resp = response.data.response;
                if(resp.value!==undefined){
                    setSessionMonth(resp.value);
                }
                setIsLoading(false);
            });
            APP_HTTP.REQUEST('settings/get_option',{name:'attach_next_month_in_demand_bill'},true,true).then((response) => {
                let resp = response.data.response;
                if(resp.value!==undefined){
                    setIsAttachNextMonth(resp.value);
                }
            });
            APP_HTTP.REQUEST('settings/get_academic_session',{},true,true).then((response) => {
                let resp = response.data.response;
                if(resp.length>0){
                    setSessionList(resp)
                }else{
                    setSessionList([{id:'',start:'',end:''}]);
                }
            });
        }
    },[dispatch])

    const handleSetSessonYears = () =>{
        let syear = [...session_years];
        let sesison_start_year = 2020;
        let sesison_end_year = 2050;
        for (let index = sesison_start_year; index <= sesison_end_year; index++) {
            let build_year = {key:index,value:index};
            syear.push(build_year)
        }
        setSessionYears(syear);
    }

    
      const handleAddMore = () =>{
        let hol_list = [...session_list];
        hol_list.push({id:'',start:'',end:''})
        setSessionList(hol_list);
      }
      const handleRemoveItem = (index) =>{
        let hol_list = [...session_list];
        hol_list.splice(index,1)
        setSessionList(hol_list);
        
      }

    const handleChangeMonth = (id,value) =>{
        setSessionMonth(value);
        APP_HTTP.REQUEST('settings/save_option',{name:'session_month',value:value},true,true).then((response) => {
            let resp = response.data;
        });
    }
    const handleChangeStartSession = (id,value) =>{
        let secitem = [...session_list];
        secitem[id].start = value;
        setSessionList(secitem);
    }
    const handleChangeEndSession = (id,value) =>{
        let secitem = [...session_list];
        secitem[id].end = value;
        setSessionList(secitem);
    }

    const handleSaveAcademicSession = () =>{
        setIsSaving(true);
        APP_HTTP.REQUEST('settings/save_academic_session',{session_list},true,true).then((response) => {
            setIsSaving(false);
        });
    }

    const handleAttachNextMonthDB = (is_checked) =>{
        setIsAttachNextMonth(is_checked);
        APP_HTTP.REQUEST('settings/save_option',{name:'attach_next_month_in_demand_bill',value:is_checked},true,true).then((response) => {
            let resp = response.data;
        });
    }
  return (
   
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Financial & Session Settings
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px',marginTop:'30px'}}>
                    <Grid container spacing={2} className="">
                        {(school_info.is_college===true) &&
                        <Grid item xs={12} sm={12} md={9} spacing={2} style={{paddingTop:'8px'}}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                 
                                    <TableBody>
                                        {(session_list.map((value,index)=>{
                                            return (
                                                <StyledTableRow style={{background:'#fff'}}>
                                            <StyledTableCell>
                                            {(session_years.length>0) &&
                                            <>
                                                {(value.start!=="") &&
                                                    <>
                                                    <AppDropDown label="Session Start Year" list_item={session_years} size="small" handleChangeData={handleChangeStartSession} defaultValue={value.start} id={index}/>
                                                    </>
                                                }
                                                {(value.start==="") &&
                                                    <AppDropDown label="Session Start Year" list_item={session_years} size="small" handleChangeData={handleChangeStartSession} defaultValue={2000} id={index}/>
                                                }
                                            </>
                                            }
                                            </StyledTableCell>
                                            <StyledTableCell>
                                            {(session_years.length>0) &&
                                            <>
                                                {(value.end!=="") &&
                                                    <AppDropDown label="Session End Year" list_item={session_years} size="small" handleChangeData={handleChangeEndSession} defaultValue={value.end} id={index}/>
                                                }
                                                {(value.end==="") &&
                                                    <AppDropDown label="Session End Year" list_item={session_years} size="small" handleChangeData={handleChangeEndSession} defaultValue={value.end} id={index}/>
                                                }
                                            </>
                                            }
                                            </StyledTableCell>
                                            <StyledTableCell style={{textAlign:'right',position:'relative'}}>
                                           {/*  {(session_list.length>1) &&
                                                <Close onClick={()=>handleRemoveItem(index)} style={{position:'absolute',right:'112px',bottom:'24px'}}/>
                                            } */}
                                            {((session_list.length-1)===index) &&
                                                <Button onClick={handleAddMore}>Add More</Button>
                                            }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                            )
                                        }))}
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={3} style={{textAlign:'right'}}>
                                                {(isSaving===true) &&
                                                    <LoaderButton variant="outlined"/>
                                                }
                                                {(isSaving===false) &&
                                                    <Button variant="outlined" onClick={handleSaveAcademicSession}>
                                                        Save Academic Session
                                                    </Button>
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                      </Table>
                    </TableContainer>
                        </Grid>
                        }
                        <Grid item xs={12} sm={12} md={3} spacing={2} style={{paddingTop:'8px',display:'none'}}>
                            {(is_loading===false) &&
                                <AppDropDown label="Financial Session Start Month" list_item={month_list} size="small" handleChangeData={handleChangeMonth} defaultValue={session_month} id={'session_month'}/>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                            <FormControlLabel
                                control={<Checkbox value="next_month_demand_bill" color="primary" onChange={(e)=>handleAttachNextMonthDB(e.target.checked)} checked={is_attach_next_month}/>}
                                label="Attach next month fee in Demand Bill"
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <StartNewSession />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
  );
}

export default SessionAndYear;