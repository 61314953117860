import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CreateUser from './CreateUser';
import { Edit } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function Users(props) {
  const state = useSelector((state) => state);
  const [isCreateUser,setIsCreateUser] = React.useState(false);
  const [editUserInfo,setEditUser] = React.useState({});
  const [users,setUsers] = React.useState([]);
  const [isUserLoading,setIsUserLoading] = React.useState(false);
  const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            getUsers();
        }
    },[])
  const handleCloseCreate = () =>{
    setIsCreateUser(false);
  }
  const handleOpenCreateUser = () =>{
    setIsCreateUser(true);
  }
  const handleRefreshList = () =>{
    getUsers();
  }
  const getUsers = () =>{
    setIsUserLoading(true);
    APP_HTTP.REQUEST('users/get_users',{},true,true).then((response) => {
        let resp = response.data.response;
        setUsers(resp);
        setIsUserLoading(false);
    });
 }
 const handleVerify = (idx,status) =>{
  let uinfo = [...users];
  let id = uinfo[idx].user_id;
  uinfo[idx].status=status;
  setUsers(uinfo);
  APP_HTTP.REQUEST('users/mark_user_as_verified',{id:id,status:status},true,true).then((response) => {

  });
 }
 const handleShowPassword = (idx,status) =>{
  let uinfo = [...users];
  let id = uinfo[idx].role_id;
  uinfo[idx].sup=status;
  setUsers(uinfo);
 }
 const handleEditUser = (idx) =>{
    let uinfo = [...users];
    let ui = uinfo[idx];
    setEditUser(ui);
    setIsCreateUser(true);
 }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>
                      USERS 
                      {(AppUtils.has_access(state,'19_users_add')) &&
                        <Button variant="outlined" sx={{float:'right'}} onClick={handleOpenCreateUser}>Create New User</Button>
                      }
                    </h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Here, We can create users to get access of the application.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(AppUtils.has_access(state,'19_users_view')) &&
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2}>
                  {(users.map((value,index)=>{
                    return(
                      <Grid item xs={12} sm={12} md={3} spacing={2} sx={{marginTop:'10px'}}>
                        <Box sx={{boxShadow:'1px 1px 1px  1px #d7d7d7',padding: '10px 10px',position:'relative'}}>
                        {(AppUtils.has_access(state,'19_users_edit')) &&
                          <Typography sx={{position:'absolute',right:'8px',cursor:'pointer'}} onClick={()=>handleEditUser(index)}><Edit sx={{fontSize:'18px'}}/></Typography>
                        }
                        <Typography variant="h2" sx={{fontSize: '18px',textAlign: 'left',fontWeight:'700'}}>
                        <Typography  variant='span'>
                          {value.user_name} 
                        </Typography>
                        
                      
                        {(parseInt(value.status)===1 && value.user_id>1) &&
                          <>
                            <Typography variant='span' style={{marginLeft:'5px',fontSize:'10px',padding:'1px 5px',background:'#01c701',color:'#fff'}}>Verified</Typography>
                            {(AppUtils.has_access(state,'19_users_delete')) &&
                            <Typography variant='span' onClick={()=>handleVerify(index,0)} sx={{fontSize: '10px',color: 'red',marginLeft: '5px', cursor:'pointer' }}>Deny</Typography>
                            }
                          </>
                        }
                        {(parseInt(value.status)===0 && value.user_id>1) &&
                        <>
                          <Typography  variant='span' style={{marginLeft:'5px',fontSize:'10px',padding:'1px 5px',background:'red',color:'#fff'}}>Unverified</Typography>
                          {(AppUtils.has_access(state,'19_users_verify')) &&
                          <Typography variant='span' onClick={()=>handleVerify(index,1)} sx={{fontSize: '10px',color: '#01c701',marginLeft: '5px', cursor:'pointer' }}>Verify</Typography>
                          }
                        </>
                        }
                        
                        </Typography>
                        {(value.mobile!=="") &&
                          <Typography sx={{fontSize:'12px'}}>+91 {value.mobile}</Typography>
                        }
                        <Typography sx={{fontSize:'12px'}}>{value.role_name}</Typography>
                        <Typography variant="span" style={{fontSize:'12px'}}>Password : {(value.sup===true)?value.up:'XXXXXX'}</Typography>
                        {(AppUtils.has_access(state,'19_users_show_password')) &&
                        <>
                        {(value.sup===false) &&
                          <Typography variant="span" onClick={()=>handleShowPassword(index,true)} sx={{fontSize: '10px',color: 'blue', cursor:'pointer',marginLeft:'5px' }}>Show Password</Typography>
                        }
                        {(value.sup===true) &&
                          <Typography variant="span" onClick={()=>handleShowPassword(index,false)} sx={{fontSize: '10px',color: 'blue', cursor:'pointer',marginLeft:'5px' }}>Hide Password</Typography>
                        }
                        </>
                        }
                        </Box>
                      </Grid>
                    )
                  }))}
                {(isUserLoading===true) &&
                <Grid item xs={12} sm={12} md={3} spacing={2} sx={{marginTop:'10px'}}>
                  <Stack spacing={1}>
                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={80} />
                  </Stack>
                </Grid>
                }
                </Grid>
                </Grid>
                }
            </Grid>
        </Box>
        {(isCreateUser===true) &&
          <CreateUser handleClose={handleCloseCreate} handleRefreshList={handleRefreshList} data={editUserInfo}/>
        }
    </Box>
  );
}
export default React.memo(Users);