import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddSubject,handleSaveSubjectToList,handleUpdateSubject } from './Action';
import APP_HTTP from '../../../APP_HTTP';
let initialState = {id:0,name:'',is_edit:false};
const CreateSubject = (props)=> {
  const [subjectItem,setSubjectItem] = React.useState(initialState);
  const [subjectName,setSubjectName] = React.useState('');
  const [fullMarks,setFullMarks] = React.useState('');
  const [passMarks,setPassMarks] = React.useState('');
  const state = useSelector((state) => state);
  const is_open_add_subject = state.is_open_add_subject;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(handleOpenAddSubject(false));
  };
  React.useEffect(() => {
      let subjectValue = props.subjectValue;
      setSubjectItem(subjectValue);
      setSubjectName(subjectValue.name);
      setFullMarks(subjectValue.full_marks);
      setPassMarks(subjectValue.pass_marks);
  },[props]);
  const handleSaveClass = () =>{
    let update_idx = 0;
    let update_id = 0;
    if(props.subjectValue && props.subjectValue.is_edit && props.subjectValue.update_id>=0){
      update_idx = props.subjectValue.update_id;
      update_id = props.subjectValue.id;
      dispatch(handleUpdateSubject(update_idx,subjectName));
    }
    APP_HTTP.REQUEST('settings/create_subject',{id:update_id,name:subjectName,full_marks:fullMarks,pass_marks:passMarks},true,true).then((response) => {
      let resp = response.data;
      let id = resp.id;
      if(update_id===0){
        subjectItem.id = parseInt(id);
        dispatch(handleSaveSubjectToList(subjectItem));
      }
    });

    handleClose();
  }
  const handleSetSubjectName = (e) =>{
    let thisvalue = e.currentTarget.value;
    let secitem = subjectItem;
    secitem.name = thisvalue;
    setSubjectItem(secitem);
    setSubjectName(thisvalue);
  }
  const handleSetFullMarks = (e) =>{
    let thisvalue = e.currentTarget.value;
    let secitem = subjectItem;
    secitem.full_marks = thisvalue;
    setSubjectItem(secitem);
    setFullMarks(thisvalue);
  }
  const handleSetPassMarks = (e) =>{
    let thisvalue = e.currentTarget.value;
    let secitem = subjectItem;
    secitem.pass_marks = thisvalue;
    setSubjectItem(secitem);
    setPassMarks(thisvalue);
  }
  return (
      <Dialog open={is_open_add_subject} onClose={handleClose}>
        <DialogTitle>Create Subject</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="subject_name"
            label="Enter Subject Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetSubjectName}
            value={subjectName}
          />
          <TextField
            autoFocus
            margin="dense"
            id="full_marks"
            label="Enter Subject Full Marks"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetFullMarks}
            value={fullMarks}
          />
          <TextField
            autoFocus
            margin="dense"
            id="pass_marks"
            label="Enter Subject Pass Marks"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetPassMarks}
            value={passMarks}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSaveClass} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreateSubject;