import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, FormLabel, InputAdornment, InputLabel, MenuItem, NativeSelect, OutlinedInput, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import LoaderButton from '../../LoaderButton.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import { Textarea } from '@mui/joy';
import Draggable from 'react-draggable';

const HomeworkSettings = (props) => {
    let draggable_list_temp = [
        {key:'class',value:'CLASS',is_checked:false,position:{x:0,y:0},cssText:''},
        {key:'section',value:'SECTION',is_checked:false,position:{x:0,y:0},cssText:''},
        {key:'date',value:'DATE',is_checked:false,position:{x:0,y:0},cssText:''}
    ];
    let design_data_tmp = {design_name:'',draggable_header:[],page_height:1122,page_width:793,page_header_height:312,page_body_height:721,page_body_pad_right:28,subject_list_name_width:163,subject_list_name_height:85,page_body_pad_left:35,number_of_items:[1,2,3,4,5,6,7,8]};
    const [header_type, setValue] = React.useState('default');
    const [image_url,setImageURL] = React.useState('');
    
    const [is_loading_design,setIsLoading] = React.useState(true);
    const [is_saving_header,setSavingHeader] = React.useState(false);

    const [draggable_list,setDraggableList] = React.useState(draggable_list_temp);
    const [design_data,setDesignData] = React.useState(design_data_tmp);
    const handleChange = (event) => {
      setValue(event.target.value);
    };
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetHomeworkTemplate();
           
        }
    },[])
  
    const handleGetHomeworkTemplate = ()=>{
        APP_HTTP.REQUEST('settings/get_option',{name:'homework_type'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value!==undefined && resp.value.header_type!==undefined){
                setValue(resp.value.header_type);
                
                if(resp.value.header!==undefined){
                    let img_url = resp.value.header+'?time='+Math.random(1111,9999);
                    setImageURL(img_url);
                }
                
            }
        });
        
        APP_HTTP.REQUEST('settings/get_option',{name:'homework_template_design'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value!==undefined){
                let drag_list = resp.value.draggable_list;
                setDraggableList(drag_list);
                setDesignData(resp.value);
            }
            setIsLoading(false);
        });
      
    }
   
    const handleUploadFileClick = () =>{
        if(document.getElementById("upload_file")){
            document.getElementById("upload_file").click();   
        }
    }
    const handleUploadFile = (e) =>{
        const files = e.target.files;
        if(files[0]){
            var url = URL.createObjectURL(files[0]);
            setImageURL(url);
        }
    }
    const handleSaveSettings = (e) =>{
        let ddata = {...design_data};
        ddata.draggable_list = draggable_list;
        const formData = new FormData();
        if(document.getElementById("upload_file")){
            let upload_file = document.getElementById("upload_file");
            const files = upload_file.files;
            let photo = files[0];
            if(photo!==undefined){
                setSavingHeader(true);
                formData.append('photo', photo);
                formData.append('header_type', header_type);
                formData.append('design_data', JSON.stringify(ddata));
                APP_HTTP.REQUEST('settings/save_homework_settings',formData,true,true,true).then((response) => {
                    setSavingHeader(false);
                });
            }else{
                setSavingHeader(true);
                formData.append('photo', '');
                formData.append('header_type', header_type);
                formData.append('design_data', JSON.stringify(ddata));
                APP_HTTP.REQUEST('settings/save_homework_settings',formData,true,true,true).then((response) => {
                    setSavingHeader(false);
                });
            }
        }else{
            setSavingHeader(true);
            formData.append('photo', '');
            formData.append('header_type', header_type);
            formData.append('design_data', JSON.stringify(ddata));
            APP_HTTP.REQUEST('settings/save_homework_settings',formData,true,true,true).then((response) => {
                setSavingHeader(false);
            });
        }
    }
    const handleCheckDraggableItems = (is_checked,name,idx) =>{
        let dr_list = [...draggable_list];
        dr_list[idx].is_checked=is_checked;
        setDraggableList(dr_list);
        let ddata = {...design_data};
        let draggable_header = [];
        for (let index = 0; index < dr_list.length; index++) {
            const element = dr_list[index];
            if(element.is_checked===true){
                draggable_header.push(element);
            }
        }
        ddata.draggable_header = draggable_header;
        setDesignData(ddata)
    }
    const handleChangeDesignValue = (type,value) =>{
        let ddata = {...design_data};
        if(type==='number_of_items'){
          let items = [];
          for (let index = 0; index < value; index++) {
            items.push(index);
          }
          value = items;
        }
        ddata[type] = value;
        setDesignData(ddata)
      }

      const handleStart = (e) =>{
        //console.log(e);
      }
      const handleDrag = (e) =>{
        //console.log(e);
      }
      const handleStop = (event, data) =>{
        let ddata = {...design_data};
        let draggable_header = ddata.draggable_header;
        
        let idx = parseInt(data.node.id);
        
        let cssText = data.node.style.cssText;
        let x = data.x;
        let y = data.y;
        let dh = {x:x,y:y};
        draggable_header[idx].position = dh;
        draggable_header[idx].cssText = cssText;
        ddata.draggable_header=draggable_header;
        setDesignData(ddata);
      }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Homework Settings
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10">
                       
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">SELECT HOMEWORK TYPE</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={header_type}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="default" control={<Radio/>} label="Default" />
                                <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                            </RadioGroup>
                            </FormControl>
                            
                        </Grid>
                        {(header_type==='custom' && is_loading_design===false) &&
                            <>
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <Typography variant='h5'>DESIGN HOMEWORK TEMPLATE</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <Typography>PAGE DIMENTION</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} spacing={2}>
                                        <TextField
                                        size='small'
                                            label="Page Width"
                                            id="outlined-start-adornment"
                                            sx={{ m: 1 }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">px</InputAdornment>,
                                            }}
                                            onChange={(e)=>handleChangeDesignValue('page_width',e.target.value)} value={design_data.page_width} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} spacing={2}>
                                    <TextField
                                    size='small'
                                        label="Page Height"
                                        id="outlined-start-adornment"
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">px</InputAdornment>,
                                        }}
                                        onChange={(e)=>handleChangeDesignValue('page_height',e.target.value)} value={design_data.page_height} 
                                    />
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                
                                <Grid container spacing={2}>
                                    
                                    <Grid item xs={12} sm={12} md={4} spacing={2}>
                                    <TextField
                                    size='small'
                                        label="Page Header Height"
                                        id="outlined-start-adornment"
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">px</InputAdornment>,
                                        }}
                                        onChange={(e)=>handleChangeDesignValue('page_header_height',e.target.value)} value={design_data.page_header_height} 
                                    />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} spacing={2}>
                                        <TextField
                                        size='small'
                                            label="Page Body Height"
                                            id="outlined-start-adornment"
                                            sx={{ m: 1 }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">px</InputAdornment>,
                                            }}
                                            onChange={(e)=>handleChangeDesignValue('page_body_height',e.target.value)} value={design_data.page_body_height} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} spacing={2}></Grid>
                                   
                                    
                                    <Grid item xs={12} sm={12} md={4} spacing={2}>
                                        <TextField
                                        size='small'
                                            label="Page Body Padding Left"
                                            id="outlined-start-adornment"
                                            sx={{ m: 1 }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">px</InputAdornment>,
                                            }}
                                            onChange={(e)=>handleChangeDesignValue('page_body_pad_left',e.target.value)} value={design_data.page_body_pad_left} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} spacing={2}>
                                        <TextField
                                            size='small'
                                            label="Page Body Padding Right"
                                            id="outlined-start-adornment"
                                            sx={{ m: 1 }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">px</InputAdornment>,
                                            }}
                                            onChange={(e)=>handleChangeDesignValue('page_body_pad_right',e.target.value)} value={design_data.page_body_pad_right} 
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(design_data.page_width!==undefined && design_data.page_height!==undefined && design_data.page_width!=="" && design_data.page_height!=="") &&
                                <>
                                     <Grid item xs={12} sm={12} md={12} spacing={2}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                                        <input type='file' id="upload_file" accept="image/png,image/jpg, image/jpeg" style={{display:'none'}} onChange={handleUploadFile}/>
                                        <Button variant="outlined" onClick={handleUploadFileClick} style={{marginBottom:'10px'}}>Upload Background Image From Gallary</Button>
                                        
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                                    <Typography>Select the following header to show in homework template</Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                                        {(draggable_list.map((value,index)=>{
                                            return(
                                                <FormControlLabel
                                                control={<Checkbox value="remember" color="primary" checked={value.is_checked} onChange={(e)=>handleCheckDraggableItems(e.target.checked,value.key,index)}/>}
                                                label={value.value}
                                                />
                                            )
                                        }))}
                                    </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                                        <Divider />
                                    </Grid>
                                  
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                        <TextField
                                            size='small'
                                            margin="normal"
                                            fullWidth
                                            id="number_of_items"
                                            label="Number Of Subjects In the list"
                                            name="number_of_items"
                                            onChange={(e)=>handleChangeDesignValue('number_of_items',e.target.value)} value={design_data.number_of_items.length} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                        <TextField
                                            size='small'
                                            label="Subject Name Box Height"
                                            id="outlined-start-adornment"
                                            sx={{ m: 1 }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">px</InputAdornment>,
                                            }}
                                            onChange={(e)=>handleChangeDesignValue('subject_list_name_height',e.target.value)} value={design_data.subject_list_name_height} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                        <TextField
                                            size='small'
                                            label="Subject Name Box Width"
                                            id="outlined-start-adornment"
                                            sx={{ m: 1 }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">px</InputAdornment>,
                                            }}
                                            onChange={(e)=>handleChangeDesignValue('subject_list_name_width',e.target.value)} value={design_data.subject_list_name_width} 
                                        />
                                    </Grid>
                                   
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                                        <Box style={{border:'1px solid black',height:(design_data.page_height)+'px',width:(design_data.page_width)+'px',backgroundImage:(image_url!=="")?'url('+image_url+')':'',backgroundSize: "contain",backgroundRepeat: "no-repeat"}}>
                                            {(design_data.page_header_height!==undefined && design_data.page_header_height!=="") &&
                                            <Box style={{border:'2px solid green',height:(design_data.page_header_height)+'px',width:'100%',position:'relative'}}>
                                                {(design_data.draggable_header!==undefined) && 
                                          <>
                                          {(design_data.draggable_header.map((value,index)=>{
                                            return(
                                              <Draggable
                                                handle=".handle"
                                                position={null}
                                                defaultPosition={value.position}
                                                bounds="parent"
                                                grid={[1, 1]}
                                                scale={1}
                                                onStart={handleStart}
                                                onDrag={handleDrag}
                                                onStop={handleStop} key={value.key}>

                                                <Box id={index}>
                                                  <Box className="handle">
                                                      <Typography variant='label' style={{background:'gray',color:'#fff',padding:'5px',borderRadius:'5px',cursor:'all-scroll'}}>{value.value}</Typography>
                                                  </Box>
                                                </Box>
                                              </Draggable>
                                            )
                                          }))}
                                            
                                          </>
                                        }
                                            </Box>
                                            }
                                            {(design_data.page_body_height!==undefined && design_data.page_body_height!=="") &&
                                            <Box style={{border:'2px solid red',height:(design_data.page_body_height)+'px',width:'100%',paddingLeft:(design_data.page_body_pad_left)+'px',paddingRight:(design_data.page_body_pad_right)+'px'}}>
                                                <table style={{border:'1px solid red',width:'100%',height:'100%'}} cellPadding={0} cellSpacing={0}>
                                                    <tbody>
                                                        {(design_data.number_of_items.map((value,index)=>{
                                                            return(
                                                                <tr>
                                                                    <td style={{height:design_data.subject_list_name_height+'px',width:design_data.subject_list_name_width+'px',border:'1px solid red'}}>
                                                                        Subject
                                                                    </td>
                                                                    <td style={{border:'1px solid red'}}>
                                                                        Homework Content
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }))}
                                                    </tbody>
                                                </table>
                                            </Box>
                                            }
                                        </Box>
                                    </Grid>
                                    
                                </>
                            }
                            </>
                        }
                        
                        <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                        {(is_saving_header===false) &&
                            <Button variant="contained" onClick={() => handleSaveSettings()}>Save Settings</Button>
                        }
                        {(is_saving_header===true) &&
                            <LoaderButton label="Save Settings"/>
                        }
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} spacing={2}><Divider/></Grid>
                    </Grid>
                </Grid>
            </Grid>
           
        </Box>
    </>
  );
}

export default HomeworkSettings;