import { DELETE_ITEM_CONFIRM,DELETE_TRANSPORT,SET_TRANSPORTS_LIST,IS_TRANSPORT_EDIT,SET_TRANSPORT_INFO,IS_TRANSPORT_VIEW } from "../../actionTypes/actionTypes";

const handleConfirmDeleteTransport = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteTransport = (index, delete_data) =>{
  return {
    type: DELETE_TRANSPORT,
    state :{
      id : index,
    }
  };
}
const handleSetTransportsList = (transports_list) =>{
    return {
      type: SET_TRANSPORTS_LIST,
      state :{
        transports_list : transports_list,
      }
    };
  }
const handleToggleEditTransport = (is_transport_edit) =>{
    return {
      type: IS_TRANSPORT_EDIT,
      state :{
        is_transport_edit : is_transport_edit,
      }
    };
  }
const handleToggleViewTransport = (is_transport_view) =>{
    return {
      type: IS_TRANSPORT_VIEW,
      state :{
        is_transport_view : is_transport_view,
      }
    };
  }
const handleSetTransportInfo = (transport_info) =>{
    return {
      type: SET_TRANSPORT_INFO,
      state :{
        transport_info : transport_info,
      }
    };
  }

export { handleConfirmDeleteTransport, handleDeleteTransport,handleSetTransportsList,handleToggleEditTransport,handleSetTransportInfo,handleToggleViewTransport};