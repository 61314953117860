import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ContactsIcon from '@mui/icons-material/Contacts';
import { green, orange, pink, red } from '@mui/material/colors';
import { ArrowDownward, ArrowUpward, CurrencyRupee, Group } from '@mui/icons-material';
import { Divider, appBarClasses } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AppUtils from '../../AppUtils';
import CircularProgress from '@mui/material/CircularProgress';
import APP_HTTP from '../../APP_HTTP';
import StudentCard from '../students/StudentCard';
import StaffCard from '../staffs/StaffCard';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const AppDashboard = () => {
    const initialized = React.useRef(false);
    const [student_count,setStudentCount] = React.useState('');
    const [staff_count,setStaffCount] = React.useState('');
    const [this_month_earnings,setThisMonthEarnings] = React.useState('');
    const [last_month_earnings,setLastMonthEarnings] = React.useState('');

    const [this_month_expenses,setThisMonthExpenses] = React.useState('');
    const [last_month_expenses,setLastMonthExpenses] = React.useState('');
    const [latest_earnings,setLatestEarnings] = React.useState('');
    const [latest_expenses,setLatestExpenses] = React.useState('');

    const [students_leave_request,setStudentsLeaveRequest] = React.useState('');
    const [staffs_leave_request,setStaffsLeaveRequest] = React.useState('');

    const [student_absent_list,setStudentAbsentList] = React.useState('');
    const [staff_absent_list,setStaffAbsentList] = React.useState('');

    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetStudentCount();
            handleGetStaffCount();
            handleGetLeaveRequest('student');
            handleGetLeaveRequest('staff');
            handleGetEarnings('this_month');
            handleGetExpenses('this_month');
            handleGetEarnings('last_month');
            handleGetExpenses('last_month');
            handleGetLatestEarnings();
            handleGetLatestExpenses();
            handleGetAbsentList('student');
            handleGetAbsentList('staff');
        }
    },[])


    const handleGetStudentCount = () =>{
      APP_HTTP.REQUEST('dashboard/get_student_count',{},true,true).then((response) => {
          let resp = response.data;
          let total = resp.total;
          setStudentCount(total);
      });
    }
    const handleGetStaffCount = () =>{
      APP_HTTP.REQUEST('dashboard/get_staff_count',{},true,true).then((response) => {
          let resp = response.data;
          let total = resp.total;
          setStaffCount(total);
      });
    }
    const handleGetEarnings = (type) =>{
      APP_HTTP.REQUEST('dashboard/get_earnings',{type:type},true,true).then((response) => {
          let resp = response.data;
          let total = resp.total;
          if(type==='this_month'){
            setThisMonthEarnings(total);
          }else if(type==='last_month'){
            setLastMonthEarnings(total);
          }
      });
    }
    const handleGetLeaveRequest = (type) =>{
      APP_HTTP.REQUEST('dashboard/get_leave_requests',{type:type},true,true).then((response) => {
          let resp = response.data.response;
          if(type==='student'){
            setStudentsLeaveRequest(resp);
          }else if(type==='staff'){
            setStaffsLeaveRequest(resp);
          }
      });
    }
    const handleGetExpenses = (type) =>{
      APP_HTTP.REQUEST('dashboard/get_expenses',{type:type},true,true).then((response) => {
          let resp = response.data;
          let total = resp.total;
          if(type==='this_month'){
            setThisMonthExpenses(total);
          }else if(type==='last_month'){
            setLastMonthExpenses(total);
          }
      });
    }
    const handleGetLatestEarnings = () =>{
      APP_HTTP.REQUEST('dashboard/get_latest_earnings',{limit:50},true,true).then((response) => {
          let resp = response.data;
          let earnings = resp.reponses;
          setLatestEarnings(earnings);
      });
    }
    const handleGetLatestExpenses = () =>{
      APP_HTTP.REQUEST('dashboard/get_latest_expenses',{limit:10},true,true).then((response) => {
          let resp = response.data;
          let expenses = resp.reponses;
          setLatestExpenses(expenses);
      });
    }
    const handleGetAbsentList= (type) =>{
      APP_HTTP.REQUEST('dashboard/get_absent_list',{type:type},true,true).then((response) => {
          let resp = response.data;
          let absents = resp.reponses;
          if(type==='student'){
            setStudentAbsentList(absents);
          }else if(type==='staff'){
            setStaffAbsentList(absents);
          }
      });
    }
    const handleClickCard = (type) =>{
      if(type==='student'){
        window.location.href='/students';
      }else if(type==='staff'){
        window.location.href='/staffs';
      }
    }
  return (
      <>
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                <Card onClick={()=>handleClickCard('student')} style={{cursor:'pointer'}}>
                  <CardContent sx={{position:'relative'}}>
                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>NO. OF STUDENTS</Typography>
                    <Avatar sx={{ bgcolor: pink[500],position:'absolute',top:'30px',right:'10px' }}>
                      <ContactsIcon/>
                    </Avatar>
                    <Typography variant='h6'>
                      {(student_count==='') &&
                        <CircularProgress color="inherit" size={20}/>
                      }
                      {(student_count>=0) &&
                        <>{student_count}</>
                      }
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                <Card>
                  <CardContent sx={{position:'relative'}}>
                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>THIS MONTH EARNINGS</Typography>
                    <Avatar sx={{ bgcolor: green[500],position:'absolute',top:'30px',right:'10px' }}>
                      <CurrencyRupee/>
                    </Avatar>
                    <Typography variant='h6'>
                        {(this_month_earnings==='') &&
                          <CircularProgress color="inherit" size={20}/>
                        }
                        {(this_month_earnings>=0) &&
                          <>₹{AppUtils.currency_format(this_month_earnings)}</>
                        }
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                <Card>
                  <CardContent sx={{position:'relative'}}>
                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>THIS MONTH EXPENSE</Typography>
                    <Avatar sx={{ bgcolor: red[500],position:'absolute',top:'30px',right:'10px' }}>
                      <CurrencyRupee/>
                    </Avatar>
                    <Typography variant='h6'>
                    {(this_month_expenses==='') &&
                          <CircularProgress color="inherit" size={20}/>
                        }
                        {(this_month_expenses>=0) &&
                          <>₹{AppUtils.currency_format(this_month_expenses)}</>
                        }
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                <Card>
                  <CardContent sx={{position:'relative'}}>
                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>PROFIT</Typography>
                    <Avatar sx={{ bgcolor: orange[500],position:'absolute',top:'30px',right:'10px' }}>
                      <CurrencyRupee/>
                    </Avatar>
                    <Typography variant='h6'>
                        {(this_month_earnings>=0 && this_month_expenses>=0) &&
                          <>
                          ₹{AppUtils.currency_format(Math.abs(this_month_earnings-this_month_expenses))}
                          {((this_month_earnings-this_month_expenses)>=0) &&
                            <><ArrowUpward  sx={{color:green[500]}}/></>
                          }
                          {((this_month_earnings-this_month_expenses)<0) &&
                            <><ArrowDownward sx={{color:red[500]}}/></>
                          }
                          </>
                        }
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                <Card  onClick={()=>handleClickCard('staff')} style={{cursor:'pointer'}}>
                  <CardContent sx={{position:'relative'}}>
                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>NO. OF STAFF</Typography>
                    <Avatar sx={{ bgcolor: pink[500],position:'absolute',top:'30px',right:'10px' }}>
                      <Group/>
                    </Avatar>
                    <Typography variant='h6'>
                      {(staff_count==='') &&
                        <CircularProgress color="inherit" size={20}/>
                      }
                      {(staff_count>=0) &&
                        <>{staff_count}</>
                      }
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                <Card>
                  <CardContent sx={{position:'relative'}}>
                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>LAST MONTH EARNINGS</Typography>
                    <Avatar sx={{ bgcolor: green[500],position:'absolute',top:'30px',right:'10px' }}>
                      <CurrencyRupee/>
                    </Avatar>
                    <Typography variant='h6'>
                    {(last_month_earnings==='') &&
                          <CircularProgress color="inherit" size={20}/>
                        }
                        {(last_month_earnings>=0) &&
                          <>₹{AppUtils.currency_format(last_month_earnings)}</>
                        }
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                <Card>
                  <CardContent sx={{position:'relative'}}>
                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>LAST MONTH EXPENSE</Typography>
                    <Avatar sx={{ bgcolor: red[500],position:'absolute',top:'30px',right:'10px' }}>
                      <CurrencyRupee/>
                    </Avatar>
                    <Typography variant='h6'>

                        {(last_month_expenses==='') &&
                          <CircularProgress color="inherit" size={20}/>
                        }
                        {(last_month_expenses>=0) &&
                          <>₹{AppUtils.currency_format(last_month_expenses)}</>
                        }

                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                <Card>
                  <CardContent sx={{position:'relative'}}>
                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>PROFIT</Typography>
                    <Avatar sx={{ bgcolor: orange[500],position:'absolute',top:'30px',right:'10px' }}>
                      <CurrencyRupee/>
                    </Avatar>
                    <Typography variant='h6'>
                        {(last_month_earnings>=0 && last_month_expenses>=0) &&
                          <>
                          ₹{AppUtils.currency_format(Math.abs(last_month_earnings-last_month_expenses))}
                          {((last_month_earnings-last_month_expenses)>=0) &&
                            <><ArrowUpward  sx={{color:green[500]}}/></>
                          }
                          {((last_month_earnings-last_month_expenses)<0) &&
                            <><ArrowDownward sx={{color:red[500]}}/></>
                          }
                          </>
                        }
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
              <Grid item xs={12} sm={12} md={6} spacing={2}>
                <Typography variant='h6'>
                  STAFF LEAVE REQUESTS
                </Typography>
                
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {(staffs_leave_request!=='' && staffs_leave_request.length>=0) &&
                      <>
                      {(staffs_leave_request.map((value,index)=>{
                        return (
                          <StyledTableRow>
                          <StyledTableCell>
                            <StaffCard info={value.contact_info} is_photo={true}/>
                          </StyledTableCell>
                          <StyledTableCell>{value.leave_from_date} To {value.leave_to_date}</StyledTableCell>
                          <StyledTableCell>{value.days}</StyledTableCell>
                          <StyledTableCell>{value.leave_reason}</StyledTableCell>
                        </StyledTableRow>
                        )
                      }))}
                      </>
                      }
                      {(staffs_leave_request==='') &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={4}>
                            <CircularProgress color="inherit" size={20}/>
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                      {(staffs_leave_request!=='' && staffs_leave_request.length===0) &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={4} sx={{textAlign:'center'}}>
                              No ! Leave Request Found
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={6} spacing={2}>
                <Typography variant='h6'>
                  STUDENT LEAVE REQUESTS
                </Typography>
                
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {(students_leave_request!=='' && students_leave_request.length>=0) &&
                      <>
                      {(students_leave_request.map((value,index)=>{
                        return (
                          <StyledTableRow>
                          <StyledTableCell>
                            <StudentCard info={value.contact_info} is_photo={true}/>
                          </StyledTableCell>
                          <StyledTableCell>{value.leave_from_date} To {value.leave_to_date}</StyledTableCell>
                          <StyledTableCell>{value.days}</StyledTableCell>
                          <StyledTableCell>{value.leave_reason}</StyledTableCell>
                        </StyledTableRow>
                        )
                      }))}
                      </>
                      }
                      {(students_leave_request==='') &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={4}>
                            <CircularProgress color="inherit" size={20}/>
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                      {(students_leave_request!=='' && students_leave_request.length===0) &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={4} sx={{textAlign:'center'}}>
                              No ! Leave Request Found
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
              <Grid item xs={12} sm={12} md={6} spacing={2}>
                <Typography variant='h6'>
                  STUDENTS ABSENT TODAY 
                  {(student_absent_list!=='' && student_absent_list.length>0) &&
                  <Typography variant='span' style={{background: 'red',padding: '2px 2px 1px 1px',borderRadius: '9px',color: '#fff',fontWeight: 'bold',marginLeft:'5px'}}>{student_absent_list.length}</Typography>
                  }
                </Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableBody>
                    {(student_absent_list!=='' && student_absent_list.length>0) &&
                      <>
                      {(student_absent_list.map((value,index)=>{
                        return (
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row" sx={{width:'50px'}}>
                              <StudentCard info={value} is_photo={true}/>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }))}
                      </>
                      }
                      {(student_absent_list==='') &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                            <CircularProgress color="inherit" size={20}/>
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                      {(student_absent_list!=='' && student_absent_list.length===0) &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row"  sx={{textAlign:'center'}}>
                              No ! Student Absent
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={6} spacing={2}>
                <Typography variant='h6'>
                  STAFFS ABSENT TODAY  {(staff_absent_list!=='' && staff_absent_list.length>0) &&
                  <Typography variant='span' style={{background: 'red',padding: '2px 2px 1px 1px',borderRadius: '9px',color: '#fff',fontWeight: 'bold',marginLeft:'5px'}}>{staff_absent_list.length}</Typography>
                  }
                </Typography>
                
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                  <TableBody>
                    {(staff_absent_list!=='' && staff_absent_list.length>0) &&
                      <>
                      {(staff_absent_list.map((value,index)=>{
                        return (
                          <StyledTableRow>
                           
                            <StyledTableCell component="th" scope="row">
                            <StaffCard info={value} is_photo={true}/>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }))}
                      </>
                      }
                      {(staff_absent_list==='') &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                            <CircularProgress color="inherit" size={20}/>
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                      {(staff_absent_list!=='' && staff_absent_list.length===0) &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row"  sx={{textAlign:'center'}}>
                              No ! Staff Absent
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
              <Grid item xs={12} sm={12} md={6} spacing={2}>
                <Typography variant='h6'>
                  LATEST 50 EARNINGS
                </Typography>
                
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {(latest_earnings!=='' && latest_earnings.length>=0) &&
                      <>
                      {(latest_earnings.map((value,index)=>{
                        return (
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              {value.payment_sequence}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                            ₹{AppUtils.currency_format(value.paid_amount)} <ArrowUpward sx={{fontSize:'16px',color:green[500]}}/>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }))}
                      </>
                      }
                      {(latest_earnings==='') &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={2}>
                            <CircularProgress color="inherit" size={20}/>
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                      {(latest_earnings!=='' && latest_earnings.length===0) &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={2} sx={{textAlign:'center'}}>
                              No ! Earnings Found
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={6} spacing={2}>
                <Typography variant='h6'>
                  LATEST 10 EXPENSES
                </Typography>
                
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableBody>
                        
                        {(latest_expenses!=='' && latest_expenses.length>=0) &&
                        <>
                          {(latest_expenses.map((value,index)=>{
                            return(
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  {value.payment_sequence}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                ₹{AppUtils.currency_format(value.paid_amount)} <ArrowDownward sx={{fontSize:'16px',color:red[500]}}/>
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                        }))}
                        </>
                      }
                       {(latest_expenses==='') &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={2}>
                            <CircularProgress color="inherit" size={20}/>
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                      {(latest_expenses!=='' && latest_expenses.length===0) &&
                      <>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={2} sx={{textAlign:'center'}}>
                              No ! Expenses Found
                            </StyledTableCell>
                        </StyledTableRow>
                      </>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
           
          </Box>
      </>
  );
}

export default AppDashboard;