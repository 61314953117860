import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import { useSelector, useDispatch } from "react-redux";
import { handleConfirmDeleteClassRoom, handleOpenAddClassRoom,handleSetClassRoomList } from './Action.jsx';
import DeleteConfirm from '../../DeleteConfirm.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import APP_HTTP from '../../../APP_HTTP.jsx';
import CreateClassRoom from './CreateClassRoom.jsx';
import AppUtils from '../../../AppUtils.jsx';
const ClassRooms = (props) => {
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    const [is_loading,setLoading] = React.useState(true);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const is_open_add_class_room = state.is_open_add_class_room;
    const is_delete_item = state.is_delete_item;
    const class_room_list = state.class_room_list;
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if(class_room_list && class_room_list.length===0){
            if (!initialized.current) {
                initialized.current = true;
                APP_HTTP.REQUEST('settings/get_class_room_list',{},true,true).then((response) => {
                    let resp = response.data;
                    let class_room_list = resp.response;
                    setLoading(false);
                    dispatch(handleSetClassRoomList(class_room_list));
                });
            }
        }
    },[dispatch,class_room_list])

    const list_item = [{key:0,value:'Select Class Room'}];
    for (let index = 0; index < class_room_list.length; index++) {
        const element = class_room_list[index];
        let ptype_item = {key:element.id,value:element.name};
        list_item.push(ptype_item)
    }
    const [class_roomValue,setClassRoomEdit] = React.useState({id:0,name:'',is_edit:true});
    
    const handleDeleteItem = (id)=> {
        let ptypeValue = class_room_list[id];
        let ptypeId = ptypeValue.id;
        
        let delData = {id:ptypeId,index:id,target:'class_room'};
        setDeleteData(delData);
        dispatch(handleConfirmDeleteClassRoom(true));
    }
    const handleEditItem = (id)=>{
        let ptypeValue = class_room_list[id];
        if(ptypeValue.is_edit===undefined){
            ptypeValue['is_edit'] = true;
        }else {
            ptypeValue.is_edit = true;
        }
        if(ptypeValue.update_id===undefined){
            ptypeValue['update_id'] = id;
        }else{
            ptypeValue.update_id = id;
        }
        setClassRoomEdit(ptypeValue);
        dispatch(handleOpenAddClassRoom(true));
    }
    const openAddClassRoom = () =>{
        setClassRoomEdit({id:0,name:'',is_edit:false});
        dispatch(handleOpenAddClassRoom(true));
    }
  return (
    <>
    {(props.show==='list') &&
    <>
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Class Rooms
                        {(AppUtils.has_access(state,'28_class_room_add')) &&
                        <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='large' onClick={openAddClassRoom}/>
                        }
                    </Typography>
                </Grid>
                {(AppUtils.has_access(state,'28_class_room_view')) &&
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <Divider />
                    <List>
                        {(class_room_list.map((value,index)=>{
                            return (
                                <>
                                <ListItem key={value.name} disablePadding>
                                    <ListItemButton>
                                        <ListItemText primary={value.name} />
                                        {(AppUtils.has_access(state,'28_class_room_delete')) &&
                                        <Delete fontSize='small' onClick={() =>handleDeleteItem(index)}/>
                                        }
                                        {(AppUtils.has_access(state,'28_class_room_edit')) &&
                                        <Edit  fontSize='small' onClick={() =>handleEditItem(index)}/>
                                        }
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                            </>
                            )
                        }))}
                        {(is_loading===false && class_room_list && class_room_list.length===0) &&
                             <ListItem key={"No Record Found"} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"No Record Found"} />
                                </ListItemButton>
                            </ListItem>
                        }
                        {(is_loading===true && class_room_list && class_room_list.length===0) &&
                             <ListItem key={"Loading..."} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Loading..."} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                
                </Grid>
                }
            </Grid>
        </Box>
       {(is_open_add_class_room) && 
        <CreateClassRoom class_roomValue={class_roomValue}/>
       }
        {(is_delete_item) &&
        <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
       }
    </>
    }
    {(props.show==='dropdown') &&
    <>
        {(list_item.length>0) &&
            <AppDropDown label="Class Room" id="class_room" defaultValue={(props.defaultValue)?parseFloat(props.defaultValue):0} list_item={list_item} size="small" className={props.className} handleChangeData={props.handleChangeData}/>
        }
    </>
    }
    </>
  );
}
export default ClassRooms;