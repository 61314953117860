import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import InputAdornment from '@mui/material/InputAdornment';
import { Autocomplete, Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import { handleSetTransportInfo, handleToggleEditTransport, handleToggleViewTransport } from './Action';
import { handleChangeMenu } from '../../actions/Action';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#ffffff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const CreateTransport = (props)=> {
    let transportDataInitialState = {
        transport_id :0,
        transport_number :'',
        transport_name :'',
        transporter_name :'',
        transporter_phone :'',
        transporter_email :'',
        driver :'',
        vehicle_number :'',
        number_of_seat :'',
        seat_m :'',
        seat_n :'',
    }
 
    let [driver_list,setDriverList] = React.useState([]);
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    let [transportData,setTransportData] = React.useState(transportDataInitialState);
    
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
   
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(props.transport_data){
                let transport_data = props.transport_data;
                if(transport_data.is_db===undefined){
                    transport_data.driver = transport_data.autocomplete;
                    setTransportData(transport_data);
                }else{
                    APP_HTTP.REQUEST('transport/get_transport_on_id',{id:transport_data.transport_id},true,true).then((response) => {
                        let resp = response.data.response;
                        resp.driver = resp.autocomplete;
                        setTransportData(resp);
                        dispatch(handleSetTransportInfo(resp));
                    });
                }
            }
            APP_HTTP.REQUEST('staff/search_staff',{search:'',type:'autocomplete',staff_type:'driver'},true,true,false,true).then((stresponse) => {
                let stresp = stresponse.data;
                let search_list = stresp.response;
                setDriverList(search_list);
            });
        }
       
    },[props])

  const handleSaveInformation = (type) =>{
    let trData = {...transportData};
    setIsSaveClicked(true);
    
    APP_HTTP.REQUEST('transport/save_transport',trData,true,true,false,true).then((response) => {
        setIsSaveClicked(false);
        let last_id = response.data.id;
        handleCancelEdit();
    });
  }
 
  const handleChangeData = (id,value) =>{
    let transData = transportData;
    if(id.target && id.target.id){
        let value = id.target.value;
        let key = id.target.id;
        transData[key] = value;
    }else{
        transData[id] = value;
    }
    setTransportData(transData);
  }
  const handleCancelEdit = () =>{
    dispatch(handleToggleEditTransport(false));
    dispatch(handleChangeMenu('transports','','Transports',true));
  }
  const handleChangeValue = (value) =>{
    let transData = transportData;
    transData['driver'] = value;
    setTransportData(transData);
  }
  const handleChangeInputValue = (value) =>{
    
  }
  return (
    <div id="create-transport">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            <Grid item xs={6}> 
                            {(props.transport_data===undefined) &&
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>Create Transport</Typography>
                            }
                            {(props.transport_data!==undefined) &&
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>Update Transport</Typography>
                            }
                                <Typography variant='span' sx={{fontSize:'14px',color:'gray'}}>Please fill the transport information in below field</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'right'}}> 
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3 style={{marginBottom:'0px'}}>Basic Information</h3>
                        <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Transport Route Number"
                                defaultValue={transportData.transport_number}
                                key={transportData.transport_number}
                                size="small"
                                id="transport_number"
                                fullWidth
                                inputProps={{className:"transport-data"}}
                                onChange={handleChangeData}
                                />
                        </Grid>
                    
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Transport Route Name"
                                defaultValue={transportData.transport_name}
                                key={transportData.transport_name}
                                size="small"
                                id="transport_name"
                                fullWidth
                                inputProps={{className:"transport-data"}}
                                onChange={handleChangeData}
                                />
                        </Grid>
                        
                        
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Transporter/Vendor Name"
                                onChange={handleChangeData}
                                size="small"
                                id="transporter_name"
                                fullWidth
                                inputProps={{className:"transport-data"}}
                                defaultValue={transportData.transporter_name}
                                key={transportData.transporter_name}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Transporter Mobile Number"
                                onChange={handleChangeData}
                                size="small"
                                id="transporter_phone"
                                fullWidth
                                inputProps={{className:"transport-data"}}
                                defaultValue={transportData.transporter_phone}
                                key={transportData.transporter_phone}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Email ID"
                                onChange={handleChangeData}
                                size="small"
                                id="transporter_email"
                                fullWidth
                                inputProps={{className:"transport-data"}}
                                defaultValue={transportData.transporter_email}
                                key={transportData.transporter_email}
                                />
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3 style={{marginBottom:'0px'}}>Vehicle Information</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        <Autocomplete
                        freeSolo
                            value={(transportData.autocomplete)?transportData.autocomplete:''}
                            noOptionsText={'No Driver Found ! Type To Search'}
                            onChange={(event, newValue) => handleChangeValue(newValue)}
                            onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                            disablePortal
                            id="driver_dropdown"
                            options={driver_list}
                            size="small"
                            renderInput={(params) => <TextField {...params} label={'Select Driver'} />}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Vehicle Number"
                                onChange={handleChangeData}
                                size="small"
                                id="vehicle_number"
                                fullWidth
                                inputProps={{className:"transport-data"}}
                                defaultValue={transportData.vehicle_number}
                                key={transportData.vehicle_number}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Number Of Seat"
                                onChange={handleChangeData}
                                size="small"
                                id="number_of_seat"
                                fullWidth
                                inputProps={{className:"transport-data"}}
                                defaultValue={transportData.number_of_seat}
                                key={transportData.number_of_seat}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <Grid container spacing={2} className="mrtop-10">
                            <Grid item xs={5} sm={5} md={5} className="mrtop-15">
                            <TextField
                                label="M"
                                onChange={handleChangeData}
                                size="small"
                                id="seat_m"
                                fullWidth
                                inputProps={{className:"transport-data"}}
                                defaultValue={transportData.seat_m}
                                key={transportData.seat_m}
                            />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} className="mrtop-15" sx={{textAlign:'center'}}>X</Grid>
                            <Grid item xs={5} sm={5} md={5} className="mrtop-15">
                            <TextField
                                label="N"
                                onChange={handleChangeData}
                                size="small"
                                id="seat_n"
                                fullWidth
                                inputProps={{className:"transport-data"}}
                                defaultValue={transportData.seat_n}
                                key={transportData.seat_n}
                            />
                            </Grid>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} spacing={2} className="mrtop-30 footer-btn-blk">
            <Grid xs={6}>
                
            <Button variant="outlined" onClick={handleCancelEdit}>Close</Button>
                
            </Grid>
            <Grid xs={6} sx={{textAlign:'right'}}>
            {(isSaveClicked===false) &&
                <Button variant="contained" onClick={() => handleSaveInformation('save')}>Save Information</Button>
            }
            {(isSaveClicked===true) &&
                <LoaderButton label="Save Information"/>
            }
            </Grid>
        </Grid>
        
        </Box>
    </div>
  );
}
export default CreateTransport;