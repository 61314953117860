import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const PrintScheduledExamFormat2 = (props)=> {
    let [scheduledExamData,setScheduledExamData] = React.useState([]);
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            
            if(props.scheduled_exam_data){
                let scheduled_exam_data = props.scheduled_exam_data;
                
                setScheduledExamData(scheduled_exam_data);
                setTimeout(() => {
                    handlePrintScheduledExam();
                }, 100);
            }
        }
       
    },[props])
   const handlePrintScheduledExam = ()=>{
    if(document.getElementById("scheduledExamPrintBlock")){
        var divContents = document.getElementById("scheduledExamPrintBlock").innerHTML; 

      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
     
          a.document.write('<style type="text/css"><style type="text/css">@page{size:"A4";margin:5cm}table{font-family:verdana, arial, sans-serif;font-size:16px;font-weight:bold;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');  
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="scheduledExamPrintBlock" style={{display:'none'}}>
        {(props.number_of_copy.map((cval,cindex)=>{
            return(
            <div style={{position:'relative'}}>
            {(scheduledExamData.school_info) &&
                <table style={{pageBreakAfter:'always'}}>
                    <Watermark />
                    <PrintHeader header_data={scheduledExamData.school_info} colSpan={(scheduledExamData.print_data.dates.length+1)}/>
                    <tbody>
                        <tr>
                            <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}} colSpan={(scheduledExamData.print_data.dates.length+1)}><h2 style={{margin:'0px',textAlign:'center'}}>Exam Time Table</h2></th>
                        </tr>    
                        <tr>
                            <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}} colSpan={(scheduledExamData.print_data.dates.length+1)}>
                                <h2 style={{margin:'0px',textAlign:'center'}}>
                                    {scheduledExamData.exam_type_name} for class {scheduledExamData.class_name} from {scheduledExamData.date}
                                </h2>
                            </th>
                        </tr>
                                
                        <tr>
                            <th>
                                <p style={{fontSize:'22px',margin:'0px'}}>Date</p>
                                <p style={{margin:'0px'}}>Day</p>
                            </th>
                            {(scheduledExamData.print_data.dates.map((value,index)=>{
                                return(
                                    <th>
                                        <p style={{fontSize:'22px',margin:'0px'}}>{value.date}</p>
                                        <p style={{margin:'0px'}}>{value.day}</p>
                                    </th>
                                )
                            }))}
                        </tr>   
                        {(scheduledExamData.print_data.setting_count.map((value,index)=>{
                            return(
                                <tr>
                                    <th>
                                        Sitting-{value+1}
                                    </th>
                                    {(scheduledExamData.print_data.dates.map((date,index)=>{
                                        return(
                                            <th>
                                                
                                               {(scheduledExamData.print_data.sittings[date.date]!==undefined && scheduledExamData.print_data.sittings[date.date][value]!==undefined && scheduledExamData.print_data.sittings[date.date][value].name!==undefined) &&
                                               <>
                                                    <p style={{fontSize:'22px',margin:'0px'}}>{scheduledExamData.print_data.sittings[date.date][value].name}</p>
                                                    <p style={{margin:'0px'}}>{scheduledExamData.print_data.sittings[date.date][value].exam_time}</p>
                                               </>
                                                }
                                                {(scheduledExamData.print_data.sittings[date.date]!==undefined && scheduledExamData.print_data.sittings[date.date][value]!==undefined && scheduledExamData.print_data.sittings[date.date][value].name===undefined) &&
                                                    <p style={{margin:'0px'}}>-</p>
                                                }
                                            </th>
                                        )
                                    }))}
                                </tr>
                            )
                        }))}
                    </tbody>
                    <PrintFooter colSpan={(scheduledExamData.print_data.dates.length+1)}  type="scheduled_exam" info={scheduledExamData.school_info}/>
                </table>
            }
            </div>
            )
        }))}
        
    </div>
  );
}
export default PrintScheduledExamFormat2;