import { DELETE_ITEM_CONFIRM,DELETE_PAYMENT,SET_PAYMENTS_LIST,IS_PAYMENT_EDIT,SET_PAYMENT_INFO,IS_PAYMENT_VIEW, IS_OPEN_CREATE_DEMAND_BILL,SET_BULK_DEMAND_BILL_PRINT } from "../../actionTypes/actionTypes";

const handleConfirmDeletePayment = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeletePayment = (index, delete_data) =>{
  return {
    type: DELETE_PAYMENT,
    state :{
      id : index,
    }
  };
}
const handleSetPaymentsList = (payments_list) =>{
    return {
      type: SET_PAYMENTS_LIST,
      state :{
        payments_list : payments_list,
      }
    };
  }
const handleToggleEditPayment = (is_payment_edit) =>{
    return {
      type: IS_PAYMENT_EDIT,
      state :{
        is_payment_edit : is_payment_edit,
      }
    };
  }
const handleToggleViewPayment = (is_payment_view) =>{
    return {
      type: IS_PAYMENT_VIEW,
      state :{
        is_payment_view : is_payment_view,
      }
    };
  }
const handleOpenCreateDemandBill = (is_open_create_demand_bill) =>{
    return {
      type: IS_OPEN_CREATE_DEMAND_BILL,
      state :{
        is_open_create_demand_bill : is_open_create_demand_bill,
      }
    };
  }
const handleSetPaymentInfo = (payment_info) =>{
    return {
      type: SET_PAYMENT_INFO,
      state :{
        payment_info : payment_info,
      }
    };
  }
const handleBulkDemandBillPrint = (is_bulk_demand_bill_print) =>{
    return {
      type: SET_BULK_DEMAND_BILL_PRINT,
      state :{
        is_bulk_demand_bill_print : is_bulk_demand_bill_print,
      }
    };
  }

export { handleConfirmDeletePayment, handleDeletePayment,handleSetPaymentsList,handleToggleEditPayment,handleSetPaymentInfo,handleToggleViewPayment,handleOpenCreateDemandBill,handleBulkDemandBillPrint};