import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Key, KeyOff, Login, NotificationsActive, NotificationsOff, RemoveRedEyeOutlined, Subscriptions, SubscriptionsTwoTone, ViewAgenda } from '@mui/icons-material';
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import { Grid, TextField } from '@mui/material';
import AppDropDown from '../AppDropDown';
import BasicDatePicker from '../DatePicker';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [is_change_status,setIsChangeStatus] = React.useState(false);
  const [is_extend_subscription,setIsSubscription] = React.useState(false);
  const [school_status,setSchoolStatus] = React.useState('');
  const [is_open_login,setOpenLogin] = React.useState(false);
  const [passkey,setPasskey] = React.useState('');
  const [is_logging_in,setIsLogging] = React.useState(false);
  const [is_changing_status,setIsChangingStatus] = React.useState(false);
  const [is_extending_subscription,setExtendingSubscription] = React.useState(false);
  
  const [subscription_type,setSubscriptionType] = React.useState(1);
  const [subscription_duration,setSubscriptionDuration] = React.useState(365);
  const [start_date,setStartDate] = React.useState(new Date());
  const [end_date,setEndDate] = React.useState(new Date());
  const open = Boolean(anchorEl);
  const handleChangeStartDate = (id,value)=>{
    setStartDate(value);
  }
  const handleChangeEndDate = (id,value)=>{
    setEndDate(value);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogin = () =>{
    setOpenLogin(true);
    //school/admin/login
  }
  const handleCloseLogin = ()=>{
    setOpenLogin(false);
  }
  const handleSetPasskey = (value) =>{
    setPasskey(value);
  }
  const handleProceedToLogin = () =>{
    setIsLogging(true);
    APP_HTTP.REQUEST('school/admin/login',{school_name:props.school,passkey:passkey},true,false,false,false,true).then((response) => {
      setIsLogging(false);
      if(response.data.success===1){
        let data = response.data.data;
        localStorage.setItem('MnzYs4pVGL',JSON.stringify(data));
        setOpenLogin(false);
        window.open('/dashboard', '_blank');
      }
    });
  }
  const handleProceedChangeStatus = () =>{
    setIsChangingStatus(true);
    APP_HTTP.REQUEST('school/admin/change_status',{school_name:props.school,status:school_status,passkey:passkey},true,false,false,false,true).then((response) => {
      setIsChangingStatus(false);
      if(response.data.success===1){
        setIsChangeStatus(false);
        props.handleAfterAction(school_status);
      }
    });
  }
  const handleChangeSchoolStatus = (status) =>{
    setIsChangeStatus(true);
    setSchoolStatus(status);
  }
  const handleCloseChangeStatus = ()=>{
    setIsChangeStatus(false);
  }
  const handleCloseSubscription = ()=>{
    setIsSubscription(false);
  }
  const handleExtendSubscription = () =>{
    setIsSubscription(true);
  }
  
  const handleProcessExtendSubscription = () =>{
    setExtendingSubscription(true);
    APP_HTTP.REQUEST('school/admin/extend_subscription',{school_name:props.school,status:school_status,passkey:passkey,subscription_type:subscription_type,subscription_duration:subscription_duration,start_date:start_date,end_date:end_date},true,false,false,false,true).then((response) => {
      setExtendingSubscription(false);
      if(response.data.success===1){
      }
    });
  }
  const handleChangeSubscriptionType = (id,value) =>{
    setSubscriptionType(value);
    if(value===0){
      setSubscriptionDuration(15);
    }else{
      setSubscriptionDuration(365);
    }
  }
  const handleChangeSubscriptionDuration = (id,value) =>{
    setSubscriptionDuration(value);
  }
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
         <MenuItem onClick={handleLogin} disableRipple>
            <Login />
            Login
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={handleExtendSubscription} disableRipple>
            <Subscriptions />
            Extend Subscription
          </MenuItem>
         {/*  <MenuItem onClick={handleClose} disableRipple>
            <SubscriptionsTwoTone />
            Cancel Subscription
          </MenuItem> */}
          {(props.data.status===0) &&
          <MenuItem onClick={()=>handleChangeSchoolStatus('active')} disableRipple>
            <Key />
            Make It Active
          </MenuItem>
          }
          {(props.data.status===1) &&
          <MenuItem onClick={()=>handleChangeSchoolStatus('inactive')} disableRipple>
            <KeyOff />
            Make It Inactive
          </MenuItem>
          }
      </StyledMenu>
      {(is_open_login===true) &&
        <Dialog open={true} onClose={handleCloseLogin}>
          <DialogTitle>Login Passkey</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="passkey"
              label="Enter Passkey"
              type="password"
              fullWidth
              variant="standard"
              onChange={(e)=>handleSetPasskey(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseLogin} size='small'>Close</Button>
            {(is_logging_in===false) &&
              <Button onClick={handleProceedToLogin} variant='contained' color='success' size='small'>Login</Button>
            }
            {(is_logging_in===true) &&
              <LoaderButton variant='contained' color='success' size='small'/>
            }
          </DialogActions>
        </Dialog>
      }
      {(is_change_status===true) &&
        <Dialog open={true} onClose={handleCloseChangeStatus}>
          <DialogTitle>Change Status Passkey</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="passkey"
              label="Enter Passkey"
              type="password"
              fullWidth
              variant="standard"
              onChange={(e)=>handleSetPasskey(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseChangeStatus} size='small'>Close</Button>
            {(is_changing_status===false) &&
              <Button onClick={handleProceedChangeStatus} variant='contained' color='success' size='small'>Ok</Button>
            }
            {(is_changing_status===true) &&
              <LoaderButton variant='contained' color='success' size='small'/>
            }
          </DialogActions>
        </Dialog>
      }
      {(is_extend_subscription===true) &&
        <Dialog open={true} onClose={handleCloseSubscription}>
          <DialogTitle>Extend Subscription</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} style={{marginTop:'5px'}}>  
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                  <AppDropDown label="Select Subscription Plan" list_item={[{key:'1',value:'Basic Plan'},{key:'0',value:'Trial Priod'}]} size="small" handleChangeData={handleChangeSubscriptionType} defaultValue={subscription_type} id={'sub_type'}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                  <AppDropDown label="Select Duration" list_item={[{key:'15',value:'15 Days'},{key:'30',value:'30 Days'},{key:'90',value:'3 Months'},{key:'180',value:'6 Months'},{key:'365',value:'1 Year'},{key:'custom',value:'Custom'}]} size="small" handleChangeData={handleChangeSubscriptionDuration} defaultValue={subscription_duration} id={'sub_duration'}/>
                </Grid>
                {(subscription_duration==='custom') &&
                  <>
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                      <BasicDatePicker label="Start Date" size="small" id="start_date" handleChangeData={handleChangeStartDate} defaultValue={start_date}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                      <BasicDatePicker label="End Date" size="small" id="end_date" handleChangeData={handleChangeEndDate} defaultValue={end_date}/>
                    </Grid>
                  </>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="passkey"
                    label="Enter Passkey"
                    type="password"
                    fullWidth
                    variant="standard"
                    onChange={(e)=>handleSetPasskey(e.target.value)}
                  />
                </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} style={{marginTop:'5px'}}>  
                  <Grid item xs={12} sm={12} md={6} spacing={2} style={{paddingLeft:'25px'}}>
                    <Button onClick={handleCloseSubscription} size='small'>Close</Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} spacing={2} style={{textAlign:'right',paddingRight:'16px'}}>
                    {(is_extending_subscription===false) &&
                      <Button onClick={handleProcessExtendSubscription} variant='contained' color='success' size='small'>Extend</Button>
                    }
                    {(is_extending_subscription===true) &&
                      <LoaderButton variant='contained' color='success' size='small'/>
                    }
                  </Grid>
              </Grid>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
}