import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Checkbox, Divider, FormControlLabel, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';
import { AccountCircleRounded } from '@mui/icons-material';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import ChangePassword from './ChangePassword';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const UserProfile = (props)=> {
    let schoolDataInitialState = {
        school_name:'',
    }
    let [image_url,setImageURL] = React.useState('');
    
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    let [is_changed_password,setIsChangePassword] = React.useState(false);
    let [schoolData,setSchoolData] = React.useState(schoolDataInitialState);
    const state = useSelector((state) => state);
    const states = state.states;
    let state_list = [];
    for (let index = 0; index < states.length; index++) {
        const state_name = states[index];
        let list_item = {key:state_name,value:state_name};
        state_list.push(list_item);
    }
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            APP_HTTP.REQUEST('school/get_school_profile',{},true,true).then((response) => {
                let resp = response.data.response;
                if(resp){
                    let info = resp.info;
                    setSchoolData(info);
                    setImageURL(info.photo);
                }
            });
        }
       
    },[props])

  const handleSaveInformation = (type) =>{
    
    //setIsSaveClicked(true);
    let upload_file = document.getElementById("upload_file");
    const files = upload_file.files;
    const formData = new FormData();
    let photo = files[0];
    if(photo===undefined){
        photo = '';
    }
    const jsonString = JSON.stringify(Object.assign({}, schoolData)) 
    formData.append('photo', photo);
    formData.append('data', jsonString);
    APP_HTTP.REQUEST('school/save_school',formData,true,true,true).then((response) => {
        window.location.reload();   
    });
  }
  const handleUploadFileClick = () =>{
    if(document.getElementById("upload_file")){
        document.getElementById("upload_file").click();   
    }
  }
  const handleUploadFile = (e) =>{
    const files = e.target.files;
    if(files[0]){
        var url = URL.createObjectURL(files[0]);
        setImageURL(url);
    }
  }
  const handleChangeData = (id,value) =>{
    let stuData = schoolData;
    if(id.target && id.target.id){
        let value = id.target.value;
        let key = id.target.id;
        stuData[key] = value;
    }else{
        stuData[id] = value;
    }
    
    setSchoolData(stuData);
  }
  
  const handleChangeIsCollege = (is_checked) =>{
    let stuData = schoolData;
    stuData.is_college = is_checked;
    setSchoolData(stuData);
  }
  const handleChangePassword = ()=>{
    setIsChangePassword(true);
  }
  const handleCloseChangePassword = ()=>{
    setIsChangePassword(false);
  }
  return (
    <div id="create-school">
        {(AppUtils.has_access(state,'31_profile_view')) &&
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            <Grid item xs={6}> 
                            <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>User Profile Settings</Typography>
                          {/*   <Typography variant='span' sx={{fontSize:'14px',color:'gray'}}>Please fill the school information in below field</Typography> */}
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'right'}}> 
                                <Button variant="outlined" size='small' onClick={handleChangePassword}>Change Password</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      
        </Box>
        }
        {(is_changed_password===true) &&
            <ChangePassword handleCloseChangePassword={handleCloseChangePassword}/>
        }
    </div>
  );
}
export default UserProfile;