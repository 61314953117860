import { OPEN_ADD_EXAM_TYPE,CREATE_EXAM_TYPE,UPDATE_EXAM_TYPE, DELETE_ITEM_CONFIRM, DELETE_EXAM_TYPE,SET_EXAM_TYPE_LIST } from "../../../actionTypes/actionTypes";

const handleOpenAddExamType = (is_open_add_exam_type) =>{
  return {
    type: OPEN_ADD_EXAM_TYPE,
    state :{
      is_open_add_exam_type : is_open_add_exam_type,
    }
  };
}
const handleSetExamTypeList = (exam_type_list) =>{
  return {
    type: SET_EXAM_TYPE_LIST,
    state :{
      exam_type_list : exam_type_list,
    }
  };
}

const handleSaveExamTypeToList = (exam_type_list) =>{
  return {
    type: CREATE_EXAM_TYPE,
    state :{
      exam_type_list : exam_type_list,
    }
  };
}
const handleUpdateExamType = (update_id,name) =>{
  return {
    type: UPDATE_EXAM_TYPE,
    state :{
      update_id : update_id,
      name : name
    }
  };
}
const handleConfirmDeleteExamType = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteExamType = (index, delete_data) =>{
  return {
    type: DELETE_EXAM_TYPE,
    state :{
      id : index,
    }
  };
}

export { handleOpenAddExamType, handleSaveExamTypeToList,handleUpdateExamType,handleConfirmDeleteExamType,handleDeleteExamType,handleSetExamTypeList };