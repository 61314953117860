import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, ButtonGroup, Divider, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import dayjs from 'dayjs';
import AppUtils from '../../AppUtils';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Textarea } from '@mui/joy';
import LoaderButton from '../LoaderButton';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Enquiry = (props) => {
    const initialized = React.useRef(false);
    const [enqData,setEnqData] = React.useState({enquiry_id:0,enquiry_date:new Date(),student_name:'',mobile_number:'',student_query:''});
    const [enquiry_list,setEnquiryList] = React.useState([]);
    const [is_saving,setIsSaving] = React.useState(false);
    const [is_loading,setIsLoading] = React.useState(false);
    const state = useSelector((state) => state);
    const main_menu = state.main_menu;
    
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetEnquiry();
        }
    },[])
   const handleChangeEnqiryDate = (newDate) =>{
        let edata = {...enqData};
        edata['enquiry_date'] = newDate;
        setEnqData(edata);
   }
   const handleChangeQueryData = (type,value) =>{
        let edata = {...enqData};
        edata[type] = value;
        setEnqData(edata);
   }
   const handleSubmitQuery = () =>{
        setIsSaving(true);
        APP_HTTP.REQUEST('enquiry/submit_enquiry',{enquiry_data:enqData},true,true).then((response) => {
            setIsSaving(false);
            handleGetEnquiry();
        });
   }
   const handleGetEnquiry = () =>{
    setIsLoading(true);
    APP_HTTP.REQUEST('enquiry/get_enquiry',{},true,true).then((response) => {
        let resp = response.data.response;
        setEnquiryList(resp);
        setIsLoading(false);
    });
   }
   const handleResolve = (id,idx) =>{
    let enc_list = [...enquiry_list];
    enc_list[idx].query_status=0;
    setEnquiryList(enc_list);
    APP_HTTP.REQUEST('enquiry/resolve_enquiry',{id:id},true,true).then((response) => {
        
    });
   }
  return (
      <>
      {(AppUtils.has_access(state,'301_enquiry')) &&
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Divider />
                <Grid item xs={12} sm={12} md={4} spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                    <DateTimePicker
                                        label="Enqiury Date"
                                        value={dayjs(enqData.enquiry_date)}
                                        onChange={(newValue) => handleChangeEnqiryDate(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} spacing={2}>
                        <TextField
                            margin="normal"
                            
                            fullWidth
                            id="student_name"
                            label="Student Name"
                            name="student_name"
                            autoComplete="student_name"
                            
                            value={enqData.student_name}
                            onChange={(e)=>handleChangeQueryData('student_name',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} spacing={2}>
                        <TextField
                            margin="normal"
                            
                            fullWidth
                            id="mobile_number"
                            label="Mobile Number"
                            name="mobile_number"
                            autoComplete="mobile_number"
                            
                            value={enqData.mobile_number}
                            onChange={(e)=>handleChangeQueryData('mobile_number',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Textarea placeholder="Write query here..."  minRows={10} onChange={(e)=>handleChangeQueryData('student_query',e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                        {(is_saving===false) &&
                            <Button onClick={()=>handleSubmitQuery()} size='medium' variant='outlined'>Submit Query</Button>
                        }
                        {(is_saving===true) &&
                            <LoaderButton size='medium' variant='outlined' />
                        }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} spacing={2}>
                {(is_loading===true) &&
                  
                  <Stack spacing={1}>
                    <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                  </Stack>
                
                }
                {(is_loading===false && enquiry_list.length>0) &&
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                       
                        <StyledTableCell>CONTACT INFO</StyledTableCell>
                        <StyledTableCell>QUERY</StyledTableCell>
                        <StyledTableCell>DATE</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {enquiry_list.map((row,index) => (
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                           <Typography style={{textTransform:'uppercase'}}>
                            {row.student_name} | {row.mobile_number}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography>{row.student_query}</Typography>
                          </StyledTableCell>
                          <StyledTableCell>{row.enquiry_date}</StyledTableCell>
                          <StyledTableCell>
                            {(parseInt(row.query_status)===1) &&
                                <>
                                <Button size="small" variant='contained' color={row.button} onClick={()=>handleResolve(row.enquiry_id,index)}>Resolve</Button>
                                </>
                            }
                            {(parseInt(row.query_status)===0) &&
                                <Typography sx={{color:'green'}}>Resolved</Typography>
                            }
                          </StyledTableCell>
                        
                        </StyledTableRow>
                      ))}
                        
                    </TableBody>
                  </Table>
                </TableContainer>
                }
                </Grid>
            </Grid>
          </Box>
        }
      </>
  );
}

export default Enquiry;