import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, ButtonGroup, Divider, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppPagination from '../AppPagination';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded, Print } from '@mui/icons-material';
import DeleteConfirm from '../DeleteConfirm';
import { handleConfirmDeletePayment, handleSetPaymentsList, handleToggleEditPayment,handleSetPaymentInfo, handleToggleViewPayment, handleBulkDemandBillPrint } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import { useUtils } from '@mui/x-date-pickers/internals';
import AppUtils from '../../AppUtils';
import StudentCard from '../students/StudentCard';
import BulkDemandBillPrint from './BulkDemandBillPrint';
import AppCheckbox from '../AppCheckbox';
import AppDropDown from '../AppDropDown';
import PrintDemandBill from './PrintDemandBill';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const DemandBillList = (props) => {
  const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
  
  const [payment_data,setPaymentData] = React.useState([]);
  const state = useSelector((state) => state);
  const is_delete_item = state.is_delete_item;
  const payment_list = state.payments_list;
  const is_college = state.school_info.school_info.is_college;
  const [number_of_page,setNumberOfPage] = React.useState(0);
  const [total_records,setTotalRecords] = React.useState(0);
  const [selected_month,setSelecteMonth] = React.useState(0);
  const [is_loading_list,setIsLoadingList] = React.useState(true);
  const [is_print,setIsPrint] = React.useState(false);
  const [selected_action,setSelectedAction] = React.useState('print');
  const [printList,setPrintList] = React.useState([]);
  const [selected_session,setSession] = React.useState(0);
  const [selected_class,setClass] = React.useState(0);
  const [selected_section,setSection] = React.useState(0);
  const [search_value,setSearchValue] = React.useState('');
  
  
  const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            let current_path = window.location.pathname;
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('search');
            
            let numberPattern = /\d+/g;
            let get_page = current_path.match( numberPattern );
            if(myParam){
              handleSearch(myParam);
            }else{
              if(get_page===null){
                handleLoadDemandBillList(1)
              }
            }
        }
    },[])
    const handleLoadDemandBillList = (page) =>{
      setIsLoadingList(true);
      dispatch(handleSetPaymentsList([]));
      APP_HTTP.REQUEST('payment/get_demand_bills',{page:page,total_records:total_records,type:props.type},true,true).then((response) => {
          let resp = response.data;
          let stu_list = resp.response;
          
          let tot_record = resp.total_record;
          let no_of_page = resp.number_of_page;
          setTotalRecords(tot_record);
          setNumberOfPage(no_of_page);
          
          dispatch(handleSetPaymentsList(stu_list));
          setIsLoadingList(false);
          setPaymentData(resp);
      });
    }
    const handleNavigatePage = (page) =>{
      handleLoadDemandBillList(page);
    }

    const handleDeleteItem = (id)=> {
      let paymentValue = payment_list[id];
      let payment_id = paymentValue.demand_bill_payment_id;
      
      let delData = {id:payment_id,index:id,target:'demand_bill'};
      setDeleteData(delData);
      dispatch(handleConfirmDeletePayment(true));
  }
  const handleEditItem = (id)=>{
    let payment_info = payment_list[id];
    let payment_id = payment_info.demand_bill_payment_id;
    dispatch(handleChangeMenu('payment','edit/demand-bill/'+payment_id,'Update Demand Bill',false));
    dispatch(handleToggleEditPayment(true));
    dispatch(handleSetPaymentInfo(payment_info));
  }
  const handleViewItem = (id)=>{
    if(AppUtils.has_access(state,'503_demand_bill_view')===false){
      return false;
    }
    let payment_info = payment_list[id];
    let payment_id = payment_info.demand_bill_payment_id;
    dispatch(handleChangeMenu('payment','view/demand-bill/'+payment_id,'View Demand Bill',false));
    dispatch(handleToggleViewPayment(true));
    dispatch(handleSetPaymentInfo(payment_info));
    
  }
  const handleSearch = (thisvalue)=>{
    const url = new URL(window.location.href);
    setSearchValue(thisvalue);
    if(thisvalue!==''){
      url.searchParams.set('search', thisvalue);
      let new_url = url.toString();
      localStorage.setItem('referer',new_url);
      window.history.pushState(null, '',new_url);
      if(thisvalue.length<2){
        return false; 
      }
      setIsLoadingList(true);
      dispatch(handleSetPaymentsList([]));
      APP_HTTP.REQUEST('payment/search_demand_bill',{search:thisvalue},true,true,false,true).then((response) => {
            if(response.data){
              let resp = response.data;
              let stf_list = resp.response;
              let tot_record = resp.total_record;
              let no_of_page = resp.number_of_page;
              setTotalRecords(tot_record);
              setNumberOfPage(no_of_page);
              
              dispatch(handleSetPaymentsList(stf_list));
              setIsLoadingList(false);
          }
      });
    }else{
      url.search = "";
      localStorage.setItem('referer',url.href);
      window.history.pushState(null, '',url.href);
      handleLoadDemandBillList(1);
    }
  }
  const handleConvertToPayment = (index) =>{
    let plist = [...payment_list];
    let id = plist[index].demand_bill_payment_id;
    let add_main_menu = 'payment';
    let add_sub_menu = 'convert/'+id;
    let add_label = 'Convert To Payment';
    dispatch(handleChangeMenu(add_main_menu,add_sub_menu,add_label,false));
  }
  const handleRefreshList = () =>{
    window.location.reload();
  }
  const handleBulkPrint = () =>{
    dispatch(handleBulkDemandBillPrint(true));
  }
  const handleSelectAll = (is_checked) =>{
    let plist = [...payment_list];
    for (let index = 0; index < plist.length; index++) {
      const element = plist[index];
      element.is_selected = is_checked;
      plist[index]=element;
    }
    
    dispatch(handleSetPaymentsList(plist));
  }
  const handleSelectOne = (index,is_checked) =>{
    let plist = [...payment_list];
    plist[index].is_selected = is_checked;
    
    dispatch(handleSetPaymentsList(plist));
  }
  const handleChangeAction = (id,value)=>{
    setSelectedAction(value);
  }
  const handleProceedActon = ()=>{
    let plist = [...payment_list];
    let print_list =[];
    for (let index = 0; index < plist.length; index++) {
      const element = plist[index];
      if(element.is_selected===true){
        print_list.push(element);
      }
    }
    if(print_list.length>0){
      setIsPrint(true);
      setPrintList(print_list);
      setTimeout(() => {
        setIsPrint(false);
      }, 2000);
    }
  }
  const handleChangeSession = (id,value) =>{
      setSession(value);
  }
  const handleChangeClass = (id,value) =>{
      setClass(0);
      setSection(0);
      setSelecteMonth(0);
      setTimeout(() => {
        setClass(value);
      }, 100);
  }
  const handleChangeSection = (id,value) =>{
      setSection(value);
  }
  const handleChangeMonth = (id,value) =>{
    setSelecteMonth(value);
    if(selected_class>0 && value>0){
      setIsLoadingList(true);
      dispatch(handleSetPaymentsList([]));
      APP_HTTP.REQUEST('payment/search_demand_bill',{academic_session:selected_session,class:selected_class,section:selected_section,month:value},true,true,false,true).then((response) => {
            if(response.data){
              let resp = response.data;
              let stf_list = resp.response;
              let tot_record = resp.total_record;
              let no_of_page = resp.number_of_page;
              setTotalRecords(tot_record);
              setNumberOfPage(no_of_page);
              dispatch(handleSetPaymentsList(stf_list));
              setIsLoadingList(false);
          }
      });
    }else{
      let resp = payment_data;
      let stf_list = resp.response;
      if(stf_list){
        let tot_record = resp.total_record;
        let no_of_page = resp.number_of_page;
        setTotalRecords(tot_record);
        setNumberOfPage(no_of_page);
        
        dispatch(handleSetPaymentsList(stf_list));
        setIsLoadingList(false);
      }
    }
  }
  return (
      <>
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
              
              <Grid item xs={12} sm={12} md={2} style={{textAlign:'right'}} spacing={2}>
              <TextField
                label="Search Demand Bill"
                size="small"
                fullWidth
                onChange={(e)=>handleSearch(e.target.value)}
                value={search_value}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1} spacing={2} style={{textAlign:'center'}}>
                <Typography>OR</Typography>
              </Grid>
              {(is_college===true) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <SessionDropdown handleChangeData={handleChangeSession}/>
                </Grid>
                }
              <Grid item xs={12} sm={12} md={2} spacing={2}>
                <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>
              </Grid>
              <Grid item xs={12} sm={12} md={2} spacing={2}>
                {(selected_class>0) &&
                <Sections show="dropdown" handleChangeData={handleChangeSection} defaultValue={selected_section}/>
                }
              </Grid>
              
              <Grid item xs={12} sm={12} md={2} spacing={2}>
              {(selected_section>0) &&
                <AppDropDown label="Select Month" list_item={[{key:0,value:'Select Month'},{key:4,value:'Apr'},{key:5,value:'May'},{key:6,value:'Jun'},{key:7,value:'Jul'},{key:8,value:'Aug'},{key:9,value:'Sep'},{key:10,value:'Oct'},{key:11,value:'Nov'},{key:12,value:'Dec'},{key:1,value:'Jan'},{key:2,value:'Feb'},{key:3,value:'Mar'}]} size="small" handleChangeData={handleChangeMonth} defaultValue={selected_month}/>
              }
              </Grid>
              
              <Grid item xs={12} sm={12} md={3} spacing={2} style={{display:'flex'}}>
                <AppDropDown label="Action" list_item={[{key:'print',value:'Print'}]} size="small" handleChangeData={handleChangeAction} defaultValue={selected_action}/>
                <Button variant='outlined' size='small' onClick={()=>handleProceedActon()} style={{marginLeft:'5px',width:'110px'}}>PROCEED</Button>
              </Grid>
              <Divider />
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        
                        <StyledTableCell>
                          <AppCheckbox label={''} value={'select_all'}  handleChangeCB={(e)=>handleSelectAll(e.target.checked)}/>
                        </StyledTableCell>
                        <StyledTableCell>DEMAND BILL NO</StyledTableCell>
                        <StyledTableCell>STUDENT</StyledTableCell>
                        <StyledTableCell>DATE</StyledTableCell>
                        
                        <StyledTableCell>AMOUNT</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payment_list && payment_list.map((row,index) => (
                        <StyledTableRow key={row.payment_sequence} sx={{textDecoration:(row.status===0 || row.status==='0')?'line-through':'',color:(row.status===0 || row.status==='0')?'red':''}}>
                          <StyledTableCell>
                            {(row.is_selected!==undefined && row.is_selected===true) &&
                              <AppCheckbox label={''} value={'select_all'}  handleChangeCB={(e)=>handleSelectOne(index,e.target.checked)} checked={true}/>
                            }
                            {(row.is_selected===undefined || row.is_selected===false) &&
                              <AppCheckbox label={''} value={'select_all'}  handleChangeCB={(e)=>handleSelectOne(index,e.target.checked)} checked={false}/>
                            }
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row"  onClick={() =>handleViewItem(index)} sx={{cursor:'pointer'}}>
                            {row.payment_sequence} <span style={{background: (row.payment_type_lbl==='RECEIVED')?'green':'red',
    color: 'white',
    fontSize: '10px',
    fontWeight: 'bold',
    padding: '2px'}}>{row.payment_type_lbl}</span>
     {(row.converted_bill_sequence!=='') &&
                            <span style={{fontSize:'10px',fontWeight:'bold',marginLeft:'6px'}}>({row.converted_bill_sequence})</span>
                            }
                          </StyledTableCell>
                          <StyledTableCell>
                            <StudentCard info={row.contact_info} is_photo={true}/>
                          </StyledTableCell>
                          <StyledTableCell>{row.payment_date_display}</StyledTableCell>
                          <StyledTableCell>₹{AppUtils.currency_format(row.paid_amount)}</StyledTableCell>
                          
                          <StyledTableCell sx={{textAlign:'right'}}>
                            {(row.status===1 || row.status==='1') &&
                              <>
                              {(AppUtils.has_access(state,'503_demand_bill_convert')) &&
                               <Button variant='outlined' size='small' onClick={()=>handleConvertToPayment(index)}>CONVERT TO PAYMENT</Button>
                              }
                              </>
                            }
                           
                          </StyledTableCell>
                          <StyledTableCell  sx={{textAlign:'right'}}>
                            {(row.status===1 || row.status==='1') &&
                              <>
                                {(AppUtils.has_access(state,'503_demand_bill_delete')) &&
                                    <Delete fontSize='small' onClick={() =>handleDeleteItem(index)} sx={{cursor:'pointer'}}/>
                                }
                                {/* {(AppUtils.has_access(state,'503_demand_bill_edit')) &&
                                    <Edit  fontSize='small' onClick={() =>handleEditItem(index)} sx={{cursor:'pointer'}}/>
                                } */}
                                
                              </>
                            }
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      {(is_loading_list===true) &&
                      <StyledTableCell component="th" scope="row" colSpan={5} sx={{textAlign:'center'}}>
                      Please wait while loading the list...
                      </StyledTableCell>
                      }   
                      {(is_loading_list===false && payment_list && payment_list.length===0) &&
                      <StyledTableCell component="th" scope="row" colSpan={5} sx={{textAlign:'center'}}>
                        Oops ! No Record(s) Found.
                      </StyledTableCell>
                      }   
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <AppPagination number_of_page={number_of_page} handleNavigatePage={handleNavigatePage} url={'/payments/demand_bill/page'}/>
              </Grid>
            </Grid>
            {(is_delete_item) &&
              <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" handleRefreshList={handleRefreshList}/>
            }
            {(is_print===true) &&
              <PrintDemandBill payment_data={printList} is_demand_bill={true}/>
            }
          </Box>
      </>
  );
}

export default DemandBillList;