import * as React from 'react';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { IconButton, Typography } from '@mui/material';
import { NotificationsActive } from '@mui/icons-material';
import APP_HTTP from '../APP_HTTP';
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));
  
export default function AppNotification() {
    const initialized = React.useRef(false);
    const [notifications,setNotifications] = React.useState([]);
    const [is_read_nofication,setIsReadNotification] = React.useState(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            APP_HTTP.REQUEST('reports/get_log_history',{},true,true).then((response) => {
                let resp = response.data.response;
                setNotifications(resp);
            });
            APP_HTTP.REQUEST('settings/get_option',{name:'notification_flag'},true,true).then((response) => {
                let resp = response.data.response;
                if(resp.value && resp.value){
                    setIsReadNotification(resp.value);
                }
            });
        }
    },[])
    const handleCloseNotification = ()=>{
        setIsReadNotification(false);
        APP_HTTP.REQUEST('reports/read_log_history',{},true,true).then((response) => {
           
        });
    }
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          
            <IconButton aria-label="cart"  {...bindTrigger(popupState)} style={{float:'right'}}>
                <NotificationsActive style={{color:(is_read_nofication===false)?'#fff':'yellow'}} onClick={handleCloseNotification}/>
            </IconButton>
          
          <Menu {...bindMenu(popupState)}>
            {(notifications.map((value,index)=>{
                return(
                    <MenuItem onClick={popupState.close} key={'mik-'+index} style={{display:'list-item',maxWidth:'300px',whiteSpace:'normal'}}>
                        <Typography style={{fontSize:'12px',color:'#1976d2',fontWeight:'bold'}}>{value.action_title}</Typography>
                        <Typography style={{fontSize:'12px'}}>{value.action_message}</Typography>
                    </MenuItem>
                )
            }))}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}