import { Box, Typography } from '@mui/material';
import * as React from 'react';
const StaffCard = (props)=> {
  const handleViewStaff = (id) =>{
    window.location.href = '/staff/view/'+id;
  }
  return (
      
      <Box style={{display:'flex',cursor:'pointer'}} onClick={()=>handleViewStaff(props.info.staff_id)}>
        <Box style={{height: '70px',width: '70px',background: 'rgb(255, 255, 255)',borderRadius: '50%',textAlignLast: 'center',alignContent: 'center',zIndex: 9,border: '10px solid #ffcc00',boxShadow:'0px 0px 3px black'}}>
            <img src={props.info.photo} height={50} width={50} style={{borderRadius:'50%',objectFit: 'cover'}}/>
        </Box>
        <Box style={{background: '#c71585',color:'#fff',height: '50px',borderTopRightRadius: '10px',borderBottomRightRadius: '10px',paddingLeft:'27px',paddingRight:'10px',paddingTop:'2px',marginTop:'10px',marginLeft:'-17px',boxShadow:'0px 0px 3px black'}}>
          <Typography style={{fontWeight:'bold',whiteSpace:'nowrap',fontSize:'16px'}} variant='h6'>
          {props.info.staff_sequence} / {props.info.staff_name} ({props.info.staff_type})
          </Typography>
          <Typography style={{fontWeight:'bold',fontSize:'11px'}}>
            {props.info.mobile_number}
            {(props.info.alt_mobile_number!=="") &&
            <> | {props.info.alt_mobile_number}</>
            }
          </Typography> 
        
        </Box>
      </Box>
      
  );
}

export default StaffCard;