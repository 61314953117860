import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import APP_HTTP from '../../APP_HTTP';
import { Alert, AlertTitle, Avatar, Button, ButtonGroup, Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Send } from '@mui/icons-material';
import AppDropDown from '../AppDropDown';
import AppUtils from '../../AppUtils';
import { Textarea } from '@mui/joy';
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Notices = (props) => {
    const [notice_to,setNoticeType] = React.useState('all');
    const [notice_title,setNoticeTitle] = React.useState('');
    const [editorState,setEditorState] = React.useState(EditorState.createEmpty());
    const [notice_template_type,setDesignType] = React.useState('default');
    const [template_background,setNoticeBackground] = React.useState('');
    const [design_data,setDesignData] = React.useState({});
    const [message,setMessage] = React.useState('');
    const [notice_list,setNoticeList] = React.useState([]);
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetNoticeTemplate();
            handleLoadNotice();
        }
    },[])
    const handleGetNoticeTemplate = ()=>{
       APP_HTTP.REQUEST('settings/get_option',{name:'notice_type'},true,true).then((response) => {
           let resp = response.data.response;
           if(resp.value!==undefined && resp.value.header_type!==undefined){
            setDesignType(resp.value.header_type);
               
               if(resp.value.header!==undefined){
                   let img_url = resp.value.header+'?time='+Math.random(1111,9999);
                   setNoticeBackground(img_url);
               }
               
           }
       });
       
       APP_HTTP.REQUEST('settings/get_option',{name:'notice_template_design'},true,true).then((response) => {
           let resp = response.data.response;
           if(resp.value!==undefined){
               let drag_list = resp.value.draggable_list;
               //setDraggableList(drag_list);
               setDesignData(resp.value);
           }
       });
     
   }
    const handleLoadNotice = () =>{
        APP_HTTP.REQUEST('notice/get_notices',{},true,true).then((response) => {
            let resp = response.data;
            let rep_list = resp.response;
            setNoticeList(rep_list);
        });
    }
    const handleSetMessage = (value) =>{
        
        setMessage(value)
    }
    const handleAddNotice = () =>{
        let not_list = [...notice_list];
        let not_item = {notice_to:notice_to,message:message,notice_title:notice_title};
        not_list.push(not_item);
        setNoticeList(not_list);
        APP_HTTP.REQUEST('notice/save_notice',{notice_to:notice_to,notice_title:notice_title,message:message},true,true).then((response) => {
            setMessage('');
            setNoticeTitle('');
        });
    }
    const handleEnterPress = (e) =>{
        if(e.which===13){
            handleAddNotice();
        }
    }
    const handleRemoveNotice = (id,index) =>{
      if(AppUtils.has_access(state,'200_notices_delete')){
        let not_list = [...notice_list];
        not_list.splice(index,1);
        setNoticeList(not_list);
        APP_HTTP.REQUEST('notice/remove_notice',{id:id},true,true).then((response) => {
          
        });
      }
    }
    const handleChangeNoticeType = (id,value) =>{
        setNoticeType(value);
    }
    const onEditorStateChange = (e)=>{
      setEditorState(e)
    }
  return (
      <>
       {(AppUtils.has_access(state,'200_notices') && AppUtils.has_access(state,'200_notices_view')) &&
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <AppDropDown label="Notice To" list_item={[{key:'all',value:'To All'},{key:'staff',value:'To Staff'},{key:'parent',value:'To Parent'}]} size="small" handleChangeData={handleChangeNoticeType} defaultValue={notice_to} id='notice-type'/>
            </Grid>
            {(AppUtils.has_access(state,'200_notices_add')) &&
            <>
              {(notice_template_type==='default') &&
              <>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TextField
                                        size='small'
                                            label="Notice Title"
                                            id="outlined-start-adornment"
                                            sx={{ m: 1 }}
                                            onChange={(e)=>setNoticeTitle(e.target.value)}
                                            value={notice_title}
                                        />
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-reply">Notice</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-reply"
                      type='text'
                      onChange = {(event)=>handleSetMessage(event.target.value)}
                      onKeyDown={(e)=>handleEnterPress(e)}
                      value={message}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="outlined-adornment-reply"
                            onClick={handleAddNotice}
                            edge="end"
                          >
                          <Button variant='outlined'>Create</Button>
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Create"
                    />
                  </FormControl>
                </Grid>
              </>
              }
              {(notice_template_type==='custom') &&
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Box style={{height:(design_data.page_height)+'px',width:(design_data.page_width)+'px',backgroundImage:(template_background!=="")?'url('+template_background+')':'',backgroundSize: "contain",backgroundRepeat: "no-repeat",margin:'0 auto'}}>
                {(design_data.page_header_height!==undefined && design_data.page_header_height!=="") &&
                <Box style={{height:(design_data.page_header_height)+'px',width:'100%',position:'relative'}}>
                    {(design_data.draggable_header!==undefined) && 
                      <>
                      {(design_data.draggable_header.map((value,index)=>{
                        return(
                          <Box style={{position:'absolute',transform:'translate('+value.position.x+'px,'+value.position.y+'px)'}}>
                                <Box className="handle">
                                  
                                    {(value.key==='title') &&
                                    <>
                                      <TextField
                                        size='small'
                                            label={value.value}
                                            id="outlined-start-adornment"
                                            sx={{ m: 1 }}
                                            onChange={(e)=>setNoticeTitle(e.target.value)}
                                            value={notice_title}
                                        />
                                    </>
                                    }
                                    {(value.key==='date') &&
                                    <Typography variant='label' style={{background:'gray',color:'#fff',padding:'5px',borderRadius:'5px',padding:'5px'}}>
                                      DATE
                                    
                                  </Typography>
                                    }
                              </Box>
                          </Box>
                        )
                      }))}
                        
                      </>
                    }
                        </Box>
                        }
                        {(design_data.page_body_height!==undefined && design_data.page_body_height!=="") &&
                        <Box style={{height:(design_data.page_body_height)+'px',width:'100%',paddingLeft:(design_data.page_body_pad_left)+'px',paddingRight:(design_data.page_body_pad_right)+'px'}}>
                            <table style={{width:'100%',height:'100%'}} cellPadding={0} cellSpacing={0}>
                                
                                <tbody>
                                    {(design_data.number_of_items.map((value,index)=>{
                                        return(
                                            <tr>
                                                <td style={{paddingRight:'5px',paddingLeft:'5px',verticalAlign:'top'}}>
                                                <Textarea placeholder="Write your notice here..."  minRows={15} value={message} onChange={(event)=>handleSetMessage(event.target.value)}/>
                                               {/*  <Editor
                                                editorStyle={{ height: '100%',border:'1px solid #d7d7d7' }} 
  editorState={editorState}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={onEditorStateChange}
/>; */}
                                                </td>
                                            </tr>
                                        )
                                    }))}
                                </tbody>
                                
                            </table>
                        </Box>
                        }
                </Box>
                <Divider />
                <Box style={{marginTop:'10px',textAlign:'right'}}>
                
                  <Button onClick={()=>handleAddNotice()} size='medium' variant='outlined'>Save Notice</Button>
               
                </Box>
              </Grid>
              
              }
            </>
            }
              <Grid item xs={12} sm={12} md={12} spacing={2}>
              {(notice_list.length>0) &&
              <Stack sx={{ width: '100%' }} spacing={2}>
                {(notice_list.map((value,index)=>{
                    return (
                      <>
                      
                        <Alert variant='filled' severity="warning" onClose={() => handleRemoveNotice(value.notice_id,index)}>
                            <AlertTitle sx={{textTransform:'capitalize'}}>{value.notice_title} ({value.notice_to})</AlertTitle>
                            {value.message}
                        </Alert>
                      </>
                    )
                }))}
                </Stack>
                }
              </Grid>
            </Grid>
          
          </Box>
}
      </>
  );
}
export default Notices;