import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import { handleChangeMenu, handleSetAccessCheck } from "../../actions/Action";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';

import ContactsIcon from '@mui/icons-material/Contacts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Android, BusAlert, CurrencyRupee, DirectionsBus, EditNote, Error, Group, Inbox, Logout, MoveToInbox, NoteAlt, Payments, QueryBuilder, School, Storage, VerifiedUser, Warning } from '@mui/icons-material';


import { Typography } from '@mui/material';

import AdminLogin from './AdminLogin';
import APP_HTTP from '../../APP_HTTP';
import SchoolList from './SchoolList';
import AdminQueryBuilder from './AdminQueryBuilder';
import ViewSchool from './ViewSchool';
import AdminAPK from './AdminAPK';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const BASE_URL = 'school/admin';
const  AdminMain = ()=> {
  const dispatch = useDispatch();
  const [user_name,setUserName] =  React.useState('');
  const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
          let school_info = JSON.parse(localStorage.getItem('MnzYs4pVGL'));
            initialized.current = true;
        }
  })

  const theme = useTheme();
  const state = useSelector((state) => state);
  const active_menu = state.main_menu;
  
  const sub_menu = state.sub_menu;
  let menu_label = state.menu_label;
  
  let is_admin_logged_in = state.is_admin_logged_in;
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSelectMenu = (label,menu,sub_menu='',is_enable_add_btn=false) => {
    if(menu==='logout'){
      localStorage.removeItem('YRqQPcMQMz');
      window.location.href = '/school/admin/login';
  
    }else{
      dispatch(handleChangeMenu(menu,sub_menu,label,is_enable_add_btn));
    }
    handleDrawerClose();
  }
  return (
    <>
    {(is_admin_logged_in===true) &&
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box variant="h6" component="div" sx={{width:'100%'}}>
              <Box component="span"></Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <Typography sx={{textTransform:'capitalize',fontWeight:'700'}}>Welcome {user_name}</Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
              <List>
                <ListItem key={'Schools'} disablePadding onClick={() => handleSelectMenu('Schools',BASE_URL+'/registered-school','',false)} selected={(active_menu.match('registered-school'))?true:false}>
                  <ListItemButton>
                    <ListItemIcon sx={{minWidth:'36px'}}>
                      <School />
                    </ListItemIcon>
                    <ListItemText primary={'Schools'} />
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem key={'Query Builder'} disablePadding onClick={() => handleSelectMenu('Query Builder',BASE_URL+'/query-builder','',false)} selected={(active_menu.match('query-builder'))?true:false}>
                  <ListItemButton>
                    <ListItemIcon sx={{minWidth:'36px'}}>
                      <Storage />
                    </ListItemIcon>
                    <ListItemText primary={'Query Builder'} />
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem key={'Apps'} disablePadding onClick={() => handleSelectMenu('Apps',BASE_URL+'/apps','',false)} selected={(active_menu.match('apps'))?true:false}>
                  <ListItemButton>
                    <ListItemIcon sx={{minWidth:'36px'}}>
                      <Android />
                    </ListItemIcon>
                    <ListItemText primary={'Apps'} />
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem key={'Logout'} disablePadding onClick={() => handleSelectMenu('Logout','logout','',false)}>
                  <ListItemButton>
                    <ListItemIcon sx={{minWidth:'36px'}}>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText primary={'Logout'} />
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
           
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Box>
            {(active_menu.match('registered-school')) &&
              <SchoolList />
            }
            {(active_menu.match('school_view')) &&
              <ViewSchool />
            }
            {(active_menu.match('query-builder')) &&
              <AdminQueryBuilder />
            }
            {(active_menu.match('apps')) &&
              <AdminAPK />
            }
          </Box>
        </Main>
      </Box>
    }
    {(is_admin_logged_in===false) &&
      <>
        <AdminLogin />
      </>
    }
    </>
  );
}
export default AdminMain;