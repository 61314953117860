import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useSelector, useDispatch } from "react-redux";
import FromStudent from './FromStudent';
import FromStaff from './FromStaff';
import { handleChangeMenu } from '../../actions/Action';
import AppUtils from '../../AppUtils';

function CustomTabPanel(props) {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function LeaveRequest() {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('');
  const state = useSelector((state) => state);
  const initialized = React.useRef(false);
  React.useEffect(()=>{
    if (!initialized.current) {
        initialized.current = true;
        let current_path = window.location.pathname;
        let st_match = /student/g;
        let st_page = current_path.match( st_match );
        
        if(st_page){
          setValue(0);
        }
        let sta_match = /staff/g;
        let sta_page = current_path.match( sta_match );
        if(sta_page){
          setValue(1);
        }
    }
},[])
  const handleChange = (event, newValue) => {
    let menu = 'leave_requests';
    let sub_menu = '';
    let label = 'Leave Reqeusts';
    if(newValue===0){
      sub_menu = 'student';
      label = 'Leave Reqeusts / Students';
    }else{
      sub_menu = 'staff';
      label = 'Leave Reqeusts / Staffs';
    }
    dispatch(handleChangeMenu(menu,sub_menu,label,false));
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {(AppUtils.has_access(state,'170_from_student_view')) &&
          <Tab label="From Student" {...a11yProps(0)} />
        }
         {(AppUtils.has_access(state,'170_from_staff_view')) &&
          <Tab label="From Staff" {...a11yProps(1)} />
        }
        </Tabs>
      </Box>
     
        {(value===0 && AppUtils.has_access(state,'170_from_student_view')) &&
          <CustomTabPanel value={value} index={0}>
            <FromStudent />
          </CustomTabPanel>
        }
     
        {(value===1 && AppUtils.has_access(state,'170_from_staff_view')) &&
            <CustomTabPanel value={value} index={1}>
            <FromStaff />
            </CustomTabPanel>
        }
     
    </Box>
  );
}
