import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function AppMultiSelectCB(props) {
  const [selectedValue, setSelectedValue] = React.useState([]);
  const list_items = props.list_item;
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    if(props.handleChangeData){
      props.handleChangeData(event.target.name,event.target.value);
    }
  };
  const initialized = React.useRef(false);
  React.useEffect(()=>{
      if (!initialized.current) {
        if(props.defaultValue){
          let value = props.defaultValue;
          setSelectedValue(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
        }
      }
  },[props])
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id={props.id+"-checkbox-label"}>{props.label}</InputLabel>
        <Select
          labelId={props.id+"-checkbox-label"}
          id={props.id+"-checkbox"}
          name={props.id}
          multiple
          value={selectedValue}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          size='small'
        >
          {list_items.map((item_name) => (
            <MenuItem key={item_name} value={item_name}>
              <Checkbox checked={selectedValue.indexOf(item_name) > -1} />
              <ListItemText primary={item_name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
export default React.memo(AppMultiSelectCB);