import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Divider, InputLabel, MenuItem, NativeSelect, TextField, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, Close } from '@mui/icons-material';
import LoaderButton from '../../LoaderButton';
import APP_HTTP from '../../../APP_HTTP';
import { useSelector, useDispatch } from "react-redux";
import AppUtils from '../../../AppUtils';
const MarksGrade = (props) => {
    const state = useSelector((state) => state);
    let [divisions,setDivisions] = React.useState([]);    
    let [grades,setGrades] = React.useState([]);    
    let [removed_divisions,setRemovedDivisions] = React.useState([]);    
    let [removed_grades,setRemovedGrades] = React.useState([]);    
    let [is_save_division,setIsSaveDivision] = React.useState(false);    
    let [is_save_grade,setIsSaveGrade] = React.useState(false);    
      
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        
        if (!initialized.current) {
            initialized.current = true;
            APP_HTTP.REQUEST('settings/get_divisions',{},true,true).then((response) => {
                let resp = response.data;
                let dvsns = resp.response;
                setDivisions(dvsns);
            });
            APP_HTTP.REQUEST('settings/get_grades',{},true,true).then((response) => {
                let resp = response.data;
                let grds = resp.response;
                setGrades(grds);
            });
        }
    
    },[])

    const handleAddNewDivision = () =>{
        let div_tmp = [...divisions];
        let divs = {division_id:'',name:'',greater_than:'',less_than:''};
        div_tmp.push(divs);
        setDivisions(div_tmp);
    }

    const handleRemoveDivisionItem = (index)=>{
        let div_tmp = [...divisions];

        // REMOVED ITEMS OPEN
            let rm_tmp = [...removed_divisions];
            let rm_item = div_tmp[index];
            rm_tmp.push(rm_item);
            setRemovedDivisions(rm_tmp);
        // REMOVED ITEMS CLOSE

        div_tmp.splice(index,1);
        setDivisions(div_tmp);
    }
    const handleChangeDivisionValue = (index,key,value) =>{
        let div_tmp = [...divisions];
        div_tmp[index][key] = value;
        setDivisions(div_tmp);
    }
    const handleAddNewGrade = () =>{
        let grade_tmp = [...grades];
        let grds = {grade_id:'',name:'',greater_than:'',less_than:''};
        grade_tmp.push(grds);
        setGrades(grade_tmp);
    }

    const handleRemoveGradeItem = (index)=>{
        let grade_tmp = [...grades];

        // REMOVED ITEMS OPEN
            let rm_tmp = [...removed_grades];
            let rm_item = grade_tmp[index];
            rm_tmp.push(rm_item);
            setRemovedGrades(rm_tmp);
        // REMOVED ITEMS CLOSE

        grade_tmp.splice(index,1);
        setGrades(grade_tmp);
    }
    const handleChangeGradeValue = (index,key,value) =>{
        let grade_tmp = [...grades];
        grade_tmp[index][key] = value;
        setGrades(grade_tmp);
    }
    const handleSaveDivision = () =>{
        setIsSaveDivision(true);
        APP_HTTP.REQUEST('settings/save_division',{divisions:divisions,removed_divisions:removed_divisions},true,true).then((response) => {
            setIsSaveDivision(false);
        });
    }
    const handleSaveGrade = () =>{
        setIsSaveGrade(true);
        APP_HTTP.REQUEST('settings/save_grade',{grades:grades,removed_grades:removed_grades},true,true).then((response) => {
            setIsSaveGrade(false);
        });
    }
  return (
    <>
         <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Division & Grade Settings
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                {(AppUtils.has_access(state,'32_division_view')) &&
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} spacing={2} sx={{textAlign:'left'}}>
                    <Typography variant='h5'>Division Settings</Typography>
                    </Grid>
                    {(AppUtils.has_access(state,'32_division_add')) &&
                    <Grid item xs={12} sm={12} md={6} spacing={2} sx={{textAlign:'right'}}>
                        {(divisions.length===0) &&
                            <Button variant='outlined' size='medium' onClick={handleAddNewDivision}>Add New Division</Button>
                        }
                       
                    </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <Divider />
                    </Grid>
                    {(divisions.map((value,index)=>{
                            return(
                                <>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                        <TextField
                                            label="Division Name"
                                            size="small"
                                            fullWidth
                                            onChange={(event)=>handleChangeDivisionValue(index,'name',event.target.value)}
                                            value={value.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                        <TextField
                                            label="Percent Greater Than"
                                            size="small"
                                            fullWidth
                                            onChange={(event)=>handleChangeDivisionValue(index,'greater_than',event.target.value)}
                                            value={value.greater_than}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                        <TextField
                                            label="Percent Less Than"
                                            size="small"
                                            fullWidth
                                            onChange={(event)=>handleChangeDivisionValue(index,'less_than',event.target.value)}
                                            value={value.less_than}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                       <Close onClick={()=>handleRemoveDivisionItem(index)} sx={{cursor:'pointer'}}/>
                                       {(index===divisions.length-1) &&
                                            <AddCircleOutline onClick={handleAddNewDivision} sx={{cursor:'pointer'}}/>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                                        <Divider />
                                    </Grid>
                                </>
                            )
                    }))}
                    {(AppUtils.has_access(state,'32_division_add') || AppUtils.has_access(state,'32_division_edit')) &&
                    <>
                    {(divisions.length>0) &&
                        <>
                            <Grid item xs={12} sm={12} md={12} spacing={2} sx={{textAlign:'right'}}>
                                {(is_save_division===false) &&
                                    <Button variant='outlined' size='medium' onClick={handleSaveDivision}>Save</Button>
                                }
                                {(is_save_division===true) &&
                                    <LoaderButton variant='outlined' size='medium'/>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <Divider />
                            </Grid>
                        </>
                    }
                    </>
                    }
                </Grid>
                }
                {(AppUtils.has_access(state,'26_grade_view')) &&
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} spacing={2} sx={{marginTop:'10px'}}>
                    <Typography variant='h5'>Grade Settings</Typography>
                    </Grid>
                    {(AppUtils.has_access(state,'26_grade_add')) &&
                    <Grid item xs={12} sm={12} md={6} spacing={2} sx={{marginTop:'10px',textAlign:'right'}}>
                    {(grades.length===0) &&
                        <>
                            <Button variant='outlined' size='medium' onClick={handleAddNewGrade}>Add New Grade</Button>
                        </>
                    }
                    </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <Divider />
                    </Grid>
                    {(grades.map((value,index)=>{
                            return(
                                <>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                        <TextField
                                            label="Grade Name"
                                            size="small"
                                            fullWidth
                                            onChange={(event)=>handleChangeGradeValue(index,'name',event.target.value)}
                                            value={value.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                        <TextField
                                            label="Percent Greater Than"
                                            size="small"
                                            fullWidth
                                            onChange={(event)=>handleChangeGradeValue(index,'greater_than',event.target.value)}
                                            value={value.greater_than}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                        <TextField
                                            label="Percent Less Than"
                                            size="small"
                                            fullWidth
                                            onChange={(event)=>handleChangeGradeValue(index,'less_than',event.target.value)}
                                            value={value.less_than}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                                       <Close onClick={()=>handleRemoveGradeItem(index)} sx={{cursor:'pointer'}}/>
                                       {(index===grades.length-1) &&
                                            <AddCircleOutline onClick={handleAddNewGrade} sx={{cursor:'pointer'}}/>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                                        <Divider />
                                    </Grid>
                                </>
                            )
                    }))}
                    {(AppUtils.has_access(state,'26_grade_add') || AppUtils.has_access(state,'26_grade_edit')) &&
                    <>
                    {(grades.length>0) &&
                        <>
                            <Grid item xs={12} sm={12} md={12} spacing={2} sx={{textAlign:'right'}}>
                            {(is_save_grade===false) &&
                                <Button variant='outlined' size='medium' onClick={handleSaveGrade}>Save</Button>
                            }
                            {(is_save_grade===true) &&
                                <LoaderButton variant='outlined' size='medium'/>
                            }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <Divider />
                            </Grid>
                        </>
                    }
                    </>
                    }
                </Grid>
                }
                </Grid>
                
            </Grid>
        </Box>
    </>
  );
}
export default MarksGrade;