import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Classes from '../settings/classes/Classes';
import Paper from '@mui/material/Paper';
import { deepOrange, deepPurple } from '@mui/material/colors';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Modal from '@mui/material/Modal';
import { Cancel, CheckCircle } from '@mui/icons-material';
import AppDropDown from '../AppDropDown';
import Sections from '../settings/sections/Sections';
import BasicDatePicker from '../DatePicker';
import DownloadAttendence from './DownloadAttendence';
import AppUtils from '../../AppUtils';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
     
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function Attendence(props) {
  const state = useSelector((state) => state);
   const [attendence_type,setAttendenceType] = React.useState('student');
   const [fromDate,setFromDate] = React.useState(new Date());
   const [toDate,setToDate] = React.useState(new Date());
   const [filterType,setFilterType] = React.useState('today');
   const [isGenerating,setIsGenerating] = React.useState(false);
   const [selected_class,setClass] = React.useState(0);
   const [selected_section,setSection] = React.useState(0);
   const [students,setStudentsList] = React.useState([]);
   const [attendence_ids,setAttendenceIds] = React.useState([]);
   const [attendence_info,setAttendenceInfo] = React.useState([]);
   const [attendence_list,setAttendenceList] = React.useState([]);
   let [contact_info,setContactInfo] = React.useState([]);
   const initialized = React.useRef(false);
   React.useEffect(()=>{
       if (!initialized.current) {
           initialized.current = true;
           if(props.type!==undefined){
            setAttendenceType(props.type);
            handleChangeAttendenceType(0,props.type);
           }
       }
   })
   const handleChangeAttendenceType = (id,value)=>{
    setAttendenceList([]);
    setAttendenceIds([]);
    setAttendenceInfo([]);
    setContactInfo([]);
    setAttendenceType(value);
    if(value==='staff'){
      APP_HTTP.REQUEST('staff/search_staff',{search:'',type:'autocomplete'},true,true,false,true).then((stresponse) => {
        let stresp = stresponse.data;
        let search_list = stresp.response;
        setStudentsList(search_list);
      });
    }
   }
   const handleChangeFilterType = (id,value)=>{
    setFilterType(value);
   }
   const handleChangeSection = (id,value) =>{
    setSection(value);
    APP_HTTP.REQUEST('student/search_student',{search:'',type:'autocomplete',class:selected_class,section:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setStudentsList(search_list);
    });
  }
  const handleChangeClass = (id,value) =>{
    setClass(value);
    setSection(0);
    APP_HTTP.REQUEST('student/search_student',{search:'',type:'autocomplete',class:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setStudentsList(search_list);
    });
  }
  const handleChangeInputValue = (value) =>{
    
  }
  const handleChangeValue = (value) =>{
    setContactInfo(value)
  }
  const handleChangeFromDate = (id,date) =>{
    setFromDate(date);
  }
  const handleChangeToDate = (id,date) =>{
    setToDate(date);
  }
  const handleGenerateReport = () =>{
    setAttendenceList([]);
      setAttendenceIds([]);
      setAttendenceInfo([]);
    setIsGenerating(true);
     let data = {};
     if(attendence_type==='student'){
      data = {
        filter_type : filterType,
        attendence_type : attendence_type,
        fromDate:fromDate,
        toDate:toDate,
        class : selected_class,
        section : selected_section,
        contact_info : contact_info,
      }
     }else if(attendence_type==='staff'){
      data = {
        filter_type : filterType,
        attendence_type : attendence_type,
        fromDate:fromDate,
        toDate:toDate,
        contact_info : contact_info,
      }
     }
     APP_HTTP.REQUEST('reports/attendence_report',data,true,true,false,true).then((response) => {
      let resp = response.data;
      let att_list = resp.attendence_list;
      let id_arr = resp.id_arr;
      let info_arr = resp.info_arr;
      setAttendenceList(att_list);
      setAttendenceIds(id_arr);
      setAttendenceInfo(info_arr);
      setIsGenerating(false);
    });
  }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>ATTENDENCE REPORT</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Use the following filter to generate attendence report of student or staff.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <AppDropDown label="Filter Type" list_item={[{key:'today',value:'Today'},{key:'yesterday',value:'Yesterday'},{key:'thisweek',value:'This Week'},{key:'lastweek',value:'Last Week'},{key:'lastmonth',value:'Last Month'},{key:'thismonth',value:'This Month'},{key:'custom',value:'Custom'}]} size="small" handleChangeData={handleChangeFilterType} defaultValue={filterType}/>
                </Grid>
                {(filterType==='custom') &&
                <>
                  <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="Select From Date" size="small" handleChangeData={handleChangeFromDate}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="Select To Date" size="small" handleChangeData={handleChangeToDate}/>
                  </Grid>
                </>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(props.type===undefined) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <AppDropDown label="Attendence Type" list_item={[{key:'student',value:'Student'},{key:'staff',value:'Staff'}]} size="small" handleChangeData={handleChangeAttendenceType} defaultValue={attendence_type}/>
                </Grid>
                }
                {(attendence_type==='student') &&
                    <>
                        <Grid item xs={12} sm={12} md={2}>
                            <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>
                        </Grid>
                        {(selected_class>0) &&
                            <Grid item xs={12} sm={12} md={2}>
                            <Sections show="dropdown" handleChangeData={handleChangeSection}  defaultValue={selected_section}/>
                            </Grid>
                        }
                        {(selected_class>0 && attendence_type==='student') &&
                            <Grid item xs={12} sm={12} md={3}>
                                <Autocomplete
                                    value={contact_info}
                                    noOptionsText={'No Student Found ! Type To Search'}
                                    onChange={(event, newValue) => handleChangeValue(newValue)}
                                    onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                                    disablePortal
                                    id="payee_dropdown"
                                    options={students}
                                    size="small"
                                    renderInput={(params) => <TextField {...params} label={'Select Student'} />}
                                />
                            </Grid>
                            }
                    </>
                }     
                {(attendence_type==='staff') &&
                    <Grid item xs={12} sm={12} md={3}>
                      <Autocomplete
                          value={contact_info}
                          noOptionsText={'No Staff Found ! Type To Search'}
                          onChange={(event, newValue) => handleChangeValue(newValue)}
                          onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                          disablePortal
                          id="payee_dropdown"
                          options={students}
                          size="small"
                          renderInput={(params) => <TextField {...params} label={'Select Staff'} />}
                      />
                    </Grid>
                } 
                {(AppUtils.has_access(state,'14_attendence_view')) &&
                <>
                {(selected_class>0 || attendence_type==='staff') &&
                  <>
                    <Grid item xs={12} sm={12} md={2} spacing={2}>
                      {(isGenerating===false) &&
                        <Button variant='outlined' size='medium' onClick={handleGenerateReport}>GENERATE</Button>
                      }
                      {(isGenerating===true) &&
                        <LoaderButton label="Generating..."  variant='outlined' size='medium'/>
                      }
                    </Grid>
                  </>
                }
                </>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(AppUtils.has_access(state,'14_attendence_print')) &&
                <>
                {(attendence_ids.length>0) &&
                  <Grid item xs={12} sm={12} md={12} spacing={2} sx={{textAlign:'right'}}>
                    <DownloadAttendence data={{attendence_type:attendence_type,attendence_ids:attendence_ids,attendence_info:attendence_info,attendence_list:attendence_list}}/>
                  </Grid>
                }
                </>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    {attendence_ids.map((value,index)=>{
                      return (
                        <>
                        <Typography variant='h4' sx={{fontSize: '25px', background: '#19d29d',color:'#fff',padding: '10px'}}>
                        {attendence_info[value].info_sequence} | {attendence_info[value].info_name}
                        {(attendence_info[value].roll_number) &&  
                        <>
                        - {attendence_info[value].roll_number}/{attendence_info[value].class}({attendence_info[value].section})
                        </> 
                        }
                        </Typography>
                        <Divider />
                          {(attendence_list[value]!==undefined) &&
                          <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                          
                            {attendence_list[value].map((attval,idx)=>{
                              return(
                                <Grid item xs={6} sm={6} md={1} spacing={2} sx={{marginTop:'10px'}}>
                                  <Box sx={{boxShadow:'5px 5px 5px black'}}>
                                  <Typography variant="h2" sx={{fontSize: '12px',color: '#fff',background:(attval.present===2)?'#d1d1d1':(attval.present===1)?'green':'red',textAlign: 'center',padding: '10px 0px',textTransform: 'uppercase',fontWeight:'900'}}>
                                    {attval.day}
                                  </Typography>
                                  <Typography variant="h2" sx={{fontSize: '18px',color: '#fff',textAlign: 'center',padding: '10px 0px',textTransform: 'uppercase',background:'#f0ffff'}}>
                                    {(attval.present===1) &&
                                      <>
                                        <CheckCircle sx={{color:'green'}}/>
                                        <Typography sx={{color:'green'}}>
                                          Present 
                                        </Typography>
                                        {(attval.late_by!==0) &&
                                        <Typography sx={{color:'red', fontSize:'11px'}}>
                                          LATE BY {attval.late_by}
                                        </Typography>
                                        }
                                      </>
                                    }
                                    {(attval.present===0) &&
                                      <>
                                        <Cancel sx={{color:'red'}}/>
                                        <Typography sx={{color:'red'}}>
                                          Absent
                                        </Typography>
                                      </>
                                    }
                                    {(attval.present===2) &&
                                      <>
                                          <Cancel sx={{color:'#d1d1d1'}}/> 
                                          <Typography sx={{color:'#d1d1d1'}}>
                                            Leave
                                          </Typography>
                                      </>
                                    }
                                  </Typography>
                                  <Typography variant="h2"  sx={{fontSize: '14px',color: '#fff',background:(attval.present===2)?'#d1d1d1':(attval.present===1)?'green':'red',textAlign: 'center',padding: '10px 0px',textTransform: 'uppercase',fontWeight:'900'}}>
                                    {attval.date}
                                  </Typography>
                                  </Box>
                                </Grid>
                              )
                            })}
                          </Grid>
                          } 
                          {(attendence_list[value]===undefined) &&
                            <Typography>No Attendence found</Typography>
                          }
                        </>
                      )
                    })}
                    {(isGenerating===true) &&
                    <>
                      
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                        </Stack>
                    
                      <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                      {Array.from(Array(12), (e, i) => {
                        return(
                          <Grid item xs={6} sm={6} md={1} spacing={2} sx={{marginTop:'10px'}}>
                            <Stack spacing={1}>
                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={150} />
                            </Stack>
                          </Grid>
                        )
                        })}
                      </Grid>
                    </>
                    }
                </Grid>
               
            </Grid>
        </Box>
    </Box>
  );
}
export default React.memo(Attendence);