import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { Divider, Modal, TextField, Typography } from '@mui/material';
import Textarea from '@mui/joy/Textarea';
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';

  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'300px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px',
  };

const AdminQueryBuilder = (props)=> {
  const [isProcessQuery,setisProcessQuery] = React.useState(false);
  const [new_query,setNewQuery] = React.useState('');
  const [auth_data,setAuthData] = React.useState({username:'',password:''});
  const [isAuth,setIsAuth] = React.useState(false);
  const handleProcessQuery = () =>{
    setIsAuth(true);
   
  }
  const handleChangeQuery = (value) =>{
    setNewQuery(value);
  }
  const handleClose = () =>{
    setIsAuth(false);
  }
  const handleProcessForQuery = () =>{
    let pu = auth_data.username;
    let pk = auth_data.password;
    setisProcessQuery(true);
    APP_HTTP.REQUEST('school/admin/process_query',{query:new_query,pu:pu,pk:pk},true,false,false,false,true).then((response) => {
        let resp = response.data;
        if(resp.success===1){
          setIsAuth(false);
        }
        setisProcessQuery(false);
    });
  }
  const handleChangeAuth = (key,value) =>{
    let adata = {...auth_data};
    adata[key] = value;
    setAuthData(adata);
  }
  return (
    <div id="query-builder">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            <Grid item xs={12}> 
                            
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>Query Builder</Typography>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                  
                    <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                      <Textarea placeholder="Write query here..."  minRows={10} onChange={(e)=>handleChangeQuery(e.target.value)}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} spacing={2} className="mrtop-30 footer-btn-blk">
            <Grid xs={12} sx={{textAlign:'right'}}>
              
              <Button variant="contained" onClick={() => handleProcessQuery()}>Save Query</Button>
              
              
            </Grid>
        </Grid>
        {(isAuth===true) &&
          <Modal
          open={true}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal_style}>
            <Box sx={{maxHeight:'400px',overflowY:'auto'}}>
              <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} spacing={2} sx={{marginTop:'10px'}}>
                      <Typography variant='h4'>Authorization</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} spacing={2} sx={{marginTop:'10px'}}>
                      <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                      <TextField
                          label="Username"
                          size="small"
                          id="username"
                          fullWidth
                          onChange={(e)=>handleChangeAuth('username',e.target.value)}
                          />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                      <TextField
                          label="Password"
                          size="small"
                          id="password"
                          type='password'
                          fullWidth
                          onChange={(e)=>handleChangeAuth('password',e.target.value)}
                          />
                  </Grid>
              </Grid>
            </Box>
            <Typography id="modal-modal-footer">
              <Grid container spacing={2}>    
                  <Grid item xs={12} sm={12} md={12} spacing={2} sx={{marginTop:'10px'}}>
                      <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} spacing={2} sx={{paddingTop:'10px',textAlign:'right'}}>
                      <Button sx={{float:'left'}} onClick={()=>handleClose()}>Close</Button>
                      {(isProcessQuery===false) &&
                        <Button variant="outlined" onClick={()=>handleProcessForQuery()}>
                            Proceed
                        </Button>
                      }
                      {(isProcessQuery===true) &&
                          <LoaderButton variant="outlined"/>
                      }
                  </Grid>
              </Grid>
            </Typography>
          </Box>
          </Modal>
        }
        </Box>
    </div>
  );
}
export default AdminQueryBuilder;