import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, ButtonGroup, Divider, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppPagination from '../AppPagination';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import DeleteConfirm from '../DeleteConfirm';
import { handleConfirmDeletePayment, handleSetPaymentsList, handleToggleEditPayment,handleSetPaymentInfo, handleToggleViewPayment } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import { useUtils } from '@mui/x-date-pickers/internals';
import AppUtils from '../../AppUtils';
import StudentCard from '../students/StudentCard';
import PettyCashEntry from './PettyCashEntry';
import BasicDatePicker from '../DatePicker';
import PrintPettyCash from './PrintPettyCash';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PettyCash = (props) => {
  
  const [is_open_petty_cash_entry,setIsOpenPettyCashEntry] = React.useState(false);
  const [is_print,setIsPrint] = React.useState(false);
  const [is_loading_list,setIsLoadingList] = React.useState(false);
  const [petty_cash_list,setPettyCashList] = React.useState([]);
  const [cash_data,setCashData] = React.useState({});
  const [petty_cash_date,setPettyCashDate] = React.useState(new Date());
 
  const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleLoadPettyCash(new Date());
        }
    },[])
    const handleLoadPettyCash = (pdate) =>{
      setIsLoadingList(true);
      dispatch(handleSetPaymentsList([]));
      APP_HTTP.REQUEST('payment/get_petty_cash',{petty_cash_date:pdate},true,true).then((response) => {
          let resp = response.data;
          let petty_cash = resp.response;
          let data = resp.data;
          setCashData(data);
          setIsLoadingList(false);
          setPettyCashList(petty_cash);
      });
    }
    const handleOpenPettyCashEntry = ()=>{
      setIsOpenPettyCashEntry(true)
    }
    const handleClosePettyCash = ()=>{
      setIsOpenPettyCashEntry(false)
    }
    const handleChangePettyCashDate = (id,value) =>{
      setPettyCashDate(value);
      handleLoadPettyCash(value);
    }
    const handlePrintPettyCash = ()=>{
        setIsPrint(true);
        setTimeout(() => {
          setIsPrint(false);
        }, 3000);
    }
  return (
      <>
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            
              <Grid item xs={12} sm={12} md={2} spacing={2}>
              <BasicDatePicker label="Petty Cash Date" size="small" id="petty_cash_date" handleChangeData={handleChangePettyCashDate} defaultValue={petty_cash_date}/>
              </Grid>
              <Grid item xs={12} sm={12} md={2} spacing={2}>
              {(cash_data.in!==undefined) &&
                <Typography style={{textAlign:'left',color:'#06be06',fontSize: '14px',border: '1px solid', padding: '5px',textAlign: 'center',borderRadius: '5px'}}> 
                      TOTAL IN(+) <Typography variant='h6'>₹{AppUtils.currency_format(cash_data.in)}</Typography> 
                </Typography>
              }
              </Grid>
              <Grid item xs={12} sm={12} md={2} spacing={2}>
              {(cash_data.in!==undefined) &&
                <Typography  style={{textAlign:'left',color:'red',fontSize: '14px',border: '1px solid', padding: '5px',textAlign: 'center',borderRadius: '5px'}}> 
                      TOTAL OUT(-) <Typography variant='h6'>₹{AppUtils.currency_format(cash_data.out)}</Typography> 
                </Typography>
              }
              </Grid>
              <Grid item xs={12} sm={12} md={2} spacing={2}>
              {(cash_data.in!==undefined) &&
                <Typography style={{textAlign:'left',color:(cash_data.cash_in_couner<0)?'red':'#06be06',fontSize: '14px',border: '1px solid', padding: '5px',textAlign: 'center',borderRadius: '5px'}}> 
                      CASH IN COUNTER <Typography variant='h6'>₹{AppUtils.currency_format(cash_data.cash_in_couner)}</Typography> 
                </Typography>
              }
              </Grid>
              
              <Grid item xs={12} sm={12} md={2} spacing={2}>
                <Button variant='outlined' size='medium' onClick={handlePrintPettyCash}>Print</Button>
              </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2} style={{textAlign:'right'}}>
                    <Button variant='outlined' size='medium' onClick={handleOpenPettyCashEntry}>New Petty Cash Entry</Button>
                </Grid>
                {(is_open_petty_cash_entry===true) &&
                  <PettyCashEntry handleClosePettyCash={handleClosePettyCash}/>
                }
              
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>CONTACT NAME</StyledTableCell>
                        <StyledTableCell>DATE</StyledTableCell>
                        <StyledTableCell>PARTICULARS</StyledTableCell>
                        <StyledTableCell>REFERENCE</StyledTableCell>
                        <StyledTableCell>IN(+)</StyledTableCell>
                        <StyledTableCell>OUT(-)</StyledTableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {petty_cash_list && petty_cash_list.map((row,index) => (
                        <StyledTableRow key={row.petty_cash_list_id}>
                          <StyledTableCell>{row.contact_name}</StyledTableCell>
                          <StyledTableCell>{row.date_display}</StyledTableCell>
                          <StyledTableCell>{row.particular}</StyledTableCell>
                          <StyledTableCell>{row.reference}</StyledTableCell>
                          <StyledTableCell style={{color:'#06be06'}}><Typography variant='h5'>{(row.entry_type==='in')?'₹'+AppUtils.currency_format(row.amount):'-'}</Typography></StyledTableCell>
                          <StyledTableCell style={{color:'red'}}><Typography variant='h5'>{(row.entry_type==='out')?'₹'+AppUtils.currency_format(row.amount):'-'}</Typography></StyledTableCell>
                          
                        </StyledTableRow>
                      ))}
                      {(is_loading_list===true) &&
                      <StyledTableCell component="th" scope="row" colSpan={6} sx={{textAlign:'center'}}>
                      Please wait while loading the list...
                      </StyledTableCell>
                      }   
                      {(is_loading_list===false && petty_cash_list.length===0) &&
                        <StyledTableCell component="th" scope="row" colSpan={6} sx={{textAlign:'center'}}>
                            Oops ! No Record(s) Found.
                        </StyledTableCell>
                      }   
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {(is_print===true) &&
            <PrintPettyCash printData={petty_cash_list} cash_data={cash_data}/>
            }
          </Box>
      </>
  );
}

export default PettyCash;