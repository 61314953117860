import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
let total_obtained_marks =0;
let total_full_marks =0;
const PrintMarksAsList = (props)=> {
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            
            if(props.data){
                let data = props.data;
                setTimeout(() => {
                    handlePrintAllList();
                }, 200);
            }
        }
       
    },[props])
   const handlePrintAllList = ()=>{
    if(document.getElementById("markSheetAsListPrintBlock")){
        var divContents = document.getElementById("markSheetAsListPrintBlock").innerHTML; 

      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
     
          a.document.write('<style type="text/css"><style type="text/css">@page{size:"A4";margin:5cm}table{font-family:verdana, arial, sans-serif;font-size:14px;font-weight:bold;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:5px;vertical-align:center;border:1px solid black}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 1.5 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
         a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
            a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');  
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="markSheetAsListPrintBlock" style={{display:'none'}}>
        <div style={{position:'relative'}}>
        <table>
        <Watermark />
            <thead>
            <PrintHeader header_data={props.data.school_info} colSpan={(props.data.subjects.length+3)}/>    
            <tr>
                <th colSpan={(props.data.subjects.length+3)} style={{textAlign:'center',textTransform:'uppercase',fontSize:'14px'}}>{props.data.exam_type_name} for class {props.data.class_name}</th>
            </tr>
            <tr>
                <th>STUDENT</th>
                
                {props.data.subjects.map((value,index)=>{
                    return (
                        <th style={{textTransform:'uppercase'}}>
                            <p style={{fontSize:'14px',margin:'0px'}}>{value.name}</p>
                        </th>
                    )
                })}
                <th>TOTAL</th>
            </tr>
            </thead>
            <tbody>
                {(props.data && props.data.studentData && props.data.studentData.map((value,index)=>{
                    total_obtained_marks = 0;
                    total_full_marks = 0;
                    return(
                        <tr>
                        <td style={{textTransform:'uppercase'}}>
                            <p style={{margin:'0px'}}>{value.roll_number}/{value.student_name} ({value.section})</p>
                            <p style={{marginTop:'5px'}}>{value.father_name}</p>
                        </td>
                        {(value.marks.map((mvalue,idx)=>{
                            if(!isNaN(mvalue.total_marks) && mvalue.total_marks!=="" && mvalue.total_marks!==null){
                                total_obtained_marks = total_obtained_marks + parseFloat(mvalue.total_marks);
                            }
                            if(!isNaN(mvalue.full_marks) && mvalue.full_marks!=="" && mvalue.full_marks!==null){
                                total_full_marks = total_full_marks + parseFloat(mvalue.full_marks);
                            }
                            return(
                                <>
                                    <td style={{textAlign:'center'}}>
                                        {(mvalue.total_marks!=="")?mvalue.total_marks:'-'}
                                       {/*  <hr />
                                        {(mvalue.full_marks!=="" && !isNaN(mvalue.full_marks))?mvalue.full_marks:'-'} */}
                                    </td>
                                </>
                            )
                        }))}
                        <td style={{textAlign:'center'}}>
                            <p style={{margin:'0px'}}>
                                {total_obtained_marks}
                            </p>
                            <hr />
                            <p style={{margin:'0px'}}>
                                {total_full_marks}
                            </p>
                        </td>
                        </tr>
                    )
                }))}
            </tbody>
        </table>
        </div>
    </div>
  );
}
export default PrintMarksAsList;