import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Typography } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddSection,handleSaveSectionToList,handleUpdateSection } from './Action';
import APP_HTTP from '../../../APP_HTTP';
let initialState = {id:0,name:'',is_edit:false};
const CreateSection = (props)=> {
  const [sectionItem,setSectionItem] = React.useState(initialState);
  const [sectionName,setSectionName] = React.useState('');

  const state = useSelector((state) => state);
  const is_open_add_section = state.is_open_add_section;
  const is_college = state.school_info.school_info.is_college;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(handleOpenAddSection(false));
  };
  React.useEffect(() => {
      let sectionValue = props.sectionValue;
      setSectionItem(sectionValue);
      setSectionName(sectionValue.name);
  },[props]);
  const handleSaveSection = () =>{
    let update_idx = 0;
    let update_id = 0;
    if(props.sectionValue && props.sectionValue.is_edit && props.sectionValue.update_id>=0){
      update_idx = props.sectionValue.update_id;
      update_id = props.sectionValue.id;
      dispatch(handleUpdateSection(update_idx,sectionName));
    }
    APP_HTTP.REQUEST('settings/create_section',{id:update_id,name:sectionName},true,true).then((response) => {
      let resp = response.data;
      let id = resp.id;
      if(update_id===0){
        sectionItem.id = parseInt(id);
        dispatch(handleSaveSectionToList(sectionItem));
      }
    });
    handleClose();
  }
  const handleSetSectionName = (e) =>{
    let thisvalue = e.currentTarget.value;
    let secitem = sectionItem;
    secitem.name = thisvalue;
    setSectionItem(secitem);
    setSectionName(thisvalue);
  }
  
  return (
      <Dialog open={is_open_add_section} onClose={handleClose}>
        <DialogTitle>Create {(is_college===true)?'Course Year':'Section'}</DialogTitle>
        <DialogContent>
          
          <TextField
            autoFocus
            margin="dense"
            id="section_name"
            label={(is_college===true)?'Enter Course Year':'Enter Section'}
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetSectionName}
            value={sectionName}
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSaveSection} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreateSection;