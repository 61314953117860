import { DELETE_ITEM_CONFIRM,DELETE_SCHEDULED_EXAM} from "../../actionTypes/actionTypes";

const handleConfirmDeleteScheduledExam = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}


export { handleConfirmDeleteScheduledExam};