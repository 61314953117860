import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { handleOpenAddHoliday,handleSaveHolidayToList,handleSetHolidayList,handleUpdateHoliday } from './Action';
import APP_HTTP from '../../../APP_HTTP';
import { Divider, Grid } from '@mui/material';
import BasicDatePicker from '../../DatePicker';
import { Close } from '@mui/icons-material';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
let initialState = {id:0,name:'',is_edit:false};
const CreateHoliday = (props)=> {
  const [holidayItem,setHolidayItem] = React.useState(initialState);
  const [holidayName,setHolidayName] = React.useState('');
  const [holidays_list,setHolidayList] = React.useState([{id:'',name:'',date:new Date()}]);
  const state = useSelector((state) => state);
  const is_open_add_holiday = state.is_open_add_holiday;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(handleOpenAddHoliday(false));
  };
  React.useEffect(() => {
      let holidayValue = props.holidayValue;
      setHolidayList(holidayValue);
      //setHolidayName(holidayValue.name);
  },[props]);
  const handleSaveHoliday = () =>{
    dispatch(handleSetHolidayList([]));
    APP_HTTP.REQUEST('settings/create_holiday',{holidays_list:holidays_list},true,true).then((response) => {
      let resp = response.data.response;
      dispatch(handleSetHolidayList(resp));
      //dispatch(handleSaveHolidayToList(holidays_list));
    });
    handleClose();
  }

  const handleSetHolidayName = (index,value) =>{
    let thisvalue = value;
    let secitem = [...holidays_list];
    secitem[index].name = thisvalue;
    setHolidayList(secitem);
  }
  const handleChangeDate = (id,value) =>{
    let secitem = [...holidays_list];
    secitem[id].date = new Date(value);
    setHolidayList(secitem);
  }
  const handleAddMore = () =>{
    let hol_list = [...holidays_list];
    hol_list.push({id:'',name:'',date:new Date()})
    setHolidayList(hol_list);
  }
  const handleRemoveItem = (index) =>{
    let hol_list = [...holidays_list];
    hol_list.splice(index,1)
    setHolidayList(hol_list);
    
  }
  return (
      <Dialog open={is_open_add_holiday} onClose={handleClose}>
        <DialogTitle>Create Holiday</DialogTitle>
        <DialogContent>
        <Grid container spacing={2} className="mrtop-30">
            {(holidays_list.map((value,index)=>{
              return(
                <>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                          autoFocus
                          margin="dense"
                          id="holiday_name"
                          label="Enter Holiday Name"
                          type="text"
                          fullWidth
                          variant="standard"
                          onChange={(e)=>handleSetHolidayName(index,e.target.value)}
                          value={value.name}
                        />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} sx={{marginTop:'12px'}}>
                    {(value.is_edit===false) &&
                      <BasicDatePicker label="Holiday Date" size="small" id={index} handleChangeData={handleChangeDate} defaultValue={value.date}/>
                    }
                    {(value.is_edit===true) &&
                      <BasicDatePicker label="Holiday Date" size="small" id={index} handleChangeData={handleChangeDate} defaultValue={value.date}/>
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} sx={{marginTop:'12px',justifyContent:'center',position:'relative'}}>
                    {(holidays_list.length>0) &&
                      <Close sx={{position:'absolute',top:20}} onClick={()=>handleRemoveItem(index)}/>
                    }
                    {((holidays_list.length-1)===index) &&
                      <Button onClick={handleAddMore} sx={{position:'absolute',right:'0'}}>Add More</Button>
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}><Divider /></Grid>
                </>
              )
            }))}
              
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSaveHoliday} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreateHoliday;