import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Button, Checkbox, Divider, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, NativeSelect, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';

const GeneralSettings = (props) => {
    const [hide_mobile_number, setValue] = React.useState(false);
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetSettings();
        }
    },[])
  
    const handleGetSettings = ()=>{
        APP_HTTP.REQUEST('settings/get_option',{name:'hide_mobile_number'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value!==''){

                setValue(resp.value);
            }
        });
    }
   
    const handleToggleMobileNumber = (checked) =>{
        setValue(checked);
        APP_HTTP.REQUEST('settings/save_option',{name:'hide_mobile_number',value:checked},true,true).then((response) => {
            let resp = response.data;
        });
    }
   
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        General Setting
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>  
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <FormControlLabel control={<Checkbox value="hide_mobile_number" color="primary"  onChange={(e)=>handleToggleMobileNumber(e.target.checked)} checked={hide_mobile_number}/>} label="Hide Mobile Number"/>
                </Grid>  
            </Grid>
           
        </Box>
    </>
  );
}

export default GeneralSettings;