import { Button } from '@mui/material';
import React from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
const DownloadAttendence = (props) =>  {
        const tableRef = React.useRef(null);
        const { onDownload } = useDownloadExcel({
          currentTableRef: tableRef.current,
          filename: 'Attendance Report',
          sheet: 'Attendance'
      })
        return (
            <>
          
            <Button variant='outlined' size='medium' onClick={onDownload}>DOWNLOAD</Button>
            
            <div style={{display:'none'}}>
                <table ref={tableRef}>
                    <tbody>
                        <tr>
                            <th colSpan={(props.data.attendence_type==='staff')?3:2} style={{textAlign:'left',background:'#1976d2',color:'#fff', fontSize:'24px'}}><span style={{textTransform:'capitalize'}}>{(props.data.attendence_type==='staff')?'STAFF':'STUDENT'} ATTENDENCE REPORT</span></th>
                        </tr>
                    {props.data.attendence_ids.map((value,index)=>{
                      return (
                        <>
                            <tr>
                                <th colSpan={2} style={{textAlign:'left',background:'#19d29d',color:'#fff', fontSize:'20px'}}>
                                <span>
                                {props.data.attendence_info[value].info_sequence} | {props.data.attendence_info[value].info_name}
                                {(props.data.attendence_info[value].roll_number) &&  
                                <>
                                - {props.data.attendence_info[value].roll_number}/{props.data.attendence_info[value].class}({props.data.attendence_info[value].section})
                                </> 
                                }
                                </span>
                                </th>
                                {(props.data.attendence_type==='staff') &&
                                  <th style={{color:'red'}}>LATE BY</th>
                                }
                            </tr>
                            {(props.data.attendence_list[parseInt(value)]!==undefined) &&
                                <>
                                {props.data.attendence_list[parseInt(value)].map((attval,idx)=>{
                                    return(
                                        <tr>
                                            <td  style={{textAlign:'left'}}>
                                                {attval.date}
                                            </td>
                                            <td style={{textAlign:'left',color:(attval.present===1)?'#19d29d':(attval.present===0)?'red':'#d1d1d1'}}>
                                            {(attval.present===1) &&
                                                <>
                                                  <span>
                                                    PRESENT
                                                  </span>
                                                </>
                                              }
                                              {(attval.present===0) &&
                                                <>
                                                  
                                                  <span>
                                                    ABSENT
                                                  </span>
                                                </>
                                              }
                                              {(attval.present===2) &&
                                                <>
                                                    
                                                    <span style={{color:'gray'}}>
                                                      LEAVE
                                                    </span>
                                                </>
                                              }
                                            </td>
                                            {(props.data.attendence_type==='staff') &&
                                              <td style={{color:(attval.late_by!==0)?'red':''}}>{attval.late_by}</td>
                                            }
                                        </tr>
                                    )
                                })}
                                </>
                            }
                        </>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            </>
        );
}
export default DownloadAttendence