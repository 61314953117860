import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Divider, InputAdornment, List, ListItem, ListItemButton, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import LoaderButton from '../../LoaderButton.jsx';
import { DoNotTouch } from '@mui/icons-material';
import AppUtils from '../../../AppUtils.jsx';
const SequenceNumber = (props) => {
    const state = useSelector((state) => state);
    const [is_loaded_sequence,setIsLoadingSequence] = React.useState(false);
    const [staff_type_list,setStaffTypes] = React.useState([]);
    const [current_sequences,setCurrentSequence] = React.useState([]);
    const [isSaveClicked,setSaveClicked] = React.useState(false);
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        
            if (!initialized.current) {
                initialized.current = true;
                APP_HTTP.REQUEST('settings/get_staff_type_list',{},true,true).then((response) => {
                    let resp = response.data;
                    let stf_list = resp.response;
                    setStaffTypes(stf_list)
                });
                APP_HTTP.REQUEST('settings/get_current_sequence',{},true,true).then((response) => {
                    let resp = response.data;
                    let seq_list = resp.response;
                    setCurrentSequence(seq_list);
                    setIsLoadingSequence(true);
                });
            }
        
    },[])
    const handleSaveInformation = () =>{
        setSaveClicked(true);
        let sequence_setting = [];
        let qs = document.querySelectorAll('.sequence-block');
        for (let index = 0; index < qs.length; index++) {
            const element = qs[index];
            let id = element.id;
            let key = document.getElementById("key-"+id).innerHTML;
            let prefix = document.getElementById("prefix-"+id).value;
            let sequence = document.getElementById("sequence-"+id).value;
            let seq_set = {key:key,prefix:prefix,sequence};
            sequence_setting.push(seq_set);
        }
        APP_HTTP.REQUEST('settings/save_sequence_setting',{sequence_setting:sequence_setting},true,true).then((response) => {
            let resp = response.data;
            
            setSaveClicked(false);
        });
    }
    const handleChangeSequence = (e) =>{
        
        let id = e.currentTarget.id;
        let thisval = e.currentTarget.value;
        let current_sequence = current_sequences;
        let data_target = e.currentTarget.getAttribute('dataTarget');
        let data_id = e.currentTarget.getAttribute('dataId');
        current_sequence[data_target][data_id] = thisval;
        document.getElementById(id).value = thisval; 
        setCurrentSequence(current_sequence);
    }
    return (
        <>
            <Box sx={{ flexGrow: 1 }} className="MainContainer">
                <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                            Sequence Settings
                        </Typography>
                    </Grid>
                    {(AppUtils.has_access(state,'30_sequence_view')) &&
                    <>
                    {(is_loaded_sequence===true) &&
                        <List>
                                <ListItem key={10000} disablePadding className="sequence-block"  id={10000}>
                                        <ListItemButton style={{width:'150px'}}>
                                        <Typography id={"key-10000"}>Student</Typography>
                                        </ListItemButton>
                                        <ListItemButton style={{textAlign:'right'}}>
                                        <TextField
                                            id={"prefix-10000"}
                                            label="Prefix"
                                            fullWidth
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                            }}
                                            inputProps={{
                                                dataId : 'prefix',
                                                dataTarget : 'student'
                                            }}
                                            defaultValue={(current_sequences['student']!==undefined && current_sequences['student']['prefix']!==undefined)?current_sequences['student']['prefix']:"STU"}
                                            onChange={handleChangeSequence}
                                            
                                            />
                                        </ListItemButton>
                                        <ListItemButton style={{textAlign:'right'}}>
                                        <TextField
                                            id={"sequence-10000"}
                                            label="Student Sequence Number"
                                            fullWidth
                                            size='small'
                                            defaultValue={(current_sequences['student']!==undefined && current_sequences['student']['current']!==undefined)?current_sequences['student']['current']:1}
                                            onChange={handleChangeSequence}
                                            inputProps={{ dataId:'current',                                        dataTarget:'student'
                                            }}
                                            />
                                        </ListItemButton>
                                </ListItem>
                                <ListItem key={10001} disablePadding className="sequence-block"  id={10001}>
                                        <ListItemButton style={{width:'150px'}}>
                                        <Typography id={"key-10001"}>Payment IN</Typography>
                                        </ListItemButton>
                                        <ListItemButton style={{textAlign:'right'}}>
                                        <TextField
                                            id={"prefix-10001"}
                                            label="Prefix"
                                            fullWidth
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                            }}
                                            inputProps={{
                                                dataId : 'prefix',
                                                dataTarget : 'payment_in'
                                            }}
                                            defaultValue={(current_sequences['payment_in']!==undefined && current_sequences['payment_in']['prefix']!==undefined)?current_sequences['payment_in']['prefix']:"PYI"}
                                            onChange={handleChangeSequence}
                                            
                                            />
                                        </ListItemButton>
                                        <ListItemButton style={{textAlign:'right'}}>
                                        <TextField
                                            id={"sequence-10001"}
                                            label="Payment IN Sequence Number"
                                            fullWidth
                                            size='small'
                                            defaultValue={(current_sequences['payment_in']!==undefined && current_sequences['payment_in']['current']!==undefined)?current_sequences['payment_in']['current']:1}
                                            onChange={handleChangeSequence}
                                            inputProps={{ dataId:'current',                                        dataTarget:'payment_in'
                                            }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem key={10002} disablePadding className="sequence-block"  id={10002}>
                                        <ListItemButton style={{width:'150px'}}>
                                        <Typography id={"key-10002"}>Payment OUT</Typography>
                                        </ListItemButton>
                                        <ListItemButton style={{textAlign:'right'}}>
                                        <TextField
                                            id={"prefix-10002"}
                                            label="Prefix"
                                            fullWidth
                                            size='small'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                            }}
                                            inputProps={{
                                                dataId : 'prefix',
                                                dataTarget : 'payment_out'
                                            }}
                                            defaultValue={(current_sequences['payment_out']!==undefined && current_sequences['payment_out']['prefix']!==undefined)?current_sequences['payment_out']['prefix']:"PYO"}
                                            onChange={handleChangeSequence}
                                            
                                            />
                                        </ListItemButton>
                                        <ListItemButton style={{textAlign:'right'}}>
                                        <TextField
                                            id={"sequence-10002"}
                                            label="Payment OUT Sequence Number"
                                            fullWidth
                                            size='small'
                                            defaultValue={(current_sequences['payment_out']!==undefined && current_sequences['payment_out']['current']!==undefined)?current_sequences['payment_out']['current']:1}
                                            onChange={handleChangeSequence}
                                            inputProps={{ dataId:'current',                                        dataTarget:'payment_out'
                                            }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider/>
                                <Divider/>
                                {(staff_type_list.length>0) &&
                                    <>
                                    {(staff_type_list.map((value,index)=>{
                                        return (
                                            <>
                                            <ListItem key={value.name} disablePadding className="sequence-block" id={value.id}>
                                                <ListItemButton  style={{width:'150px'}}>
                                                <Typography id={"key-"+value.id}>{value.name}</Typography>
                                                </ListItemButton>
                                                <ListItemButton>
                                                    <TextField
                                                    id={"prefix-"+value.id}
                                                    label="Prefix"
                                                    fullWidth
                                                    size='small'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                                        
                                                    }}
                                                    inputProps={{
                                                        dataId:'prefix',                                        dataTarget:value.key
                                                    }}
                                                    defaultValue={(current_sequences[value.key]!==undefined && current_sequences[value.key]['prefix']!==undefined)?current_sequences[value.key]['prefix']:"STF"}
                                                    onChange={handleChangeSequence}
                                                    />
                                                </ListItemButton>
                                                <ListItemButton>
                                                    <TextField
                                                    id={"sequence-"+value.id}
                                                    label={value.name+" Sequence No"}
                                                    fullWidth
                                                    size='small'
                                                    
                                                    defaultValue={(current_sequences[value.key]!==undefined && current_sequences[value.key]['current']!==undefined)?current_sequences[value.key]['current']:1}
                                                    onChange={handleChangeSequence}
                                                    inputProps={{ dataId:'current',                                        dataTarget:value.key
                                                    }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider/>
                                        </>

                                        )
                                    }))}
                                    </>
                                }
                        </List>
                    }
                    </>
                    }
                    {(AppUtils.has_access(state,'30_sequence_add') || AppUtils.has_access(state,'30_sequence_edit')) &&
                    <Grid item xs={12} sm={12} md={12} spacing={2} style={{borderBottom:'1px solid #d7d7d7',borderTop:'1px solid #d7d7d7',padding:'10px',textAlign:'right'}}>
                    {(isSaveClicked===false) &&
                        <Button variant="contained" onClick={() => handleSaveInformation()}>Save</Button>
                    }
                    {(isSaveClicked===true) &&
                        <LoaderButton variant="contained" />
                    }
                    </Grid>
                    }
                </Grid>
            </Box>
        </>
    );
    
}

export default SequenceNumber;