import { DirectionsBus, FamilyRestroom, FamilyRestroomOutlined, Group, GroupAdd, GroupOutlined, GroupRounded, ManageAccounts, PeopleAlt, PersonAdd, PersonAddAlt, PersonAddAltRounded, PersonAddRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
const StudentCard = (props)=> {
  const handleViewStudent = (id) =>{
    let referer = window.location.href;
    localStorage.setItem('referer',referer);
    let redirect_link = '/student/view/'+id;
    if(referer===redirect_link){
      localStorage.setItem('referer','/students');
    }
    window.location.href = redirect_link;
  }
  return (
      
      <Box style={{display:'flex',cursor:'pointer'}}>
        <Box style={{height: '70px',width: '70px',background: 'rgb(255, 255, 255)',borderRadius: '50%',textAlignLast: 'center',alignContent: 'center',zIndex: 9,border: '10px solid #ffcc00',boxShadow:'0px 0px 3px black',position:'relative'}}  onClick={()=>handleViewStudent(props.info.student_id)}>
            <img src={props.info.photo} height={50} width={50} style={{borderRadius:'50%',objectFit: 'cover'}}/>
            {(props.info.meta!==undefined && props.info.meta.bus_facility!==undefined && props.info.meta.bus_facility=='yes') &&
              <DirectionsBus style={{position: 'absolute',top:'4px', right: '-18px', color: 'yellow'}}fontSize='small'/>
            }
            {(props.info.family_group!==undefined && props.info.family_group.length>0) &&
            <>
            {(props.info.family_group[0].is_parent===false) &&
              <PeopleAlt style={{position: 'absolute',top: '25px', right: '-18px', color: '#55ff55'}}fontSize='small'/>
            }
            {(props.info.family_group[0].is_parent===true) &&
              <PersonAddAltRounded style={{position: 'absolute',top: '25px', right: '-18px', color: '#55ff55'}} fontSize='small'/>
            }
            </>
            }
        </Box>
        <Box style={{background: '#c71585',color:'#fff',height: '50px',borderTopRightRadius: '10px',borderBottomRightRadius: '10px',paddingLeft:'27px',paddingRight:'10px',paddingTop:'2px',marginTop:'10px',marginLeft:'-17px',boxShadow:'0px 0px 3px black'}}  onClick={()=>handleViewStudent(props.info.student_id)}>
          <Typography style={{whiteSpace:'nowrap',fontSize:'16px',fontWeight:'bold'}} variant='h6'>
          {props.info.roll_number} / {props.info.student_name} ({props.info.father_name})
          </Typography>
          <Typography style={{fontWeight:'bold',fontSize:'11px',whiteSpace:'nowrap'}}>
            {props.info.class}({props.info.section}) {(props.info.mobile_number!=="")?' | ':''} {props.info.mobile_number}
            {(props.info.alt_mobile_number!=="") &&
            <> | {props.info.alt_mobile_number}</>
            }
          </Typography> 
        
        </Box>
      </Box>
      
  );
}

export default StudentCard;