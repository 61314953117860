import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Schedule from './Schedule';
import ClassRoomAllocation from './ClassRoomAllocation';
import HallTicketGeneration from './HallTicketGeneration';
import MarksEntry from './MarksEntry';
import { useSelector, useDispatch } from "react-redux";
import AppUtils from '../../AppUtils';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Exam() {
  const [value, setValue] = React.useState(0);
  const state = useSelector((state) => state);
  const handleChange = (event, newValue) => {
    
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {(AppUtils.has_access(state,'7_schedule_exam')) &&
          <Tab label="Schedule Exam" {...a11yProps(0)} />
        }
        {(AppUtils.has_access(state,'8_exam_hall_allocation')) &&
          <Tab label="Exam Hall Allocation" {...a11yProps(1)} />
        }
        {(AppUtils.has_access(state,'9_hall_ticket_generation')) &&
          <Tab label="Hall Ticket Generation" {...a11yProps(2)} />
        }
        {(AppUtils.has_access(state,'10_subject_marks_entry')) &&
          <Tab label="Subject Marks Entry" {...a11yProps(3)} />
        }
        </Tabs>
      </Box>
      {(AppUtils.has_access(state,'7_schedule_exam')) &&
        <>
        {(value===0) &&
          <CustomTabPanel value={value} index={0}>
            <Schedule />
          </CustomTabPanel>
        }
        </>
      }
      {(AppUtils.has_access(state,'8_exam_hall_allocation')) &&
      <>
      {(value===1) &&
        <CustomTabPanel value={value} index={1}>
          <ClassRoomAllocation />
        </CustomTabPanel>
      }
      </>
      }
      {(AppUtils.has_access(state,'9_hall_ticket_generation')) &&
      <>
      {(value===2) &&
        <CustomTabPanel value={value} index={2}>
          <HallTicketGeneration/>
        </CustomTabPanel>
      }
      </>
      }
      {(AppUtils.has_access(state,'10_subject_marks_entry')) &&
      <>
      {(value===3) &&
        <CustomTabPanel value={value} index={3}>
          <MarksEntry />
        </CustomTabPanel>
      }
      </>
      }
    </Box>
  );
}
