import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import {Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../../LoaderButton';


const ClassSectionMapping = (props) => {
    const [is_saving,setIsSaving] = React.useState(false);
    const state = useSelector((state) => state);
    const class_list = state.class_list;
    const section_list = state.section_list;
   
    const dispatch = useDispatch();

    const handleSaveMapping = () =>{
        setIsSaving(true);
        setTimeout(() => {
            setIsSaving(false);
        }, 500);
    }
  return (
    <>

    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                    Class Section Mapping
                    <Box sx={{float:'right'}}>
                        {(is_saving===false) &&
                            <Button  sx={{cursor:'pointer'}} variant='outlined' color='success' size='small' onClick={handleSaveMapping}>Save</Button>
                        }
                        {(is_saving===true) &&
                        <LoaderButton color="success"/>
                        }
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
            <Divider />
                <List>
                    {(section_list.map((cls_value,index)=>{
                        return (
                            <>
                            <ListItem key={cls_value.name} disablePadding>
                                <ListItemButton>
                                    <span style={{fontSize:'30px'}}>{cls_value.name}</span>
                                </ListItemButton>
                            </ListItem>
                            <Divider/>
                            <div style={{marginLeft:'35px'}}>
                                {(class_list.map((sec_value,index)=>{
                                    return (
                                        <>
                                            <FormControl component="fieldset">
                                                <FormGroup aria-label="position" row>
                                                    <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox/>}
                                                    label={sec_value.name}
                                                    labelPlacement="end"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </>
                                    )
                                }))}
                            </div>
                            <Divider/>
                        </>
                        )
                    }))}
                    {(section_list.length===0) &&
                            <ListItem key={"No Record Found"} disablePadding>
                            <ListItemButton>
                                <ListItemText primary={"No Record Found"} />
                            </ListItemButton>
                        </ListItem>
                    }
                </List>
            
            </Grid>
        </Grid>
    </Box>
      
    </>
  );
}

export default ClassSectionMapping;