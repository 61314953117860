import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Alert, AlertTitle, Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, NativeSelect, Radio, RadioGroup, Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';
import { AccountCircleRounded, Add, AddCircle, AddCircleOutline, BookOutlined, CheckCircle, Close, DoubleArrow, Edit, History, LibraryBooks, MenuBook, Person } from '@mui/icons-material';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import SessionDropdown from '../settings/session_and_year/SessionDropDown';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import StudentCard from '../students/StudentCard';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const SchoolLibrary = (props)=> {
    const state = useSelector((state) => state);
    const [is_open_add_book_category, setIsOpenBookCategory] = React.useState(false);
    const [is_open_add_book, setIsOpenBook] = React.useState(false);
    const [is_loading_category, setIsLoadingCategory] = React.useState(true);
    const [is_saving_category, setIsSavingCategory] = React.useState(false);
    const [is_saving_book, setIsSavingBook] = React.useState(false);
    const [new_category_list,setNewCategories] = React.useState([{category:''}]);
    const [new_book_list,setNewBook] = React.useState([{category_id:'',book_name:'',book_author:'',added_quantity:''}]);
    const [categories_list,setCategoriesList] = React.useState([]);
    let [contact_info,setContactInfo] = React.useState([]);
    let [book_info,setBookInfo] = React.useState([]);
    const [book_list,setBookList] = React.useState([]);
    const [is_loading_book, setIsLoadingBooks] = React.useState(true);
    const [is_loading_alloted_book, setIsLoadingAllotedBooks] = React.useState(true);
    const [is_loading_alloted_book_history, setIsLoadingAllotedBooksHistory] = React.useState(true);
    const [is_return_book, setIsReturnBook] = React.useState(false);
    const [is_returning_book, setIsReturningBook] = React.useState(false);
    const [is_book_allotment, setIsAllotment] = React.useState(false);
    const [is_book_allotment_history, setIsAllotmentHistory] = React.useState(false);
    const [allotment_type, setAllotmentType] = React.useState('book_allotment');
    const [is_saving_allotment, setIsSavingAllotment] = React.useState(false);
    const [return_book_info, setReturnInfo] = React.useState({});
    let [selected_session,setSession] = React.useState(0);
    let [selected_class,setClass] = React.useState(0);
    let [selected_section,setSection] = React.useState(0);
    let [students_list,setStudentList] = React.useState([]);
    let [student_alloted_book,setStudentAllotedBook] = React.useState([]);
    let [all_alloted_book_list,setAllocatedBookList] = React.useState([]);
    let [all_alloted_book_list_history,setAllocatedBookListHistory] = React.useState([]);
    const is_college = state.school_info.school_info.is_college;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetBooks();
            handleGetBookCategories();
            handleGetAllotedBookList();
        }
    },[])
    const handleChangeSection = (id,value) =>{
        setSection(value);
        APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:selected_session,class:selected_class,section:value},true,true,false,true).then((response) => {
          let resp = response.data;
          let search_list = resp.response;
          setStudentList(search_list);
        });
      }
      const handleChangeSession = (id,value) =>{
        setStudentList([]);
        setSession(value);
        setClass(0);
        setSection(0);
        APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:value},true,true,false,true).then((response) => {
          let resp = response.data;
          let search_list = resp.response;
          setStudentList(search_list);
        });
      }
      const handleChangeClass = (id,value) =>{
        setStudentList([]);
        setClass(value);
        setSection(0);
        APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',academic_session:selected_session,class:value},true,true,false,true).then((response) => {
          let resp = response.data;
          let search_list = resp.response;
          setStudentList(search_list);
        });
      }
    const handleGetBooks = () =>{
        APP_HTTP.REQUEST('library/get_books',{},true,true).then((response) => {
            let resp = response.data.response;
            setBookList(resp);
            setIsLoadingBooks(false);
        });
    }
    const handleSaveBook = ()=>{
        setIsSavingBook(true);
        APP_HTTP.REQUEST('library/save_book',{new_book_list},true,true).then((response) => {
            setIsSavingBook(false);
            setIsOpenBook(false);
            handleGetBooks();
        });
    }
    const handleOpenAddBook = ()=>{
        setIsOpenBook(true);
    }
    const handleCloseAddBook = ()=>{
        setIsOpenBook(false);
        setNewBook([{category_id:'',book_name:'',book_author:'',added_quantity:''}]);
    }
    const handleAddMoreBook = () =>{
        let clist = [...new_book_list];
        let newcl = {category_id:'',book_name:'',book_author:'',added_quantity:''};
        clist.push(newcl)
        setNewBook(clist);
    }
    const handleChangeBookCategory= (id,value) =>{
        let blist  = [...new_book_list];
        blist[id]['category_id'] = value;
        setNewBook(blist);
    }
    const handleChangeBookInfo= (id,value,type) =>{
        let blist  = [...new_book_list];
        blist[id][type] = value;
        setNewBook(blist);
    }
    const handleOpenAddCategory = ()=>{
        setIsOpenBookCategory(true);
    }
    const handleCloseAddBookCategory = ()=>{
        setIsOpenBookCategory(false);
        setNewCategories([{category:''}]);
    }
    const handleSaveCategory = ()=>{
        setIsSavingCategory(true);
        APP_HTTP.REQUEST('library/save_book_category',{new_category_list},true,true).then((response) => {
            setIsSavingCategory(false);
            setIsOpenBookCategory(false);
            handleGetBookCategories();
        });
    }
    const handleGetAllotedBookListHistory = (history_id) =>{
        setAllocatedBookListHistory([]);
        setIsLoadingAllotedBooksHistory(true);
        APP_HTTP.REQUEST('library/get_allotted_books_history',{book_id:history_id},true,true).then((response) => {
            let resp = response.data.response;
            setAllocatedBookListHistory(resp);
            setIsLoadingAllotedBooksHistory(false);
        });
    }
    const handleGetAllotedBookList = () =>{
        setIsLoadingAllotedBooks(true);
        APP_HTTP.REQUEST('library/get_allotted_books',{},true,true).then((response) => {
            let resp = response.data.response;
            setAllocatedBookList(resp);
            setIsLoadingAllotedBooks(false);
        });
    }
    const handleGetBookCategories = () =>{
        APP_HTTP.REQUEST('library/get_book_categories',{},true,true).then((response) => {
            let resp = response.data.response;
            setCategoriesList(resp);
            setIsLoadingCategory(false)
        });
    }
    const handleChangeCategory = (index,cat)=>{
       let ncl = [...new_category_list];
       ncl[index].category = cat;
       setNewCategories(ncl);
    }
    const handleAddMoreCategory = () =>{
        let clist = [...new_category_list];
        let newcl = {category:''};
        clist.push(newcl)
        setNewCategories(clist);
    }
    const handleEditCategory = (index) =>{
        let clist = [...categories_list];
        let newcl = clist[index];
        setNewCategories([newcl]);
        setIsOpenBookCategory(true);
    }
    const handleEditBook = (index) =>{
        let clist = [...book_list];
        let newcl = clist[index];
        setNewBook([newcl]);
        setIsOpenBook(true);
    }
    const handleLoadCategoryBooks = (sinx,id,is_selected) =>{
        
        let clist = [...categories_list];
        for (let index = 0; index < clist.length; index++) {
            const element = clist[index];
            element.is_selected = false;
            clist[index] = element;
        }
        clist[sinx].is_selected = !is_selected;
        setNewCategories(clist);
        setIsLoadingBooks(true);
        if(is_selected===false){
            APP_HTTP.REQUEST('library/get_books_on_category',{category_id:id},true,true).then((response) => {
                let resp = response.data.response;
                setBookList(resp);
                setIsLoadingBooks(false);
            });
        }else{
            handleGetBooks();
        }
    }
    const handleCloseAllotment = ()=>{
        setIsAllotment(false);
    }
    const handleOpenAllotment = ()=>{
        setIsAllotment(true);
    }
    const handleSaveAllotment = ()=>{
        setIsSavingAllotment(true);
        APP_HTTP.REQUEST('library/allot_book_to_student',{student_id:contact_info.id,book_id:book_info.id,allotment_type:allotment_type},true,true).then((response) => {
            let resp = response.data;
            handleGetAllotedBookList();
            handleGetBooks();
            setIsSavingAllotment(false);
            handleCloseAllotment();
        });
    }
    const handleChangeAllotmentType = (value)=>{
        setAllotmentType(value);
    }
    const handleChangeInputValue = (value) =>{
    
    }
    const handleChangeValue = (value) =>{
      setContactInfo(value);
      if(value && value.id!==undefined){
        APP_HTTP.REQUEST('library/get_allotted_book_on_student',{student_id:value.id},true,true).then((response) => {
            let resp = response.data;
            let search_list = resp.response;
            setStudentAllotedBook(search_list);
          });
      }
    }
    const handleChangeBookInput = (value) =>{
    
    }
    const handleChangeBook = (value) =>{
      setBookInfo(value);
    }
    const handleCloseReturnBook = (id)=>{
        setIsReturnBook(false);
    }
    const handleReturnBook = (book_allotment_id,student_id)=>{
        setReturnInfo({book_allotment_id:book_allotment_id,student_id:student_id,allotment_type:'book_return'});
        setIsReturnBook(true);
    }
    const handleReturningBook = ()=>{
        setIsReturningBook(true);
        APP_HTTP.REQUEST('library/return_book_from_student',{return_book_info},true,true).then((response) => {
            let resp = response.data;
            setIsReturningBook(false);
            handleGetAllotedBookList();
            handleGetBooks();
            handleCloseReturnBook();
        });
    }
    const handleSearchBook = (value) =>{
        if(value===''){
            handleGetAllotedBookList();
        }
        if(value.length<2){
            return false;
        }
        setIsLoadingAllotedBooks(true);
        APP_HTTP.REQUEST('library/get_allotted_books',{search:value},true,true).then((response) => {
            let resp = response.data.response;
            setAllocatedBookList(resp);
            setIsLoadingAllotedBooks(false);
        });
    }
    const handleShowBookHistory = (history_id) =>{
        setIsAllotmentHistory(true);
        handleGetAllotedBookListHistory(history_id)
    }
    const handleCloseHistory = ()=>{
        setIsAllotmentHistory(false);
    }
  return (
    <div id="create-library">
        {(AppUtils.has_access(state,'303_library_view')) &&
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant='h4'>Library</Typography>
                        <Typography>Here, You can add book and allocate book(s) to the student !</Typography>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={12}>
                        <Divider />
                    </Grid> 
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={2} className="mrtop-10">  
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant='h4' sx={{backgroundColor: '#5e86ad',marginTop: '8px', padding: '3px 10px',color:'#fff'}}>
                                    Books & Categories
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} style={{paddingTop:'0px'}}>
                                <Typography variant='h5' style={{background:'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)',marginBottom:'10px'}}>
                                    Categories
                                    <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='medium' onClick={handleOpenAddCategory}/>
                                </Typography>
                                {(categories_list.length===0 && is_loading_category===false) &&
                                    <Typography style={{textAlign:'center',fontSize:'14px',marginTop:'5px'}}>Not Found ! Please add one</Typography>
                                }
                                {(categories_list.length===0 && is_loading_category===true) &&
                                    <Typography style={{textAlign:'center',fontSize:'14px',marginTop:'5px'}}>Loading...</Typography>
                                }
                                {(categories_list.map((value,index)=>{
                                    return(
                                        <>
                                        {(value.is_selected===false) &&
                                            <Button size='medium' variant='outlined' color='success' fullWidth={true} style={{marginBottom:'5px',display:'block'}}>
                                            {value.category} ({value.book_count})
                                            <DoubleArrow  style={{float:'right'}} onClick={()=>handleLoadCategoryBooks(index,value.book_category_id,value.is_selected)}/>
                                            {/* <Close style={{float:'right'}}/> */}
                                            <Edit  style={{float:'right'}} onClick={()=>handleEditCategory(index)}/>
                                            </Button>
                                        }
                                        {(value.is_selected===true) &&
                                            <Button size='medium' variant='contained' color='success' fullWidth={true} style={{marginBottom:'5px'}} onClick={()=>handleLoadCategoryBooks(index,value.book_category_id,value.is_selected)}>
                                            <CheckCircle /> {value.category} ({value.book_count})
                                            <Close  style={{float:'right'}}/>
                                            </Button>
                                        }
                                        </>
                                    )
                                }))}
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} style={{paddingTop:'0px'}}>
                                <Typography variant='h5' style={{background:'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                                    Books
                                    <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='medium' onClick={handleOpenAddBook}/>
                                </Typography>
                              
                                <TableContainer component={Paper}>
                                    <Table  aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                            
                                            <StyledTableCell>BOOK INFO</StyledTableCell>
                                            <StyledTableCell>QUANTITY</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {book_list && book_list.map((row,index) => (
                                            <StyledTableRow>
                                            <StyledTableCell>
                                                <Typography style={{fontSize:'20px'}}>
                                                {row.book_name}
                                                </Typography>
                                                <Typography style={{display:'flex',textTransform:'capitalize',fontSize:'13px',marginBottom:'5px'}}><Person size='small' style={{fontSize:'18px'}}/> {row.book_author}</Typography>
                                                <span style={{background:'gray',color:'#fff',padding:'2px 5px',borderRadius:'5px',fontSize:'12px'}}>{row.category_info.category_name}</span>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography style={{fontSize:'20px',fontWeight:'bold'}}><span style={{color:'#1edc1e'}}>{row.added_quantity}</span> / <span style={{color:'red'}}>{row.available_quantity}</span></Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <History style={{marginRight:'10px', cursor:'pointer'}} onClick={()=>handleShowBookHistory(row.library_book_id)}/>
                                                <Edit style={{cursor:'pointer'}} onClick={()=>handleEditBook(index)} fontSize='small'/>
                                            </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        {(is_loading_book===true) &&
                                        <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                                            Please wait while loading the list...
                                        </StyledTableCell>
                                        }   
                                        {(is_loading_book===false && book_list && book_list.length===0) &&
                                        <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                                            Oops ! No Book(s) Found
                                        </StyledTableCell>
                                        }   
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                               
                            </Grid>
                        </Grid>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={6}>
                    
                        <Typography variant='h4' sx={{backgroundColor: '#5e86ad',marginTop: '8px', padding: '3px 10px',color:'#fff'}}>
                            Books Allotment
                            <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='large' onClick={handleOpenAllotment}/>
                        </Typography>
                        <TextField
                            margin="dense"
                            id={"search-book-box"}
                            label="Type to Search"
                            type="text"
                            fullWidth
                            size='small'
                            onChange={(e)=>handleSearchBook(e.target.value)}
                        />
                        <TableContainer component={Paper}>
                                    <Table  aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                            
                                            <StyledTableCell>BOOK INFO</StyledTableCell>
                                            <StyledTableCell>STUDENT INFO</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {all_alloted_book_list && all_alloted_book_list.map((row,index) => (
                                            <StyledTableRow>
                                            <StyledTableCell>
                                                <Typography style={{fontSize:'14px'}}>
                                                {row.book_info.book_name}
                                                </Typography>
                                                <Typography style={{fontSize:'12px'}}>
                                                    {row.date_display}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography style={{fontSize:'14px'}}>{row.student_info.roll_number} / {row.student_info.student_name}({row.student_info.father_name})</Typography>
                                                <Typography style={{fontSize:'14px'}}>{row.student_info.class}({row.student_info.section})</Typography>
                                               
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Button size='small' variant='contained' color='error' fullWidth={true} onClick={()=>handleReturnBook(row.book_allotment_id,row.student_info.student_id)}>Return</Button>
                                            </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        {(is_loading_alloted_book===true) &&
                                        <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                                            Please wait while loading the list...
                                        </StyledTableCell>
                                        }   
                                        {(is_loading_alloted_book===false && all_alloted_book_list && all_alloted_book_list.length===0) &&
                                        <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                                            Oops ! No Book(s) Found
                                        </StyledTableCell>
                                        }   
                                        </TableBody>
                                    </Table>
                        </TableContainer>
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
        </Box>
        }
        {(is_open_add_book_category===true) &&
        <Dialog open={true} maxWidth={'sm'}>
        <DialogTitle>Book Category</DialogTitle>
        <DialogContent>
        <Grid container spacing={2} className="mrtop-10">  
       
            {(new_category_list.map((value,index)=>{
                return(
                    <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        autoFocus={true}
                        margin="dense"
                        id={"book_category_"+index}
                        label="Enter Category Name"
                        type="text"
                        fullWidth
                        size='small'
                        value={value.category}
                        onChange={(e)=>handleChangeCategory(index,e.target.value)}
                    />
                    </Grid>
                )
            }))}
            <Grid item xs={12} sm={12} md={12}>
                <Button size='small' variant='outlined' fullWidth={true} onClick={handleAddMoreCategory}><Add style={{marginRight:'5px'}} /> Add More Category</Button>
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button size='small' onClick={handleCloseAddBookCategory}>Close</Button>
          {(is_saving_category===false) &&
            <Button variant='contained' color='success' size='small' onClick={handleSaveCategory}>Save</Button>
            }
            {(is_saving_category===true) &&
                <LoaderButton variant='contained'/>
            }
          
        </DialogActions>
        </Dialog>
        }
        {(is_open_add_book===true) &&
        <Dialog open={true} maxWidth={'lg'}>
        <DialogTitle>Book List</DialogTitle>
        <DialogContent>
        <Grid container spacing={2} className="mrtop-10">  
       
            {(new_book_list.map((value,index)=>{
                return(
                    <>
                    <Grid item xs={12} sm={12} md={3} style={{marginTop:'8px'}}>
                        <AppDropDown label="Select Book Category" list_item={categories_list} size="small" handleChangeData={handleChangeBookCategory} id={index} defaultValue={value.category_id}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        autoFocus={true}
                        margin="dense"
                        id={"book_"+index}
                        label="Enter Book Name"
                        type="text"
                        fullWidth
                        size='small'
                        value={value.book_name}
                        onChange={(e)=>handleChangeBookInfo(index,e.target.value,'book_name')}
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        
                        margin="dense"
                        id={"book_author"+index}
                        label="Enter Author Name"
                        type="text"
                        fullWidth
                        size='small'
                        value={value.book_author}
                        onChange={(e)=>handleChangeBookInfo(index,e.target.value,'book_author')}
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        
                        margin="dense"
                        id={"added_quantity"+index}
                        label="Enter New Quantity"
                        type="text"
                        fullWidth
                        size='small'
                        value={value.added_quantity}
                        onChange={(e)=>handleChangeBookInfo(index,e.target.value,'added_quantity')}
                    />
                    </Grid>
                    </>
                )
            }))}
            <Grid item xs={12} sm={12} md={12}>
                <Button size='small' variant='outlined' fullWidth={true} onClick={handleAddMoreBook}><Add style={{marginRight:'5px'}} /> Add More Book</Button>
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button size='small' onClick={handleCloseAddBook}>Close</Button>
          {(is_saving_book===false) &&
            <Button variant='contained' color='success' size='small' onClick={handleSaveBook}>Save</Button>
            }
            {(is_saving_book===true) &&
                <LoaderButton variant='contained'/>
            }
          
        </DialogActions>
        </Dialog>
        }
        {(is_book_allotment===true) &&
        <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>Book Allotment</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}  style={{marginTop:'8px'}}>  
               {/*  <Grid item xs={12} sm={12} md={12} style={{marginTop:'8px'}}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="book_allotment" control={<Radio  checked={(allotment_type==='book_allotment')?true:false} onChange={(e)=>handleChangeAllotmentType(e.target.value)} sx={{
    color: 'red',
    '&.Mui-checked': {
      color: 'red',
    },
  }}/>} label="Book Allotment"/>
                        <FormControlLabel value="book_return" control={<Radio checked={(allotment_type==='book_return')?true:false} onChange={(e)=>handleChangeAllotmentType(e.target.value)} sx={{
    color: 'green',
    '&.Mui-checked': {
      color: 'green',
    },
  }}/>} label="Book Return" />
                    </RadioGroup>
                </FormControl>
                </Grid> */}
                {(is_college===true) &&
                <Grid item xs={12} sm={12} md={6} spacing={2}>
                    <SessionDropdown handleChangeData={handleChangeSession}/>
                </Grid>
                }
                <Grid item xs={12} sm={12} md={6}>
                    <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>
                </Grid>
                {(selected_class>0) &&
                    <Grid item xs={12} sm={12} md={6}>
                    <Sections show="dropdown" handleChangeData={handleChangeSection}  defaultValue={selected_section}/>
                    </Grid>
                }
              
              {(selected_class>0) &&
                <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                        value={contact_info}
                        noOptionsText={'No Student Found ! Type To Search'}
                        onChange={(event, newValue) => handleChangeValue(newValue)}
                        onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                        disablePortal
                        id="payee_dropdown"
                        options={students_list}
                        size="small"
                        renderInput={(params) => <TextField {...params} label={'Select Student'} />}
                    />
                </Grid>
              }
              {(contact_info && contact_info.id!==undefined && contact_info.id>0) &&
              <>
              {(student_alloted_book.length>0) &&
                <Grid item xs={12} sm={12} md={12}>
                    <Typography style={{fontSize:'14px',color:'gray'}}>Allotted Book(s)</Typography>
                    {(student_alloted_book.map((value,index)=>{
                        return(
                            <Typography style={{fontSize:'14px'}}>{value.book_info.book_name}({value.date_display})</Typography>
                        )
                    }))}
                </Grid>
            }
                <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                        value={book_info}
                        noOptionsText={'No Books Found ! Type To Search'}
                        onChange={(event, newValue) => handleChangeBook(newValue)}
                        onInputChange={(event, newInputValue) => handleChangeBookInput(newInputValue)}
                        disablePortal
                        id="payee_dropdown"
                        options={book_list}
                        size="small"
                        renderInput={(params) => <TextField {...params} label={'Select Book'} />}
                    />
                </Grid>
            </>
              }
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button size='small' onClick={handleCloseAllotment}>Close</Button>
            {(is_saving_allotment===false && book_info.id!==undefined) &&
                <Button variant='contained' color='success' size='small' onClick={handleSaveAllotment}>Allot</Button>
            }
            {(is_saving_allotment===true) &&
                <LoaderButton variant='contained'/>
            }
          
        </DialogActions>
        </Dialog>
        }
        {(is_return_book===true) &&
        <Dialog open={true} fullWidth maxWidth='xs'>
        <DialogTitle>Return Book</DialogTitle>
        <DialogContent>
        <Alert severity="warning">
        <AlertTitle>Return Book?</AlertTitle>
        Are you sure, Student want to return this book?
      </Alert>
                
        </DialogContent>
        <DialogActions>
          <Button size='small' onClick={handleCloseReturnBook}>No</Button>
          {(is_returning_book===false) &&
            <Button variant='contained' color='success' size='small' onClick={handleReturningBook}>Yes</Button>
            }
            {(is_returning_book===true) &&
                <LoaderButton variant='contained' color='success'/>
            }
          
        </DialogActions>
        </Dialog>
        }
        {(is_book_allotment_history===true) &&
        <Dialog open={true} fullWidth maxWidth="lg">

            {(all_alloted_book_list_history.length===0) &&
            <DialogTitle>Book History</DialogTitle>
            }
            {(all_alloted_book_list_history.length>0) &&
            <DialogTitle style={{paddingBottom:'0px', borderBottom:'1px solid #d7d7d7'}}>
                    <span style={{fontSize:'20px'}}>{all_alloted_book_list_history[0].book_info.book_name}</span>
                    <span style={{display:'flex',textTransform:'capitalize',fontSize:'13px',marginBottom:'5px'}}><Person size='small' style={{fontSize:'18px'}}/> {all_alloted_book_list_history[0].book_info.book_author}</span>
            </DialogTitle>
            }

                                               
                                              
        
        <DialogContent>
            <Grid container spacing={2} style={{marginTop:'8px'}}>
                <Grid item xs={12} sm={12} md={12}>
                <TableContainer component={Paper}>
                    <Table  aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            {/* <StyledTableCell>BOOK INFO</StyledTableCell> */}
                            <StyledTableCell>STUDENT INFO</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {all_alloted_book_list_history && all_alloted_book_list_history.map((row,index) => (
                            <StyledTableRow>
                            {/* <StyledTableCell>
                                <Typography style={{fontSize:'14px'}}>
                                {row.book_info.book_name}
                                </Typography>
                                
                            </StyledTableCell> */}
                            <StyledTableCell>
                                <Typography style={{fontSize:'14px'}}>{row.student_info.roll_number} / {row.student_info.student_name}({row.student_info.father_name})</Typography>
                                <Typography style={{fontSize:'14px'}}>{row.student_info.class}({row.student_info.section})</Typography>
                                
                            </StyledTableCell>
                            <StyledTableCell>
                                <Typography style={{fontSize:'14px',color:row.color}}>{row.message}</Typography>
                                <Typography style={{fontSize:'12px'}}>
                                    {row.date_display}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Typography style={{fontSize:'14px',color:row.color}}>{row.quantity}</Typography>
                            </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {(is_loading_alloted_book_history===true) &&
                        <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                            Please wait while loading the list...
                        </StyledTableCell>
                        }   
                        {(is_loading_alloted_book_history===false && all_alloted_book_list_history && all_alloted_book_list_history.length===0) &&
                        <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                            Oops ! No Book(s) Found
                        </StyledTableCell>
                        }   
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button size='small' onClick={handleCloseHistory}>Close</Button>
        </DialogActions>
        </Dialog>
        }
    </div>
  );
}
export default SchoolLibrary;