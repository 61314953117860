import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSelector, useDispatch } from "react-redux";
import { handleConfirmDeleteClass, handleDeleteClass } from './settings/classes/Action';
import { handleConfirmDeleteSection, handleDeleteSection } from './settings/sections/Action';
import { handleConfirmDeleteSubject, handleDeleteSubject } from './settings/subjects/Action';
import { handleConfirmDeleteStaffTypes, handleDeleteStaffType } from './settings/staffs/Action';
import APP_HTTP from '../APP_HTTP';
import { handleConfirmDeleteStudent, handleDeleteStudent } from './students/Action';
import { handleConfirmDeleteStaff, handleDeleteStaff } from './staffs/Action';
import { handleConfirmDeletePaymentType, handleDeletePaymentType } from './settings/payment-types/Action';
import { handleConfirmDeletePayment, handleDeletePayment } from './payments/Action';
import { handleConfirmDeleteExamType, handleDeleteExamType } from './settings/exam/Action';
import { handleConfirmDeleteScheduledExam } from './exam/Action';
import { handleConfirmDeleteClassRoom, handleDeleteClassRoom } from './settings/class-rooms/Action';
import { handleConfirmDeleteTransport, handleDeleteTransport } from './transport/Action';
import { handleConfirmDeleteHoliday, handleDeleteHoliday } from './settings/holiday/Action';
export default function DeleteConfirm(props) {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const open = state.is_delete_item;
  
  const handleClose = () => {
    let delete_data = props.delete_data;
    let target = delete_data.target;
    let delete_id = delete_data.id;
    if(target==='class'){
      dispatch(handleConfirmDeleteClass(false));
    }else if(target==='section'){
      dispatch(handleConfirmDeleteSection(false));
    }else if(target==='subject'){
      dispatch(handleConfirmDeleteSubject(false));
    }else if(target==='staff_type'){
      dispatch(handleConfirmDeleteStaffTypes(false));
    }else if(target==='student'){
      dispatch(handleConfirmDeleteStudent(false));
    }else if(target==='staff'){
      dispatch(handleConfirmDeleteStaff(false));
    }else if(target==='payment_type'){
      dispatch(handleConfirmDeletePaymentType(false));
    }else if(target==='payment' || target==='demand_bill' || target==='staff_advance'){
      dispatch(handleConfirmDeletePayment(false));
    }else if(target==='exam_type'){
      dispatch(handleConfirmDeleteExamType(false));
    }else if(target==='scheduled_exam'){
      dispatch(handleConfirmDeleteScheduledExam(false));
    }else if(target==='class_room'){
      dispatch(handleConfirmDeleteClassRoom(false));
    }else if(target==='transport'){
      dispatch(handleConfirmDeleteTransport(false));
    }else if(target==='holiday'){
      dispatch(handleConfirmDeleteHoliday(false));
    }
  };

  const handleDeleteItem = () =>{
    let delete_data = props.delete_data;
      let idx = delete_data.index;
      let target = delete_data.target;
      let delete_id = delete_data.id;
      
      let endpoint = '';
      if(target==='class'){
        endpoint = 'settings/delete_class';
        dispatch(handleDeleteClass(idx,delete_data));
      }else if(target==='section'){
        endpoint = 'settings/delete_section';
        dispatch(handleDeleteSection(idx,delete_data));
      }else if(target==='subject'){
        endpoint = 'settings/delete_subject';
        dispatch(handleDeleteSubject(idx,delete_data));
      }else if(target==='staff_type'){
        endpoint = 'settings/delete_staff_type';
        dispatch(handleDeleteStaffType(idx,delete_data));
      }else if(target==='student'){
        endpoint = 'student/delete';
        dispatch(handleDeleteStudent(idx,delete_data));
      }else if(target==='staff'){
        endpoint = 'staff/delete';
        dispatch(handleDeleteStaff(idx,delete_data));
      }else if(target==='payment_type'){
        endpoint = 'settings/delete_payment_type';
        dispatch(handleDeletePaymentType(idx,delete_data));
      }else if(target==='payment'){
        endpoint = 'payment/delete';
        dispatch(handleDeletePayment(idx,delete_data));
      }else if(target==='exam_type'){
        endpoint = 'settings/delete_exam_type';
        dispatch(handleDeleteExamType(idx,delete_data));
      }else if(target==='class_room'){
        endpoint = 'settings/delete_class_room';
        dispatch(handleDeleteClassRoom(idx,delete_data));
      }else if(target==='scheduled_exam'){
        endpoint = 'exam/delete_scheduled_exam';
      }else if(target==='transport'){
        endpoint = 'transport/delete';
        dispatch(handleDeleteTransport(idx,delete_data));
      }else if(target==='demand_bill'){
        endpoint = 'demand_bill/delete';
        dispatch(handleDeletePayment(idx,delete_data));
      }else if(target==='staff_advance'){
        endpoint = 'staff_advance/delete';
        dispatch(handleDeletePayment(idx,delete_data));
      }else if(target==='holiday'){
        endpoint = 'settings/delete_holiday';
        dispatch(handleDeleteHoliday(idx,delete_data));
      }
      if(props.handleModifyDeletedStatus){
        props.handleModifyDeletedStatus();
      }
      
      APP_HTTP.REQUEST(endpoint,{id:delete_id},true,true).then((response) => {
        if(props.handleRefreshList){
          props.handleRefreshList();
        }
      });
      handleClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{textAlign:'center',paddingBottom:'0px'}}>
          <WarningAmberIcon fontSize='large' sx={{fontSize:'100px',color:'red'}}/>
        </DialogTitle>
        <DialogContent sx={{paddingBottom:'0px'}}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDeleteItem} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}