import * as React from 'react';
import { styled } from '@mui/material/styles';

import { useSelector, useDispatch } from "react-redux";
import AppDropDown from '../../AppDropDown.jsx';
import APP_HTTP from '../../../APP_HTTP.jsx';


const SessionDropdown = (props) => {
    const [session_list,setSessionList] = React.useState([]);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
   
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            
            const list_item = [{key:0,value:'Select Academic Session'}];
            APP_HTTP.REQUEST('settings/get_academic_session',{},true,true).then((response) => {
                let resp = response.data.response;
                for (let index = 0; index < resp.length; index++) {
                    const element = resp[index];
                    list_item.push(element)
                }
                setSessionList(list_item);
            });
        }
    },[dispatch])

    const handleChangeData = (id,value) =>{
        props.handleChangeData(id,value);
    }
  return (
    <AppDropDown label="Academic Session" list_item={session_list} size="small" handleChangeData={handleChangeData} defaultValue={(props.defaultValue!==undefined)?props.defaultValue:0} id={'academic_session_id'}/>
  );
}

export default SessionDropdown;