import * as React from 'react';
import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddPaymentType,handleOpenUpdateStudentFeeStructure,handleSavePaymentTypeToList,handleUpdatePaymentType } from './Action';
import APP_HTTP from '../../../APP_HTTP';
import { handleSetClassList } from '../classes/Action';
import AppDropDown from '../../AppDropDown';
import LoaderButton from '../../LoaderButton';
import SuccessLoader from '../../SuccessLoader';

let initialState = {id:0,name:'',is_edit:false};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const link_months = [
    {key:'none',value:'Select Link Month'},
    {key:'apr',value:'Apr'},
    {key:'may',value:'May'},
    {key:'jun',value:'Jun'},
    {key:'jul',value:'Jul'},
    {key:'aug',value:'Aug'},
    {key:'sep',value:'Sep'},
    {key:'oct',value:'Oct'},
    {key:'nov',value:'Nov'},
    {key:'dec',value:'Dec'},
    {key:'jan',value:'Jan'},
    {key:'feb',value:'Feb'},
    {key:'mar',value:'Mar'}
  ]
const CreateStudentFeeStructure = (props)=> {
    const [fee_structure_list,setFeeStructureList] = React.useState([]);
    const [is_saving,setIsSaving] = React.useState(false);
    const [is_saved,setIsSaved] = React.useState(false);
  const state = useSelector((state) => state);
  const is_open_update_student_fee_structure = state.is_open_update_student_fee_structure;
  const student_info = state.student_info;
  const dispatch = useDispatch();
  const initialized = React.useRef(false);
  const handleClose = ()=>{
     initialized.current = false;
     dispatch(handleOpenUpdateStudentFeeStructure(false));
  }
  React.useEffect(() => {
    if (!initialized.current) {
        initialized.current = true;
        handleGetFeeStructure();
    }
  },[props]);
  const handleGetFeeStructure = () =>{
    APP_HTTP.REQUEST('settings/get_payment_type_list',{type:'student',id:student_info.student_id},true,true).then((response) => {
        let resp = response.data;
        let payment_type_list = resp.response;
        setFeeStructureList(payment_type_list);
    });
  }
 const handleChangeDiscountType = (index,value)=>{
    let fs = [...fee_structure_list];
    fs[index].fee_structure.discount_type = value;
    setFeeStructureList(fs);
  
    handleCalculateFees(index);
 }
 const handleChangeFee = (value,index)=>{
    let fs = [...fee_structure_list];
    fs[index].fee_structure.fee = value;
    setFeeStructureList(fs);
   
    handleCalculateFees(index);
 }
 const handleChangeDiscount = (value,index)=>{
    let fs = [...fee_structure_list];
    fs[index].fee_structure.discount = value;
    setFeeStructureList(fs);
    handleCalculateFees(index);
 }
 const handleCalculateFees = (parent_idx) =>{
    let fs = [...fee_structure_list];
    let dis_type = fs[parent_idx].fee_structure.discount_type;
    let dis = fs[parent_idx].fee_structure.discount;
    let fee = fs[parent_idx].fee_structure.fee;
    let total = fs[parent_idx].fee_structure.total;
    let f_duration = fs[parent_idx].fee_duration;
    let link_month = fs[parent_idx].link_month;
    if(fee===""){
        fee = 0;
    }else{
        fee = parseFloat(fee);
    }
    if(dis===""){
        dis=0;
    }else{
        dis = parseFloat(dis);
    }
    let c_disc = 0;
    
    if(dis_type==='%'){
        c_disc = (fee*dis)/100;
        total = fee - c_disc;
    }else if(dis_type=='rs'){
        total = fee - dis;
    }
    fs[parent_idx].fee_structure.total = total;
    
    let months = fs[parent_idx].fee_structure.months;
    
    if(f_duration==='monthly'){
        let monthly_fee = total;
        let months_arr = [];
        for (let mi = 0; mi < months.length; mi++) {
            const element = months[mi];
            element.fee = Math.round(monthly_fee);
            months_arr.push(element);
        }
        fs[parent_idx].fee_structure.months = months_arr;
    }else if(f_duration==='yearly'){
        let monthly_fee = total;
        for (let mi = 0; mi < months.length; mi++) {
            const element = months[mi];
            let month_name = element.month;
            if(link_month===month_name){
                months[mi].fee = Math.round(monthly_fee);
            }
        }
        fs[parent_idx].fee_structure.months = months;
    }
    setFeeStructureList(fs);
 }
 const handleUpdateFeeStructure = ()=>{
    setIsSaving(true);
    APP_HTTP.REQUEST('settings/update_student_fee_structure',{student_id:student_info.student_id,data:fee_structure_list},true,true).then((response) => {
        setIsSaving(false);
        setIsSaved(true);
        setTimeout(() => {
            setIsSaved(false);
            handleClose();
        }, 700);
    });
 }
 const handleChangeMonthFee = (value,parent_idx,idx)=>{
    let fs = [...fee_structure_list];
    fs[parent_idx].fee_structure.months[idx].fee = value;
    setFeeStructureList(fs);
 }
  return (
      <Dialog open={is_open_update_student_fee_structure} onClose={handleClose} fullWidth={true} fullScreen={true}>
        <DialogTitle>Update Fee Structure of {student_info.student_name}</DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
            <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{'whiteSpace':'nowrap'}}>FEE TYPE</StyledTableCell>
                        <StyledTableCell style={{'whiteSpace':'nowrap'}}>FEES</StyledTableCell>
                        <StyledTableCell style={{'whiteSpace':'nowrap'}}>DISC. TYPE</StyledTableCell>
                        <StyledTableCell style={{'whiteSpace':'nowrap'}}>DISCOUNT</StyledTableCell>
                        <StyledTableCell style={{'whiteSpace':'nowrap'}}>TOTAL</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fee_structure_list && fee_structure_list.map((row,index) => (
                        <>
                        <StyledTableRow style={{backgroundColor:'#fff'}}>
                          <StyledTableCell style={{'whiteSpace':'nowrap'}}>
                            <Typography sx={{textTransform:'uppercase'}} variant='h6'>
                              {row.name} <Typography sx={{textTransform:'capitalize',fontSize:'12px',color:'#fff',background:'#5e86ad',fontWeight:'bold',padding:'5px'}} variant='span'> {row.fee_duration}</Typography>
                            </Typography>
                            
                            {(row.fee_duration==='yearly') &&
                                <Typography sx={{textTransform:'uppercase',fontSize:'12px'}}>Link Month : {row.link_month}</Typography>
                            }
                         </StyledTableCell>
                          <StyledTableCell>
                            <TextField
                            margin="dense"
                            size='small'
                            id={"fee-"+index}
                            label="Fee"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={(event)=>handleChangeFee(event.target.value,index)}
                            defaultValue={row.fee_structure.fee}
                            />
                          </StyledTableCell>
                          <StyledTableCell style={{'whiteSpace':'nowrap'}}>
                          <AppDropDown label="DISC. TYPE" list_item={[{key:'%',value:'%'},{key:'rs',value:'Rs'}]} size="small" handleChangeData={handleChangeDiscountType} defaultValue={row.fee_structure.discount_type} id={index}/>
                          </StyledTableCell>
                          <StyledTableCell>
                            <TextField
                            margin="dense"
                            size='small'
                            id={"discount-"+index}
                            label="Discount"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={(event)=>handleChangeDiscount(event.target.value,index)}
                            defaultValue={row.fee_structure.discount}
                            />
                          </StyledTableCell>
                          <StyledTableCell style={{'whiteSpace':'nowrap'}}>{row.fee_structure.total}</StyledTableCell>
                        </StyledTableRow>
                        {(row.fee_duration==='monthly') &&
                            <StyledTableRow  style={{background: '#fafffe'}}>
                                <StyledTableCell style={{'whiteSpace':'nowrap'}} colSpan={5}>
                                <Table aria-label="customized table">
                                    <TableBody>
                                        <StyledTableRow style={{background: '#5e86ad'}}>
                                        <StyledTableCell colSpan={12}>
                                        <Typography sx={{textTransform:'uppercase',color:'#fff'}}>
                                        {row.name} MONTHWISE SUMMARY
                                        </Typography>
                                        </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            {(row.fee_structure.months.map((fsv,fsi)=>{
                                                return(
                                                    <StyledTableCell  style={{'whiteSpace':'nowrap',width:'100px',textTransform:'uppercase'}}>
                                                        <TextField
                                                            margin="dense"
                                                            size='small'
                                                            id={"month-fee-"+fsi}
                                                            label={fsv.month}
                                                            type="text"
                                                            fullWidth
                                                            variant="outlined"
                                                            value={fsv.fee}
                                                            onChange={(event)=>handleChangeMonthFee(event.target.value,index,fsi)}
                                                            />
                                                    </StyledTableCell>
                                                    )
                                            }))}

                                           
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                                </StyledTableCell>
                            </StyledTableRow>
                        }
                        </>
                      ))}
                       
                    </TableBody>
                  </Table>
                </TableContainer>
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          {(is_saving===false) &&
            <Button onClick={handleUpdateFeeStructure} variant='contained' color='success'>Update Fee Structure</Button>
          }
          {(is_saving===true) &&
            <LoaderButton  variant='contained' color='success'/>
          }
        </DialogActions>
        {(is_saved===true) &&
            <SuccessLoader />
        }
      </Dialog>
    
  );
}
export default CreateStudentFeeStructure;