import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { ButtonGroup, Chip, Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';

import { AccountCircleRounded, CloseRounded } from '@mui/icons-material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import APP_HTTP from '../../APP_HTTP';
import { handleConfirmDeleteStudent, handleSetStudentInfo, handleToggleEditStudent } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import DeleteConfirm from '../DeleteConfirm';
import PrintStudent from './PrintStudent';
import AppUtils from '../../AppUtils';
import LoaderButton from '../LoaderButton';
import StudentCard from './StudentCard';
import { handleSetPaymentInfo, handleToggleViewPayment } from '../payments/Action';
import { handleOpenUpdateStudentFeeStructure } from '../settings/payment-types/Action';
import CreateStudentFeeStructure from '../settings/payment-types/StudentFeeStructure';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#ffffff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

let studentDataInitialState = {
    update_id : 0,
    student_name : '',
    gender : 'male',
    dob : '',
    father_name : '',
    mother_name : '',
    mobile_number : '',
    alt_mobile_number : '',
    email : '',
    city : '',
    state : 'Bihar',
    pincode : '',
    locality : '',
    admission_date : '',
    class : '',
    class_id : 0,
    academic_session_id : 0,
    section : '',
    section_id : 0,
    fee : '',
    fee_type : 'monthly',
    status : 'active',
}

const StudentProfile = (props)=> {
    const [page,setPage] = React.useState(0);
    const [transaction_list,setTransactionList] = React.useState([]);
    const [demand_bill_list,setDemandBillList] = React.useState([]);
    let [is_print,setIsPrint] = React.useState(false);
    let [image_url,setImageURL] = React.useState('');
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    let [studentData,setStudentData] = React.useState(studentDataInitialState);
    const [fromDate,setFromDate] = React.useState(new Date());
    const [toDate,setToDate] = React.useState(new Date());
    const [isGenerating,setIsGenerating] = React.useState(false);
    const [isLoadMore,setIsLoadMore] = React.useState(false);
    let [family_group,setFamilyGroup] = React.useState([]);
    const [isAllRecordLoaded,setIsAllRecordLoaded] = React.useState(false);
    const state = useSelector((state) => state);
    const is_college = state.school_info.school_info.is_college;
    const is_open_update_student_fee_structure = state.is_open_update_student_fee_structure;
    const is_delete_item = state.is_delete_item;
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            let curl = window.location.href;
            const get_id = curl.match(/student\/view\/(\d+$)/i);
            const sid = parseInt(get_id[1]);
            initialized.current = true;
            APP_HTTP.REQUEST('student/get_student_on_id',{id:sid},true,true).then((response) => {
                let resp = response.data.response;
                setStudentData(resp);
                setCurrentSequence(resp.student_sequence);
                setImageURL(resp.photo);
                dispatch(handleSetStudentInfo(resp));
                handleLoadReport(sid);
                handleLoadDemandBill(sid);
                setFamilyGroup(resp.family_group);
                
            });
        }
       
    },[props])

    const states = state.states;
    const gender_list = [{key:'male',value:'Male'},{key:'female',value:'Female'}];
    let state_list = [];
    for (let index = 0; index < states.length; index++) {
        const state_name = states[index];
        let list_item = {key:state_name,value:state_name};
        state_list.push(list_item);
    }
    const handleChangeData = () =>{
        
    }
    const handleEditStudent = () =>{
        let student_info = studentData;
        let student_id = student_info.student_id;
        window.location.href = '/student/edit/'+student_id;
        /* dispatch(handleChangeMenu('student','edit/'+student_id,'Update Student',false));
        dispatch(handleToggleEditStudent(true));
        dispatch(handleSetStudentInfo(student_info)); */
    }
    const handleDeleteStudent = () =>{
        let studentValue = studentData;
        let student_id = studentValue.student_id;
        let delData = {id:student_id,target:'student'};
        setDeleteData(delData);
        dispatch(handleConfirmDeleteStudent(true));
    }
  
    const handlePrintStudent = ()=>{
        setIsPrint(true);
        setTimeout(() => {
          setIsPrint(false);
        }, 200);
    }
    const handleModifyDeletedStatus = ()=>{
        let pdata = studentData;
        pdata.status = 0;
        setStudentData(pdata)
      }
      const handleLoadMore = () =>{
        setIsLoadMore(true);
        handleLoadReport(studentData.student_id);
      }

      const handleLoadDemandBill = (id) =>{
        APP_HTTP.REQUEST('payment/get_demand_bill_on_student_id',{student_id:id},true,true).then((response) => {
            let resp = response.data.response;
            setDemandBillList(resp);
        });
      }

      const handleLoadReport = (sid) =>{
        let transList = [...transaction_list];
        let next_page = page+1;
        setPage(next_page);
        let data = {page:next_page,fromDate:fromDate,toDate:toDate,filterType:'all',transaction_type:'in',selection_type:'student',contact_info:{id:sid},target:'profile'};
         APP_HTTP.REQUEST('reports/transaction',data,true,true,false,true).then((response) => {
          if(next_page===1){
            setIsGenerating(false);
          }else{
            setIsLoadMore(false);
          }
          let trans_list = response.data.response;
          if(trans_list.length>0){
            for (let index = 0; index < trans_list.length; index++) {
              const element = trans_list[index];
              transList.push(element);
            }
            setTransactionList(transList);
          }else{
            setIsAllRecordLoaded(true);
          }
        });
      }
      const handleViewItem = (id)=>{
        if(AppUtils.has_access(state,'503_demand_bill_view')===false){
          return false;
        }
        let payment_info = demand_bill_list[id];
        let payment_id = payment_info.demand_bill_payment_id;
        dispatch(handleChangeMenu('payment','view/demand-bill/'+payment_id,'View Demand Bill',false));
        dispatch(handleToggleViewPayment(true));
        dispatch(handleSetPaymentInfo(payment_info));
        
      }
      const handleConvertToPayment = (index) =>{
        let plist = [...demand_bill_list];
        let id = plist[index].demand_bill_payment_id;
        let add_main_menu = 'payment';
        let add_sub_menu = 'convert/'+id;
        let add_label = 'Convert To Payment';
        dispatch(handleChangeMenu(add_main_menu,add_sub_menu,add_label,false));
      }
      const handleViewStudent =(stid) =>{
        window.location.href = '/student/view/'+stid;
      }
      const handleEditStudentFeeStructure = (idx) =>{
        let student_info = studentData;
        dispatch(handleSetStudentInfo(student_info));
        dispatch(handleOpenUpdateStudentFeeStructure(true));
      }
  return (
    <div id="view-profile">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            <Grid item xs={12} sm={12} md={8}> 
                                    <StudentCard info={studentData} is_photo={true}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                            
                               <Box
                               sx={{
                                 display: 'flex',
                                 '& > *': {
                                   m: 1,
                                 },
                                 float:'right'
                               }}
                             >
                                {(parseInt(studentData.status)===1) &&
                               <ButtonGroup
                                 aria-label="vertical outlined button group"
                               >
                                {(AppUtils.has_access(state,'29_payment_types_edit')) &&
                                  <Button key="fee_structure" onClick={handleEditStudentFeeStructure}>Fee Structure</Button>
                                }
                                {(AppUtils.has_access(state,'2_students_edit')) &&
                                  <Button key="edit" onClick={handleEditStudent}>Edit</Button>
                                }
                                    {(AppUtils.has_access(state,'2_students_delete')) &&
                                      <Button key="delete" onClick={handleDeleteStudent}>Delete</Button>
                                    }
                                    {(AppUtils.has_access(state,'2_students_print')) &&
                                  <Button key="print" onClick={handlePrintStudent}>Print</Button>
                                }
                               </ButtonGroup>
                                }
                              </Box>
                            
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2}>  
                        <Grid item xs={12} sm={12} md={12}>
                            <h3>Basic Information</h3>
                            <Divider />
                        </Grid>
                      
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>PEN</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.pen_no}</p>
                        </Grid>
                      
                      
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Admission Number</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.admission_number}</p>
                        </Grid>
                      
                      
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Class Roll Number</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.roll_number}</p>
                        </Grid>
                      
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Date Of Birth</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.dob_display}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Father Name</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.father_name}</p>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Mother Name</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.mother_name}</p>
                        </Grid>
                       
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Alternate Mobile Number</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.alt_mobile_number}</p>
                           
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Email</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.email}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>City</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.city}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>State</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.state}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Pincode</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.pincode}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Blood Group</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.blood_group}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Locality</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.locality}</p>
                        </Grid>
                            {(family_group.length>0) &&
                            <>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>More Student From Same Family</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <Stack direction="row" spacing={1}>
                            {(family_group.map((value,index)=>{
                                return(
                                    <StudentCard info={value} is_photo={true}/>
                                   /*  <Chip
                                        label={value.student_name}
                                        variant="outlined"
                                        onClick={()=>handleViewStudent(value.student_id)}
                                    /> */
                                )
                            }))}
                           
                            </Stack>
                        </Grid>
                        </>
}
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Advanced Information</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Aadhar Card</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.aadhar_card}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Admission Date</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.admission_date_display}</p>
                        </Grid>
                        {(is_college===true) &&
                            <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                                <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Academic Session</p>
                                <p style={{margin:'0px',fontSize:'16px'}}>{studentData.academic_session}</p>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>{(is_college===true)?'Course/Course Year':'Section'}</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.class} | {studentData.section}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Fee amount</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>₹{studentData.fee}/{studentData.fee_type}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Status</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{(parseInt(studentData.status)===1)?'Active':'Inactive'}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Transportation</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Bus Facility</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.bus_facility}</p>
                        </Grid>
                        {(studentData.bus_facility==='yes') &&
                        <>
                            <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Transport Route</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.transport_name}</p>
                             
                            </Grid>
                           
                        </>
                        }
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Tuition</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Tuition Facility</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.tuition_facility}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Hostel</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Hostel Facility</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.hostel_facility}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>School in he/she was studying</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>School Name</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.prev_school_name}</p>
                            
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Class</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.prev_class}</p>
                           
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Medium</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{studentData.prev_medium}</p>
                           
                        </Grid>
                       
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Documents</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className='mrtop-15'>
                            <TableContainer component={Paper}>
                                <Table  aria-label="customized table">
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.affidavit && studentData.affidavit!=='') &&
                                                    <>
                                                        <img height="50" width="40" src={studentData.affidavit} />
                                                        <Typography variant="span" sx={{marginLeft:'5px'}}>Affidavit </Typography>
                                                        <a style={{marginLeft:'5px'}} href={studentData.affidavit} download={true}>
                                                            Download
                                                        </a>
                                                    </>
                                                }
                                                {(studentData.affidavit===undefined || studentData.affidavit==='') &&
                                                    <>
                                                        <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                        <Typography variant="span" sx={{marginLeft:'5px'}}>Affidavit </Typography>
                                                    </>
                                                }
                                            </StyledTableCell>      
                                            
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.aadharc && studentData.aadharc!=='') &&
                                                    <>
                                                        <img height="50" width="40" src={studentData.aadharc} />
                                                        <Typography variant="span" sx={{marginLeft:'5px'}}>Aadhar Card </Typography>
                                                        <a style={{marginLeft:'5px'}} href={studentData.aadharc} download={true}>
                                                            Download
                                                        </a>
                                                    </>
                                                }
                                                {(studentData.aadharc===undefined || studentData.aadharc==='') &&
                                                    <>
                                                        <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                        <Typography variant="span" sx={{marginLeft:'5px'}}>Aadhar Card </Typography>
                                                    </>
                                                }
                                            </StyledTableCell>      
                                            
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.dobc && studentData.dobc!='') &&
                                                <>
                                                    <img height="50" width="40" src={studentData.dobc} />
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>Date of Birth Certificate</Typography>
                                                    <a style={{marginLeft:'5px'}} href={studentData.dobc} download={true}>
                                                    Download
                                                        </a>
                                                </>
                                                }
                                                {(studentData.dobc===undefined || studentData.dobc==='') &&
                                                   <>
                                                    <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>Date of Birth Certificate</Typography>
                                                   </>
                                                }
                                            </StyledTableCell>      
                                                
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.tcc && studentData.tcc!='') &&
                                                <>
                                                    <img height="50" width="40" src={studentData.tcc} />
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>TC/SLC</Typography>
                                                    <a style={{marginLeft:'5px'}} href={studentData.tcc} download={true}>
                                                    Download
                                                        </a>
                                                </>
                                                }
                                                {(studentData.tcc===undefined || studentData.tcc==='') &&
                                                <>
                                                   <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>TC/SLC</Typography>
                                                </>
                                                }
                                            </StyledTableCell>      
                                               
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.mark_sheetc && studentData.mark_sheetc!='') &&
                                                <>
                                                    <img height="50" width="40" src={studentData.mark_sheetc} />
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>Passing Certificate / Mark Sheet</Typography>
                                                    <a style={{marginLeft:'5px'}} href={studentData.mark_sheetc} download={true}>
                                                    Download
                                                        </a>
                                                </>
                                                }
                                                {(studentData.mark_sheetc===undefined || studentData.mark_sheetc==='') &&
                                                <>
                                                   <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                   <Typography variant="span" sx={{marginLeft:'5px'}}>Passing Certificate / Mark Sheet</Typography>
                                                </>
                                                }
                                            </StyledTableCell>      
                                             
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.characterc && studentData.characterc!='') &&
                                                    <>
                                                    <img height="50" width="40" src={studentData.characterc} />
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>Character Certificate</Typography>
                                                    <a style={{marginLeft:'5px'}} href={studentData.characterc} download={true}>
                                                    Download
                                                    </a>
                                                    </>
                                                }
                                                {(studentData.characterc===undefined || studentData.characterc==='') &&
                                                <>
                                                   <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                   <Typography variant="span" sx={{marginLeft:'5px'}}>Character Certificate</Typography>
                                                </>
                                                }
                                            </StyledTableCell>      
                                          
                                        </StyledTableRow>    
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {(studentData.parent_login!==undefined && studentData.parent_login.app_user_id!==undefined) &&
                        <>
                        <Grid item xs={12} sm={12} md={12} className='mrtop-15'>
                            <h3>App Login Details</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className='mrtop-15'>
                            <Typography>Username : {studentData.parent_login.username}</Typography>
                            <Typography>Password : {studentData.parent_login.password}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className='mrtop-15'>
                            <Divider />
                        </Grid>
                        </>
                        }
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                    <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                                        <h3>Demand Bill Request(s)</h3>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                                    <TableContainer component={Paper}>
                                        <Table  aria-label="customized table">
                                            <TableHead>
                                            <TableRow>
                                                
                                                <StyledTableCell>DEMAND BILL NO</StyledTableCell>
                                                
                                                <StyledTableCell>DATE</StyledTableCell>
                                                
                                                <StyledTableCell>AMOUNT</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {demand_bill_list && demand_bill_list.map((row,index) => (
                                                <StyledTableRow key={row.payment_sequence} sx={{textDecoration:(row.status===0 || row.status==='0')?'line-through':'',color:(row.status===0 || row.status==='0')?'red':''}}>
                                                
                                                <StyledTableCell component="th" scope="row"  onClick={() =>handleViewItem(index)} sx={{cursor:'pointer'}}>
                                                    {row.payment_sequence}
                                                </StyledTableCell>
                                                <StyledTableCell>{row.payment_date_display}</StyledTableCell>
                                                <StyledTableCell>₹{AppUtils.currency_format(row.paid_amount)}</StyledTableCell>
                                                
                                                <StyledTableCell sx={{textAlign:'right'}}>
                                                    {(row.status===1 || row.status==='1') &&
                                                    <>
                                                    {(AppUtils.has_access(state,'503_demand_bill_convert')) &&
                                                    <Button variant='outlined' size='small' onClick={()=>handleConvertToPayment(index)}>CONVERT TO PAYMENT</Button>
                                                    }
                                                    </>
                                                    }
                                                
                                                </StyledTableCell>
                                               
                                                </StyledTableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                        </TableContainer>
                                    </Grid>
                                    </Grid>
                                </Grid>
                               
                                <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                    <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                                        <h3>Payment History</h3>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                            
                                        <TableContainer component={Paper}>
                                            <Table  aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                <StyledTableCell>PAYMENT NO</StyledTableCell>
                                                
                                                <StyledTableCell>DATE</StyledTableCell>
                                                <StyledTableCell>PARTICULARS</StyledTableCell>
                                                <StyledTableCell>AMOUNT</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {transaction_list && transaction_list.map((row,index) => (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row">
                                                    <a href={'/payment/view/'+row.payment_id} target='_blank'>{row.payment_sequence}</a>
                                                    </StyledTableCell>
                                                    
                                                    <StyledTableCell component="th" scope="row">
                                                    {row.payment_date}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                    {row.particulars}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                    ₹{AppUtils.currency_format(row.paid_amount)}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                ))}
                                                {(transaction_list.length===0 && isGenerating===false) &&
                                                <StyledTableRow>
                                                <StyledTableCell component="th" scope="row" colSpan={4} sx={{textAlign:'center'}}>
                                                    No Payment(s) Found
                                                </StyledTableCell>
                                                </StyledTableRow>
                                                }
                                                {(isLoadMore===true) &&
                                                <StyledTableRow>
                                                <StyledTableCell component="th" scope="row" colSpan={4}>
                                                <Stack spacing={1}>
                                                            <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                                        </Stack>
                                                </StyledTableCell>
                                                </StyledTableRow>
                                                }
                                                {(isGenerating===true) &&
                                                <>
                                                    
                                                    {Array.from(Array(5), (e, i) => {
                                                        return(
                                                            <StyledTableRow>
                                                            <StyledTableCell component="th" scope="row" colSpan={4}>
                                                            <Stack spacing={1}>
                                                                    <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                                                </Stack>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                        )
                                                        })}
                                                    
                                                </>
                                                }
                                                {(isAllRecordLoaded===false && transaction_list.length>0) &&
                                                <StyledTableRow>
                                                <StyledTableCell component="th" scope="row" sx={{textAlign:'center'}} colSpan={4}>
                                                {(isLoadMore===false) &&
                                                    <Button variant='outlined' size='small' onClick={handleLoadMore}>Load More</Button>
                                                }
                                                {(isLoadMore===true) &&
                                                    <LoaderButton label="Loading..."  variant='outlined' size='small'/>
                                                }
                                                </StyledTableCell>
                                                </StyledTableRow>
                                                }
                                            </TableBody>
                                            </Table>
                                        </TableContainer>
                                        
                                    </Grid>
                                    </Grid>
                                </Grid>
                               
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
        </Grid>
        </Box>
        {(is_delete_item) &&
          <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" handleModifyDeletedStatus={handleModifyDeletedStatus}/>
        }
        {(is_print) &&
        <PrintStudent student_data={studentData} />
        } 
        {(is_open_update_student_fee_structure===true) &&
              <CreateStudentFeeStructure />
            }
    </div>
  );
}
export default StudentProfile;