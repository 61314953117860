import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import APP_HTTP from '../../APP_HTTP';
import { Grid } from '@mui/material';
import LoaderButton from '../LoaderButton';
import AppDropDown from '../AppDropDown';
const PettyCashEntry = (props)=> {
  
  const [is_saving,setIsSaving] = React.useState(false);
  const [entry_data,setEntryData] = React.useState({entry_type:'out',contact_name:'',particular:'',amount:'',reference:''});
  
  const initialized = React.useRef(false);
  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  },[props]);
  const handleChangeData = (id,value)=>{
    let ent_data = {...entry_data}
    ent_data[id] = value;
    setEntryData(ent_data);
  }
  const handleSavePettyCash = () =>{
    setIsSaving(true);
    APP_HTTP.REQUEST('payment/save_petty_cash',{entry_data},true,true).then((response) => {
        setIsSaving(false);
        window.location.reload();
    });
  }
  const handleClosePettyCash = ()=>{
    props.handleClosePettyCash();
  }
  return (
      <Dialog open={true} maxWidth={'xs'}>
        <DialogTitle>Petty Cash Entry</DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} style={{marginTop:'10px'}}>
                <AppDropDown label="Entry Type" id="entry_type" defaultValue={entry_data.entry_type}  list_item={[{key:'out',value:'OUT(-)'},{key:'in',value:'IN(+)'}]} size="small" className="student-data" handleChangeData={handleChangeData}/>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <TextField
                margin="dense"
                label="Enter Contact Name"
                type="text"
                fullWidth
                size='small'
                onChange={(e)=>handleChangeData('contact_name',e.target.value)}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <TextField
                margin="dense"
                label="Enter Particular Name"
                type="text"
                fullWidth
                size='small'
                onChange={(e)=>handleChangeData('particular',e.target.value)}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <TextField
                margin="dense"
                label="Enter Reference"
                type="text"
                fullWidth
                size='small'
                onChange={(e)=>handleChangeData('reference',e.target.value)}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <TextField
                margin="dense"
                label="Enter Amount"
                type="text"
                fullWidth
                size='small'
                onChange={(e)=>handleChangeData('amount',e.target.value)}
            />
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button size='large' onClick={handleClosePettyCash}>Close</Button>
          {(is_saving===false) &&
            <Button variant='contained' color='success' size='large' onClick={handleSavePettyCash}>Save</Button>
          }
          {(is_saving===true) &&
            <LoaderButton variant='contained' color='success' size='large'/>
          }
        </DialogActions>
    
      </Dialog>
  );
}
export default PettyCashEntry;