import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleBulkDemandBillPrint} from './Action';
import Classes from '../settings/classes/Classes';
import { Divider, Grid } from '@mui/material';
const BulkDemandBillPrint = (props)=> {
    const state = useSelector((state) => state);
    let is_bulk_demand_bill_print = state.is_bulk_demand_bill_print;
    const dispatch = useDispatch();
    const [selected_class,setClass] = React.useState(0);
    const handleChangeClass = (id,value) =>{
        setClass(value);
    }
    const handleClose = ()=>{
        dispatch(handleBulkDemandBillPrint(false));
    }
  return (
      <Dialog open={is_bulk_demand_bill_print} onClose={handleClose}>
        <DialogTitle>Print Demand Bill</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} style={{marginTop:'0px'}}>
            <Grid item xs={12} sm={12} md={12} spacing={2}>
          <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>
          </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        {(selected_class>0) &&
          <DialogActions>
            <Button size='small' onClick={handleClose}>Close</Button>
            <Button variant='contained' color='success' size='small'>Print</Button>
          </DialogActions>
        }
      </Dialog>
    
  );
}
export default BulkDemandBillPrint;