import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ContactsIcon from '@mui/icons-material/Contacts';
import { Avatar, Card, CardContent, Checkbox, CircularProgress, Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import { green, orange, pink, red } from '@mui/material/colors';
import LoaderButton from '../LoaderButton';
import { AccountCircleRounded, Key, KeyOff, Subscriptions } from '@mui/icons-material';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import ActionMenu from './ActionMenu';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const ViewSchool = (props)=> {
    let schoolDataInitialState = {
        school_name:'',
    }
    let [image_url,setImageURL] = React.useState('');
    let [number_of_students,setNumberOfStudents] = React.useState('');
    const [subscription_list,setSubscriptionList] = React.useState([]);
    const [active_modules,setActiveModules] = React.useState([{key:'teacher_attendance',label:'Teacher Attendance',is_active:false,date:''}]);
    let [schoolData,setSchoolData] = React.useState(schoolDataInitialState);
    let [is_loading_module,setIsLoadingModule] = React.useState(false);
    const state = useSelector((state) => state);
   
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleViewSchool();
            handleGetSubscriptionList();
            handleGetActiveModules();
        }
    },[props])
    const handleViewSchool = () =>{
        APP_HTTP.REQUEST('school/admin/view_school',{school_name:state.sub_menu},true,false,false,false,true).then((response) => {
            let resp = response.data;
            let sinfo = resp.response.info;
            let number_of_students = resp.response.number_of_students;
            setNumberOfStudents(number_of_students)
            setSchoolData(sinfo);
        });
      }
      const handleGetSubscriptionList = () =>{
        APP_HTTP.REQUEST('school/admin/get_subscription',{school_name:state.sub_menu},true,false,false,false,true).then((response) => {
          let sub_list = response.data.response;
          setSubscriptionList(sub_list);
        });
      }
    const handleAfterAction = (status) =>{
        let sData = {...schoolData};
        if(status==='active'){
            sData.status = 1;
        }else{
            sData.status = 0;
        }
        setSchoolData(sData);
        handleGetSubscriptionList();
      }
    const handleChangeModuleStatus = (index,is_checked) =>{
        let act_modules = active_modules;
        act_modules[index].is_active = is_checked;
        if(act_modules[index].date===''){
            act_modules[index].date = new Date();
        }
        setActiveModules(act_modules);
        APP_HTTP.REQUEST('school/admin/enable_modules',{school_name:state.sub_menu,modules:act_modules},true,false,false,false,true).then((response) => {
            let sub_list = response.data.response;
        });
    }
    const handleGetActiveModules = ()=>{
        setIsLoadingModule(true);
        APP_HTTP.REQUEST('school/admin/get_modules',{school_name:state.sub_menu},true,false,false,false,true).then((response) => {
            let mod_list = response.data.response;
            if(mod_list.value){
                let new_list = mod_list.value;
                let mlist = mod_list.value;
                let act_mod = active_modules;
                for (let i = 0; i < act_mod.length; i++) {
                    const element = act_mod[i];
                    let tmp_key = element.key;
                    for (let j = 0; j < mlist.length; j++) {
                        const mod_element = mlist[j];
                        let mod_key = mod_element.key;
                        if(tmp_key!==mod_key){
                            new_list.value.push(element);
                        }
                    }
                }
                setActiveModules(new_list);
            }
            setIsLoadingModule(false);
        });
    }
  return (
    <div id="create-school">
        
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
                <Grid item xs={12} sm={12} md={6} spacing={2} style={{paddingRight:'5px',borderRight:'1px solid #d7d7d7'}}>
                    <Grid container spacing={2} className="mrtop-10">  
                        <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                            <Grid container spacing={2} className="mrtop-10"> 
                                <Grid item xs={6}> 
                                <Typography variant='h6' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>PROFILE</Typography>
                                <Typography variant='span' sx={{fontSize:'14px',color:'gray'}}>Please fill the school information in below field</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}> 
                                <ActionMenu target="view" school={state.sub_menu} data={schoolData} handleAfterAction={handleAfterAction} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15" style={{textAlign:'center'}}>
                        
                            <Box sx={{height:'150px',width:'150px',border:'5px solid #d7d7d7',margin:'0 auto',borderRadius:'50%',cursor:'pointer'}}>
                                {(image_url!=='' && image_url!==undefined) &&
                                    <img src={image_url} alt="Image" height={'100%'} width={'100%'} style={{borderRadius:'50%'}}/>
                                }
                                {(image_url==='' || image_url===undefined) &&
                                    <AccountCircleRounded size="large" sx={{height:'100%',width:'100%',color:'#d7d7d7'}}/>
                                }
                            </Box>
                        </Grid>
                            <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                                <h3>SCHOOL INFORMATION</h3>
                            <Divider />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                <Typography style={{textTransform:'uppercase',color:'gray'}}>School Name</Typography>
                                <Typography variant='h6'>{(schoolData && schoolData.school_name)?schoolData && schoolData.school_name:''}</Typography>
                            </Grid>
                        
                            <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                <Typography style={{textTransform:'uppercase',color:'gray'}}>Mobile Number</Typography>
                                <Typography variant='h6'>{(schoolData && schoolData.mobile_number)?schoolData && schoolData.mobile_number:''}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                <Typography style={{textTransform:'uppercase',color:'gray'}}>Alternate Mobile Number</Typography>
                                <Typography variant='h6'>
                                    {(schoolData && schoolData.alt_mobile_number)?schoolData && schoolData.alt_mobile_number:''}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                <Typography style={{textTransform:'uppercase',color:'gray'}}>Email ID</Typography>
                                <Typography variant='h6'>{(schoolData && schoolData.email)?schoolData && schoolData.email:''}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                <Typography style={{textTransform:'uppercase',color:'gray'}}>City</Typography>
                                <Typography variant='h6'>{(schoolData && schoolData.city)?schoolData && schoolData.city:''}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                <Typography style={{textTransform:'uppercase',color:'gray'}}>State</Typography> 
                                <Typography variant='h6'>{(schoolData && schoolData.state)?schoolData && schoolData.state:''}</Typography> 
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                <Typography style={{textTransform:'uppercase',color:'gray'}}>Pincode</Typography>
                                <Typography variant='h6'>{(schoolData && schoolData.pincode)?schoolData && schoolData.pincode:''}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                                <Typography style={{textTransform:'uppercase',color:'gray'}}>Locality</Typography>
                                <Typography variant='h6'>{(schoolData && schoolData.locality)?schoolData && schoolData.locality:''}
                                </Typography>
                            </Grid>
                    </Grid>
                </Grid>
                
                <Grid item xs={12} sm={12} md={6} spacing={2} style={{paddingLeft:'20px'}}>
                    <Grid container spacing={2} className="mrtop-10">  
                        <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                            <Grid container spacing={2} className="mrtop-10"> 
                                <Grid item xs={12}> 
                                <Typography variant='h6' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>MORE INFORMATION</Typography>
                                <Typography variant='span' sx={{fontSize:'14px',color:'gray'}}>Here we can find more information about school</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                            <Card>
                                <CardContent sx={{position:'relative'}}>
                                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>NO. OF STUDENTS</Typography>
                                    <Avatar sx={{ bgcolor: pink[500],position:'absolute',top:'30px',right:'10px' }}>
                                    <ContactsIcon/>
                                    </Avatar>
                                    <Typography variant='h4'>
                                    {(number_of_students==='') &&
                                        <CircularProgress color="inherit" size={20}/>
                                    }
                                    {(number_of_students>=0) &&
                                        <>{number_of_students}</>
                                    }
                                    </Typography>
                                </CardContent>
                                </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                            <Card>
                                <CardContent sx={{position:'relative'}}>
                                    <Typography variant='b' sx={{color:'gray',fontWeight:'bold',fontSize:'12px'}}>SCHOOL STATUS</Typography>
                                    <Avatar sx={{ bgcolor: (schoolData && schoolData.status===1)?green[500]:'gray',position:'absolute',top:'30px',right:'10px' }}>
                                    {(schoolData && schoolData.status===1) &&
                                        <Key/>
                                    }
                                    {(schoolData && schoolData.status!==1) &&
                                        <KeyOff/>
                                    }
                                    </Avatar>
                                    <Typography variant='h4'>
                                    {(schoolData && schoolData.status===1)?'Active':'Inactive'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}  style={{marginTop:'20px'}}>  
                        <Grid item xs={12} sm={12} md={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{marginTop:'20px'}}>  
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography>SUBSCRIPTION LIST</Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                    <TableRow>
                                        
                                        <StyledTableCell>TYPE</StyledTableCell>
                                        <StyledTableCell>DURATION</StyledTableCell>
                                        <StyledTableCell>EXTEND DATE</StyledTableCell>
                                        
                                    
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {(subscription_list.map((value,index)=>{
                                    return(
                                        <StyledTableRow>
                                        <StyledTableCell>
                                            <Typography>
                                                {value.sub_type}
                                            </Typography>
                                            <Typography>
                                            {(parseInt(value.status)===1)?'Active':'Inactive'}
                                            </Typography>
                                        </StyledTableCell>
                                
                                        <StyledTableCell>
                                            <Typography>
                                                {value.sub_start_date}
                                            </Typography>
                                            <Typography>
                                                {value.sub_end_date}
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        {value.subscription_date}
                                        </StyledTableCell>
                                
                                    </StyledTableRow>
                                    )
                                    }))}
                                    {(subscription_list.length===0) &&
                                    <StyledTableRow>
                                    <StyledTableCell colSpan={3}>No Subscription Found</StyledTableCell>
                                    </StyledTableRow>
                                    }
                                    
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {(is_loading_module===false) &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography>MODULES</Typography>
                            <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell>MODULE NAME</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {(active_modules.map((value,index)=>{
                                    return(
                                        <StyledTableRow style={{background:'#fff'}}>
                                            <StyledTableCell>
                                                <Typography>
                                                    {value.label}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                            <Checkbox defaultChecked={value.is_active} size="small" onChange={(e)=>handleChangeModuleStatus(index,e.target.checked)}/>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                    }))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        
    </div>
  );
}
export default ViewSchool;