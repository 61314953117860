import { OPEN_ADD_PAYMENT_TYPE,CREATE_PAYMENT_TYPE,UPDATE_PAYMENT_TYPE, DELETE_ITEM_CONFIRM, DELETE_PAYMENT_TYPE,SET_PAYMENT_TYPE_LIST,OPEN_UPDATE_STUDENT_FEE_STRUCTURE } from "../../../actionTypes/actionTypes";

const handleOpenAddPaymentType = (is_open_add_payment_type) =>{
  return {
    type: OPEN_ADD_PAYMENT_TYPE,
    state :{
      is_open_add_payment_type : is_open_add_payment_type,
    }
  };
}
const handleOpenUpdateStudentFeeStructure = (is_open_update_student_fee_structure) =>{
  return {
    type: OPEN_UPDATE_STUDENT_FEE_STRUCTURE,
    state :{
      is_open_update_student_fee_structure : is_open_update_student_fee_structure,
    }
  };
}
const handleSetPaymentTypeList = (payment_type_list) =>{
  return {
    type: SET_PAYMENT_TYPE_LIST,
    state :{
      payment_type_list : payment_type_list,
    }
  };
}

const handleSavePaymentTypeToList = (payment_type_list) =>{
  return {
    type: CREATE_PAYMENT_TYPE,
    state :{
      payment_type_list : payment_type_list,
    }
  };
}
const handleUpdatePaymentType = (update_id,name) =>{
  return {
    type: UPDATE_PAYMENT_TYPE,
    state :{
      update_id : update_id,
      name : name
    }
  };
}
const handleConfirmDeletePaymentType = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeletePaymentType = (index, delete_data) =>{
  return {
    type: DELETE_PAYMENT_TYPE,
    state :{
      id : index,
    }
  };
}

export { handleOpenAddPaymentType, handleSavePaymentTypeToList,handleUpdatePaymentType,handleConfirmDeletePaymentType,handleDeletePaymentType,handleSetPaymentTypeList,handleOpenUpdateStudentFeeStructure };