import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function AppPagination(props) {
  const [current_page,setCurrentPage] = React.useState(1);
  
  const initialized = React.useRef(false);
  React.useEffect(()=>{
      if (!initialized.current) {
          initialized.current = true;
          let current_path = window.location.pathname;
          let numberPattern = /\d+/g;
          let get_page = current_path.match( numberPattern );
          if(get_page){
            let cpage = parseInt(get_page);
            setCurrentPage(cpage);
            props.handleNavigatePage(cpage);
          }
      }
  },[setCurrentPage])
  
  const handleChangePage = (event,page) =>{
    props.handleNavigatePage(page);
    //window.location.href = props.url;
    let url = props.url+'/'+page;
    const nextState = {};
    window.history.pushState(nextState, '', url);
    setCurrentPage(page);
  }
  return (
    <Stack spacing={2}>
      <Pagination count={props.number_of_page} color="primary" onChange={handleChangePage} page={current_page}/>
    </Stack>
  );
}