import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, ButtonGroup, Divider, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppPagination from '../AppPagination';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import DeleteConfirm from '../DeleteConfirm';
import { handleConfirmDeletePayment, handleSetPaymentsList, handleToggleEditPayment,handleSetPaymentInfo, handleToggleViewPayment } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import { useUtils } from '@mui/x-date-pickers/internals';
import AppUtils from '../../AppUtils';
import StudentCard from '../students/StudentCard';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PaymentList = (props) => {
  const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
  
  const [payment_data,setPaymentData] = React.useState([]);
  const state = useSelector((state) => state);
  const is_delete_item = state.is_delete_item;
  const payment_list = state.payments_list;
  const sub_menu = state.sub_menu;
  
  const [number_of_page,setNumberOfPage] = React.useState(0);
  const [total_records,setTotalRecords] = React.useState(0);
  const [is_loading_list,setIsLoadingList] = React.useState(true);
  const [search_value,setSearchValue] = React.useState('');
  
  const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            let current_path = window.location.pathname;
            let numberPattern = /\d+/g;
            let get_page = current_path.match( numberPattern );

            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('search');
            if(myParam){
              handleSearch(myParam);
            }else{
              if(get_page===null){
                handleLoadPaymentList(1)
              }
            }
        }
    },[])
    const handleLoadPaymentList = (page) =>{
      setIsLoadingList(true);
      dispatch(handleSetPaymentsList([]));
      APP_HTTP.REQUEST('payment/get_payments',{page:page,total_records:total_records,type:props.type},true,true).then((response) => {
          let resp = response.data;
          let stu_list = resp.response;
          
          let tot_record = resp.total_record;
          let no_of_page = resp.number_of_page;
          setTotalRecords(tot_record);
          setNumberOfPage(no_of_page);
        
          dispatch(handleSetPaymentsList(stu_list));
          setIsLoadingList(false);
          setPaymentData(resp);
      });
    }
    const handleNavigatePage = (page) =>{
      handleLoadPaymentList(page);
    }

    const handleDeleteItem = (id)=> {
      let paymentValue = payment_list[id];
      let payment_id = paymentValue.payment_id;
      
      let delData = {id:payment_id,index:id,target:'payment'};
      setDeleteData(delData);
      dispatch(handleConfirmDeletePayment(true));
  }
  const handleEditItem = (id)=>{
    let payment_info = payment_list[id];
    let payment_id = payment_info.payment_id;
    window.location.href = '/payment/edit/'+payment_id;
   /*  dispatch(handleChangeMenu('payment','edit/'+payment_id,'Update Payment',false));
    dispatch(handleToggleEditPayment(true));
    dispatch(handleSetPaymentInfo(payment_info)); */
    
  }
  const handleViewItem = (id)=>{
    if(AppUtils.has_access(state,'5_payments_view')===false){
      return false;
    }
    let payment_info = payment_list[id];
    let payment_id = payment_info.payment_id;
    window.location.href = '/payment/view/'+payment_id;
   /*  dispatch(handleChangeMenu('payment','view/'+payment_id,'View Payment',false));
    dispatch(handleToggleViewPayment(true));
    dispatch(handleSetPaymentInfo(payment_info)); */
    
  }
  const handleSearch = (thisvalue)=>{
    const url = new URL(window.location.href);
    setSearchValue(thisvalue);
    let stype = 'in';
    if(sub_menu=='out'){
      stype = 'out';
    }
    if(thisvalue!==''){
      url.searchParams.set('search', thisvalue);
      let new_url = url.toString();
      localStorage.setItem('referer',new_url);
      window.history.pushState(null, '',new_url);
      if(thisvalue.length<2){
        return false; 
      }
      setIsLoadingList(true);
      dispatch(handleSetPaymentsList([]));
      APP_HTTP.REQUEST('payment/search_payment',{search:thisvalue,type:stype},true,true,false,true).then((response) => {
            if(response.data){
              let resp = response.data;
              let stf_list = resp.response;
              let tot_record = resp.total_record;
              let no_of_page = resp.number_of_page;
              setTotalRecords(tot_record);
              setNumberOfPage(no_of_page);
              
              dispatch(handleSetPaymentsList(stf_list));
              setIsLoadingList(false);
          }
      });
    }else{
      url.search = "";
      localStorage.setItem('referer',url.href);
      window.history.pushState(null, '',url.href);
      handleLoadPaymentList(1);
    }
  }
 
  return (
      <>
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={3} spacing={2}>
              <TextField
                label="Search Payment"
                size="small"
                fullWidth
                onChange={(e)=>handleSearch(e.target.value)}
                value={search_value}
                />
              </Grid>
              <Divider />
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        
                        <StyledTableCell>PAYMENT NO</StyledTableCell>
                        <StyledTableCell>CONTACT INFO</StyledTableCell>
                        <StyledTableCell>DATE</StyledTableCell>
                        <StyledTableCell>PARTICULARS</StyledTableCell>
                        
                        <StyledTableCell>AMOUNT</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payment_list && payment_list.map((row,index) => (
                        <StyledTableRow key={row.payment_sequence} sx={{textDecoration:(row.status===0 || row.status==='0')?'line-through':'',color:(row.status===0 || row.status==='0')?'red':''}}>
                        
                          <StyledTableCell component="th" scope="row"  onClick={() =>handleViewItem(index)} sx={{cursor:'pointer'}}>
                            <p style={{margin:'0px'}}>{row.payment_sequence} 
                              {(row.username!=='')&&
                                <span style={{marginLeft:'5px',padding:'1px 5px',fontSize:'12px',background:'gray',color:'#fff'}}>{row.username}</span>
                              }</p> <p style={{margin:'0px'}}><span style={{background: (row.payment_type_lbl==='RECEIVED' || row.payment_type_lbl==='CONVERTED')?'green':'red',
    color: 'white',
    fontSize: '10px',
    fontWeight: 'bold',
    padding: '2px'}}>{row.payment_type_lbl}</span>
    {(row.converted_bill_sequence!=='') &&
      <span style={{fontSize:'10px',fontWeight:'bold',marginLeft:'6px'}}>({row.converted_bill_sequence})</span>
    }
    </p>
                          </StyledTableCell>
                          <StyledTableCell>
                            {(row.contact_type==='student') &&
                            <StudentCard info={row.contact_info} is_photo={true}/>
                            }
                            {(row.contact_type!=='student') &&
                            <>{row.contact_name}</>
                            }
                          </StyledTableCell>
                          <StyledTableCell>{row.payment_date_display}</StyledTableCell>
                          <StyledTableCell>{row.particulars}</StyledTableCell>
                          <StyledTableCell>₹{AppUtils.currency_format(row.paid_amount)}</StyledTableCell>
                          {(row.payment_type==='payment_in') &&
                          <StyledTableCell>
                            {(parseInt(row.status)===1) &&
                              <>
                              {(AppUtils.has_access(state,'501_payment_in_delete')) &&
                                <Delete fontSize='small' onClick={() =>handleDeleteItem(index)} sx={{cursor:'pointer'}}/>
                              }
                                {(AppUtils.has_access(state,'501_payment_in_edit') && row.payment_type_lbl!=='CONVERTED') &&
                                <Edit  fontSize='small' onClick={() =>handleEditItem(index)} sx={{cursor:'pointer'}}/>
                                }
                              </>
                            }
                          </StyledTableCell>
                          }
                          {(row.payment_type==='payment_out') &&
                          <StyledTableCell>
                            {(row.status===1 || row.status==='1') &&
                              <>
                              {(AppUtils.has_access(state,'502_payment_out_delete')) &&
                                <Delete fontSize='small' onClick={() =>handleDeleteItem(index)} sx={{cursor:'pointer'}}/>
                              }
                                {(AppUtils.has_access(state,'502_payment_out_edit')) &&
                                <Edit  fontSize='small' onClick={() =>handleEditItem(index)} sx={{cursor:'pointer'}}/>
                                }
                              </>
                            }
                          </StyledTableCell>
                          }
                        </StyledTableRow>
                      ))}
                      {(is_loading_list===true) &&
                      <StyledTableCell component="th" scope="row" colSpan={6} sx={{textAlign:'center'}}>
                      Please wait while loading the list...
                      </StyledTableCell>
                      }   
                      {(is_loading_list===false && payment_list && payment_list.length===0) &&
                      <StyledTableCell component="th" scope="row" colSpan={6} sx={{textAlign:'center'}}>
                        Oops ! No Record(s) Found.
                      </StyledTableCell>
                      }   
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <AppPagination number_of_page={number_of_page} handleNavigatePage={handleNavigatePage} url={'/payments/page'}/>
              </Grid>
            </Grid>
            {(is_delete_item) &&
              <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
            }
          </Box>
      </>
  );
}

export default PaymentList;