import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, ButtonGroup, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Skeleton, Stack, TextField, TextareaAutosize, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Add, Check, CheckRounded, Close, KeyboardDoubleArrowRight, Reply } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
import BasicDatePicker from '../DatePicker';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import LoaderButton from '../LoaderButton';
import { Textarea } from '@mui/joy';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AppDropDown from '../AppDropDown';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Homework = (props) => {
    let [homework_date,setHomeworkDate] = React.useState(new Date());
    let [selected_date,setSelectedDate] = React.useState('');
    const [homework_template_type, setHomeworkType] = React.useState('default');
    const [template_background,setHomeworkBackground] = React.useState('');
    const [design_data,setDesignData] = React.useState({});
    const [is_clicked_show, setIsClickedShow] = React.useState(false);
    const [is_loading, setLoading] = React.useState(false);
    const [is_saving, setSaving] = React.useState(false);
    const [is_changing_subject, setChangingSubject] = React.useState(false);
    const [is_selecting_class, setSelectingClass] = React.useState(false);
    const [is_selecting, setSelecting] = React.useState(false);
    const [homeworks, setClassHomeWork] = React.useState([]);
    const [selected_homework, setSelectedHomework] = React.useState([]);
    const [selected_class_item, setSelectedClassHomework] = React.useState([]);
    const [selected_index, setSelectedIndex] = React.useState('');
    const [selected_class, setSelectedClass] = React.useState('');
    const [selected_section, setSelectedSection] = React.useState('');
    const [selected_section_index, setSelectedSectionIndex] = React.useState('');
    const [selected_class_name, setSelectedClassName] = React.useState('');
    const [selected_section_name, setSelectedSectionName] = React.useState('');
    const [subject_item_list, setSubjectList] = React.useState([]);
    const [number_of_items_list, setNumberOfItemsList] = React.useState([]);
    const initialized = React.useRef(false);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetClassHomework();
            handleGetSubjects();
            //handleGetHomeworkTemplate();
        }
    },[])
    const handleGetSubjects = () =>{
      APP_HTTP.REQUEST('settings/get_subject_list',{},true,true).then((response) => {
          let resp = response.data;
          let subject_list = resp.response;
          let sub_li = [];
          for (let index = 0; index < subject_list.length; index++) {
            const element = subject_list[index];
            let sub_id = element.id;
            let sub_name = element.name;
            let sli = {key:sub_id,value:sub_name};
            sub_li.push(sli);
          }
          setSubjectList(sub_li);
      });
    }
    const handleGetHomeworkTemplate = ()=>{
     /*  APP_HTTP.REQUEST('settings/get_option',{name:'homework_type'},true,true).then((response) => {
          let resp = response.data.response;
          if(resp.value!==undefined && resp.value.header_type!==undefined){
              setHomeworkType(resp.value.header_type);
              
              if(resp.value.header!==undefined){
                  let img_url = resp.value.header+'?time='+Math.random(1111,9999);
                  setHomeworkBackground(img_url);
              }
              
          }
      }); */
      
      /* APP_HTTP.REQUEST('settings/get_option',{name:'homework_template_design'},true,true).then((response) => {
          let resp = response.data.response;
          if(resp.value!==undefined){
              let drag_list = resp.value.draggable_list;
              //setDraggableList(drag_list);
              setDesignData(resp.value);
          }
      }); */
    
  }
    const handleGetClassHomework = () =>{
        setLoading(false);
        APP_HTTP.REQUEST('home_work/get_class_homework',{homework_date:homework_date},true,true).then((response) => {
            let resp = response.data;
            let class_list = resp.response;
            let selected_date = resp.selected_date;
            let homework_type = resp.homework_type;
            if(homework_type.value!==undefined && homework_type.value.header_type!==undefined){
                setHomeworkType(homework_type.value.header_type);
                if(homework_type.value.header!==undefined){
                    let img_url = homework_type.value.header+'?time='+Math.random(1111,9999);
                    setHomeworkBackground(img_url);
                }
                
            }
            let design_template = resp.design_template;
            if(design_template.value!==undefined){
                let drag_list = design_template.value.draggable_list;
                //setDraggableList(drag_list);
                setDesignData(design_template.value);
            }
            setSelectedDate(selected_date)
            setClassHomeWork(class_list);
            setLoading(false);
            setIsClickedShow(false);
        }); 
    }

    const handleChangeDate = (id,value) =>{
        setHomeworkDate(value);
    }
   
    const handleShowHomework = () =>{
        setIsClickedShow(true);
        setClassHomeWork([]);
        handleGetClassHomework();
    }
    const handleSelectClass = (index) =>{
        setSelectedIndex(index);
        let cl_hw = [...homeworks];
        let classInfo = cl_hw[index];
        
        setSelectedClass(classInfo.class_id);
        setSelectedClassName(classInfo.name);
        setSelectedClassHomework(classInfo);

        setSelectedSection('');
        setSelectedSectionName('');
        setSelectingClass(true);
        setSelecting(true);
        setSelectedSectionIndex('');
        setSelectedHomework([]);
        setTimeout(() => {
          setSelecting(false);
          setSelectingClass(false);
        }, 100);
    }
    const handleRemoveItem = (index) =>{
      let cl_hw = [...homeworks];
      cl_hw[index].homework.text.homework='';
      setClassHomeWork(cl_hw);
    }
    const handleAddMore = () =>{
      let cl_hw = [...homeworks];
      cl_hw[selected_index].homework.text.push('');
      setClassHomeWork(cl_hw);
    }
    const handleChangeTextHW = (index,value) =>{
      let cl_hw = [...homeworks];
      if(homework_template_type==='custom'){
        let nili = [...number_of_items_list];
        let sub_id_tmp = nili[index];
        if(sub_id_tmp.subject_id===undefined){
          return false;
        }
        sub_id_tmp = sub_id_tmp.subject_id;
        let hw_tmp = cl_hw[selected_index].sections_hw[selected_section_index].homework.text;
        for (let hwidx = 0; hwidx < hw_tmp.length; hwidx++) {
          const element = hw_tmp[hwidx];
          let sub_id = element.subject_id;
          if(parseInt(sub_id)===parseInt(sub_id_tmp)){
            index = hwidx;
            break;
          }
        }
      }
      cl_hw[selected_index].sections_hw[selected_section_index].homework.text[index].homework = value;
      setClassHomeWork(cl_hw);
    }
    const handleSaveHomework = () =>{
      let cl_hw = [...homeworks];
      let next_index = selected_index + 1;
      let next_section_index = selected_section_index + 1;
      setSaving(false);
      let is_empty = true;
      for (let index = 0; index < selected_homework.length; index++) {
        const element = selected_homework[index];
        if(element.homework!==""){
          is_empty = false;
        }
      }
      
      //if(is_empty===false){
        APP_HTTP.REQUEST('home_work/save_text_home_work',{homework_date:homework_date,homeworks:selected_homework,class_id:selected_class,section_id:selected_section},true,true).then((response) => {
            let resp = response.data.response;
            setSaving(false);
          
            cl_hw[selected_index].sections_hw[selected_section_index].is_saved = true;
            cl_hw[selected_index].sections_hw[selected_section_index].homework.text = resp;
            setSelectedHomework(cl_hw);

            if(cl_hw[next_index]!==undefined && cl_hw[next_index].sections_hw!==undefined && cl_hw[next_index].sections_hw[next_section_index]!==undefined){
              handleChangeSection(next_section_index);
            }else if(cl_hw[next_index]!==undefined){
                handleSelectClass(next_index);
            }
        }); 
      //}
    }
    const handleChangeSection = (index) =>{
      let cl_hw = [...homeworks];
      let section_info = cl_hw[selected_index]['sections_hw'][index];
      setSelectedSection(section_info.section_id);
      setSelectedSectionName(section_info.name);
      setSelecting(true);
      setSelectedSectionIndex(index);
      setSelectedHomework(section_info.homework);
      let sel_hw_txt = section_info.homework.text;
      if(design_data.number_of_items!==undefined){
        let number_of_items = design_data.number_of_items;
        let nites_tmp = [];
        for (let nidx = 0; nidx < number_of_items.length; nidx++) {
          if(sel_hw_txt[nidx]!==undefined){
            let sel_txt_tmp = sel_hw_txt[nidx];
            nites_tmp.push(sel_txt_tmp)
          }
        }
        setNumberOfItemsList(nites_tmp);
      }
      setTimeout(() => {
        setSelecting(false);
      }, 100);
    }
    const handleChangeSubject = (id,value,list) =>{
      let nili = [...number_of_items_list];
      
      setChangingSubject(true);
      let sid_tmp = value;
      for (let index = 0; index < nili.length; index++) {
        const element = nili[index];
        let sub_id_tmp = element.subject_id;
        if(parseInt(sid_tmp)===parseInt(sub_id_tmp)){
          let old_list = nili[id];
          let new_list = nili[index];
          if(id!==index){
            nili[id] = new_list;
            nili[index] = old_list;
            break;
          }
        }
      }
      setNumberOfItemsList(nili);
      setTimeout(() => {
        setChangingSubject(false);
      }, 10);
    }
  return (
      <>
      {(AppUtils.has_access(state,'300_homework_view')) &&
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">

              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>Homework</Typography>
                <Typography variant='span' sx={{fontSize:'14px',color:'gray'}}>
                    Assign Homework to the class for today and filter as per the criteria.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                <BasicDatePicker label="Homework Date" size="small" id="homework_date" handleChangeData={handleChangeDate} defaultValue={homework_date} maxDate={new Date()}/>
              </Grid>
              <Grid item xs={12} sm={12} md={3} spacing={2}>
                  {(is_clicked_show===false) &&
                      <Button variant="outlined" onClick={() => handleShowHomework()}>Proceed</Button>
                  }
                  {(is_clicked_show===true) &&
                      <LoaderButton/>
                  }
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={4} spacing={2}>
              <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell colSpan={2}>CLASS HOMEWORK FOR {selected_date}</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {homeworks.map((value,index)=>{
                              return (
                                <StyledTableRow style={{backgroundColor:(selected_index===index)?'#f4f4f4':'',cursor:'pointer'}}  onClick={()=>handleSelectClass(index)}>
                                  <StyledTableCell style={{textTransform:'capitalize'}}>
                                      
                                      <Typography>{(value.is_saved===true) &&
                                        <Check style={{color:'#26e126',fontSize:'16px'}}/>
                                      } {value.name}</Typography>
                                  </StyledTableCell>
                                  
                                  <StyledTableCell style={{textAlign:'right'}}>
                                    {(value.selected!==true) &&
                                    <Button size='small' sx={{fontSize:'9px'}}><Typography variant='span' style={{marginTop:'3px'}}>Select</Typography> <KeyboardDoubleArrowRight size="medium"/></Button>
                                    }
                                    {(value.selected===true) &&
                                      <CheckRounded/>
                                    }
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                          })}
                          {(homeworks.length===0) &&
                            <StyledTableRow >
                            <StyledTableCell style={{textAlign:'center'}} colSpan={2}>
                                Loading...
                            </StyledTableCell>
                            
                          </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
              </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={8} spacing={2}>
              {(selected_index>=0 && selected_class_item.sections_hw!==undefined && is_selecting_class===false) &&
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">SELECT SECTION</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      {(selected_class_item.sections_hw.map((value,index)=>{
                        return(
                          <FormControlLabel value={value.section_id} control={<Radio onChange={()=>handleChangeSection(index)} color='success' sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 40,
                            },
                          }}/>} label={value.name} checked={(selected_section_index===index)?true:false}/>
                        )
                      }))}
                      
                    </RadioGroup>
                  </FormControl>
                  </Grid>
                }
                {(is_selecting===false) &&
                <Grid container spacing={2}>
                  {(selected_class_item.class_id!==undefined) &&
                  <>
                  <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                  </Grid>
                  </>
                  }
                 
                  {(selected_homework.text!==undefined) &&
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                      <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>CLASS DIARY AS TEXT</Typography>
                      <Divider style={{marginTop:'10px'}}/>
                      {(homework_template_type==='default') &&
                        <TableContainer component={Paper} style={{marginTop:'10px'}}>
                          <Table aria-label="customized table">
                            <TableBody>
                              {selected_homework.text.map((value,index)=>{
                                  return (
                                    <StyledTableRow>
                                      <StyledTableCell>
                                        <Typography>{value.subject_name}</Typography>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                      <Textarea minRows={4} size="lg" name="Size" placeholder="Please type homework here..." defaultValue={value.homework}
                                        onChange={(event)=>handleChangeTextHW(index,event.target.value)} autoFocus={(index===0)?true:false}/>
                                  
                                      </StyledTableCell>
                                    {/*  <StyledTableCell style={{textAlign:'right',width:'170px'}}>
                                      {((selected_homework.text.length - 1)==index) &&
                                        <Button onClick={()=>handleAddMore(index)} size='small' sx={{fontSize:'9px'}}><Typography variant='span' style={{marginTop:'3px'}}>Add More</Typography></Button>
                                      }
                                      <Button onClick={()=>handleRemoveItem(index)} size='small' sx={{fontSize:'9px'}}><Close /> </Button>
                                      </StyledTableCell> */}
                                    </StyledTableRow>
                                  )
                              })}
                              <StyledTableRow>
                                <StyledTableCell colSpan={2} style={{textAlign:'right'}}>
                                  {(is_saving===false) &&
                                    <Button onClick={()=>handleSaveHomework()} size='medium' variant='outlined'>Save Homework & Move To Next Section/Class</Button>
                                  }
                                  {(is_saving===true) &&
                                      <LoaderButton size='medium' variant='outlined' />
                                  }
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      }
                      {(homework_template_type==='custom') &&
                      <>
                        <Box style={{height:(design_data.page_height)+'px',width:(design_data.page_width)+'px',backgroundImage:(template_background!=="")?'url('+template_background+')':'',backgroundSize: "contain",backgroundRepeat: "no-repeat"}}>
                        {(design_data.page_header_height!==undefined && design_data.page_header_height!=="") &&
                        <Box style={{height:(design_data.page_header_height)+'px',width:'100%',position:'relative'}}>
                            {(design_data.draggable_header!==undefined) && 
                              <>
                              {(design_data.draggable_header.map((value,index)=>{
                                return(
                                  <Box style={{position:'absolute',transform:'translate('+value.position.x+'px,'+value.position.y+'px)'}}>
                                        <Box className="handle">
                                          <Typography variant='label' style={{background:'gray',color:'#fff',padding:'5px',borderRadius:'5px',padding:'5px'}}>
                                            {(value.key==='class') &&
                                            <>
                                              {selected_class_name}
                                            </>
                                            }
                                            {(value.key==='section') &&
                                            <>
                                              {selected_section_name}
                                            </>
                                            }
                                            {(value.key==='date') &&
                                            <>
                                              {selected_date}
                                            </>
                                            }
                                          </Typography>
                                      </Box>
                                  </Box>
                                )
                              }))}
                                
                              </>
                            }
                                </Box>
                                }
                                {(design_data.page_body_height!==undefined && design_data.page_body_height!=="") &&
                                <Box style={{height:(design_data.page_body_height)+'px',width:'100%',paddingLeft:(design_data.page_body_pad_left)+'px',paddingRight:(design_data.page_body_pad_right)+'px'}}>
                                    <table style={{width:'100%',height:'100%'}} cellPadding={0} cellSpacing={0}>
                                        {(is_changing_subject===false) &&
                                        <tbody>
                                            {(design_data.number_of_items.map((value,index)=>{
                                                return(
                                                    <tr>
                                                        <td style={{height:design_data.subject_list_name_height+'px',width:design_data.subject_list_name_width+'px',paddingRight:'5px',paddingLeft:'5px'}}>
                                                        <AppDropDown label="Select Subject" list_item={subject_item_list} size="small" handleChangeSubject={handleChangeSubject} defaultValue={(number_of_items_list[index]!==undefined && number_of_items_list[index].subject_id!==undefined)?number_of_items_list[index].subject_id:0} id={index}/>
                                                        </td>
                                                        <td style={{paddingRight:'5px',paddingLeft:'5px'}}>
                                                        <Textarea minRows={2} size="lg" name="Size" placeholder="Please type homework here..." defaultValue={(number_of_items_list[index]!==undefined && number_of_items_list[index].homework!==undefined)?number_of_items_list[index].homework:''}
                                        onChange={(event)=>handleChangeTextHW(index,event.target.value)} autoFocus={(index===0)?true:false}/>
                                                        </td>
                                                    </tr>
                                                )
                                            }))}
                                        </tbody>
                                        }
                                    </table>
                                </Box>
                                }
                        </Box>
                        <Divider />
                        <Box style={{marginTop:'10px',textAlign:'right'}}>
                        {(is_saving===false) &&
                          <Button onClick={()=>handleSaveHomework()} size='medium' variant='outlined'>Save Homework & Move To Next Section/Class</Button>
                        }
                        {(is_saving===true) &&
                            <LoaderButton size='medium' variant='outlined' />
                        }
                        </Box>
                      </>
                      }
                    </Grid>
                  }
                  {(selected_homework.image!==undefined) &&
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                      <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>CLASS DIARY AS IMAGE</Typography>
                      <Divider style={{marginTop:'10px'}}/>
                      <Grid container spacing={2} style={{marginTop:'10px'}}>
                        {selected_homework.image.map((value,index)=>{
                          return(
                            <Grid item xl={12} sm={12} md={6} spacing={2}>
                                <Box key={value.homework} style={{ 
                                  backgroundImage: `url(${value.homework})`,height:'500px',width:'100%',backgroundRepeat: 'no-repeat',backgroundSize:'contain' 
                                }}>
                                </Box>
                            </Grid>
                          )
                        })}
                        {(selected_homework.image.length===0) &&
                        <Grid item xl={12} sm={12} md={12} spacing={2}>
                          <Typography>No ! Diary Image Uploaded</Typography>
                        </Grid>
                        }
                      </Grid>
                    </Grid>
                    }
                </Grid>
                }
              </Grid>
            </Grid>
          </Box>
        }
      </>
  );
}

export default Homework;