import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useSelector, useDispatch } from "react-redux";
import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let studentDataInitialState = {
    
}

const PrintStudent = (props)=> {
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [studentData,setStudentData] = React.useState(studentDataInitialState);
    const state = useSelector((state) => state);
    const is_college = state.school_info.school_info.is_college;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            
            initialized.current = true;
            
            if(props.student_data){
                let student_data = props.student_data;
                if(student_data.is_db===undefined){
                    setStudentData(student_data);
                    setCurrentSequence(student_data.student_sequence);
                    setTimeout(() => {
                        handlePrintStudent();
                    }, 100);
                    
                }else{
                    APP_HTTP.REQUEST('student/get_student_on_id',{id:student_data.student_id},true,true).then((response) => {
                        let resp = response.data.response;
                        setStudentData(resp);
                        setCurrentSequence(resp.student_sequence);
                        setTimeout(() => {
                        
                        handlePrintStudent();
                    }, 100);
                        //dispatch(handleSetStudentInfo(resp));
                    });
                }
            }
            
        }
       
    },[props])
   const handlePrintStudent = ()=>{
    if(document.getElementById("studentPrintBlock")){
        var divContents = document.getElementById("studentPrintBlock").innerHTML; 

      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
     
          a.document.write('<style type="text/css"><style type="text/css">@page{size:"A4";margin:5cm}table{font-family:verdana, arial, sans-serif;font-size:16px;font-weight:bold;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
            a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');  
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="studentPrintBlock" style={{display:'none'}}>
        <div style={{position:'relative'}}>
        <Watermark/>
        <table>
            <PrintHeader header_data={studentData.school_info}/>
            <tr>
                <th style={{borderRight:'none',width:'90px',textAlign:'left'}}>
                    <img src={studentData.photo} alt="image" height={'90'}/>
                </th>
                <th style={{textAlign:'left',borderLeft:'none'}} colSpan={4}>
                    <h4 style={{fontSize:'20px',fontWeight:'bold'}}>
                        <span> {studentData.student_name}</span>
                    </h4>
                    {(studentData) &&
                    <>
                        <h4 style={{fontSize:'15px'}}>
                            {(studentData.mobile_number!=="") &&
                                <span>{studentData.mobile_number}</span>
                            } 
                            {(studentData.mobile_number!=="" && studentData.alt_mobile_number!=="") &&
                                <span> | </span>
                            } 
                            {(studentData.alt_mobile_number!=="") &&
                                <span>{studentData.alt_mobile_number}</span>
                            } 
                        </h4>
                        <h4 style={{fontSize:'15px'}}>
                            {(studentData.locality!=="") &&
                                <span>{studentData.locality}</span>
                            } 
                            {(studentData.city!=="" && studentData.locality!=="") &&
                                <span>, </span>
                            } 
                            {(studentData.city!=="") &&
                                <span>{studentData.city}</span>
                            } 
                            {(studentData.city!=="" && studentData.state!=="") &&
                                <span>, </span>
                            } 
                            {(studentData.state!=="") &&
                                <span>{studentData.state}</span>
                            } 
                            {(studentData.state!=="" && studentData.pincode!=="") &&
                                <span>, </span>
                            } 
                                {(studentData.pincode!=="") &&
                                <span>{studentData.pincode}</span>
                            } 
                        </h4>
                    </>
                    }
                </th>
                
            </tr>
            <tbody>
                <tr>
                    <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}} colSpan={5}>STUDENT ADMISSION INFORMATION</th>
                </tr>  
                <tr>
                    <td colSpan={2}  style={{borderRight:'none',borderBottom:'none'}}>
                        <p style={{fontSize:'14px'}}>Admission No.</p>
                        <p style={{fontSize:'16px'}}>{studentData.student_sequence}</p>
                    </td>
                    <td colSpan={2} style={{borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                        <p style={{fontSize:'14px'}}>Admission Date</p>
                        <p style={{fontSize:'16px'}}>{studentData.admission_date_display}</p>
                    </td>
                    <td style={{borderLeft:'none',borderBottom:'none'}}>
                        <p style={{fontSize:'14px'}}>Admitted to {(is_college===true)?'Course':'Class'}</p>
                        <p style={{fontSize:'16px'}}>
                            {studentData.class} ({studentData.section})
                            {(is_college===true) &&
                                <> | {studentData.academic_session}</>
                            }
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderTop:'none'}}>
                        <p style={{fontSize:'14px'}}>Class Roll No.</p>
                        <p style={{fontSize:'16px'}}>{studentData.roll_number}</p>
                    </td>
                </tr>
                <tr>
                    <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}} colSpan={5}>ATTACHED FOLLOWING DOCUMENT</th>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none'}}>
                        <input type='checkbox' defaultChecked={(studentData.dobc && studentData.dobc!=='')?true:false}/> Xerox copy of Date Of Birth Certificate
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none',borderTop:'none'}}>
                        <input type='checkbox'  defaultChecked={(studentData.photo && studentData.photo!=='')?true:false}/> Photograph of Student
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none',borderTop:'none'}}>
                        <input type='checkbox'  defaultChecked={(studentData.aadharc && studentData.aadharc!=='')?true:false}/> Xerox copy of Aadhar Card
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none',borderTop:'none'}}>
                        <input type='checkbox'  defaultChecked={(studentData.tcc && studentData.tcc!=='')?true:false}/> School Leaving Certificate / Transfer Certificate
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none',borderTop:'none'}}>
                        <input type='checkbox'  defaultChecked={(studentData.mark_sheetc && studentData.mark_sheetc!=='')?true:false}/> Passing Certificate / Mark Sheet
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderTop:'none'}}>
                        <input type='checkbox'  defaultChecked={(studentData.characterc && studentData.characterc!=='')?true:false}/> Charracter Certificate
                    </td>
                </tr>
            </tbody>
            <PrintFooter colSpan={5}/>
        </table>
    </div>
    </div>
  );
}
export default PrintStudent;