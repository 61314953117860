import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Button, Divider, TextField, Typography } from '@mui/material';
import APP_HTTP from '../../../APP_HTTP';
import AppDropDown from '../../AppDropDown';
import LoaderButton from '../../LoaderButton';
 
const HeadersMapping = (props) => {
    const [mappedHeaders,setMappedHeaders] = React.useState({});
    const [previewData,setPreviewData] = React.useState([]);
    const [is_preview,setIsPreview] = React.useState(false);
    const [is_previewing,setIsPreviewing] = React.useState(false);
    const [is_uploading,setIsUploading] = React.useState(false);
    const handleUploadData = () =>{
        setIsUploading(true);
        APP_HTTP.REQUEST('settings/process_import',{type:'student',data:previewData},true,true).then((response) => {
            setIsUploading(true);
            window.location.href ='/students';
        });
    }
    const handleChangeHeader = (id,value)=>{
       let mHead = {...mappedHeaders};
       let leftHeader = props.data.mapHeaders;
       let key = leftHeader[id].key;
       mHead[key] = value;
       setMappedHeaders(mHead);
    }
    const handlePreviewData = () =>{
        setIsPreviewing(true);
        const formData = new FormData();
        let file = props.data.import_file;
        formData.append('file', file);
        formData.append('mappedHeaders', JSON.stringify(mappedHeaders));
        APP_HTTP.REQUEST('settings/get_import_preview',formData,true,true,true).then((response) => {
            let resp = response.data.response;
            setPreviewData(resp);
            setIsPreview(true);
            setIsPreviewing(false);
        });
    }
    const handleChangeData = (idx,type,value) =>{
        let prevData = [...previewData];
        prevData[idx][type] = value;
        setPreviewData(prevData);
    }
  return (
    <>
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            {(is_preview===false) &&
                <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingLeft:'25px'}}>
                        <Typography variant='h5' sx={{marginTop: '8px'}}>Map Headers</Typography>
                        <Typography variant='p' sx={{marginTop: '8px'}}>Select the headers as per left side value, once selection process is done then proceed to upload the file, it will upload to record as per mapped headers</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingLeft:'25px'}}>
                        <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                            {(props.data.mapHeaders.map((value,index)=>{
                                return(
                                    <>
                                        <Grid item xs={6} sm={6} md={6} spacing={2}>
                                            <Typography variant='p'>{value.value}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} spacing={2}>
                                            <AppDropDown label="Select Header" className="header-dd" id={index} list_item={props.data.headers} size="small" defaultValue={'none'} handleChangeData={handleChangeHeader}/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} spacing={2}><Divider /></Grid>
                                    </>
                                )
                            }))}
                            <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                            {(is_previewing===false) &&
                                <Button variant="outlined" onClick={handlePreviewData}>Proceed to Preview</Button>
                            }
                                {(is_previewing===true) &&
                                    <LoaderButton variant="outlined"/>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {(is_preview===true) &&
                <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingLeft:'25px'}}>
                        <Typography variant='h5' sx={{marginTop: '8px'}}>Preview Data</Typography>
                        <Typography variant='p' sx={{marginTop: '8px'}}>Please preview data before carefully before uploading, whatever you see here it will upload as it is</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingLeft:'25px'}}>
                        <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                            {(previewData.map((value,index)=>{
                                return(
                                    <React.Fragment>
                                    {(value.pen!==undefined && value.pen!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="PEN"
                                                onChange={(e)=>handleChangeData(index,'pen',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.pen}
                                                />
                                        </Grid>
                                    }
                                    {(value.admission_number!==undefined && value.admission_number!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Admission Number"
                                                onChange={(e)=>handleChangeData(index,'admission_number',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.admission_number}
                                                />
                                        </Grid>
                                    }
                                    {(value.student_sequence!==undefined && value.student_sequence!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Student Sequence"
                                                onChange={(e)=>handleChangeData(index,'student_sequence',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.student_sequence}
                                                />
                                        </Grid>
                                    }
                                    {(value.student_name!==undefined && value.student_name!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Student Name"
                                                onChange={(e)=>handleChangeData(index,'student_name',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.student_name}
                                                />
                                        </Grid>
                                    }
                                    {(value.roll_number!==undefined && value.roll_number!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Roll Number"
                                                onChange={(e)=>handleChangeData(index,'roll_number',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.roll_number}
                                                />
                                        </Grid>
                                    }
                                    {(value.gender!==undefined && value.gender!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Gender"
                                                onChange={(e)=>handleChangeData(index,'gender',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.gender}
                                            />
                                        </Grid>
                                    }
                                    {(value.class!==undefined && value.class!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Class"
                                                onChange={(e)=>handleChangeData(index,'class',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.class}
                                            />
                                        </Grid>
                                    }
                                    {(value.section!==undefined && value.section!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Section"
                                                onChange={(e)=>handleChangeData(index,'section',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.section}
                                            />
                                        </Grid>
                                    }
                                    {(value.dob!==undefined && value.dob!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="DOB"
                                                onChange={(e)=>handleChangeData(index,'dob',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.dob}
                                            />
                                        </Grid>
                                    }
                                    {(value.father_name!==undefined && value.father_name!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Father Name"
                                                onChange={(e)=>handleChangeData(index,'father_name',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.father_name}
                                            />
                                        </Grid>
                                    }
                                    {(value.mother_name!==undefined && value.mother_name!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Mother Name"
                                                onChange={(e)=>handleChangeData(index,'mother_name',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.mother_name}
                                            />
                                        </Grid>
                                    }
                                    {(value.mobile_number!==undefined && value.mobile_number!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Mobile Number"
                                                onChange={(e)=>handleChangeData(index,'mobile_number',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.mobile_number}
                                            />
                                        </Grid>
                                    }
                                    {(value.alt_mobile_number!==undefined && value.alt_mobile_number!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Alt Mobile Number"
                                                onChange={(e)=>handleChangeData(index,'alt_mobile_number',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.alt_mobile_number}
                                            />
                                        </Grid>
                                    }
                                    {(value.aadhar_number!==undefined && value.aadhar_number!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Aadhar Number"
                                                onChange={(e)=>handleChangeData(index,'aadhar_number',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.aadhar_number}
                                            />
                                        </Grid>
                                    }
                                    {(value.blood_group!==undefined && value.blood_group!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Blood Group"
                                                onChange={(e)=>handleChangeData(index,'blood_group',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.blood_group}
                                            />
                                        </Grid>
                                    }
                                    {(value.email!==undefined && value.email!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Email"
                                                onChange={(e)=>handleChangeData(index,'email',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.email}
                                            />
                                        </Grid>
                                    }
                                    {(value.city!==undefined && value.city!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="City"
                                                onChange={(e)=>handleChangeData(index,'city',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.city}
                                            />
                                        </Grid>
                                    }
                                    {(value.state!==undefined && value.state!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="State"
                                                onChange={(e)=>handleChangeData(index,'state',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.state}
                                            />
                                        </Grid>
                                    }
                                    {(value.pincode!==undefined && value.pincode!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Pincode"
                                                onChange={(e)=>handleChangeData(index,'pincode',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.pincode}
                                            />
                                        </Grid>
                                    }
                                    {(value.locality!==undefined && value.locality!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Locality"
                                                onChange={(e)=>handleChangeData(index,'locality',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.locality}
                                            />
                                        </Grid>
                                    }
                                    {(value.admission_date!==undefined && value.admission_date!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Admission Date"
                                                onChange={(e)=>handleChangeData(index,'admission_date',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.admission_date}
                                            />
                                        </Grid>
                                    }
                                    {(value.fee!==undefined && value.fee!=="") &&
                                        <Grid item xs={12} sm={12} md={3} spacing={2}>
                                             <TextField
                                                label="Fee"
                                                onChange={(e)=>handleChangeData(index,'fee',e.target.value)}
                                                size="small"
                                                fullWidth
                                                value={value.fee}
                                            />
                                        </Grid>
                                    }
                                
                                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                                        <Divider/>
                                    </Grid>
                                    
                                    </React.Fragment>
                                )
                            }))}
                            <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                                {(is_uploading===false) &&
                                    <Button variant="outlined" onClick={handleUploadData}>Proceed to Upload</Button>
                                }
                                {(is_uploading===true) &&
                                    <LoaderButton variant="outlined"/>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Box>
    
    </>
  );
}

export default HeadersMapping;