import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let printDataInitialState = {
    
}

const PrintPettyCash = (props)=> {
    let [printData,setprintData] = React.useState(printDataInitialState);
    let total_transaction_amount = 0;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            setTimeout(() => {
                handlePrintPettyCash();
            }, 100);
        }
       
    },[props])
   
   const handlePrintPettyCash = ()=>{
    if(document.getElementById("PrintPettyCash")){
        var divContents = document.getElementById("PrintPettyCash").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:16px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:5px;vertical-align:top;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
          a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
          a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    
    <div id="PrintPettyCash" style={{display:'none'}}>
      <div style={{position:'relative'}}>
      <Watermark />
      
        <table>
            <PrintHeader header_data={props.cash_data.school_info} colSpan={6}/>
            <tbody>
                <tr>
                    <th colSpan={6} style={{textAlign:'center'}}>PETTY CASH REPORT</th>
                </tr>       
                <tr>
                <th>CONTACT NAME</th>
                <th>DATE</th>
                <th>PARTICULARS</th>
                <th>REFERENCE</th>
                <th>IN(+)</th>
                <th>OUT(-)</th>
                </tr>            
                {props.printData && props.printData.map((row,index)=>{
                    return (
                        <tr>
                            <td style={{textAlign:'left',whiteSpace: 'nowrap',textTransform:'uppercase'}}>{row.contact_name}</td>
                            <td style={{textAlign:'left',whiteSpace: 'nowrap'}}>{row.date_display}</td>
                            <td style={{textAlign:'left',whiteSpace: 'nowrap'}}>{row.particular}</td>
                            <td style={{textAlign:'left',whiteSpace: 'nowrap'}}>{row.reference}</td>
                            <td style={{color:'#06be06'}}><h5>{(row.entry_type==='in')?'₹'+AppUtils.currency_format(row.amount):'-'}</h5></td>
                            <td style={{color:'red'}}><h5>{(row.entry_type==='out')?'₹'+AppUtils.currency_format(row.amount):'-'}</h5></td>
                        </tr>
                    )
                })}
               
            </tbody>
        </table>
        <div style={{justifyContent:'center'}}>
        {(props.cash_data!==undefined && props.cash_data.in!==undefined) &&
        <>
          <h4 style={{textAlign:'left',color:'#333',fontSize: '24px',border: '1px solid #d7d7d7', padding: '3px',textAlign: 'center',borderRadius: '5px',marginTop:'20px'}}> 
            <p style={{margin:'0'}}>IN(+)</p>
            <p style={{margin:'0'}}>₹{AppUtils.currency_format(props.cash_data.in)}</p>
          </h4>
        </>
        }
        {(props.cash_data!==undefined && props.cash_data.out!==undefined) &&
        <>
        <h4 style={{textAlign:'left',color:'#333',fontSize: '24px',border: '1px solid #d7d7d7', padding: '5px',textAlign: 'center',borderRadius: '5px',marginTop:'20px'}}> 
          <p style={{margin:'0'}}>OUT(-)</p>
          <p style={{margin:'0'}}>₹{AppUtils.currency_format(props.cash_data.out)}</p>
        </h4>
        </>
        }
        {(props.cash_data!==undefined && props.cash_data.cash_in_counter!==undefined) &&
        <>
        <h4 style={{textAlign:'left',color:'#333',fontSize: '24px',border: '1px solid #d7d7d7', padding: '5px',textAlign: 'center',borderRadius: '5px',marginTop:'20px'}}> 
          <p style={{margin:'0'}}>CASH IN COUNTER</p>
          <p style={{margin:'0'}}>₹{AppUtils.currency_format(props.cash_data.cash_in_counter)}</p>
        </h4>
        </>
        }
        </div>
        </div>
    </div>
  );
}
export default PrintPettyCash;