import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AppDropDown from '../AppDropDown';
import BasicDatePicker from '../DatePicker';
import AppUtils from '../../AppUtils';
import PaymentTypes from '../settings/payment-types/PaymentTypes';
import StudentCard from '../students/StudentCard';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import { Print } from '@mui/icons-material';
import PrintPendingPayments from './PrintPendingPayments';
import PendingPaymentXls from './PendingPaymentXls';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function PendingPayments(props) {
    const state = useSelector((state) => state);
   const [pending_type,setPendingType] = React.useState('in');
   const [payment_type,setPaymentType] = React.useState('');
   const [forMonth,setForMonth] = React.useState(new Date());
   const [page,setPage] = React.useState(0);
   const [pending_report_list,setTransactionList] = React.useState([]);
   const [isGenerating,setIsGenerating] = React.useState(false);
   const [isLoadMore,setIsLoadMore] = React.useState(false);
   const [isFilter,setIsFilter] = React.useState(false);
   const [isAllRecordLoaded,setIsAllRecordLoaded] = React.useState(false);
   const [total_pending_amount,setTotalPendingAmount] = React.useState(0);
   const [printData,setPrintData] = React.useState({});
   const [is_print,setIsPrint] = React.useState(false);
   const [selected_class,setClass] = React.useState(0);
   const [selected_section,setSection] = React.useState(0);
   let [contact_info,setContactInfo] = React.useState([]);
   const [students,setStudentsList] = React.useState([]);
   const [month_list,setMonthList] = React.useState([{key:0,value:'Apr'},{key:1,value:'May'},{key:2,value:'Jun'},{key:3,value:'Jul'},{key:4,value:'Aug'},{key:5,value:'Sep'},{key:6,value:'Oct'},{key:7,value:'Nov'},{key:8,value:'Dec'},{key:9,value:'Jan'},{key:10,value:'Feb'},{key:11,value:'Mar'}]);
   let [demand_bill_generate_month,setDemandBillMonth] = React.useState('');
   const initialized = React.useRef(false);
   React.useEffect(()=>{
       if (!initialized.current) {
           initialized.current = true;
           //handleGetPendingPayments(1,'all');
           handleGetDemandBillGenerateMonth();
       }
   },[])
   const handleChangeDemandBillGenerateMonth = (id,value)=>{
    setDemandBillMonth(value);
  }
   const handleChangePendingType = (id,value)=>{
    setPendingType(value);
   }
   const handleChangePaymentType = (id,value)=>{
    setPaymentType(value);
   }
   const handleGetDemandBillGenerateMonth = () =>{
    APP_HTTP.REQUEST('settings/get_demand_bill_generate_month',{},true,true,false,true).then((response) => {
      let resp = response.data;
      let month_idx = resp.response;
      setDemandBillMonth(month_idx);
    });
}
  const handleChangeForMonth = (id,date) =>{
    setForMonth(date);
  }
  const handleChangeSection = (id,value) =>{
    setSection(value);
    APP_HTTP.REQUEST('student/search_student',{search:'',type:'autocomplete',class:selected_class,section:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setStudentsList(search_list);
    });
  }
  const handleChangeClass = (id,value) =>{
    setClass(value);
    setSection(0);
    APP_HTTP.REQUEST('student/search_student',{search:'',type:'autocomplete',class:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setStudentsList(search_list);
    });
  }
  const handleChangeInputValue = (value) =>{}
  const handleChangeValue = (value) =>{
    setContactInfo(value)
  }
  const handleResetList = () =>{
    setPage(0);
    let trans = [];
    setTransactionList(trans);
    setIsGenerating(false);
    setIsLoadMore(false);
    setIsAllRecordLoaded(false);
  }
  
  const handleGenerateReport = () =>{
    setIsFilter(true);
    setPage(0);
    setTransactionList([]);
    setIsGenerating(true);
    setIsAllRecordLoaded(true);
    handleGetPendingPayments(1,'filter');
  }
  const handleLoadMore = () =>{
    setIsLoadMore(true);
    let next_page = page+1;
    handleGetPendingPayments(next_page,'all');
  }
  const handleGetPendingPayments = (next_page,load_type)=>{
    let pendingReportList = [...pending_report_list];
    let data = {page:next_page,class:selected_class,section:selected_section,student:contact_info,demand_bill_generate_month:demand_bill_generate_month};
    setPage(next_page);
    APP_HTTP.REQUEST('reports/get_all_pending_payments',data,true,true,false,true).then((response) => {
      let trans_list = response.data.response;
      let student_count = response.data.student_count;
      let total_pending_amt = response.data.total_pending_amt;
      let school_info = response.data.school_info;
      setTotalPendingAmount(total_pending_amt);
      setPrintData(response.data);
      if(next_page===1){
        setIsGenerating(false);
      }else{
        setIsLoadMore(false);
      }
      
      if(load_type==='filter'){
        setTransactionList(trans_list);
      }else{
        for (let index = 0; index < trans_list.length; index++) {
          const element = trans_list[index];
          pendingReportList.push(element);
        }
        if(trans_list.length>0){
          setTransactionList(pendingReportList);
        }
      }
      if(student_count===0){
        setIsAllRecordLoaded(true);
      }
   });
  }
  const handlePrintList = () =>{
    setIsPrint(true);
    setTimeout(() => {
      setIsPrint(false);
    }, 1500);
  }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>PENDING PAYMENTS REPORT</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Here, we can see the pending payments report as per applied filter.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>
                </Grid>
                {(selected_class>0) &&
                    <Grid item xs={12} sm={12} md={2}>
                    <Sections show="dropdown" handleChangeData={handleChangeSection}  defaultValue={selected_section}/>
                    </Grid>
                }
                {(selected_class>0) &&
                  <>
                  <Grid item xs={12} sm={12} md={2}>
                      <Autocomplete
                          value={contact_info}
                          noOptionsText={'No Student Found ! Type To Search'}
                          onChange={(event, newValue) => handleChangeValue(newValue)}
                          onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                          disablePortal
                          id="student_dropdown"
                          options={students}
                          size="small"
                          renderInput={(params) => <TextField {...params} label={'Select Student'} />}
                      />
                  </Grid>
                  {(demand_bill_generate_month!=='') &&
                    <Grid item xs={12} sm={12} md={2}>
                      <AppDropDown label="Generate Pending Payment Till Month" list_item={month_list} size="small" handleChangeData={handleChangeDemandBillGenerateMonth} defaultValue={demand_bill_generate_month} id={'demand_bill_generate_month'}/>
                    </Grid>
                    }
                  </>
                }
                {(selected_class>0) &&
                  <Grid item xs={12} sm={12} md={2}>
                    {(isGenerating===false) &&
                      <Button variant='outlined' size='medium' onClick={handleGenerateReport}>GENERATE</Button>
                    }
                    {(isGenerating===true) &&
                      <LoaderButton label="Generating..."  variant='outlined' size='medium'/>
                    }
                  </Grid>
                }
                {(isFilter===true) &&
                      <Grid item xs={12} sm={12} md={2} style={{textAlign:'right'}}>
                          <Button variant='outlined' size='medium' onClick={handlePrintList} style={{marginBottom:'10px'}}><Print></Print> Print</Button>
                          {(pending_report_list.length>0) &&
                            <PendingPaymentXls  printData={printData}/>
                          }
                      </Grid>
                    }
               
               {/*  <Grid item xs={12} sm={12} md={3} spacing={2}>
                    <AppDropDown label="Pending Type" list_item={[{key:'in',value:'PAYMENT IN(+)'},{key:'out',value:'PAYMENT OUT(-)'}]} size="small" handleChangeData={handleChangePendingType} defaultValue={pending_type}/>
                </Grid> */}
               {/*  <Grid item xs={12} sm={12} md={3} spacing={2}>
                    <PaymentTypes show="dropdown" handleChangeData={handleChangePaymentType}/>
                </Grid> */}
            {/* 
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="For The Month" size="small" handleChangeData={handleChangeForMonth} id="month_date" defaultValue={forMonth} views={['year','month']} format='MMM YYYY'/>
                </Grid> */}
               {/*  <>
                {(AppUtils.has_access(state,'16_pending_payments') || AppUtils.has_access(state,'16_pending_payments_view') || AppUtils.has_access(state,'16_pending_payments_print')) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    {(isGenerating===false && pending_report_list.length===0) &&
                      <Button variant='outlined' size='medium' onClick={handleGenerateReport}>GENERATE</Button>
                    }
                    {(isGenerating===true) &&
                      <LoaderButton label="Generating..."  variant='outlined' size='medium'/>
                    }
                    {(isGenerating===false && pending_report_list.length>0) &&
                      <Button variant='outlined' size='medium' onClick={handleResetList}>RESET LIST</Button>
                    }
                </Grid>
              }
                </> */}
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
               
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    {(isFilter===true) &&
                      <>
                          <Typography variant='h4' sx={{fontSize:'20px',textAlign:'right'}}>
                            Total Pending : ₹{AppUtils.currency_format(total_pending_amount)}
                          </Typography>
                      </>
                    }
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>CONTACT NAME</StyledTableCell>
                            <StyledTableCell>PENDING FEE</StyledTableCell>
                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pending_report_list && pending_report_list.map((row,index) => (
                            <>
                            <StyledTableRow style={{background:'#fff'}}>
                              
                              <StyledTableCell component="th" scope="row">
                              <StudentCard info={row.student_info} is_photo={true}/>
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Typography variant='h4'>
                                ₹{AppUtils.currency_format(row.total_pending_amount)}
                                </Typography>
                              </StyledTableCell>
                             
                            </StyledTableRow>
                            <StyledTableRow>
                            
                            <StyledTableCell>
                              <TableContainer component={Paper}>
                                  <Table aria-label="customized table">
                                      <TableBody>
                                      {row.pending_payment_list && row.pending_payment_list.map((pen_list,idx) => (
                                          <>
                                          {(pen_list.fee_duration==='yearly') &&
                                          <>
                                          {(pen_list.fee_structure.total>0) &&
                                            <StyledTableRow key={'fitem-'+idx}  style={{background:'#fff'}}>
                                                <StyledTableCell sx={{textTransform:'uppercase'}}>
                                                  <Typography variant='h6'>{pen_list.name}({pen_list.link_month})</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell sx={{textTransform:'uppercase'}}><Typography variant='h6'> ₹{AppUtils.currency_format(pen_list.fee_structure.total)}</Typography></StyledTableCell>
                                            </StyledTableRow>
                                          }
                                          </>
                                          }
                                          {(pen_list.fee_duration==='monthly') &&
                                            <>
                                            {(pen_list.fee_structure.months.map((mvalue,midx)=>{
                                              return(
                                                <>
                                                 {(mvalue.fee>0) &&
                                                  <StyledTableRow key={'fmitem-'+midx} style={{background:'#fff'}}>
                                                    <StyledTableCell sx={{textTransform:'uppercase'}}><Typography variant='h6'>{pen_list.name}({mvalue.month})</Typography></StyledTableCell>
                                                    <StyledTableCell sx={{textTransform:'uppercase'}}><Typography variant='h6'> ₹{AppUtils.currency_format(mvalue.fee)}</Typography></StyledTableCell>
                                                  </StyledTableRow>
                                                }
                                                </>
                                              )
                                            }))}
                                            </>
                                           
                                          }
                                          </>
                                      ))}
                                      
                                      </TableBody>
                                  </Table>
                                </TableContainer>
                              </StyledTableCell>
                              <StyledTableCell></StyledTableCell>
                            </StyledTableRow>
                            </>
                          ))}
                          {(pending_report_list.length===0 && isGenerating===false) &&
                           <StyledTableRow>
                            <StyledTableCell component="th" scope="row" sx={{textAlign:'center'}} colSpan={2}>
                            Please apply fitler to see the report
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                          {(isLoadMore===true) &&
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={2}>
                            <Stack spacing={1}>
                                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                  </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                           {(isGenerating===true) &&
                            <>
                                {Array.from(Array(5), (e, i) => {
                                    return(
                                      <StyledTableRow>
                                      <StyledTableCell component="th" scope="row" colSpan={2}>
                                      <Stack spacing={1}>
                                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                            </Stack>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    )
                                    })}
                                
                            </>
                            }
                          {(isAllRecordLoaded===false && pending_report_list.length>0) &&
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row" sx={{textAlign:'center'}} colSpan={4}>
                            {(isLoadMore===false) &&
                              <Button variant='outlined' size='small' onClick={handleLoadMore}>Load More</Button>
                            }
                            {(isLoadMore===true) &&
                              <LoaderButton label="Loading..."  variant='outlined' size='small'/>
                            }
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                   
                </Grid>
               
            </Grid>
        </Box>
        {(is_print===true) &&
          <PrintPendingPayments printData={printData}/>
        }
    </Box>
  );
}
export default PendingPayments;