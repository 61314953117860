import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useSelector, useDispatch } from "react-redux";

import PrintHeader from '../PrintHeader';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let printDataInitialState = {
    
}

const PrintStudentList = (props)=> {
    const state = useSelector((state) => state);
    const is_college = state.school_info.school_info.is_college;
    let [printData,setprintData] = React.useState(printDataInitialState);
    let total_transaction_amount = 0;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            setTimeout(() => {
                handlePrintStudentList();
            }, 100);
        }
       
    },[props])
   
   const handlePrintStudentList = ()=>{
    if(document.getElementById("printStudentList")){
        var divContents = document.getElementById("printStudentList").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:16px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:5px;vertical-align:top;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
            a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    
    <div id="printStudentList" style={{display:'none'}}>
        <div style={{position:'relative'}}>
        {/* <Watermark /> */}
        <table>
            <PrintHeader header_data={props.printData[0].school_info} colSpan={5}/>
            <tbody>
                <tr>
                    <th colSpan={5} style={{textAlign:'center'}}>STUDENT LIST</th>
                </tr>    
                {(props.is_transport_search===true) &&   
                <tr>
                    <th colSpan={5} style={{textAlign:'center'}}>{props.printData[0].transport_name}</th>
                </tr>  
                }     
                <tr>
                    <th style={{textAlign:'center'}}>NAME</th>
                    <th style={{textAlign:'center'}}>FATHER NAME</th>
                    <th style={{textAlign:'center'}}>CONTACT NUMBER</th>
                    <th style={{textAlign:'center'}}>CLASS</th>
                    <th style={{textAlign:'center'}}>ADDRESS</th>
                </tr>            
                {props.printData && props.printData.map((row,index)=>{
                    return (
                        <tr key={'stli'+index}>
                            <td style={{textAlign:'center',textTransform:'uppercase',whiteSpace:'nowrap'}}>
                                <p>{row.student_name}</p>
                            </td>
                            <td style={{textAlign:'center',whiteSpace: 'nowrap',textTransform:'uppercase'}}>
                                <p>{row.father_name}</p>
                            </td>
                            <td style={{textAlign:'center'}}>
                                {(row.mobile_number!=="") &&
                                    <p>{row.mobile_number}</p>
                                } 
                                {(row.alt_mobile_number!=="") &&
                                    <p>{row.alt_mobile_number}</p>
                                } 
                            </td>
                            <td style={{textAlign:'center',whiteSpace:'nowrap'}}>
                            
                                {row.class} ({row.section})
                                {(is_college===true) &&
                                    <> | {row.academic_session}</>
                                }
                            
                            </td>
                            <td style={{textAlign:'center',textTransform:'uppercase'}}>
                                {(row.locality!=="") &&
                                    <span>{row.locality}</span>
                                } 
                                {(row.city!=="" && row.locality!=="") &&
                                    <span>, </span>
                                } 
                                {(row.city!=="") &&
                                    <span>{row.city}</span>
                                } 
                                {(row.city!=="" && row.state!=="") &&
                                    <span>, </span>
                                } 
                                {(row.state!=="") &&
                                    <span>{row.state}</span>
                                } 
                                {(row.state!=="" && row.pincode!=="") &&
                                    <span>, </span>
                                } 
                                    {(row.pincode!=="") &&
                                    <span>{row.pincode}</span>
                                }
                            </td>
                            
                        </tr>
                    )
                })}
            </tbody>
        </table>

    </div>
    </div>
  );
}
export default PrintStudentList;