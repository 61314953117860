import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let paymentDataInitialState = {
    
}

const PrintCertificate = (props)=> {
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            setTimeout(() => {
                handlePrint();
            }, 100);
        }
       
    },[props])
   
   const handlePrint = ()=>{
    if(document.getElementById("printCertificate")){
        var divContents = document.getElementById("printCertificate").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
        
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:16px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="printCertificate" style={{display:'none'}}>
        <div style={{height:'100%', width:'100%',position:'relative',display:'flex',justifyContent:'center'}}>
                        <img src={'/bg'+props.printData.template_id+'.png'} alt="image" style={{height:'100%',width:'100%'}}/>
                        {(parseInt(props.printData.template_id)===1) &&
                            <>
                            <img src={props.school_info.info.photo} alt="image" height={90} style={{position:'absolute',top:'80px'}}/>
                             <h4 style={{position:'absolute', top:'170px',color:'#1a5a84',fontSize:'28px'}}>{props.school_info.info.school_name}</h4>
                                <h4 style={{position:'absolute', top:'240px',color:'#fac941',fontWeight:'bold',fontSize:'40px'}}>{props.printData.template_data.title}</h4>
                                <h6 style={{position:'absolute', top:'200px',color:'#1a5a84',fontStyle:'italic',fontSize:'34px'}}>{props.printData.template_data.subtitle}</h6>
                                <h3 style={{position:'absolute', top:'260px',color:'#fac941',fontSize:'50px'}}><u>{AppUtils.stylishTextConverter(props.contact_info.name)}</u></h3>
                                <p style={{position:'absolute', top:'350px',color:'#1a5a84',paddingLeft:'110px',paddingRight:'110px',textAlign:'justify',fontSize:'32px'}}>{props.printData.template_data.desc1}</p>
                                <p style={{position:'absolute', bottom:'150px',color:'#1a5a84',paddingLeft:'110px',paddingRight:'110px',textAlign:'justify',fontSize:'26px',right:'40px'}}>
                                    {AppUtils.stylishTextConverter(props.printData.template_data.signature)}</p>

                            </>
                        }
                        {(parseInt(props.printData.template_id)===2) &&
                            <>
                            <img src={props.school_info.info.photo} alt="image" height={90} style={{position:'absolute',top:'12px'}}/>
                             <h4 style={{position:'absolute', top:'108px',color:'#1b3a4f',fontSize:'25px'}}>{props.school_info.info.school_name}</h4>
                                <h4 style={{position:'absolute', top:'145px',color:'#e8ba24',fontWeight:'bold',fontSize:'45px'}}>{props.printData.template_data.title}</h4>
                                <h6 style={{position:'absolute', top:'130px',color:'#1b3a4f',fontStyle:'italic',fontSize:'34px'}}>{props.printData.template_data.subtitle}</h6>
                                <h3 style={{position:'absolute', top:'210px',color:'#e8ba24',fontSize:'50px'}}><u>{AppUtils.stylishTextConverter(props.contact_info.name)}</u></h3>
                                <p style={{position:'absolute', top:'315px',color:'#1b3a4f',paddingLeft:'110px',paddingRight:'110px',textAlign:'justify',fontSize:'32px'}}>{props.printData.template_data.desc1}</p>
                                <p style={{position:'absolute', bottom:'90px',color:'#1b3a4f',paddingLeft:'110px',paddingRight:'110px',textAlign:'justify',fontSize:'26px',right:'110px'}}>
                                    {AppUtils.stylishTextConverter(props.printData.template_data.signature)}</p>
                            </>
                        }
                        {(parseInt(props.printData.template_id)===3) &&
                            <>
                                <img src={props.school_info.info.photo} alt="image" height={100} style={{position:'absolute',top:'50px'}}/>
                                <h4 style={{position:'absolute', top:'148px',color:'#98d7fb',fontSize:'35px'}}>{props.school_info.info.school_name}</h4>
                                <h4 style={{position:'absolute', top:'194px',color:'#7dd956',fontWeight:'bold',fontSize:'45px'}}>{props.printData.template_data.title}</h4>
                                <h6 style={{position:'absolute', top:'169px',color:'#ff65c5',fontStyle:'italic',fontSize:'34px'}}>{props.printData.template_data.subtitle}</h6>
                                <h3 style={{position:'absolute', top:'230px',color:'#fecd2e',fontSize:'50px'}}><u>{AppUtils.stylishTextConverter(props.contact_info.name)}</u></h3>
                                <p style={{position:'absolute', top:'312px',color:'#1a5a84',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'32px'}}>{props.printData.template_data.desc1}</p>
                                <p style={{position:'absolute', bottom:'150px',color:'#1b3a4f',paddingLeft:'75px',paddingRight:'75px',textAlign:'justify',fontSize:'26px',right:'120px'}}>
                                    {AppUtils.stylishTextConverter(props.printData.template_data.signature)}</p>
                            </>
                        }
                        {(parseInt(props.printData.template_id)===4) &&
                            <>
                            <img src={props.school_info.info.photo} alt="image" height={90} style={{position:'absolute',top:'63px'}}/>
                            <h4 style={{position:'absolute', top:'149px',color:'#1a5a84',fontSize:'28px'}}>{props.school_info.info.school_name}</h4>
                                <h4 style={{position:'absolute', top:'176px',color:'#fac941',fontWeight:'bold',fontSize:'40px'}}>{props.printData.template_data.title}</h4>
                                <h6 style={{position:'absolute', top:'154px',color:'#1a5a84',fontStyle:'italic',fontSize:'30px'}}>{props.printData.template_data.subtitle}</h6>
                                <h3 style={{position:'absolute', top:'228px',color:'#1a5a84',fontSize:'50px'}}><u>{AppUtils.stylishTextConverter(props.contact_info.name)}</u></h3>
                                <p style={{position:'absolute', top:'319px',color:'#1a5a84',paddingLeft:'110px',paddingRight:'120px',textAlign:'justify',fontSize:'32px'}}>{props.printData.template_data.desc1}</p>
                                <p style={{position:'absolute', bottom:'140px',color:'#1a5a84',paddingLeft:'110px',paddingRight:'110px',textAlign:'justify',fontSize:'26px',right:'80px'}}>
                                    {AppUtils.stylishTextConverter(props.printData.template_data.signature)}</p>

                            </>
                        }
                        {(parseInt(props.printData.template_id)===5) &&
                            <>
                            <img src={props.school_info.info.photo} alt="image" height={90} style={{position:'absolute',top:'12px'}}/>
                                <h4 style={{position:'absolute', top:'97px',color:'#fff',fontSize:'35px'}}>{props.school_info.info.school_name}</h4>
                                <h4 style={{position:'absolute', top:'145px',color:'#fff',fontWeight:'bold',fontSize:'45px'}}>{props.printData.template_data.title}</h4>
                                <h6 style={{position:'absolute', top:'130px',color:'yellow',fontStyle:'italic',fontSize:'34px'}}>{props.printData.template_data.subtitle}</h6>
                                <h3 style={{position:'absolute', top:'210px',color:'#fff',fontSize:'50px'}}><u>{AppUtils.stylishTextConverter(props.contact_info.name)}</u></h3>
                                <p style={{position:'absolute', top:'315px',color:'#fff',paddingLeft:'110px',paddingRight:'110px',textAlign:'justify',fontSize:'32px'}}>{props.printData.template_data.desc1}</p>
                                <p style={{position:'absolute', bottom:'120px',color:'#fff',paddingLeft:'110px',paddingRight:'110px',textAlign:'justify',fontSize:'26px',right:'70px'}}>
                                    {AppUtils.stylishTextConverter(props.printData.template_data.signature)}</p>
                            </>
                        }
        </div>
    </div>
  );
}
export default PrintCertificate;