import { Box } from '@mui/material';
import * as React from 'react';
import APP_HTTP from '../APP_HTTP';
const Watermark = (props)=> {
  const initialized = React.useRef(false);
  const [water_mark,setWatermark] = React.useState('');
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetWatermark();
        }
    },[])
    const handleGetWatermark = () =>{
      let watermark = localStorage.getItem('watermark');
      if(watermark!==null && watermark!==""){
        setWatermark(watermark);
      }
      /* else{
        APP_HTTP.REQUEST('settings/get_option',{name:'water_mark'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value && resp.value.water_mark){
                setWatermark(resp.value.water_mark);
                localStorage.setItem('watermark',resp.value.water_mark)
            }
        });
      } */
    }
  return (
    <>
    {(water_mark!==null && water_mark!=='') &&
    <Box 
    style={{position: 'absolute',
      inset: '0px', opacity: '0.25'}}>
      <Box style={{ 
        backgroundImage: `url(${water_mark})`,height:'300px',width:'300px',backgroundRepeat: 'no-repeat',backgroundSize:'contain',position: 'absolute',left: '0', right: '0',top:'0',bottom:'0',margin: 'auto', opacity: '0.25'}}></Box>
    </Box>
    }
    </>
  );
}

export default Watermark;