import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Divider, InputLabel, MenuItem, NativeSelect, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import LoaderButton from '../../LoaderButton.jsx';


const MessagesList = (props) => {
    const [is_loading,setLoading] = React.useState(true);
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    const [messages_list,handleSetMessagesList] = React.useState([]);

    const [is_saving,setSaving] = React.useState(false);

    const state = useSelector((state) => state);
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            setLoading(true);
            initialized.current = true;
            APP_HTTP.REQUEST('settings/get_messages_list',{},true,true).then((response) => {
                let resp = response.data;
                let msg_list = resp.response;
                setLoading(false);
                //if(msg_list.length>0){
                    handleSetMessagesList(msg_list);
                //}
            });
        }
    
    },[dispatch])
   
    const handleChangeMessage = (value,index) =>{
        let msgs = [...messages_list];
        msgs[index].message = value;
        handleSetMessagesList(msgs);
    }
    const handleSaveMessages = () =>{
        setSaving(true);
        APP_HTTP.REQUEST('settings/save_messages_list',{messages_list:messages_list},true,true).then((response) => {
            let resp = response.data;
            setSaving(false);
        });
    }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Messages
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <Divider />
                    <List>
                        {(messages_list && messages_list.map((value,index)=>{
                            return (
                                <>
                                <ListItem key={value.name} disablePadding>
                                    <ListItemButton>
                                        <ListItemText>
                                        <TextField
                                            value={value.message}
                                            margin="dense"
                                            label={(value.label)}
                                            id={value.type}
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e)=>handleChangeMessage(e.target.value,index)}
                                        />
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                            </>
                            )
                        }))}
                        {(messages_list.length>0) &&
                            <ListItem key={"Save"} disablePadding>
                                <ListItemButton style={{textAlign:'right'}}>
                                    <ListItemText>
                                        {(is_saving===false) &&
                                        <Button onClick={handleSaveMessages} variant='outlined' size='medium'>Save Message</Button>
                                        }
                                        {(is_saving===true) &&
                                            <LoaderButton variant='outlined' size='medium'/>
                                        }
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem> 
                        }
                        {(is_loading===false && messages_list.length===0) &&
                             <ListItem key={"No Record Found"} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"No Record Found"} />
                                </ListItemButton>
                            </ListItem>
                        }
                        {(is_loading===true && messages_list.length===0) &&
                             <ListItem key={"Loading..."} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Loading..."} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                </Grid>  
            </Grid>
        </Box>
    </>
  );
}

export default MessagesList;