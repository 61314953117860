import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, InputLabel, MenuItem, NativeSelect, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import LoaderButton from '../../LoaderButton.jsx';

let id_card_templates = [
    {'template_name':'Front Back Template 1','front':'1-front.jpg','back':'1-back.jpg','is_landscape':false},
    {'template_name':'Front Back Template 2','front':'2-front.jpg','back':'2-back.jpg','is_landscape':false},
    {'template_name':'Template 3','front':'3-front.jpg','is_landscape':false},
    /* {'template_name':'Portrate Template 4','front':'4-front.jpg','is_landscape':true} */
]
const GenearateIDCard = (props) => {
    
    const state = useSelector((state) => state);
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
        }
    },[dispatch])
   const handleChooseTemplate = () =>{
    
   }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        GENERATE ID CARD
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid> 
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            
                            <Typography variant='h4'>Select a template to genarate ID Card</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                                {(id_card_templates.map((value,index)=>{
                                    return(
                                        <Grid item xs={12} sm={12} md={6} spacing={2}>
                                        <Box style={{display:'flex',border:'1px solid #d7d7d7',padding:'5px',justifyContent:'space-around'}}>
                                          <img src={'/id-card-template/'+value.front} alt="image" height={'324px'} width={(value.is_landscape===false)?'204px':'324px'}/>
                                          {(value.back!==undefined) &&
                                           <img src={'/id-card-template/'+value.back} alt="image" height={'324px'} width={(value.is_landscape===false)?'204px':'324px'}/>
                                        }
                                        </Box>
                                        <Box style={{border:'1px solid #d7d7d7',padding:'5px'}}>
                                            <Typography style={{textAlign:'center'}}>{value.template_name}</Typography>
                                        </Box>
                                        <Box style={{border:'1px solid #d7d7d7',padding:'5px'}}>
                                            <Button variant='outlined' size='small' style={{width:'100%'}} onClick={()=>handleChooseTemplate(index)}>Choose This Template</Button>
                                        </Box>
                                        </Grid>
                                    )
                                }))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </>
  );
}
export default GenearateIDCard;