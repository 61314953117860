import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { Divider, Modal, TextField, Typography } from '@mui/material';
import Textarea from '@mui/joy/Textarea';
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';

  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'300px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px',
  };

const AdminAPK = (props)=> {
  const [apk_data,setData] = React.useState({'url':'',version:''});
  const handleSaveAPK = () =>{
    APP_HTTP.REQUEST('school/admin/save_apk',apk_data,true,false,false,false,true).then((response) => {
        
    });
  }
  const handleChangeValue = (key,value) =>{
    let adata = {...apk_data};
    adata[key] = value;
    setData(adata);
  }
  
  return (
    <div id="query-builder">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            <Grid item xs={12}> 
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>Apps</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="mrtop-15">
                        <Grid container spacing={2} className="mrtop-10">
                            <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                                <TextField
                                    margin="dense"
                                    label="APP URL"
                                    type="text"
                                    fullWidth
                                    size="small"
                                    onChange={(e)=>handleChangeValue('url',e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                                <TextField
                                    margin="dense"
                                    label="APP VERSION"
                                    type="text"
                                    fullWidth
                                    size="small"
                                    onChange={(e)=>handleChangeValue('version',e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} spacing={2} className="mrtop-30 footer-btn-blk">
            <Grid xs={12} sx={{textAlign:'right'}}>
              <Button variant="contained" onClick={() => handleSaveAPK()}>Save APK</Button>
            </Grid>
        </Grid>
        </Box>
    </div>
  );
}
export default AdminAPK;