import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Avatar, Button, Divider, InputLabel, MenuItem, NativeSelect, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import LoaderButton from '../../LoaderButton.jsx';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const ClassTeacherMapping = (props) => {
    const [is_loading,setLoading] = React.useState(true);
    const [mapping_list,handleSetClassTeacherMapping] = React.useState([]);
    const [class_list,handleSetClassList] = React.useState([]);
    const [section_list,handleSetSectionList] = React.useState([]);

    const [is_saving,setSaving] = React.useState(false);
    const state = useSelector((state) => state);
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            setLoading(true);
            initialized.current = true;
            APP_HTTP.REQUEST('settings/get_class_teacher_list',{},true,true).then((response) => {
                let resp = response.data;
                let tchr_list = resp.teachers;
                let cls_list = resp.classes;
                let sec_list = resp.sections;
               
                setLoading(false);
                handleSetClassTeacherMapping(tchr_list);
                handleSetClassList(cls_list);
                handleSetSectionList(sec_list);
            });
        }
    
    },[dispatch])
   
 
    const handleSaveMapClass = () =>{
        setSaving(true);
        APP_HTTP.REQUEST('settings/save_class_teacher_mapping',{mapping_list:mapping_list},true,true).then((response) => {
            let resp = response.data;
            setSaving(false);
        });
    }
    const handleChangeClass = (id,value) =>{
        let mcls = [...mapping_list];
        mcls[id].mapped_class.class_id = value;
        handleSetClassTeacherMapping(mcls);
    }
    const handleChangeSection = (id,value) =>{
        let mcls = [...mapping_list];
        mcls[id].mapped_class.section_id = value;
        handleSetClassTeacherMapping(mcls);
    }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Class Teacher Mapping
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <Divider />
                <TableContainer component={Paper}>
                  <Table  aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>TEACHER</StyledTableCell>
                        <StyledTableCell>CLASS</StyledTableCell>
                        <StyledTableCell>SECTION</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                       {mapping_list.map((row,index) => (
                        <StyledTableRow key={row.staff_sequence}>
                          <StyledTableCell>
                            <Box style={{display:'flex',alignItems:'center'}}>
                                <Avatar
                                    alt={row.staff_name}
                                    src={row.photo}
                                />
                                <Typography style={{marginLeft:'5px'}}>{row.staff_sequence} | {row.staff_name}</Typography>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {(class_list.length>0) &&
                                <AppDropDown label={"Select Class"} id={index}  defaultValue={row.mapped_class.class_id} list_item={class_list} size="small" handleChangeData={handleChangeClass}/>
                            }
                          </StyledTableCell>
                        
                          <StyledTableCell component="th" scope="row">
                            {(section_list.length>0) &&
                                <AppDropDown label={"Select Section"} id={index}  defaultValue={row.mapped_class.section_id} list_item={section_list} size="small" handleChangeData={handleChangeSection}/>
                            }
                          </StyledTableCell>
                        
                          
                        </StyledTableRow>
                      ))}
                      {(is_loading===true) &&
                      <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                      Please wait while loading the list...
                      </StyledTableCell>
                      }   
                      {(is_loading===false && mapping_list.length===0) &&
                      <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                        Oops ! No Record(s) Found.
                      </StyledTableCell>
                      }   
                      {(is_loading===false && mapping_list.length>0) &&
                      <StyledTableCell component="th" scope="row" colSpan={3} sx={{textAlign:'right'}}>
                        {(is_saving===false) &&
                            <Button variant='outlined' size='medium' onClick={handleSaveMapClass}>Save</Button>
                        }
                        {(is_saving===true) &&
                            <LoaderButton variant='outlined' size='medium' />
                        }
                      </StyledTableCell>
                      } 
                    </TableBody>
                  </Table>
                </TableContainer>
                </Grid>  
            </Grid>
        </Box>
    </>
  );
}

export default ClassTeacherMapping;