import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { AppBar, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, NativeSelect, RadioGroup, Toolbar, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Transaction from './Transaction';
import Attendence from './Attendence';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const AttendanceDownloadPopup = (props)=> {
 const handleClose = ()=>{
    props.handleClose()
 }
  return (
    
    <Dialog open={true} fullWidth={true} fullScreen={true}>
        <AppBar position="fixed" component="nav">
          <Toolbar>
              <DialogTitle>Show Attendance Report</DialogTitle>
          </Toolbar>
        </AppBar>
      
        <DialogContent sx={{marginTop:'100px'}}>
            <Attendence type={props.type}/>
        </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} size='small' variant='outlined'>Close</Button>  
          </DialogActions>
       
      </Dialog>
      
  );
}
export default AttendanceDownloadPopup;