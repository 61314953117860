import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
import { useSelector } from 'react-redux';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let paymentDataInitialState = {
    
}

const PrintPaymentFormat2 = (props)=> {
    let [paper_size,setPaperSize] = React.useState('A4');
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [no_of_print_page,setNumberOfPage] = React.useState([1]);
    let [paymentData,setPaymentData] = React.useState(paymentDataInitialState);
    const state = useSelector((state) => state);
    const is_college = state.school_info.school_info.is_college;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(props.payment_data){
                let payment_data = props.payment_data;
                let number_of_copy = props.payment_data.number_of_copy;
                if(number_of_copy!==undefined){
                    number_of_copy = parseInt(number_of_copy);
                    let number_of_copy_arr = [];
                    for (let index = 0; index < number_of_copy; index++) {
                        number_of_copy_arr.push(index)
                    }
                    setNumberOfPage(number_of_copy_arr);
                }
                if(payment_data.is_db===undefined){
                    
                    setPaymentData(payment_data);
                    setCurrentSequence(payment_data.payment_sequence);
                    setTimeout(() => {
                        handlePrintPayment();
                    }, 100);
                    
                }else{
                    let is_demand_bill = false;
                    if(props.is_demand_bill!==undefined){
                        is_demand_bill = props.is_demand_bill;
                    }
                    APP_HTTP.REQUEST('payment/get_payment_on_id',{id:payment_data.payment_id,is_demand_bill:is_demand_bill},true,true).then((response) => {
                        let resp = response.data.response;
                        setPaymentData(resp);
                        setCurrentSequence(resp.payment_sequence);
                        setTimeout(() => {
                        handlePrintPayment();
                    }, 100);
                        //dispatch(handleSetPaymentInfo(resp));
                    });
                }
            }
            
        }
       
    },[props])
   
   const handlePrintPayment = ()=>{
    if(document.getElementById("paymentPrintBlock")){
        var divContents = document.getElementById("paymentPrintBlock").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
        if(props.payment_data.paper_size==='A5'){
            page_settings='@page{size: "A5"; width: 148mm;height: 210mm;margin: 0;}html {width: 148mm;height: 210mm;margin: 0;}body {width: 148mm;height: 210mm; margin: 0;}';
        }
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:16px;width:99%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:5px;vertical-align:top;border:none}.tbody td,.tbody th{padding:5px;vertical-align:top;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
          a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
          a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="paymentPrintBlock" style={{display:'none'}}>
        {(no_of_print_page.map(()=>{
            return(
                <div style={{position:'relative'}}>
                <Watermark/>
<table>
            {(paymentData.bill_message!==undefined && paymentData.bill_message!=='') &&
                <tr>
                    <th  colSpan={3} style={{textAlign:'center'}}>{paymentData.bill_message}</th>
                </tr>
            }
            <PrintHeader header_data={paymentData.school_info} more_data={paymentData} colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?5:3}  target="payment"/>
           
            <tr>
            <th colSpan={2} style={{textAlign:'left'}}>
                <p style={{fontSize:'14px',fontWeight:'bold'}}>Receipt No.      :   <span style={{fontWeight:'normal'}}>{current_sequence}</span></p>
            </th>
            <th colSpan={(props.is_yearly_package===true)?3:1} style={{textAlign:'right',borderLeft:'none'}}>
                <p style={{fontSize:'14px',fontWeight:'bold'}}>Receipt Date      :   <span style={{fontWeight:'normal'}}>{paymentData.payment_date_display}</span></p>
            </th>
            </tr>
            <tr>
                <th style={{textAlign:'left'}}>
                    <p style={{fontSize:'14px',fontWeight:'bold'}}>Student Name      :   <span style={{fontWeight:'normal'}}> {(paymentData.contact_info && paymentData.contact_info.roll_number)?paymentData.contact_info.roll_number+'/':''}</span><span style={{fontWeight:'normal'}}>{(paymentData.contact_info && paymentData.contact_info.family_group_lbl)?paymentData.contact_info.family_group_lbl:paymentData.contact_name}{(paymentData.contact_info && paymentData.contact_info.student_sequence)?'('+paymentData.contact_info.father_name+')':''}</span></p>
                </th>
                {(is_college!==true) &&
                <th colSpan={2} style={{textAlign:'right'}}>
                    {(paymentData.contact_info && paymentData.contact_info.roll_number) &&
                        <p style={{fontSize:'14px',fontWeight:'bold'}}>Class      :      <span style={{fontWeight:'normal'}}>{paymentData.contact_info.class}({paymentData.contact_info.section})</span></p>
                    }
                </th>
                }
                {(is_college===true) &&
                <th colSpan={2} style={{textAlign:'right'}}>
                    {(paymentData.contact_info && paymentData.contact_info.roll_number) &&
                        <p style={{fontSize:'14px',fontWeight:'bold'}}>Class      :<span style={{fontWeight:'normal'}}>{paymentData.contact_info.section}</span></p>
                    }
                </th>
                }
            </tr>
            {(is_college===true && paymentData.contact_info && paymentData.contact_info.roll_number) &&
            <tr>
                <th colSpan={2} style={{textAlign:'left'}}>
                {(paymentData.contact_info.roll_number) &&
                     <p style={{fontSize:'14px',fontWeight:'bold'}}>Program.      :<span style={{fontWeight:'normal'}}>{paymentData.contact_info.class}</span></p>
                }
                </th>
            </tr>
            }
            <tbody className='tbody'>
                           
                <tr>
                {/* <th style={{textAlign:'left'}} width='90'>SNo</th> */}
               
                <th style={{textAlign:'left'}}>Particulars</th>
                {(props.is_demand_bill===undefined || props.is_demand_bill===false) &&
                    <th style={{textAlign:'left'}}>REFERENCE</th>
                }
                {(props.is_yearly_package===true) &&
                    <>
                    <th style={{textAlign:'right'}}>Price</th>
                    <th style={{textAlign:'right'}}>Quantity</th>
                    </>
                }
                {(props.is_demand_bill===undefined || props.is_demand_bill===false) &&
                    <th style={{textAlign:'right'}}>Amount</th>
                }
                
                {(props.is_demand_bill!==undefined && props.is_demand_bill===true) &&
                    <th style={{textAlign:'right'}}>Fee</th>
                }
                </tr>            
                            {paymentData.list_item && paymentData.list_item.map((value,index)=>{
                            return (
                                <>
                                {(value.amount>0) &&
                                <tr>
                                {/*  <td style={{textAlign:'left'}} width='90'>
                                    <span>{index+1}</span>
                                    </td> */}
                                    {(props.is_yearly_package===true) &&
                                    <td style={{textAlign:'left',textTransform:'capitalize',whiteSpace: 'nowrap'}}>
                                    {value.particular_name}
                                    </td>
                                    }
                                    {(props.is_yearly_package===false || props.is_demand_bill===true) &&
                                    <td style={{textAlign:'left',textTransform:'capitalize',whiteSpace: 'nowrap'}}>
                                    {(value.is_uniform===true) &&
                                    <>{value.particulars_name}</>
                                    }
                                    {(value.is_uniform!==true) &&
                                    <>
                                    {(parseInt(value.particulars)===3) &&
                                        <>
                                        {(parseFloat(value.amount)>0)?value.particulars_name:'Advance Payment'}
                                        </>
                                    }
                                    {(parseInt(value.particulars)!==3) &&
                                    <>
                                    {value.particulars_name} ({value.fee_month})
                                    </>
                                    }
                                    </>
                                    }
                                    </td>
                                    }
                                    {(props.is_demand_bill===undefined || props.is_demand_bill===false) &&
                                    <td style={{textAlign:'left'}}>
                                        {(value.is_uniform===true) &&
                                    <>{value.reference_name}</>
                                    }
                                    {(value.is_uniform!==true) &&
                                    <>
                                        {value.reference}
                                    </>
                                    }
                                    </td>
                                    }
                                    {(props.is_yearly_package===true) &&
                                        <>
                                        <th style={{textAlign:'right'}}>{value.fee}</th>
                                        <th style={{textAlign:'right'}}>{value.quantity}</th>
                                        </>
                                    }
                                    <td style={{textAlign:'right'}}>₹{AppUtils.currency_format(value.amount)}</td>
                                </tr>
                                }
                                </>
                            )
                        })}
            </tbody>
            <PrintFooter colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?5:3} type="payment" info={paymentData.school_info} target="payment"/>
            <tfoot>
                <tr>
                    <td  style={{textAlign:'right'}} colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?5:3}>
                    {(paymentData.paid_amount) &&
                        <>
                        {(props.is_demand_bill!==true) &&
                            <>
                                <p style={{fontWeight:'bold',fontSize:'12px',margin:'0px',textTransform:'uppercase'}}>{AppUtils.number_to_words(paymentData.payable_amount)} Only</p>
                                <h4 style={{fontWeight:'bold',fontSize:'14px',marginTop:'5px'}}>Payable Amount : ₹{AppUtils.currency_format(paymentData.payable_amount)}</h4>
                                {(parseFloat(paymentData.advance_payment)>0) &&
                                <h4 style={{fontWeight:'bold',fontSize:'16px',marginTop:'10px'}}>Settled Advance : ₹{AppUtils.currency_format(paymentData.advance_payment)}</h4>
                                }
                                {(parseFloat(paymentData.discount)>0) &&
                                <h4 style={{fontWeight:'bold',fontSize:'14px',marginTop:'10px'}}>Discount : ₹{AppUtils.currency_format(paymentData.discount)}</h4>
                                }
                                {(parseFloat(paymentData.late_fee)>0) &&
                                <h4 style={{fontWeight:'bold',fontSize:'14px',marginTop:'10px'}}>Late Fine : ₹{AppUtils.currency_format(paymentData.late_fee)}</h4>
                                }
                                <h4 style={{fontWeight:'bold',fontSize:'14px',marginTop:'10px'}}>Paid : ₹{AppUtils.currency_format(paymentData.paid_amount)}({paymentData.payment_mode_data_text})</h4>
                                {(parseFloat(paymentData.fee_dues)>0) &&
                                <h4 style={{fontWeight:'bold',fontSize:'14px',marginTop:'10px'}}>Fee Dues : ₹{AppUtils.currency_format(paymentData.fee_dues)}</h4>
                                }
                                {(parseFloat(paymentData.fee_dues)<0) &&
                                <h4 style={{fontWeight:'bold',fontSize:'14px',marginTop:'10px'}}>Advance : ₹{AppUtils.currency_format(Math.abs(paymentData.fee_dues))}</h4>
                                }
                                
                            </>
                        }
                      
                        </>
                    }
                    </td>
                </tr>
               {/*  <tr>
                    <td colSpan={(props.is_demand_bill!==undefined && props.is_demand_bill===true)?3:(props.is_yearly_package===true)?6:4}>
                        Remarks : {(paymentData.remarks!=="")?paymentData.remarks:'-'}
                    </td>
                </tr> */}
                
            </tfoot>
        </table>
        </div>
            )
        }))}
        
    </div>
  );
}
export default PrintPaymentFormat2;