import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ExamTypes from './ExamTypes';
import MarksGrade from './MarksGrade';
import { useSelector, useDispatch } from "react-redux";
import AppUtils from '../../../AppUtils';
const Exam = (props) => {
  const state = useSelector((state) => state);
  return (
    <>
    {(AppUtils.has_access(state,'26_exam_type_view')) &&
        <ExamTypes show='list'/>
    }
    {(AppUtils.has_access(state,'27_grade_view') || AppUtils.has_access(state,'32_division_view')) &&
          <MarksGrade />
    }
    </>
  );
}
export default Exam;