import { OPEN_ADD_CLASS_ROOM,CREATE_CLASS_ROOM,UPDATE_CLASS_ROOM, DELETE_ITEM_CONFIRM, DELETE_CLASS_ROOM,SET_CLASS_ROOM_LIST } from "../../../actionTypes/actionTypes";

const handleOpenAddClassRoom = (is_open_add_class_room) =>{
  return {
    type: OPEN_ADD_CLASS_ROOM,
    state :{
      is_open_add_class_room : is_open_add_class_room,
    }
  };
}
const handleSetClassRoomList = (class_room_list) =>{
  return {
    type: SET_CLASS_ROOM_LIST,
    state :{
      class_room_list : class_room_list,
    }
  };
}

const handleSaveClassRoomToList = (class_room_list) =>{
  return {
    type: CREATE_CLASS_ROOM,
    state :{
      class_room_list : class_room_list,
    }
  };
}
const handleUpdateClassRoom = (update_id,name) =>{
  return {
    type: UPDATE_CLASS_ROOM,
    state :{
      update_id : update_id,
      name : name
    }
  };
}
const handleConfirmDeleteClassRoom = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteClassRoom = (index, delete_data) =>{
  return {
    type: DELETE_CLASS_ROOM,
    state :{
      id : index,
    }
  };
}

export { handleOpenAddClassRoom, handleSaveClassRoomToList,handleUpdateClassRoom,handleConfirmDeleteClassRoom,handleDeleteClassRoom,handleSetClassRoomList };