import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { handleConfirmDeleteHoliday, handleOpenAddHoliday, handleSetHolidayList } from './Action.jsx';
import CreateHoliday from './CreateHoliday.jsx';
import DeleteConfirm from '../../DeleteConfirm.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppUtils from '../../../AppUtils.jsx';


const HolidayList = (props) => {
    const [is_loading,setLoading] = React.useState(true);
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    const state = useSelector((state) => state);
    const is_open_add_holiday = state.is_open_add_holiday;
    const is_delete_item = state.is_delete_item;
    let holiday_list = state.holiday_list;
    if(holiday_list===undefined){
        holiday_list = [];
    }
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if(holiday_list.length===0){
            if (!initialized.current) {
                initialized.current = true;
                APP_HTTP.REQUEST('settings/get_holiday_list',{},true,true).then((response) => {
                    let resp = response.data;
                    let holiday_list = resp.response;
                    setLoading(false);
                    dispatch(handleSetHolidayList(holiday_list));
                });
            }
        }
    },[dispatch])
   
    const [holidayValue,setHolidayEdit] = React.useState([{id:0,name:'',date:new Date(),is_edit:true}]);
    
    const handleDeleteItem = (id)=> {
        let secValue = holiday_list[id];
        let holId = secValue.id;
    
        let delData = {id:holId,index:id,target:'holiday'};
        setDeleteData(delData);
        dispatch(handleConfirmDeleteHoliday(true));
    }
    const handleEditItem = (id)=>{
        let secValue = holiday_list[id];
        if(secValue.is_edit===undefined){
            secValue['is_edit'] = true;
        }else {
            secValue.is_edit = true;
        }
        if(secValue.id===undefined){
            secValue['update_id'] = id;
        }else{
            secValue.update_id = id;
        }
        setHolidayEdit([secValue]);
        dispatch(handleOpenAddHoliday(true));
    }
    const openAddHoliday = () =>{
        setHolidayEdit([{id:0,name:'',date:new Date(),is_edit:false}]);
        dispatch(handleOpenAddHoliday(true));
    }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Holiday
                        
                        <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='large' onClick={openAddHoliday}/>
                        
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <Divider />
                    <List>
                        {(holiday_list && holiday_list.map((value,index)=>{
                            return (
                                <>
                                <ListItem key={value.name} disablePadding>
                                    <ListItemButton>
                                        <ListItemText primary={value.name} />
                                        <ListItemText primary={value.date} sx={{textAlign:'right',marginRight:'10px'}}/>
                                        <Delete fontSize='small' onClick={() =>handleDeleteItem(index)}/>
                                        <Edit  fontSize='small' onClick={() =>handleEditItem(index)}/>
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                            </>
                            )
                        }))}
                        {(is_loading===false && holiday_list.length===0) &&
                             <ListItem key={"No Record Found"} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"No Record Found"} />
                                </ListItemButton>
                            </ListItem>
                        }
                        {(is_loading===true && holiday_list.length===0) &&
                             <ListItem key={"Loading..."} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Loading..."} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                
                </Grid>
                
            </Grid>
        </Box>
       {(is_open_add_holiday) && 
        <CreateHoliday holidayValue={holidayValue}/>
       }
        {(is_delete_item) &&
        <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
       }
   
   
    </>
  );
}

export default HolidayList;