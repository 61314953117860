import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from "react-redux";
import AppUtils from '../../AppUtils';
import PaymentList from './PaymentList';
import { handleChangeMenu } from '../../actions/Action';
import { Button, ButtonGroup, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import DemandBillList from './DemandBills';
import AutoSendDemandBillList from './AutoSendDemandBillList';
import { handleOpenCreateDemandBill } from './Action';
import CreateDemandBill from './CreateDemandBill';
import PettyCash from './PettyCash';
import TransactionDownloadPopup from '../reports/TransactionDownloadPopup';
import StaffAdvancePaymentList from './StaffAdvancePaymentList';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function PaymentHome() {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('');
  const [is_show_transaction, setShowTransaction] = React.useState(false);
  const state = useSelector((state) => state);
  const is_open_create_demand_bill = state.is_open_create_demand_bill;


  const initialized = React.useRef(false);
  React.useEffect(()=>{
      let curl = window.location.href;
      if (!initialized.current) {
        initialized.current = true;
        const is_out = curl.match(/payments\/out/i);
        const is_dbill = curl.match(/payments\/demand_bill/i);
        const is_petty_cash = curl.match(/payments\/petty_cash/i);
        const is_staff_advance = curl.match(/payments\/staff_advance/i);
        if(is_out){
          setValue(1);
        }else if(is_dbill){
          setValue(2);
        }else if(is_petty_cash){
          setValue(3);
        }else if(is_staff_advance){
          setValue(4);
        }else{
          setValue(0);
        }
      }
     
  },[])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let ptype = '';
    if(newValue===1){
      ptype = 'out';
    }else if(newValue===2){
      ptype = 'demand_bill';
    }else if(newValue===3){
      ptype = 'petty_cash';
    }else if(newValue===4){
      ptype = 'staff_advance';
    }
    dispatch(handleChangeMenu('payments',ptype,'Payments',true));
  };
  
  const handleGenerateDemandBill = () =>{
    /* let add_main_menu = 'payment';
    let add_sub_menu = 'add/demand-bill';
    let add_label = 'Create Demand Bill';
    dispatch(handleChangeMenu(add_main_menu,add_sub_menu,add_label,false)); */
    dispatch(handleOpenCreateDemandBill(true));
  }
  const handleAfterAction = () =>{
    handleChange('',2);
  }
  const handleShowTransaction = ()=>{
    setShowTransaction(true);
  }
  const handleCloseTransactionPopup = ()=>{
    setShowTransaction(false);
  }
  return (
    <Box sx={{ width: '100%' }}>
      {(AppUtils.has_access(state,'503_demand_bill_add') || AppUtils.has_access(state,'15_tranaction')) &&
      <>
      <Grid container spacing={2} className="mrtop-10 mrbtm-30">
      {(AppUtils.has_access(state,'15_tranaction')) &&
      <Grid item xs={12} sm={12} md={10} spacing={2} sx={{textAlign:'right'}}>
        <Button variant='outlined' size='small' onClick={handleShowTransaction}>Show Transaction Report</Button>
        {(is_show_transaction===true) &&
          <TransactionDownloadPopup handleClose={handleCloseTransactionPopup}/>
        }
      </Grid>
      }
      {(AppUtils.has_access(state,'503_demand_bill_add')) &&
        <Grid item xs={12} sm={12} md={2} spacing={2} sx={{textAlign:'right'}}>
          <ButtonGroup aria-label="vertical outlined button group">
              <Button variant='outlined' size='small' onClick={handleGenerateDemandBill}>Generate Demand Bill</Button>
             
          </ButtonGroup>
          {(is_open_create_demand_bill===true) &&
            <CreateDemandBill />
          }
        </Grid>
      }
      </Grid>
      <Divider />
      </>
    }
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        
          {/* <Tab label="All Payments" {...a11yProps(0)} /> */}
          {(AppUtils.has_access(state,'501_payment_in')) &&
            <Tab label="Payment IN(+)" {...a11yProps(0)} />
          }
          {(AppUtils.has_access(state,'502_payment_out')) &&
          <Tab label="Payment OUT(-)" {...a11yProps(1)} />
          }
         
          {(AppUtils.has_access(state,'503_demand_bill')) &&
            <Tab label="Demand Bills" {...a11yProps(2)} />
          }
          {(AppUtils.has_access(state,'501_payment_in') || AppUtils.has_access(state,'501_payment_out')) &&
            <Tab label="Petty Cash" {...a11yProps(3)} />
          }
           {(AppUtils.has_access(state,'502_payment_out')) &&
            <Tab label="Staff Advance Payment" {...a11yProps(4)} />
          }
        {/*   {(AppUtils.has_access(state,'503_demand_bill')) &&
            <Tab label="Auto Send Demand Bills" {...a11yProps(3)} />
          } */}
       
        </Tabs>
      </Box>
     
       {/*  {(value===0) &&
          <CustomTabPanel value={value} index={0}>
            <PaymentList type='all'/>
          </CustomTabPanel>
        } */}
       
      {(value===0 && AppUtils.has_access(state,'501_payment_in')) &&
        <CustomTabPanel value={value} index={0}>
          <PaymentList type='in'/>
        </CustomTabPanel>
      }
     
      {(value===1 && AppUtils.has_access(state,'502_payment_out')) &&
        <CustomTabPanel value={value} index={1}>
          <PaymentList type='out'/>
        </CustomTabPanel>
      }
      {(value===2 && AppUtils.has_access(state,'503_demand_bill')) &&
        <CustomTabPanel value={value} index={2}>
          <DemandBillList type='demand_bills'/>
        </CustomTabPanel>
      }
      
      {/* {(value===3 && AppUtils.has_access(state,'503_demand_bill')) &&
        <CustomTabPanel value={value} index={3}>
          <AutoSendDemandBillList afterAction={handleAfterAction}/>
        </CustomTabPanel>
      } */}
      {(value===3 && (AppUtils.has_access(state,'501_payment_in') || AppUtils.has_access(state,'502_payment_out'))) &&
        <CustomTabPanel value={value} index={3}>
          <PettyCash />
        </CustomTabPanel>
      }
      {(value===4 && AppUtils.has_access(state,'502_payment_out')) &&
        <CustomTabPanel value={value} index={4}>
          <StaffAdvancePaymentList type='staff_advance'/>
        </CustomTabPanel>
      }
    </Box>
  );
}
