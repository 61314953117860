import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import APP_HTTP from '../../../APP_HTTP';
import HeadersMapping from './headers-mapping';
import LoaderButton from '../../LoaderButton';

const studentHeaders =[
                        {key:'student_sequence',value:'Student Sequence'},
                        {key:'student_name',value:'Name'},
                        {key:'roll_number',value:'Roll Number'},
                        {key:'gender',value:'Gender'},
                        {key:'class',value:'Class'},
                        {key:'section',value:'Section'},
                        {key:'dob',value:'DOB'},
                        {key:'father_name',value:'Father Name'},
                        {key:'mother_name',value:'Mother Name'},
                        {key:'mobile_number',value:'Mobile Number'},
                        {key:'alt_mobile_number',value:'Alt Mobile Number'},
                        {key:'admission_number',value:'Admission Number'},
                        {key:'pen',value:'PEN'},
                        {key:'blood_group',value:'Blood Group'},
                        {key:'aadhar_number',value:'Aadhar Number'},
                        {key:'email',value:'Email'},
                        {key:'city',value:'City'},
                        {key:'state',value:'State'},
                        {key:'pincode',value:'Pincode'},
                        {key:'locality',value:'Locality'},
                        {key:'admission_date',value:'Admission Date'},
                        {key:'fee',value:'Fee'}
                    ];
const ImportExportRecord = (props) => {
   const [import_file,setImportFile] = React.useState('');
   const [headerResponse,setHeaderResponse] = React.useState([]);
   const [is_exporting,setIsExporting] = React.useState(false);
   const [export_list,setExportList] = React.useState([]);
   const [total_records,setTotalRecords] = React.useState(0);
   const [progress,setProgress] = React.useState(0);
   const handleOpenImportStudent = () =>{
    document.getElementById('import-student').click();
   }
   const handleGetHeaders = (e) =>{
    const files = e.target.files;
    const formData = new FormData();
    let file = files[0];
    setImportFile(file);
    formData.append('file', file);
    APP_HTTP.REQUEST('settings/get_import_headers',formData,true,true,true).then((response) => {
        let resp = response.data;
        setHeaderResponse(resp);
    });
   }
   const handleExportStudent = () =>{
        setIsExporting(true);
        let exp_list = [];
        setExportList(exp_list);
        setProgress(0);
        setTimeout(() => {
            handleProcessExport(1);
        }, 1000);
   }
   const handleProcessExport = (page) =>{
        let next_page = page+1;
        APP_HTTP.REQUEST('student/export_student',{page:page,total_records:total_records},true,true).then((response) => {
            let resp = response.data;
            let stu_list = resp.response;
            let tot_record = resp.total_record;
            let no_of_page = parseFloat(resp.number_of_page);
            
            setTotalRecords(tot_record);
            let prog = (page/no_of_page)*100;
            prog = Math.ceil(prog);
            if(prog>100){
                prog=100;
            }
            setProgress(prog);
            if(stu_list.length>0){
                for (let index = 0; index < stu_list.length; index++) {
                    const element = stu_list[index];
                    export_list.push(element);
                }
                setExportList(export_list);
                setTimeout(() => {
                    handleProcessExport(next_page);
                }, 200);
            }else{
                setIsExporting(false);
                document.getElementById('download-student-xls-button').click();
            }
        });
   }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Import Export Students
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    {(headerResponse.headers===undefined) &&
                        <Box style={{padding:'10px'}}>
                            <input type='file' id="import-student" onChange={(e)=>handleGetHeaders(e)}  accept=".csv" style={{display:'none'}}/> 
                            <Button variant="contained" color="success" onClick={()=>handleOpenImportStudent()}>Import CSV</Button>
                            {(is_exporting===false) &&
                                <Button variant="contained" color="success" onClick={handleExportStudent} style={{marginLeft:'10px'}}>Download as XLS</Button>
                            }
                            {(is_exporting===true) &&
                                <>
                                <Button variant="contained" color="success" style={{marginLeft:'10px'}}>
                                     <CircularProgress color="inherit" size={20}/>
                                    <span style={{marginLeft:'10px'}}>({progress}%)</span>
                                </Button>
                                    <Box style={{display:'none'}}>
                                    <ReactHTMLTableToExcel
                                        id="download-student-xls-button"
                                        table="student-table-list"
                                        filename="student list"
                                        sheet="student list"
                                        buttonText="Download as XLS"/>
                                        <table id="student-table-list">
                                            <thead>
                                                <tr>
                                                    <th>NAME</th>
                                                    <th>CLASS ROLL NUMBER</th>
                                                    <th>CLASS</th>
                                                    <th>SECTION</th>
                                                    <th>GENDER</th>
                                                    <th>DOB</th>
                                                    <th>ADMISSION DATE</th>
                                                    <th>FATHER NAME</th>
                                                    <th>MOHTER NAME</th>
                                                    <th>CONTACT NUMBER</th>
                                                    <th>LOCALITY</th>
                                                    <th>CITY</th>
                                                    <th>PINCODE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(export_list.map((value,index)=>{
                                                    return(
                                                        <tr>
                                                            <td>{value.student_name}</td>
                                                            <td>{value.roll_number}</td>
                                                            <td>{value.class}</td>
                                                            <td>{value.section}</td>
                                                            <td>{value.gender}</td>
                                                            <td>{value.dob_display}</td>
                                                            <td>{value.admission_date_display}</td>
                                                            <td>{value.father_name}</td>
                                                            <td>{value.mother_name}</td>
                                                            <td>{value.mobile_number}</td>
                                                            <td>{value.locality}</td>
                                                            <td>{value.city}</td>
                                                            <td>{value.pincode}</td>
                                                        </tr>
                                                    )
                                                }))}
                                            </tbody>
                                        </table>
                                    </Box>
                                </>
                            }
                            
                        </Box>
                    }
                    {(headerResponse.headers) &&
                        <HeadersMapping data={{headers:headerResponse.headers,mapHeaders:studentHeaders,total_record:headerResponse.total_record,import_file:import_file}}/>
                    }
                    
                </Grid>
            </Grid>
        </Box>
    
    </>
  );
}

export default ImportExportRecord;