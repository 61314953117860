import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AppDropDown from '../AppDropDown';
import BasicDatePicker from '../DatePicker';
import AppUtils from '../../AppUtils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function CashBook(props) {
  const state = useSelector((state) => state);
   const [in_list,setInList] = React.useState([]);
   const [in_total,setInTotal] = React.useState(0);
   const [out_total,setOutTotal] = React.useState(0);
   const [out_list,setOutList] = React.useState([]);
   const [in_particulars,setInParticulars] = React.useState([]);
   const [all_response,setAllResponse] = React.useState([]);
   const [out_particulars_amount_list,setOutParticularsAmountList] = React.useState([]);
   const [in_particulars_amount_list,setInParticularsAmountList] = React.useState([]);
   const [out_particulars,setOutParticulars] = React.useState([]);
   const [date_type,setDateType] = React.useState('transaction');
   
   const [fromDate,setFromDate] = React.useState(new Date());
   const [toDate,setToDate] = React.useState(new Date());
   const [filterType,setFilterType] = React.useState('today');
   const [inFeeHead,setInFeeHead] = React.useState(0);
   const [outFeeHead,setOutFeeHead] = React.useState(0);
   
   const [isGenerating,setIsGenerating] = React.useState(false);
   const [is_print,setIsPrint] = React.useState(false);
   const [school_info,setSchoolInfo] = React.useState(false);
   
   const handleChangeDateType = (id,value)=>{
    setDateType(value);
   }
   /* const handlePrintList = () =>{
    setIsPrint(true);
    setTimeout(() => {
      setIsPrint(false);
    }, 1500);
  } */
   
   const handleChangeFilterType = (id,value)=>{
    setFilterType(value);
   }
   const handleChangeInFeeHeadType = (id,value)=>{
    let amount = all_response.total_in_amount;
    if(value>0){
      amount = in_particulars_amount_list[value];
    }
    setInTotal(amount);
    setInFeeHead(value);
   }
   const handleChangeOutFeeHeadType = (id,value)=>{
    let amount = all_response.total_out_amount;
    if(value>0){
      amount = out_particulars_amount_list[value];
    }
    setOutTotal(amount);
    setOutFeeHead(value);
   }
  
  const handleChangeFromDate = (id,date) =>{
    setFromDate(date);
  }
  const handleChangeToDate = (id,date) =>{
    setToDate(date);
  }
  
  const handleGenerateReport = () =>{
    setIsGenerating(true);
    let data = {fromDate:fromDate,toDate:toDate,filterType:filterType,date_type:date_type};
    APP_HTTP.REQUEST('reports/get_cash_book',data,true,true,false,true).then((response) => {
     setIsGenerating(false);
     setAllResponse(response.data);
     let in_report = response.data.in_report;
     let out_report = response.data.out_report;
     setInList(in_report);
     setOutList(out_report);
     let total_in_amount = response.data.total_in_amount;
     let total_out_amount = response.data.total_out_amount;
     setInTotal(total_in_amount);
     setOutTotal(total_out_amount);
     let in_particulars = response.data.in_particulars;
     let out_particulars = response.data.out_particulars;
     let in_particular_amount = response.data.in_particular_amount;
     let out_particular_amount = response.data.out_particular_amount;
     setInParticularsAmountList(in_particular_amount);
     setOutParticularsAmountList(out_particular_amount);
     setInParticulars(in_particulars)
     setOutParticulars(out_particulars);
     let sinfo = response.data.school_info;
     setSchoolInfo(sinfo);
   });
  }
  
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>HEADWISE REPORT</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Here, you can generate the headwise report.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <AppDropDown label="Date Type" list_item={[{key:'transaction',value:'Transaction Date'},{key:'payment',value:'Payment Date'}]} size="small" handleChangeData={handleChangeDateType} defaultValue={date_type}/>
                </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <AppDropDown label="Filter Type" list_item={[{key:'today',value:'Today'},{key:'yesterday',value:'Yesterday'},{key:'thisweek',value:'This Week'},{key:'lastweek',value:'Last Week'},{key:'thismonth',value:'This Month'},{key:'custom',value:'Custom'}]} size="small" handleChangeData={handleChangeFilterType} defaultValue={filterType}/>
                </Grid>
                {(filterType==='custom') &&
                <>
                  <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="Select From Date" size="small" handleChangeData={handleChangeFromDate}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="Select To Date" size="small" handleChangeData={handleChangeToDate}/>
                  </Grid>
                </>
                }
               
                
                <>
                {(AppUtils.has_access(state,'160_cash_book_view')) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    {(isGenerating===false) &&
                      <Button variant='outlined' size='medium' onClick={handleGenerateReport}>GENERATE</Button>
                    }
                    {(isGenerating===true) &&
                    <LoaderButton label="Generating..."  variant='outlined' size='medium'/>
                    }
                </Grid>
                }
                </>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{textAlign:'right'}}>
                {(in_particulars.length>0 || out_particulars.length>0) &&
                <ReactHTMLTableToExcel
                        id="headwise-table-xls-button"
                        className="download-xls-button"
                        table="headwise-report-table"
                        filename="headwise-report"
                        sheet="headwise-report"
                        buttonText="Download as XLS"
                    />
                }
                </Grid>
               
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                
                    <TableContainer component={Paper}>
                      <Table  aria-label="customized table" id="headwise-report-table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell colSpan={3} style={{textAlign:'center'}}>INFLOW</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell colSpan={3} style={{textAlign:'center'}}>OUTFLOW</StyledTableCell>
                          </TableRow>
                         
                        </TableHead>
                        <TableBody>
                          <TableRow>
                              <StyledTableCell colSpan={3} style={{verticalAlign:'top'}}>
                              <TableContainer component={Paper}>
                              <Table  aria-label="customized table">
                              <TableHead>
                                {(in_particulars.length>0) &&
                                <TableRow>
                                  <StyledTableCell colSpan={3} style={{background:'#fff'}}>
                                    <AppDropDown label="Select Fee Head" list_item={in_particulars} size="small" handleChangeData={handleChangeInFeeHeadType} defaultValue={inFeeHead}/>
                                  </StyledTableCell>
                                </TableRow>
                                }
                                <TableRow>
                                  <StyledTableCell>DATE</StyledTableCell>
                                  <StyledTableCell>PARTICULAR</StyledTableCell>
                                  <StyledTableCell>AMOUNT</StyledTableCell>
                                </TableRow>
                              </TableHead>
                                <TableBody>
                                  {(in_list.map((value,index)=>{
                                    return(
                                      <>
                                      {(inFeeHead===0 || parseInt(inFeeHead)===parseInt(value.id)) &&
                                      <TableRow>
                                        <StyledTableCell>{value.date}</StyledTableCell>
                                        <StyledTableCell>{value.name}</StyledTableCell>
                                        <StyledTableCell>₹{AppUtils.currency_format(value.amount)}</StyledTableCell>
                                      </TableRow>
                                      }
                                      </>
                                    )
                                  }))}
                                  
                                </TableBody>
                                <TableFooter>
                                  <StyledTableCell></StyledTableCell>
                                  <StyledTableCell></StyledTableCell>
                                  <StyledTableCell><Typography variant='h6'>₹{AppUtils.currency_format(in_total)}</Typography></StyledTableCell>
                                </TableFooter>
                              </Table>
                              </TableContainer>
                              </StyledTableCell>
                              <StyledTableCell></StyledTableCell>
                              <StyledTableCell colSpan={3} style={{verticalAlign:'top'}}>
                              <TableContainer component={Paper}>
                              <Table  aria-label="customized table">
                              <TableHead>
                              {(out_particulars.length>0) &&
                                <TableRow>
                                    <StyledTableCell colSpan={3} style={{background:'#fff'}}>
                                      <AppDropDown label="Select Fee Head" list_item={out_particulars} size="small" handleChangeData={handleChangeOutFeeHeadType} defaultValue={outFeeHead}/>
                                    </StyledTableCell>
                                </TableRow>
                              }
                                <TableRow>
                                  <StyledTableCell>DATE</StyledTableCell>
                                  <StyledTableCell>PARTICULAR</StyledTableCell>
                                  <StyledTableCell>AMOUNT</StyledTableCell>
                                </TableRow>
                              </TableHead>
                                <TableBody>
                                  {(out_list.map((value,index)=>{
                                    return(
                                      <>
                                      {(outFeeHead===0 || parseInt(outFeeHead)===parseInt(value.id)) &&
                                      <TableRow>
                                        <StyledTableCell>{value.date}</StyledTableCell>
                                        <StyledTableCell>{value.name}</StyledTableCell>
                                        <StyledTableCell>₹{AppUtils.currency_format(value.amount)}</StyledTableCell>
                                      </TableRow>
                                      }
                                      </>
                                    )
                                  }))}
                                  
                                </TableBody>
                                <TableFooter>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell><Typography variant='h6'>₹{AppUtils.currency_format(out_total)}</Typography></StyledTableCell>
                                </TableFooter>
                              </Table>
                              </TableContainer>
                              </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                   
                </Grid>
               
            </Grid>
        </Box>
    </Box>
  );
}
export default React.memo(CashBook);