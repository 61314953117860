import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let printDataInitialState = {
    
}
let total_pending = 0;
const PrintPendingPayments = (props)=> {
    let [printData,setprintData] = React.useState(printDataInitialState);

    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            setTimeout(() => {
                handlePrintPendingPayments();
            }, 100);
        }
       
    },[props])
   
   const handlePrintPendingPayments = ()=>{
    if(document.getElementById("printPendingPayments")){
        var divContents = document.getElementById("printPendingPayments").innerHTML; 
        
      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
        
        let page_settings='@page{size:"A4";margin:0}';
          a.document.write('<style type="text/css"><style type="text/css">'+page_settings+'table{font-family:verdana, arial, sans-serif;font-size:16px;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:5px;vertical-align:top;border:1px solid #333}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
            a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="printPendingPayments" style={{display:'none'}}>
        <div style={{position:'relative'}}>
        <Watermark/>
        <table>
            <PrintHeader header_data={props.printData.school_info} colSpan={6}/>
            <tbody>
                <tr>
                    <th colSpan={6} style={{textAlign:'center'}}>PENDING PAYMENT LIST</th>
                </tr>       
                <tr>
                    <th style={{textAlign:'left'}}>SN</th>
                    <th style={{textAlign:'left'}}>RN</th>
                    <th style={{textAlign:'left'}}>NAME</th>
                    <th style={{textAlign:'left'}}>MOBILE</th>
                    <th style={{textAlign:'left'}}>LAST PAYMENT</th>
                    <th style={{textAlign:'right'}}>AMOUNT</th>
                </tr>            
                {props.printData.response && props.printData.response.map((value,index)=>{
                    return (
                        <tr>
                        <td style={{textAlign:'left'}}>
                            <span>{index+1}</span>
                        </td>
                        <td style={{textAlign:'left'}}>
                            <p>{value.student_info.roll_number}</p>
                        </td>
                        <td style={{textAlign:'left',whiteSpace: 'nowrap',textTransform:'uppercase'}}>
                            <p style={{margin:'0px'}}>
                                {value.student_info.student_name}<span style={{fontSize:'14px'}}>{value.student_info.class}({value.student_info.section})</span>
                            </p>
                            <p style={{marginTop:'5px',fontSize:'14px'}}>
                                {value.student_info.father_name}
                            </p>
                        </td>
                        <td style={{textAlign:'left',whiteSpace: 'nowrap'}}>
                            <p>
                                 {value.student_info.mobile_number}
                                   {/*  {(value.student_info.alt_mobile_number!=="") &&
                                    <>
                                    | {value.student_info.alt_mobile_number}
                                    </>
                                    } */}
                                    </p>
                            
                        </td>
                        <td style={{textAlign:'left'}}>
                            
                                {(value.latest_payment_info!==undefined && value.latest_payment_info[0]!==undefined && value.latest_payment_info[0].display_date!==undefined) &&
                                <>
                                    <p style={{margin:'0px'}}>{value.latest_payment_info[0].payment_sequence}</p>
                                    <p style={{marginTop:'5px'}}>{value.latest_payment_info[0].display_date}</p>
                                </>
                                }
                        </td>      
                        <td style={{textAlign:'right'}}>₹{AppUtils.currency_format(value.total_pending_amount)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
           
        
    </div>
    </div>
  );
}
export default PrintPendingPayments;