import { DELETE_ITEM_CONFIRM,DELETE_STAFF,SET_STAFFS_LIST,IS_STAFF_EDIT,SET_STAFF_INFO,IS_STAFF_VIEW } from "../../actionTypes/actionTypes";

const handleConfirmDeleteStaff = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteStaff = (index, delete_data) =>{
  return {
    type: DELETE_STAFF,
    state :{
      id : index,
    }
  };
}
const handleSetStaffsList = (staffs_list) =>{
    return {
      type: SET_STAFFS_LIST,
      state :{
        staffs_list : staffs_list,
      }
    };
  }
const handleToggleEditStaff = (is_staff_edit) =>{
    return {
      type: IS_STAFF_EDIT,
      state :{
        is_staff_edit : is_staff_edit,
      }
    };
  }
const handleToggleViewStaff = (is_staff_view) =>{
    return {
      type: IS_STAFF_VIEW,
      state :{
        is_staff_view : is_staff_view,
      }
    };
  }
const handleSetStaffInfo = (staff_info) =>{
    return {
      type: SET_STAFF_INFO,
      state :{
        staff_info : staff_info,
      }
    };
  }

export { handleConfirmDeleteStaff, handleDeleteStaff,handleSetStaffsList,handleToggleEditStaff,handleSetStaffInfo,handleToggleViewStaff};