import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import InputAdornment from '@mui/material/InputAdornment';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';
import { AccountCircleRounded, CheckCircle } from '@mui/icons-material';
import BasicDatePicker from '../DatePicker';
import AppMultiSelectCB from '../AppMultiSelectCB';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import { handleSetStaffInfo, handleToggleEditStaff, handleToggleViewStaff } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import Subjects from '../settings/subjects/Subjects';
import StaffTypes from '../settings/staffs/StaffTypes';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#ffffff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const CreateStaff = (props)=> {
    let staffDataInitialState = {
        update_id : 0,
        staff_type_id : 0,
        staff_name : '',
        gender : 'male',
        dob : new Date(),
        mobile_number : '',
        alt_mobile_number : '',
        email : '',
        city : '',
        state : 'Bihar',
        pincode : '',
        locality : '',
        joining_date : new Date(),
        employement_type : 'permanent',
        employee_type : 'permanent',
        specialization : [],
        salary : '',
        salary_type : 'monthly',
        status : 'active',
    }
    let [image_url,setImageURL] = React.useState('');
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [sequences,setTypesSequences] = React.useState([]);
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    let [staffData,setStaffData] = React.useState(staffDataInitialState);
    const [is_call_service, setIsCallService] = React.useState(false);    
   
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const subject_list = state.subject_list;
    const sub_list = [];
    for (let index = 0; index < subject_list.length; index++) {
        const element = subject_list[index];
        let sub_name = element.name;
        sub_list.push(sub_name);
    }
   
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            if(sub_list.length===0){
                setIsCallService(true);
                
            }
            initialized.current = true;
            if(props.staff_data){
                let staff_data = props.staff_data;
                if(staff_data.is_db===undefined){
                    setStaffData(staff_data);
                    setCurrentSequence(staff_data.staff_sequence);
                    setImageURL(staff_data.photo);
                }else{
                    APP_HTTP.REQUEST('staff/get_staff_on_id',{id:staff_data.staff_id},true,true).then((response) => {
                        let resp = response.data.response;
                        setStaffData(resp);
                        setCurrentSequence(resp.staff_sequence);
                        setImageURL(resp.photo);
                        dispatch(handleSetStaffInfo(resp));
                    });
                }
                //handleChangeData('gender',staff_data.gender);
                //handleChangeData('gender',staff_data.gender);
            }else{
                APP_HTTP.REQUEST('settings/get_current_sequence',{},true,true).then((response) => {
                    let resp = response.data;
                    let sequence = resp.response;
                    setTypesSequences(sequence)
                    
                    /* sequence.forEach(element => {
                        console.log(element);
                    }); */
                   /*  sequence = sequence.teacher;
                    setCurrentSequence(sequence); */
                });
            }
        }
       
    },[props])

    const states = state.states;
    const gender_list = [{key:'male',value:'Male'},{key:'female',value:'Female'}];
    let state_list = [];
    for (let index = 0; index < states.length; index++) {
        const state_name = states[index];
        let list_item = {key:state_name,value:state_name};
        state_list.push(list_item);
    }

    const handleUploadDocument = (e,doc_type) =>{
        const files = e.target.files;
    
      }
  const handleSaveInformation = (type) =>{
    let stData = {...staffData};
    if(stData.staff_type_id==="" || stData.staff_type_id===0){
        document.getElementById('staff_type_id-select').focus();
        return false;
    }
    if(stData.staff_name===""){
        document.getElementById('staff_name').focus();
        return false;
    }
    if(stData.mobile_number===""){
        document.getElementById('mobile_number').focus();
        return false;
    }
    if(stData.city===""){
        document.getElementById('city').focus();
        return false;
    }
    setIsSaveClicked(true);
    let upload_file = document.getElementById("upload_file");
    let file_qs = document.querySelectorAll('input[name="documents"]');
    const files = upload_file.files
    const formData = new FormData();
    let photo = files[0];

    for (let index = 0; index < file_qs.length; index++) {
        const element = file_qs[index];
        const doc_id = element.id;
        const doc_val = element.files[0];
        formData.append(doc_id,doc_val);
        if(doc_val!==undefined){
            var doc_url = URL.createObjectURL(doc_val);
            stData[doc_id] = doc_url;
        }
    }
    setStaffData(stData);
    dispatch(handleSetStaffInfo(stData));
    formData.append('photo', photo);
    formData.append('data', JSON.stringify(staffData));
    APP_HTTP.REQUEST('staff/save_staff',formData,true,true,true).then((response) => {
        setIsSaveClicked(false);
        let last_id = response.data.id;
        window.location.href = '/staff/view/'+last_id;
    });
  }
  const handleUploadFileClick = () =>{
    if(document.getElementById("upload_file")){
        document.getElementById("upload_file").click();   
    }
  }
  const handleUploadFile = (e) =>{
    const files = e.target.files;
    if(files[0]){
        var url = URL.createObjectURL(files[0]);
        setImageURL(url);
    }
  }
  const handleChangeData = (id,value) =>{
    
    let stuData = staffData;
    
    if(id.target && id.target.id){
        let value = id.target.value;
        let key = id.target.id;
        stuData[key] = value;
    }else{
        stuData[id] = value;
    }
    setStaffData(stuData);
    if(id==='staff_type_id'){
        const staff_type_list = state.staff_type_list;
        
        for (let index = 0; index < staff_type_list.length; index++) {
            const element = staff_type_list[index];
            if(parseInt(element.id)===parseInt(value)){
                let key = element.key;
                if(sequences[key]!==undefined){
                    let cur_seq = sequences[key];
                    let current = cur_seq.current;
                    let prefix = cur_seq.prefix;
                    let staff_sequence = '#'+prefix+current;
                    setCurrentSequence(staff_sequence);
                }
            }
        }
    }
  }
  const handleCancelEdit = () =>{
    dispatch(handleChangeMenu('staffs','','Staffs',true));
    dispatch(handleToggleEditStaff(false));
  }
  return (
    <div id="create-staff">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            <Grid item xs={6}> 
                            {(props.staff_data===undefined) &&
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>Create Staff</Typography>
                            }
                            {(props.staff_data!==undefined) &&
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>Update Staff</Typography>
                            }
                                <Typography variant='span' sx={{fontSize:'14px',color:'gray'}}>Please fill the staff information in below field</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'right'}}> 
                                <Typography variant='span' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>{(current_sequence!=="")?current_sequence:''}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="mrtop-15" style={{textAlign:'center'}}>
                        <input type='file' id="upload_file" accept="image/png,image/jpg, image/jpeg" style={{display:'none'}} onChange={handleUploadFile}/>
                        <Box sx={{height:'150px',width:'150px',border:'5px solid #d7d7d7',margin:'0 auto',borderRadius:'50%',cursor:'pointer'}} onClick={handleUploadFileClick}>
                            {(image_url!=='') &&
                                <img src={image_url} alt="Image" height={'100%'} width={'100%'} style={{borderRadius:'50%'}}/>
                            }
                            {(image_url==='') &&
                                <AccountCircleRounded size="large" sx={{height:'100%',width:'100%',color:'#d7d7d7'}}/>
                            }
                        </Box>
                    </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Basic Information</h3>
                        <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        {(staffData.staff_type_id>0 && props.staff_data) &&
                            <StaffTypes show="dropdown" handleChangeData={handleChangeData} defaultValue={staffData.staff_type_id} required={true}/>
                        }
                        {(props.staff_data===undefined) &&
                            <StaffTypes show="dropdown" handleChangeData={handleChangeData} defaultValue={staffData.staff_type_id} required={true}/>
                        }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                required={true}
                                label="Staff Name"
                                defaultValue={staffData.staff_name}
                                key={staffData.staff_name}
                                size="small"
                                id="staff_name"
                                fullWidth
                                inputProps={{className:"staff-data"}}
                                onChange={handleChangeData}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        <AppDropDown label="Gender" id="gender" defaultValue={staffData.gender} list_item={gender_list} size="small" className="staff-data" handleChangeData={handleChangeData}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {(staffData.dob!=='' && props.staff_data && props.staff_data.dob) &&
                                <BasicDatePicker label="Date Of Birth" size="small" id="dob" defaultValue={props.staff_data.dob} className="staff-data" handleChangeData={handleChangeData}/>
                            }
                            {(props.staff_data===undefined) &&
                                <BasicDatePicker label="Date Of Birth" size="small" id="dob" className="staff-data" handleChangeData={handleChangeData}/>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                required={true}
                                label="Mobile Number"
                                onChange={handleChangeData}
                                size="small"
                                id="mobile_number"
                                fullWidth
                                inputProps={{className:"staff-data"}}
                                defaultValue={staffData.mobile_number}
                                key={staffData.mobile_number}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Alternate Mobile Number"
                                onChange={handleChangeData}
                                size="small"
                                id="alt_mobile_number"
                                fullWidth
                                inputProps={{className:"staff-data"}}
                                defaultValue={staffData.alt_mobile_number}
                                key={staffData.alt_mobile_number}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Email ID"
                                onChange={handleChangeData}
                                size="small"
                                id="email"
                                fullWidth
                                inputProps={{className:"staff-data"}}
                                defaultValue={staffData.email}
                                key={staffData.email}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                required={true}
                                label="City"
                                onChange={handleChangeData}
                                size="small"
                                id="city"
                                fullWidth
                                inputProps={{className:"staff-data"}}
                                defaultValue={staffData.city}
                                key={staffData.city}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <AppDropDown label="State" id="state" defaultValue={staffData.state} list_item={state_list} size="small" className="staff-data" handleChangeData={handleChangeData}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Pincode"
                                onChange={handleChangeData}
                                size="small"
                                id="pincode"
                                fullWidth
                                inputProps={{className:"staff-data"}}
                                defaultValue={staffData.pincode}
                                key={staffData.pincode}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Locality"
                                onChange={handleChangeData}
                                size="small"
                                id="locality"
                                fullWidth
                                inputProps={{className:"staff-data"}}
                                defaultValue={staffData.locality}
                                key={staffData.locality}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Advanced Information</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {(staffData.joining_date!=='' && props.staff_data && props.staff_data.joining_date) &&
                                <BasicDatePicker label="Date Of Joining" size="small" id="joining_date" defaultValue={props.staff_data.joining_date} className="staff-data" handleChangeData={handleChangeData}/>
                            }
                            {(props.staff_data===undefined) &&
                                <BasicDatePicker label="Date Of Joining" size="small" id="joining_date" className="staff-data" handleChangeData={handleChangeData}/>
                            }
                        </Grid>
                    <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        <AppDropDown label="Employement Type" id="employement_type" defaultValue={staffData.employement_type} list_item={[{key:'permanent',value:'Permanent'},{key:'guest',value:'Guest'}]}size="small" handleChangeData={handleChangeData}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        <TextField
                        label="Salary"
                        id="salary"
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        }}
                        size='small'
                        onChange={handleChangeData}
                        defaultValue={staffData.salary}
                        key={staffData.salary}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        <AppDropDown label="Salary Type" id="salary_type" defaultValue={staffData.salary_type} list_item={[{key:'monthly',value:'Monthly'},{key:'quarterly',value:'Quarterly'},{key:'semester',value:'Semester'},{key:'yearly',value:'Yearly'}]}size="small" handleChangeData={handleChangeData}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        {(is_call_service===true) &&
                            <Subjects show='service'/>
                        }
                       
                        <AppMultiSelectCB label="Specialization" list_item={sub_list} id="specialization" handleChangeData={handleChangeData}  defaultValue={staffData.specialization}/>
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        <AppDropDown label="Status" id="status"  defaultValue={staffData.status} list_item={[{key:'active',value:'Active'},{key:'inactive',value:'Inactive'}]}size="small" handleChangeData={handleChangeData}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Documents</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className='mrtop-15'>
                            <TableContainer component={Paper}>
                                <Table  aria-label="customized table">
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(staffData.aadharc && staffData.aadharc!='') &&
                                                    <CheckCircle sx={{fontSize:'14px',color:'green'}}/>
                                                }
                                                <Typography variant="span" sx={{marginLeft:'5px'}}>Aadhar Card </Typography>
                                            </StyledTableCell>      
                                            <StyledTableCell>
                                                <input type='file' accept="image/png,image/jpg, image/jpeg" name="documents" id="aadharc" onChange={(e)=>handleUploadDocument(e,'aadhar')}/></StyledTableCell>      
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(staffData.dobc && staffData.dobc!='') &&
                                                    <CheckCircle sx={{fontSize:'14px',color:'green'}}/>
                                                }
                                                <Typography variant="span" sx={{marginLeft:'5px'}}>Date of Birth Certificate</Typography>
                                            </StyledTableCell>      
                                            <StyledTableCell>
                                            <input type='file' name="documents" id="dobc" accept="image/png,image/jpg, image/jpeg" onChange={(e)=>handleUploadDocument(e,'dob')}/>
                                            </StyledTableCell>      
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(staffData.experiencec && staffData.experiencec!='') &&
                                                    <CheckCircle sx={{fontSize:'14px',color:'green'}}/>
                                                }
                                                <Typography variant="span" sx={{marginLeft:'5px'}}>Experience Letter</Typography>
                                            </StyledTableCell>      
                                            <StyledTableCell>
                                            <input type='file' name="documents" id="experiencec" accept="image/png,image/jpg, image/jpeg" onChange={(e)=>handleUploadDocument(e,'experience')}/>
                                            </StyledTableCell>      
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(staffData.payslipc && staffData.payslipc!='') &&
                                                    <CheckCircle sx={{fontSize:'14px',color:'green'}}/>
                                                }
                                                <Typography variant="span" sx={{marginLeft:'5px'}}>Last Pay Slip</Typography>
                                            </StyledTableCell>      
                                            <StyledTableCell>
                                            <input type='file' name="documents" id="payslipc" accept="image/png,image/jpg, image/jpeg" onChange={(e)=>handleUploadDocument(e,'payslip')}/>
                                            </StyledTableCell>      
                                        </StyledTableRow>    
                                          
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} spacing={2} className="mrtop-30 footer-btn-blk">
            <Grid xs={6}>
                {(props.staff_data) &&
                    <Button variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
                }
            </Grid>
            <Grid xs={6} sx={{textAlign:'right'}}>
            {(isSaveClicked===false) &&
                <Button variant="contained" onClick={() => handleSaveInformation('save')}>Save Information</Button>
            }
            {(isSaveClicked===true) &&
                <LoaderButton label="Save Information"/>
            }
            </Grid>
        </Grid>
        
        </Box>
    </div>
  );
}
export default CreateStaff;