import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Button, Divider, Paper, TextField, Typography} from '@mui/material';
import APP_HTTP from '../../APP_HTTP.jsx';
import { Add, Close, Delete, PlusOne } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BasicDatePicker from '../DatePicker.jsx';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const OccassionPublish = (props) => {
    const [image_url,setImageURL] = React.useState('');
    const [image_dimension,setImageDimension] = React.useState({width:'',height:''});
    const [scheduled_list,setScheduledList] = React.useState([]);
    const [images,setImages] = React.useState({date:'',title:''});
    const [upload_error, setUploadError] = React.useState('');
    const [active_index,setActiveIndex] = React.useState('');
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetOccasionWish();
           
        }
    },[])
  
    const handleGetOccasionWish = ()=>{
        APP_HTTP.REQUEST('settings/get_scheduled_occasion',{},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.length>0){
                setScheduledList(resp);
            }else{
                let new_li = [{id:0,date:new Date(),title:'',photo:'',dimension:{height:'',width:''}}];
                setScheduledList(new_li);
            }
        });
      
    }
   
    const handleUploadFileClick = (index) =>{
        let sched_list = [...scheduled_list];
        if(sched_list[index].title===''){
            document.getElementById('occasion-title-'+index).focus();
            return false;
        }
        setUploadError('');
        setActiveIndex(index);
        if(document.getElementById("upload_file")){
            document.getElementById("upload_file").click();   
        }
    }
    const handleUploadFile = (e) =>{
        let sched_list = [...scheduled_list];
       
        const files = e.target.files;
        if(files[0]){
            let id = active_index;
            let img = new Image()
            img.src = URL.createObjectURL(files[0]);
            
            
            img.onload = () => {
                let im_height = img.height;
                let im_width = img.width;
                if(im_height>500){
                    let uerror = 'Image height exceeded ! Please upload image less than 500 height, current image dimension width is '+im_width+' and height is '+im_height;
                    setUploadError(uerror);
                    return false;
                }
                if(im_width>500){
                    let uerror = 'Image height exceeded ! Please upload image less than 500 width, current image dimension width is '+im_width+' and height is '+im_height;
                    setUploadError(uerror);
                    return false;
                }
                let img_dimen = {height:im_height,width:im_width};
                sched_list[id].dimension = img_dimen;
                sched_list[id].photo = img.src;
                setScheduledList(sched_list);
                 let photo = files[0];
                 const formData = new FormData();
                 formData.append('photo', photo);
                 formData.append('data', JSON.stringify(sched_list[id]));
                 APP_HTTP.REQUEST('settings/save_occasion_wishes',formData,true,true,true).then((response) => {
                     let resp = response.data;
                     let insert_id = resp.id;
                     sched_list[id].id = insert_id;
                     setScheduledList(sched_list);
                 });
            }
        }
    }
    const handleRemoveItem = (index)=>{
        let sched_list = [...scheduled_list];
        let rm_id = sched_list[index].id;
        sched_list.splice(index,1);
        setScheduledList(sched_list);
        if(sched_list.length===0){
            let new_list = {date:new Date(),title:'',photo:'',image_dimension:{height:'',width:''}};
            sched_list.push(new_list);
            setScheduledList(sched_list);
        }
        APP_HTTP.REQUEST('settings/remove_occasion_wishes',{rm_id:rm_id},true,true).then((response) => {
           
        });
    }
    const handleChangeDate = (id,value)=>{
        let sched_list = [...scheduled_list];
        sched_list[id].date = value;
        setScheduledList(sched_list);
    }
    const handleChangeTitle = (id,value)=>{
        let sched_list = [...scheduled_list];
        sched_list[id].title = value;
        setScheduledList(sched_list);
    }
    const handleAddMore = () =>{
        let sched_list = [...scheduled_list];
        let new_list = {date:new Date(),title:'',photo:'',image_dimension:{height:'',width:''}};
        sched_list.push(new_list);
        setScheduledList(sched_list);
    }
   
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Typography variant='h4'>Schedule Occasion</Typography>
                            <Typography>You can schedule occasion by entering date, name and upload photo for respective occasion, this uploaded photo will be visible for teachers and parents in app </Typography>
                            {/* <input type='file' id="upload_file" accept="image/png,image/jpg, image/jpeg" style={{display:'none'}} onChange={handleUploadFile}/>
                            <Button variant="outlined" onClick={handleUploadFileClick} style={{marginBottom:'10px'}}>Upload Occassion Wish</Button> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <input type='file' id="upload_file" accept="image/png,image/jpg, image/jpeg" style={{display:'none'}} onChange={handleUploadFile}/>
                            <TableContainer component={Paper}>
                                <Table  aria-label="customized table">
                                    <TableBody>
                                    {(scheduled_list.map((value,index)=>{
                                        return(
                                            <StyledTableRow style={{background:'#fff'}}>
                                            <StyledTableCell>
                                                <BasicDatePicker label="Occasion Date" size="small" id={index} defaultValue={value.date} className="student-data" handleChangeData={handleChangeDate}/>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                            <TextField
                                            label="Occasion Name"
                                            value={value.title}
                                            /* key={studentData.pen_no} */
                                            size="small"
                                            id={'occasion-title-'+index}
                                            fullWidth
                                            inputProps={{className:"student-data"}}
                                            onChange={(e)=>handleChangeTitle(index,e.target.value)}
                                            /* InputLabelProps={{ shrink: true }} */
                                            />
                                            </StyledTableCell>
                                            <StyledTableCell style={{textAlign:'center'}}>
                                                {(value.photo!=='') &&
                                               
                                                    <img src={value.photo} width={value.dimension.width} height={value.dimension.height} style={{alignSelf:'center'}}/>
                                                
                                                }
                                                {(value.photo==='') &&
                                                <>
                                                    <Button variant="outlined" onClick={()=>handleUploadFileClick(index)} >Upload Occassion Photo</Button>
                                                    {(upload_error!=='') &&
                                                        <Typography style={{color:'red'}}>
                                                            {upload_error}
                                                        </Typography>
                                                    }   
                                                </>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell style={{textAlign:'right'}}>
                                                <Box style={{display:'flex'}}>
                                                {(value.photo!=='') &&
                                                    <Button variant='contained' size='small' style={{marginRight:'10px'}} color='error'  onClick={()=>handleRemoveItem(index)}>
                                                        Remove
                                                    </Button>
                                                }
                                                {(index===(scheduled_list.length-1)) &&
                                                    <Button onClick={()=>handleAddMore()} size='small' variant='contained' >Add More</Button>
                                                }
                                                
                                                </Box>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        )
                                    }))}
                                    

                                    
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {/* {(image_url!=='') &&
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <Box style={{position:'relative',textAlign:'center'}}>
                                <Close style={{position:'absolute', top:0,right:0,color:'red',cursor:'pointer'}} onClick={handleRemoveUpload}/>
                                <img src={image_url} width={image_dimension.width} height={image_dimension.height} style={{alignSelf:'center'}}/>
                            </Box>
                        </Grid> 
                        } */}
                    </Grid>
                </Grid>
            </Grid>
           
        </Box>
    </>
  );
}

export default OccassionPublish;