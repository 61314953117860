import { OPEN_ADD_SUBJECT,CREATE_SUBJECT,UPDATE_SUBJECT, DELETE_ITEM_CONFIRM, DELETE_SUBJECT, SET_SUBJECT_LIST } from "../../../actionTypes/actionTypes";

const handleOpenAddSubject = (is_open_add_subject) =>{
  return {
    type: OPEN_ADD_SUBJECT,
    state :{
      is_open_add_subject : is_open_add_subject,
    }
  };
}
const handleSetSubjectList = (subject_list) =>{
  return {
    type: SET_SUBJECT_LIST,
    state :{
      subject_list : subject_list,
    }
  };
}
const handleSaveSubjectToList = (subject_list) =>{
  return {
    type: CREATE_SUBJECT,
    state :{
      subject_list : subject_list,
    }
  };
}
const handleUpdateSubject = (update_id,name) =>{
  return {
    type: UPDATE_SUBJECT,
    state :{
      update_id : update_id,
      name : name
    }
  };
}
const handleConfirmDeleteSubject = (is_delete_item) =>{
  return {
    type: DELETE_ITEM_CONFIRM,
    state :{
      is_delete_item : is_delete_item,
    }
  };
}
const handleDeleteSubject = (index, delete_data) =>{
  return {
    type: DELETE_SUBJECT,
    state :{
      id : index,
    }
  };
}

export { handleOpenAddSubject, handleSaveSubjectToList,handleUpdateSubject,handleConfirmDeleteSubject,handleDeleteSubject,handleSetSubjectList };