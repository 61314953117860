import * as React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
const LoaderButton = (props)=> {
  return (
    <Button {...props}>
        <CircularProgress color="inherit" size={20}/>
        {(props.label && props.label!=="")&&
          <span style={{marginLeft:'10px'}}>{props.label}</span>
        }
    </Button>
  );
}

export default LoaderButton;