import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddClass,handleSaveClassToList,handleUpdateClass } from './Action';
import APP_HTTP from '../../../APP_HTTP';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { handleSetSectionList } from '../sections/Action';
let initialState = {id:0,name:'',is_edit:false};
const CreateClass = (props)=> {
  const [classItem,setClassItem] = React.useState(initialState);
  const [className,setClassName] = React.useState('');
  const [section_list,setSectionList] = React.useState([]);
  const [selected_sections,setSelectedSection] = React.useState([]);
  const [mapped_sections,setMappedSections] = React.useState([]); 
  const state = useSelector((state) => state);
  const is_college = state.school_info.school_info.is_college;
  const is_open_add_class = state.is_open_add_class;
  

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(handleOpenAddClass(false));
  };
  const initialized = React.useRef(false);
  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      let classValue = props.classValue;
      setClassItem(classValue);
      setClassName(classValue.name);
      if(classValue.section_info!==undefined && classValue.section_info!==null){
        let secInfo = classValue.section_info;
        setMappedSections(secInfo);
        var keys = Object.keys(secInfo);
        setSelectedSection(keys);
      }
      //if(section_list.length===0){
        APP_HTTP.REQUEST('settings/get_section_list',{},true,true).then((response) => {
            let resp = response.data;
            let secli = resp.response;
            setSectionList(secli);
        });
      //}
    }
  },[props]);
  const handleSaveClass = () =>{
    let update_idx = 0;
    let update_id = 0;
    if(props.classValue && props.classValue.is_edit && props.classValue.update_id>=0){
      update_idx = props.classValue.update_id;
      update_id = props.classValue.id;
      dispatch(handleUpdateClass(update_idx,className));
    }
    APP_HTTP.REQUEST('settings/create_class',{id:update_id,name:className,sections:selected_sections},true,true).then((response) => {
      let resp = response.data;
      let id = resp.id;
      if(update_id===0){
        classItem.id = parseInt(id);
        dispatch(handleSaveClassToList(classItem));
      }
      window.location.reload();
    });
    handleClose();
  }
  const handleSetClassName = (e) =>{
    let thisvalue = e.currentTarget.value;
    let clsitem = classItem;
    clsitem.name = thisvalue;
    setClassItem(clsitem);
    setClassName(thisvalue);
  }
  const handleCheckSectionList = (is_checked,index,id) =>{
    let sel_sec = [...selected_sections];
    let idxOf = sel_sec.indexOf(id);
    if(is_checked===false){
      if(sel_sec[idxOf]!==undefined){
        sel_sec.splice(idxOf,1);
      }
    }else{
      sel_sec.push(id)
    }
    setSelectedSection(sel_sec);
  }
  return (
      <Dialog open={is_open_add_class} onClose={handleClose}>
        <DialogTitle>Create {(is_college===true)?'Course':'Class'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="class_name"
            label={(is_college===true)?'Enter Course Name':'Enter Class Name'}
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetClassName}
            value={className}
          />
          <Box style={{marginTop:'15px'}}>
            <Typography>{(is_college===true)?'Map Year to the Course':'Map Section to the Class'}</Typography>
            <FormGroup>
              {(section_list.map((value,index)=>{
                  return(
                      <FormControlLabel control={<Checkbox onChange={(e)=>handleCheckSectionList(e.target.checked,index,value.id)} defaultChecked={(mapped_sections[value.id]!==undefined)?true:false}/>} label={value.name} />
                    )
              }))}
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSaveClass} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreateClass;