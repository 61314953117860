import * as React from 'react';
import { Autocomplete, Avatar, Box, Button, ButtonGroup, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, ListSubheader, Radio, RadioGroup, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddPaymentType,handleSavePaymentTypeToList,handleUpdatePaymentType } from './Action';
import APP_HTTP from '../../../APP_HTTP';
import { handleSetClassList } from '../classes/Action';
import AppDropDown from '../../AppDropDown';
import AppCheckbox from '../../AppCheckbox';

let initialState = {id:0,name:'',is_edit:false};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const link_months = [
  {key:'none',value:'Select Link Month'},
  {key:'apr',value:'Apr'},
  {key:'may',value:'May'},
  {key:'jun',value:'Jun'},
  {key:'jul',value:'Jul'},
  {key:'aug',value:'Aug'},
  {key:'sep',value:'Sep'},
  {key:'oct',value:'Oct'},
  {key:'nov',value:'Nov'},
  {key:'dec',value:'Dec'},
  {key:'jan',value:'Jan'},
  {key:'feb',value:'Feb'},
  {key:'mar',value:'Mar'}
]
const day_of_months = [
  {key:'1',value:'1'},
  {key:'2',value:'2'},
  {key:'3',value:'3'},
  {key:'4',value:'4'},
  {key:'5',value:'5'},
  {key:'6',value:'6'},
  {key:'7',value:'7'},
  {key:'8',value:'8'},
  {key:'9',value:'9'},
  {key:'10',value:'10'},
  {key:'11',value:'11'},
  {key:'12',value:'12'},
  {key:'13',value:'13'},
  {key:'14',value:'14'},
  {key:'15',value:'15'},
  {key:'16',value:'16'},
  {key:'17',value:'17'},
  {key:'18',value:'18'},
  {key:'19',value:'19'},
  {key:'20',value:'20'},
  {key:'21',value:'21'},
  {key:'22',value:'22'},
  {key:'23',value:'23'},
  {key:'24',value:'24'},
  {key:'25',value:'25'},
  {key:'26',value:'26'},
  {key:'27',value:'27'},
  {key:'28',value:'28'},
  {key:'29',value:'29'},
  {key:'30',value:'30'},
  {key:'31',value:'31'},
]
const CreatePaymentType = (props)=> {
  const [payment_typeItem,setPaymentTypeItem] = React.useState(initialState);
  const [payment_typeName,setPaymentTypeName] = React.useState('');
  const [fee_duration,setFeeDuration] = React.useState('');
  const [link_to,setLinkTo] = React.useState('');
  const [link_month,setLinkMonth] = React.useState('none');
  const [link_day,setLinkDay] = React.useState('');
  const [transport_route,setTransportRoute] = React.useState('');
  const [global_fee,setGlobalFee] = React.useState('');
  const [isShowClassList,setShowClassList] = React.useState(false);
  const [all_class_list,setAllClassList] = React.useState([]);
  const [is_salary,setIsSalary] = React.useState(false);
  const [is_transport_fee,setIsTransport] = React.useState(false);
  const [is_tuition_fee,setIsTuitionFee] = React.useState(false);
  const [is_demand_bill,setAttachInDemandBill] = React.useState(false);
  const [is_hostel_fee,setIsHostel] = React.useState(false);
  const [is_uniform_fee,setIsUniform] = React.useState(false);
  const [class_fee,setClassFee] = React.useState({});
  const [transport_list,setTransportList] = React.useState([]);
  const state = useSelector((state) => state);
  const is_college = state.school_info.school_info.is_college;
  const class_list = state.class_list;
  const is_open_add_payment_type = state.is_open_add_payment_type;
  const dispatch = useDispatch();
  const initialized = React.useRef(false);
  const handleChangeLinkTo = (id,value)=>{
    setLinkTo(value);
  }
  const handleClose = () => {
    initialized.current = false;
    dispatch(handleOpenAddPaymentType(false));
  };
  const handleChangeFeeDuration = (id,value) =>{
    setFeeDuration(value);
  }
  const handleChangeLinkMonth = (id,value) =>{
    setLinkMonth(value);
  }
  const handleChangeLinkDay = (id,value) =>{
    setLinkDay(value);
  }
  const handleChangeTransport = (id,value) =>{
    setTransportRoute(value);
  }
  React.useEffect(() => {
    if (!initialized.current) {
        initialized.current = true;
        getTransportList();
        let payment_typeValue = props.payment_typeValue;
        if(payment_typeValue.is_edit===true){
          let cls_fee = props.class_fee;
          setClassFee(cls_fee);
          setPaymentTypeItem(payment_typeValue);
          setPaymentTypeName(payment_typeValue.name);
          setGlobalFee(payment_typeValue.global_fee);
          if(payment_typeValue.link_day!==undefined){
            setLinkDay(payment_typeValue.link_day);
          }
          if(payment_typeValue.link_month!==undefined){
            setLinkMonth(payment_typeValue.link_month);
          }
          if(payment_typeValue.is_salary!==undefined){
            setIsSalary(payment_typeValue.is_salary);
          }
          if(payment_typeValue.is_transport_fee!==undefined){
            setIsTransport(payment_typeValue.is_transport_fee);
          }
          if(payment_typeValue.is_tuition_fee!==undefined){
            setIsTuitionFee(payment_typeValue.is_tuition_fee);
          }
          if(payment_typeValue.is_demand_bill!==undefined){
            setAttachInDemandBill(payment_typeValue.is_demand_bill);
          }
          if(payment_typeValue.is_hostel_fee!==undefined){
            setIsHostel(payment_typeValue.is_hostel_fee);
          }
          console.log(payment_typeValue);
          if(payment_typeValue.is_uniform_fee!==undefined){
            setIsUniform(payment_typeValue.is_uniform_fee);
          }
          if(payment_typeValue.link_to!==undefined){
            setLinkTo(payment_typeValue.link_to);
          }
          if(payment_typeValue.fee_duration!==undefined){
            setFeeDuration(payment_typeValue.fee_duration);
          }
        }else{
          setClassFee({});
          setPaymentTypeItem({id:0,name:'',is_edit:false});
          setPaymentTypeName('');
          setGlobalFee('');
          setLinkDay('20');
          setLinkMonth('none');
          setLinkTo('student');
          setFeeDuration('monthly');
          setIsTransport(false);
          setIsSalary(false);
        }
        //if(class_list.length===0){
          APP_HTTP.REQUEST('settings/get_class_list',{payment_type_id:props.payment_typeValue.id},true,true).then((response) => {
              let resp = response.data;
              let class_list = resp.response;
              dispatch(handleSetClassList(class_list));
              setAllClassList(class_list);
              setShowClassList(true);
          });
        /* }else{
          setShowClassList(true);
        } */
    }
  },[props]);
  const getTransportList = ()=>{
    APP_HTTP.REQUEST('transport/get_all_transports',{},true,true).then((response) => {
      let resp = response.data.response;
      setTransportList(resp);
    });
  }
  const handleSavePaymentType = () =>{
    let update_idx = 0;
    let update_id = 0;
    if(props.payment_typeValue && props.payment_typeValue.is_edit && props.payment_typeValue.update_id>=0){
      update_idx = props.payment_typeValue.update_id;
      update_id = props.payment_typeValue.id;
      dispatch(handleUpdatePaymentType(update_idx,payment_typeName));
    }
    
    APP_HTTP.REQUEST('settings/create_payment_type',{id:update_id,name:payment_typeName,class_fee:class_fee,global_fee:global_fee,fee_duration:fee_duration,link_month:link_month,link_day:link_day,link_to:link_to,is_salary:is_salary,is_transport_fee:is_transport_fee,is_hostel_fee:is_hostel_fee,is_uniform_fee:is_uniform_fee,is_tuition_fee:is_tuition_fee,transport_list:transport_list,class_list:class_list,is_demand_bill:is_demand_bill},true,true).then((response) => {
      let resp = response.data;
      let id = resp.id;
      if(update_id===0){
        payment_typeItem.id = parseInt(id);
        dispatch(handleSavePaymentTypeToList(payment_typeItem));
      }
      window.location.reload();
    });
    handleClose();
  }
  const handleSetPaymentTypeName = (e) =>{
    let thisvalue = e.currentTarget.value;
    let clsitem = payment_typeItem;
    clsitem.name = thisvalue;
    setPaymentTypeItem(clsitem);
    setPaymentTypeName(thisvalue);
  }
  const handleSetGlobalFee = (e) =>{
    let thisvalue = e.currentTarget.value;
    let clsitem = payment_typeItem;
    clsitem.global_fee = thisvalue;
    setPaymentTypeItem(clsitem);
    setGlobalFee(thisvalue);
  }
  const handleChangeAmount = (id,value) =>{
      class_fee[id] = value;
      setClassFee(class_fee);
  }
  const handleChangeCB = (e,key) =>{
    setIsSalary(e.target.checked)
  }
  const handleChangeTransportCB = (e) =>{
    let ischecked = e.target.checked;
    setIsTransport(ischecked);
    if(ischecked===true){
      setIsHostel(false);
      setIsUniform(false);
      setIsTuitionFee(false);
    }
  }
  const handleChangeTutionCB = (e) =>{
    let ischecked = e.target.checked;
    setIsTuitionFee(ischecked);
    if(ischecked===true){
      setIsTransport(false);
      setIsHostel(false);
      setIsUniform(false);
    }
  }
  const handleChangeHostelCB = (e) =>{
    let ischecked = e.target.checked;
    setIsHostel(ischecked);
    if(ischecked===true){
      setIsTransport(false);
      setIsUniform(false);
      setIsTuitionFee(false);
    }
  }
  const handleChangeUniformCB = (e) =>{
    let ischecked = e.target.checked;
    setIsUniform(ischecked);
    if(ischecked===true){
      setIsTransport(false);
      setIsHostel(false);
      setIsTuitionFee(false);
    }
  }
  const handleChangeTransportAmount = (index,value) =>{
    let tr_li = [...transport_list];
    tr_li[index].fee = value;
    setTransportList(tr_li);
  }
  const handleCheckSection = (class_index,section_idx,is_checked) =>{
      let cl_list = [...all_class_list];
      cl_list[class_index]['sections'][section_idx].is_fee = is_checked;
      setAllClassList(cl_list);
      dispatch(handleSetClassList(cl_list));
  }
  const handleAttachInDemandBill = (is_checked)=>{
    setAttachInDemandBill(is_checked);
  }
  return (
      <Dialog open={is_open_add_payment_type} onClose={handleClose}>
        <DialogTitle>Create Fee Structure</DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="payment_type_name"
              label="Enter Fee Title"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleSetPaymentTypeName}
              value={payment_typeName}
              size='small'
            />
            {(link_to==='student') &&
              <FormControlLabel control={<Checkbox color="primary" defaultChecked={is_demand_bill} onChange={(e)=>handleAttachInDemandBill(e.target.checked)}/>} label={'ATTACH IN DEMAND BILL'}/> 
            }
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{marginTop:'8px'}}>
            {(props.payment_typeValue && props.payment_typeValue.is_edit===false) &&
              <AppDropDown label="Link To" list_item={[{key:'student',value:'Student'},{key:'staff',value:'Staff'},{key:'other',value:'Other'}]} size="small" handleChangeData={handleChangeLinkTo} defaultValue={link_to} id='link_to'/>
            }
            {(props.payment_typeValue && props.payment_typeValue.is_edit===true && link_to!=='') &&
              <AppDropDown label="Link To" list_item={[{key:'student',value:'Student'},{key:'staff',value:'Staff'},{key:'other',value:'Other'}]} size="small" handleChangeData={handleChangeLinkTo} defaultValue={link_to} id='link_to'/>
            }
            {(props.payment_typeValue && props.payment_typeValue.is_edit===true && link_to==='') &&
              <AppDropDown label="Link To" list_item={[{key:'student',value:'Student'},{key:'staff',value:'Staff'},{key:'other',value:'Other'}]} size="small" handleChangeData={handleChangeLinkTo} defaultValue={link_to} id='link_to'/>
            }
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            {(link_to==='student') &&
              <Box style={{display:'flex', flexDirection:'row'}}>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">SELECT FEE TYPE</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel value={is_transport_fee} control={<Radio onChange={(e)=>handleChangeTransportCB(e)} color='success' sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 25,
                        },
                      }}/>} label={'Is Transport Fee?'} checked={(is_transport_fee===true)?true:false}/>

                      <FormControlLabel value={is_tuition_fee} control={<Radio onChange={(e)=>handleChangeTutionCB(e)} color='success' sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 25,
                        },
                      }}/>} label={'Is Tuition Fee?'} checked={(is_tuition_fee===true)?true:false}/>

                      <FormControlLabel value={is_hostel_fee} control={<Radio onChange={(e)=>handleChangeHostelCB(e)} color='success' sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 25,
                        },
                      }}/>} label={'Is Hostel Fee?'} checked={(is_hostel_fee===true)?true:false}/>

                      <FormControlLabel value={is_uniform_fee} control={<Radio onChange={(e)=>handleChangeUniformCB(e)} color='success' sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 25,
                        },
                      }}/>} label={'Is Uniform Fee?'} checked={(is_uniform_fee===true)?true:false}/>
                       
                      
                    </RadioGroup>
                  </FormControl>
{/* 
                <AppCheckbox label="is Transport Fee?" cb_key='is_transport_fee' value={is_transport_fee} checked={(is_transport_fee===true)?true:false}  handleChangeCB={handleChangeTransportCB}/>
                <AppCheckbox label="is Hostel Fee?" cb_key='is_hostel_fee' value={is_hostel_fee} checked={(is_hostel_fee===true)?true:false}  handleChangeCB={handleChangeHostelCB}/> */}
              </Box>
            }
            {(link_to==='staff') &&
              <AppCheckbox label="is Salary?" cb_key='is_salary' value={is_salary} checked={(is_salary===true)?true:false}  handleChangeCB={handleChangeCB}/>
            }
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {(props.payment_typeValue && props.payment_typeValue.is_edit===false) &&
                <AppDropDown label="Fee Duration" list_item={[{key:'monthly',value:'Monthly'},{key:'yearly',value:'Yearly'},{key:'expense',value:'Expense'}]} size="small" handleChangeData={handleChangeFeeDuration} defaultValue={fee_duration} id='fee_duration'/>
              }
              {(props.payment_typeValue && props.payment_typeValue.is_edit===true && fee_duration!=='') &&
                <AppDropDown label="Fee Duration" list_item={[{key:'monthly',value:'Monthly'},{key:'yearly',value:'Yearly'},{key:'expense',value:'Expense'}]} size="small" handleChangeData={handleChangeFeeDuration} defaultValue={fee_duration} id='fee_duration'/>
              }
              {(props.payment_typeValue && props.payment_typeValue.is_edit===true && fee_duration==='') &&
                <AppDropDown label="Fee Duration" list_item={[{key:'monthly',value:'Monthly'},{key:'yearly',value:'Yearly'},{key:'expense',value:'Expense'}]} size="small" handleChangeData={handleChangeFeeDuration} defaultValue={fee_duration} id='fee_duration'/>
              }
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            {(props.payment_typeValue && props.payment_typeValue.is_edit===false) &&
              <>
                {(fee_duration==='monthly') &&
                  <AppDropDown label="Link Day Of Every Month" list_item={day_of_months} size="small" handleChangeData={handleChangeLinkDay} defaultValue={link_day} id='link_day'/>
                }
              </>
            }
            {(props.payment_typeValue && props.payment_typeValue.is_edit===true && link_day!=='') &&
              <>
                {(fee_duration==='monthly') &&
                  <AppDropDown label="Link Day Of Every Month" list_item={day_of_months} size="small" handleChangeData={handleChangeLinkDay} defaultValue={link_day} id='link_day'/>
                }
              </>
            }
            {(props.payment_typeValue && props.payment_typeValue.is_edit===true && link_day==='') &&
              <>
                {(fee_duration==='monthly') &&
                  <AppDropDown label="Link Day Of Every Month" list_item={day_of_months} size="small" handleChangeData={handleChangeLinkDay} defaultValue={link_day} id='link_day'/>
                }
              </>
            }
            {(props.payment_typeValue && props.payment_typeValue.is_edit===true && link_month==='none') &&
              <>
              {(fee_duration==='yearly') &&
                <AppDropDown label="Link a Month" list_item={link_months} size="small" handleChangeData={handleChangeLinkMonth} defaultValue={link_month} id='link_month'/>
              }
              </>
            }
            {(props.payment_typeValue && props.payment_typeValue.is_edit===true && link_month!=='none') &&
              <>
              {(fee_duration==='yearly') &&
                <AppDropDown label="Link a Month" list_item={link_months} size="small" handleChangeData={handleChangeLinkMonth} defaultValue={link_month} id='link_month'/>
              }
              </>
            }
            {(props.payment_typeValue && props.payment_typeValue.is_edit===false) &&
              <>
              {(fee_duration==='yearly') &&
                <AppDropDown label="Link a Month" list_item={link_months} size="small" handleChangeData={handleChangeLinkMonth} defaultValue={link_month} id='link_month'/>
              }
              </>
            }
            </Grid>
            {(link_to==='student') &&
            <>
            {(is_transport_fee===true) &&
            <Grid item xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
              <Table  aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ROUTE INFO</StyledTableCell>
                    <StyledTableCell>FEE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                
                    {transport_list && transport_list.map((row,index) => (
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {row.value}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <TextField size='small' defaultValue={row.fee} onChange={(event)=>handleChangeTransportAmount(index,event.target.value)}/>
                          </StyledTableCell>
                        </StyledTableRow>
                      
                    ))}
                  
                </TableBody>
              </Table>
              </TableContainer>
            </Grid>
            }
            
            {(is_transport_fee===false && is_uniform_fee===false) &&
            <>
            <Grid item xs={12} sm={12} md={12}>
            <TextField
              size='small'
              margin="dense"
              id="global_fee"
              label="Fee"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleSetGlobalFee}
              value={global_fee}
            />
            </Grid>
            {(is_hostel_fee===false && is_uniform_fee===false) &&
            <>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant='h6' style={{marginTop:'10px'}}>Class Fee Mapping</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
              <Table  aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>CLASS</StyledTableCell>
                    <StyledTableCell>FEE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(isShowClassList===true) &&
                    <>
                      {class_list && class_list.map((row,index) => (
                        
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <TextField size='small' defaultValue={(class_fee[row.id])?class_fee[row.id]:''} onChange={(event)=>handleChangeAmount(row.id,event.target.value)}/>
                              <br />
                              {(is_college===true) &&
                                <>
                                  {(row.sections.map((sec_list, idx)=>{
                                      return(
                                        <FormControlLabel
                                          control={<Checkbox value={sec_list.id} color="primary" defaultChecked={(sec_list.is_fee===undefined)?false:sec_list.is_fee} onChange={(e)=>handleCheckSection(index,idx,e.target.checked)}/>}
                                          label={sec_list.name}
                                        /> 
                                      )
                                  }))}
                                </>
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                        
                      ))}
                    </>
                  }
                </TableBody>
              </Table>
              </TableContainer>
            </Grid>
            </>
            }
            </>
            }
           
            </>
          }
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSavePaymentType} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreatePaymentType;