import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddExamType,handleSaveExamTypeToList,handleUpdateExamType } from './Action';
import APP_HTTP from '../../../APP_HTTP';
let initialState = {id:0,name:'',is_edit:false};
const CreateExamType = (props)=> {
  const [examTypeItem,setExamTypeItem] = React.useState(initialState);
  const [examTypeName,setExamTypeName] = React.useState('');
  const state = useSelector((state) => state);
  const is_open_add_exam_type = state.is_open_add_exam_type;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(handleOpenAddExamType(false));
  };
  React.useEffect(() => {
      let exam_typeValue = props.exam_typeValue;
      setExamTypeItem(exam_typeValue);
      setExamTypeName(exam_typeValue.name);
  },[props]);
  const handleSaveExamType = () =>{
    let update_idx = 0;
    let update_id = 0;
    if(props.exam_typeValue && props.exam_typeValue.is_edit && props.exam_typeValue.update_id>=0){
      update_idx = props.exam_typeValue.update_id;
      update_id = props.exam_typeValue.id;
      dispatch(handleUpdateExamType(update_idx,examTypeName));
    }
    APP_HTTP.REQUEST('settings/create_exam_type',{id:update_id,name:examTypeName},true,true).then((response) => {
      let resp = response.data;
      let id = resp.id;
      if(update_id===0){
        examTypeItem.id = parseInt(id);
        dispatch(handleSaveExamTypeToList(examTypeItem));
      }
    });
    handleClose();
  }
  const handleSetExamTypeName = (e) =>{
    let thisvalue = e.currentTarget.value;
    let clsitem = examTypeItem;
    clsitem.name = thisvalue;
    setExamTypeItem(clsitem);
    setExamTypeName(thisvalue);
  }
  return (
      <Dialog open={is_open_add_exam_type} onClose={handleClose}>
        <DialogTitle>Create Exam Type</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="exam_type_name"
            label="Enter Exam Type Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetExamTypeName}
            value={examTypeName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSaveExamType} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreateExamType;