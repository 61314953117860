import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AddCircleOutline, ArrowRightAlt, Close, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, InputLabel, MenuItem, NativeSelect, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import LoaderButton from '../../LoaderButton.jsx';
import BasicDatePicker from '../../DatePicker.jsx';
import AppCheckbox from '../../AppCheckbox.jsx';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
[`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
},
[`&.${tableCellClasses.body}`]: {
    fontSize: 14,
},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
'&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
},
// hide last border
'&:last-child td, &:last-child th': {
    border: 0,
},
}));

const SchoolUniform = (props) => {
    const [uniform_list,setUniformList] = React.useState([{uniform_id:0,uniform_name:'',uniform_size:'',fee:''}]);
    const [isSaving, setIsSaving] = React.useState(false);
    const state = useSelector((state) => state);
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            get_uniforms();
        }
    
    },[dispatch])
   const get_uniforms = () =>{
        APP_HTTP.REQUEST('settings/get_uniform',{},true,true).then((response) => {
            let resp = response.data;
            let data = resp.response;
            if(data.length>0){
                setUniformList(data);
            }
        });
   }
  
  
    const handleAddMore = () =>{
        let uli = [...uniform_list];
        let ul = {uniform_id:0,uniform_name:'',uniform_size:'',fee:''};
        uli.push(ul);
        setUniformList(uli);
    }
   
    const handleRemoveItem = (index) =>{
        let uli = [...uniform_list];
        uli.splice(index,1)
        setUniformList(uli);
    }
    const handleChangeUniform = (type,value,index)=>{
        let uli = [...uniform_list];
        uli[index][type] = value;
        setUniformList(uli);
    }
    const handleSaveUniform = () =>{
        setIsSaving(true);
        APP_HTTP.REQUEST('settings/save_uniform',{uniform_list:uniform_list},true,true).then((response) => {
            setIsSaving(false);
        });
    }
  return (
    <>
    
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Uniform
                    </Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <TableContainer component={Paper}>
                                <Table  aria-label="customized table">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>
                                    {(uniform_list.map((value,index)=>{
                                    return(
                                        <StyledTableRow style={{background:'#fff'}}>
                                            <StyledTableCell>
                                            <TextField
                                                margin="dense"
                                                id="uniform_name"
                                                label="Enter Uniform Name"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={value.uniform_name}
                                                size="small"
                                                onChange = {(e) => handleChangeUniform('uniform_name',e.target.value,index)}
                                            />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                            <TextField
                                            size="small"
                                                margin="dense"
                                                id="uniform_size"
                                                label="Enter Uniform Size"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={value.uniform_size}
                                                onChange = {(e) => handleChangeUniform('uniform_size',e.target.value,index)}
                                            />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                            <TextField
                                            size="small"
                                                margin="dense"
                                                id="uniform_fee"
                                                label="Enter Uniform Fee"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={value.fee}
                                                onChange = {(e) => handleChangeUniform('fee',e.target.value,index)}
                                            />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                            {(uniform_list.length>0) &&
                                                <Close sx={{position:'absolute',top:20}} onClick={()=>handleRemoveItem(index)}/>
                                            }
                                            {((uniform_list.length-1)===index) &&
                                                <Button onClick={handleAddMore}>Add More</Button>
                                            }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        )
                                    }))}
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={4} style={{textAlign:'right'}}>
                                            {(isSaving===true) &&
                                                <LoaderButton variant="outlined"/>
                                            }
                                            {(isSaving===false) &&
                                                <Button variant="outlined" onClick={handleSaveUniform}>
                                                    Save Uniform
                                                </Button>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </>
  );
}

export default SchoolUniform;