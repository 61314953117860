import * as React from 'react';
import APP_HTTP from '../APP_HTTP';
const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
let currentDate = `${day}-${month}-${year}`;
const PrintFooter = (props)=> {
  const initialized = React.useRef(false);
  const [authorized_signature,setAuthorizedSignature] = React.useState('');
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleGetAuthorizedSignature();
        }
    },[])
    const handleGetAuthorizedSignature = () =>{
      let auth_sign = localStorage.getItem('auth_sign');
      if(auth_sign!==null && auth_sign!==""){
        setAuthorizedSignature(auth_sign);
      }else{
        APP_HTTP.REQUEST('settings/get_option',{name:'authorized_signature'},true,true).then((response) => {
            let resp = response.data.response;
            if(resp.value && resp.value.authorized_signature){
                setAuthorizedSignature(resp.value.authorized_signature);
                localStorage.setItem('auth_sign',resp.value.authorized_signature)
            }
        });
      }
    }
  return (
    <tfoot>
        {((props.type==='payment' || props.type==='demand_bill') && (props.info!==undefined && props.info.print_footer_text!==undefined && props.info.print_footer_text.payment!==undefined && props.info.print_footer_text.payment!=="")) &&
        <tr>
            <td  colSpan={(props.colSpan)?props.colSpan:''}>
              <h4 style={{fontWeight:'bold',fontSize:'15px',lineHeight:'1.7'}}  dangerouslySetInnerHTML={{__html: props.info.print_footer_text.payment}}></h4>
            </td>
        </tr>
        }
        {((props.type==='hall_ticket') && (props.info!==undefined && props.info.print_footer_text!==undefined && props.info.print_footer_text.hall_ticket!==undefined && props.info.print_footer_text.hall_ticket!=="")) &&
        <tr>
            <td  colSpan={(props.colSpan)?props.colSpan:''}>
              <h4 style={{fontWeight:'bold',fontSize:'15px',lineHeight:'1.7'}}  dangerouslySetInnerHTML={{__html: props.info.print_footer_text.hall_ticket}}></h4>
            </td>
        </tr>
        }
        {((props.type==='report_card') && (props.info!==undefined && props.info.print_footer_text!==undefined && props.info.print_footer_text.report_card!==undefined && props.info.print_footer_text.report_card!=="")) &&
        <tr>
            <td  colSpan={(props.colSpan)?props.colSpan:''}>
              <h4 style={{fontWeight:'bold',fontSize:'15px',lineHeight:'1.7'}}  dangerouslySetInnerHTML={{__html: props.info.print_footer_text.report_card}}></h4>
            </td>
        </tr>
        }
        {((props.type==='scheduled_exam') && (props.info!==undefined && props.info.print_footer_text!==undefined && props.info.print_footer_text.scheduled_exam!==undefined && props.info.print_footer_text.scheduled_exam!=="")) &&
        <tr>
            <td  colSpan={(props.colSpan)?props.colSpan:''}>
              <h4 style={{fontWeight:'bold',fontSize:'15px',lineHeight:'1.7'}}  dangerouslySetInnerHTML={{__html: props.info.print_footer_text.scheduled_exam}}></h4>
            </td>
        </tr>
        }
        <tr>
           {/*  <td style={{borderRight:'none',paddingTop:'30px',textAlign:'center'}}>
              <h4 style={{fontWeight:'bold',fontSize:'15px'}}>
                <i>{currentDate}</i>
              </h4>
              <h4 style={{fontWeight:'bold',fontSize:'15px'}}>Date</h4>
            </td> */}
            <td colSpan={(props.colSpan)?props.colSpan:''} style={{textAlign:'right',borderLeft:(props.target==='payment')?'none':'1px solid #333',paddingTop:(authorized_signature==="")?'50px':''}}>
                {(authorized_signature!=="") &&
                  <img src={authorized_signature+'?time='+Math.random(1111,9999)} alt="Image" height={30} width={150} style={{marginRight:'15px'}}/>
                }
                <h4 style={{fontWeight:'bold',fontSize:'15px'}}>Authorized Signature</h4>
            </td>
        </tr>
    </tfoot>
  );
}
export default PrintFooter;