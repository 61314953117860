import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
import Watermark from '../WaterMark';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const PrintHallTicketFormat2 = (props)=> {
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(props.data){
                let data = props.data;
                
                setTimeout(() => {
                    handleAllocatedHallPrint();
                }, 200);
            }
        }
       
    },[props])
   const handleAllocatedHallPrint = ()=>{
    if(document.getElementById("hallTicketPrintBlock")){
        var divContents = document.getElementById("hallTicketPrintBlock").innerHTML; 

      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
     
          a.document.write('<style type="text/css"><style type="text/css">@page{size:"A4";margin:5cm}table{font-family:verdana, arial, sans-serif;font-size:16px;font-weight:bold;width:100%;margin-bottom:1rem;color:#333;border-collapse:collapse}table td,table th{padding:5px;vertical-align:top;border:1px solid black}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
          a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
          a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');  
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="hallTicketPrintBlock" style={{display:'none'}}>
        {(props.data && props.data.studentData && props.data.studentData.map((value,index)=>{
            return (
                <div style={{position:'relative'}}>
                <Watermark />
                <table style={{pageBreakAfter:'always'}}>
                    <tr>
                        <td colSpan={2} style={{textAlign:'center'}}>
                            <h4 style={{fontSize:'20px',fontWeight:'bold'}}>
                                ADMIT CARD
                            </h4>
                        </td>
                    </tr>
                <PrintHeader header_data={props.data.school_info} colSpan={2}/>      
                <tbody>
                <tr>
                    <th style={{borderRight:'none',width:'90px',textAlign:'left'}}>
                        <img src={value.photo} alt={value.student_name} height={'60'}/>
                    </th>
                    <th style={{textAlign:'left',borderLeft:'none'}} colSpan={2}>
                        <h4 style={{fontSize:'16px',fontWeight:'bold',textTransform:'uppercase'}}>
                        Student Name : {value.student_name}({value.father_name})
                        </h4>
                        {(value) &&
                        <>
                            <h4 style={{fontSize:'12px',textTransform:'uppercase'}}>{value.roll_number}/{value.class}{(value.section!=="")?'('+value.section+')':''}</h4>
                            <h4 style={{fontSize:'16px',textTransform:'uppercase'}}>Exam Name : {props.data.exam_type_name}</h4>
                        </>
                        }
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} style={{padding:'0px'}}>
                    <table style={{margin:'0px'}}>
                    <tbody>
                        <tr>
                            <th style={{textAlign:'left'}} colSpan={(props.data.printData.dates.length+1)}><h2 style={{margin:'0px',textAlign:'center',fontSize:'16px'}}>Exam Time Table</h2></th>
                        </tr>    
                        <tr>
                            <th style={{textAlign:'left'}} colSpan={(props.data.printData.dates.length+1)}>
                                <h2 style={{margin:'0px',textAlign:'center',fontSize:'16px'}}>
                                    {props.data.examDate}
                                </h2>
                            </th>
                        </tr>
                                
                        <tr>
                            <th>
                                <p style={{fontSize:'14px',margin:'0px'}}>Date</p>
                                <p style={{margin:'0px'}}>Day</p>
                            </th>
                            {(props.data.printData.dates.map((value,index)=>{
                                return(
                                    <td style={{textAlign:'center'}}>
                                        <p style={{fontSize:'14px',margin:'0px'}}>{value.date}</p>
                                        <p style={{margin:'5px 0px 0px 0px'}}>{value.day}</p>
                                    </td>
                                )
                            }))}
                        </tr>   
                        {(props.data.printData.setting_count.map((value,index)=>{
                            return(
                                <tr>
                                    <th>
                                        Sitting-{value+1}
                                    </th>
                                    {(props.data.printData.dates.map((date,index)=>{
                                        return(
                                            <td  style={{textAlign:'center'}}>
                                                
                                               {(props.data.printData.sittings[date.date]!==undefined && props.data.printData.sittings[date.date][value]!==undefined && props.data.printData.sittings[date.date][value].name!==undefined) &&
                                               <>
                                                    <p style={{fontSize:'14px',margin:'0px'}}>{props.data.printData.sittings[date.date][value].name}</p>
                                                    <p style={{margin:'5px 0px 0px 0px'}}>{props.data.printData.sittings[date.date][value].exam_time}</p>
                                               </>
                                                }
                                                {(props.data.printData.sittings[date.date]!==undefined && props.data.printData.sittings[date.date][value]!==undefined && props.data.printData.sittings[date.date][value].name===undefined) &&
                                                    <p style={{margin:'0px'}}>-</p>
                                                }
                                            </td>
                                        )
                                    }))}
                                </tr>
                            )
                        }))}
                    </tbody>
                    
                </table>
                    </td>
                </tr>
                </tbody>
                <PrintFooter colSpan={2} type="hall_ticket" info={props.data.school_info}/>
            </table>
            </div>
            )
        }))}
       
        
    </div>
  );
}
export default PrintHallTicketFormat2;